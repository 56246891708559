import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { translations } from "../../../../lib/Language";
import api from "../../../../lib/api";
import LoginField from "../../login/_components/LoginField";
import LoadingBars from "../../../_components/LoadingBars";
import { GreenButton } from "../../../_components/GreenButton";
import DownIcon from "../../../_components/icons/DownIcon";

const regexEmailValidation = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/

const EditProfile = ({ user, authenticity_token, lang , t}) => {
  const countries = translations[lang].general.country;
  const [loading, setLoading] = useState(false);
  const [passwordForm, setPasswordForm] = useState(false)
  const [passwordInput, setPasswordInput] = useState("")
  const [uniqueFormKey, setUniqueFormKey] = useState(`form_${Date.now()}`) //use for reset form

  const initialCountries = [{value: "ES", option: t.form_country_spain}, {value: "CO", option: t.form_country_colombia}, {value: "MX", option: t.form_country_mexico}, {value: "US", option: t.form_country_unitedStates}, {value: "FR", option: t.form_country_france}]

  const countryOptions = Object.entries(countries).map(country=>({value:country[0], option: country[1]}));

  const callError = (msg) => {
    alert(msg);
    setLoading(false);
  };

  const validateEmail = (data) => {
    if (!regexEmailValidation.test(data.email)) { 
      callError(t.invalid_email); 
      return false; 
    }
    return true;
  }
  
  const validatePassword = (data) => {
    if (data.password_confirmation !== data.password) {
      callError(t.no_match);
      return false;
    }
    return true;
  };

  const processResponse = (res) => {
    if (!res.success || res.success === false) {
      setLoading(false);
      callError(t[res.response]);
    } else {
      //   window.location = "/";
      window.location.reload();
    }
  };

  const update = (data) => {
    const information = {
      user: { ...data },
      authenticity_token,
    };
    api.update(information, processResponse, user.id);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const formData = new FormData(e.target);
    const formProps = Object.fromEntries(formData);
    if (passwordForm) {
      if (!validatePassword(formProps)) return;
    }else{
      if (!validateEmail(formProps)) return;
    }
    /* After Validations */
    setLoading(true);
    /* API CALLS */
    update(formProps);
  };

  useEffect(() => {
    setUniqueFormKey(`form_${Date.now()}`)
  }, [passwordForm])

  return (
    <>
      <EditProfileForm onSubmit={(e)=>handleSubmit(e)} key={uniqueFormKey}>
        <PasswordField>
          <LoginField name="current_password" type="password" label={t.label_information} value={passwordInput} onChange={(e)=>setPasswordInput(e.target.value)} autoComplete={"password"} required/>
        </PasswordField>
        {!passwordForm ?
        <>
          <LoginField name="first_name" type="text" label={t.form_name} value={user.first_name} autoComplete={"given-name"} required/>
          <LoginField name="last_name" type="text" label={t.form_lastName} value={user.last_name} autoComplete={"family-name"} required/>
          <LoginField name="country" type="select" label={t.form_country} value={user.country} initialOptions={initialCountries} options={countryOptions} include_blank required/>
          <LoginField name="post" type="text" label={t.form_post} value={user.post} autoComplete={"organization-title"} required/>
          <LoginField name="email" type="email" label={t.form_email} value={user.email} autoComplete={"email"} required/>
          <LoginField name="phone" type="phone" label={t.form_mobile} value={user.phone} autoComplete={"tel"} required/>
        </>
        :
        <>
          <LoginField name="password" type="password" label={t.form_newPassword} value={user.password} minLength={8} autoComplete={"new-password"} required/>
          <LoginField name="password_confirmation" type="password" label={t.form_confirm_password} value={user.password_confirmation} minLength={8} autoComplete={"new-password"} required/>
        </>}

        {passwordForm ? <ButtonCont onClick={()=>setPasswordForm(false)}><DownIcon backButton/></ButtonCont> : <ButtonCont><a onClick={()=>setPasswordForm(true)}>{t.label_newPassword}</a></ButtonCont>}
          
        <SigninButtonCont>
          {loading ?
            <LoadingBars color={"#fff"}/>
          :
            <GreenButton type="submit" $width={"19.7rem"} $height={"6.2rem"} >{t.form_button}</GreenButton>}
        </SigninButtonCont>
      </EditProfileForm>
    </>
  );
};

const EditProfileForm = styled.form`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  column-gap: 6%;
  a{
    font-family: var(--BarlowCondensed);
    font-size: 2.5rem;
    font-weight: 400;
    cursor: pointer;
  }
  a, label{
    color: white;
  }
`
const PasswordField = styled.div`
  grid-column: 1 / 3;
`

const ButtonCont = styled.div`
  display: flex;
  align-items: center;
  svg{
    cursor: pointer;
  }
`

const SigninButtonCont = styled.div`
  height: 80px;
  display: flex;
  align-items: flex-end;
  justify-content: flex-end;
`

export default EditProfile;