import React, { useState } from 'react'
import styled from "styled-components";
import RoundFlag from '../../_components/RoundFlag';
import starIcon from "../../../images/svg/star_icon.svg"
import peopleIcon from "../../../images/svg/people_community.svg"
import plusIcon from "../../../images/icons/plusIcon.png"
import { LazyLoadImage } from 'react-lazy-load-image-component';

const stagingEnvironment = process.env.REACT_APP_NODE_ENV === 'staging'

const OpportunityCard = ({ opportunity, edit, proposal, suitableMessage }) => {
	const [showIdoneoInfo, setShowIdoneoInfo] = useState(false)

	const regex = /(<([^>]+)>)/ig;
	
	const handleIdoneoIconClick = (e) => {
		e.stopPropagation()
		setShowIdoneoInfo(!showIdoneoInfo)
	}

	return (
		<Card onClick={()=>window.location = `${stagingEnvironment?"/marketplace":""}/${proposal ? "proposals" : "opportunities"}/${opportunity.id}${edit?"/edit":""}`}>
			{opportunity.idoneo&&<StarIcon src={starIcon} onMouseEnter={()=>setShowIdoneoInfo(true)} onMouseLeave={()=>setShowIdoneoInfo(false)} onClick={handleIdoneoIconClick}/>}
			<CardImageCont>
				<LazyLoadImage src={opportunity.image} alt="opportunity image" effect="blur" width="100%" height="100%" style={{overflow: "hidden", display: "flex", objectFit: "cover"}}/>
			</CardImageCont>
			<CardInfoCont className="fichaOpportunity_cont">
				<CardSector $noVisible={opportunity.sector === "N/A"}>{opportunity?.sector !== null ? `${opportunity?.sector.split(" - ")[0]}: ${opportunity?.sector.split(" - ")[1]}`: "N/A"}</CardSector>
				<CardTitle>{opportunity.title}</CardTitle>
				<CardDescription>{opportunity?.description !== null && (opportunity?.description.replace(/<br>/gi, "").substring(0, 150) + "...").replace(regex, '')}</CardDescription>
				<CardBeneficiary>
					<img src={peopleIcon} alt="people comunity" />
					<div>Beneficiarios: {opportunity.nb_affected}</div>
				</CardBeneficiary>
				<CardInfoIcons>
					<RoundFlag id={opportunity.country}/>
					{opportunity.plus&&<img className="fichaOpportunity_plusImg" src={plusIcon} alt="plus icon" />}
				</CardInfoIcons>
				{showIdoneoInfo && <CardIdoneoInfo onClick={handleIdoneoIconClick}>{suitableMessage}</CardIdoneoInfo>}
			</CardInfoCont>
		</Card>
	)
}

export default OpportunityCard

//Styles
const Card = styled.div`
  display: flex;
	position: relative;
	flex-direction: column;
	width: 36.7rem;
	height: 67.8rem;
	cursor: pointer;
	font-family: var(--Roboto);

	@media (max-width: 768px) {
		width: 234px;
		height: 433px;
	}
`

const CardImageCont = styled.div`
	height: 36.6rem;

	@media (max-width: 768px) {
		height: 234px;
	}
`

const StarIcon = styled.img`//Manejar Info con Hover
	position: absolute;
	top: 2%;
  right: 4%;
	width: 5.4rem;
	height: 5.4rem;

	@media (max-width: 768px) {
		width: 35px;
		height: 35px;
	}
`

const CardInfoCont = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  background-color: var(--grey1);
	color: var(--grey2);
  padding: 7%;
  overflow-y: auto;
  text-align: center;
  position: relative;
`
const CardSector = styled.div`
	visibility: ${({$noVisible})=>$noVisible?"hidden":""};
  display: flex;
  align-items: center;
  justify-content: center;
	font-family: var(--BarlowCondensed);
  font-size: 3rem;
  font-weight: 400;
  color: var(--grey2);
  text-align: center;
	text-transform: uppercase;
	@media (max-width: 768px) {
		font-size: 18px;
	}
`
const CardTitle = styled.h3`
	font-size: 2.5rem;
  font-weight: 400;
	margin: 1rem;
	@media (max-width: 768px) {
		font-size: 16px;
	}
`
const CardDescription = styled.div`
	font-size: 1.5rem;
	font-weight: 300;
	@media (max-width: 768px) {
		font-size: 10px;
	}
`
const CardBeneficiary = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 5% 0;
	font-size: 2.1rem;
	font-weight: 600;

	img{
		width: 3.5rem;
		margin: 0 4% 0 0;
		@media (max-width: 768px) {
			width: 22px;
			font-size: 14px;
		}
	}
`

const CardInfoIcons = styled.div`
  display: flex;
  justify-content: space-evenly;
  margin: 0 0 5%;
  img{
    max-width: 10.8rem;
    max-height: 4rem;
  }
	@media (max-width: 768px) {
		img{
			max-width: 70px;
    	max-height: 23px;
		}
	}
`
const CardIdoneoInfo = styled.div`
  position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	display: flex;
	background-color: var(--grey1);
	z-index: 1;
	padding: 6%;
	font-size: 1.8rem;
	font-weight: 300;
	transition: all .7s;
	align-items: center;
	@media (max-width: 768px) {
		font-size: 14px;
	}
`