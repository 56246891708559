// /* params= {title="" children=Children close=func(opt)} */
import React, { useState, useEffect } from 'react'
import styled from 'styled-components'
import CloseIcon from '../../_components/icons/CloseIcon'

const Modal = ({ title, children, close, width, height, initialOpen = true, dontCloseModal }) => {
  const [open, setOpen] = useState(initialOpen)
  
  const closeIt = () =>{
    close&&close()
    setOpen(false)
  }

  useEffect(() => setOpen(initialOpen), [initialOpen])

  useEffect(() => {
    if (!dontCloseModal) {
      const keyEsc = (e) => {
        if (e.key == "Escape") {
          closeIt()
        }
      }
      if (open) {
        document.addEventListener('keyup', keyEsc)
      }
      else {
      }
      return () => {
        document.removeEventListener('keyup', keyEsc)
      }
    }
  }, [open])

  return (
    <ModalView $open={open}>
      <ModalBg id='modalBg' onClick={!dontCloseModal && closeIt}/>
      <ModalContainer style={{width:width, height:height}}>
        <ModalContent>
          {title&&
          <ModalTitle>
            {title}
          </ModalTitle>}
          <CloseButton onClick={!dontCloseModal && closeIt}><CloseIcon/></CloseButton>
          <ModalBody>{children}</ModalBody>
        </ModalContent>
      </ModalContainer>
    </ModalView>
  )
}

const ModalView = styled.div`
  position: fixed;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  width: 100vw;
  height: 100vh;
  z-index: 999996;
  text-align: center;
  border: none;
  background-color: #ffffff00;
  padding: 0;
  display: ${props => props.$open ? 'flex' : 'none'};
`
const ModalBg = styled.div`
  position: fixed;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  width: 100vw;
  height: 100vh;
  background-color: #D9D9D999;
  z-index: 999997;
`

const ModalContainer = styled.div`
  position: fixed;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  width: 112.8rem;
  height: 68.8rem;
  max-height: 100%;
  max-width: 100%;
  z-index: 999998;
  overflow: hidden;
  background-color: var(--grey5);
  display: flex;
  align-items: center;
  justify-content: center;
`
const ModalContent = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  gap: 5%;
  padding: 5%;
`
const ModalTitle = styled.div`
  font-family: var(--BarlowCondensed);
  font-size: 3rem;
  font-weight: 500;
  color: white;
  text-transform: uppercase;
`
const CloseButton = styled.div`
  position: absolute;
  top: 1rem;
  right: 1rem;
  cursor: pointer;
`
const ModalBody = styled.div`
  flex: 1;
  overflow: auto;
`

export default Modal