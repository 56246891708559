import React, { useState } from "react";
import styled from "styled-components";
import api from "../../../lib/api";
import { ViewContain } from "../../_components/ViewContain";
import { InfoTitle } from "../../_components/InfoTitle";
import LoginField from "./_components/LoginField";
import LoadingBars from "../../_components/LoadingBars";
import { GreenButton } from "../../_components/GreenButton";

// Change for setups
const configuration = {
  invalid_email: "invalid_email",
  password_empty: "password_empty",
  password_short: "password_short",
  no_match: "no_match",
  error_updated: "error_updated",

  password_placeholder: "form_password",
  password_confirmation_placeholder: "form_confirm_password",
  login: "form_button",

  fields: {
    password: "",
    password_confirmation:"",
  },
};

const stagingEnvironment = process.env.REACT_APP_NODE_ENV === 'staging'

const ResetPassword = ({ authenticity_token, t, user}) => {
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState(configuration.fields);

  const callError = (msg) => {
    alert(msg);
    setLoading(false);
  };

  const handleInputs = (e) => {
    let value = e.target.value;
    const key = e.target.name;
    setData((prev) => {
      return { ...prev, [key]: value };
    });
  };

  const validate = () => {
    if (data.password_confirmation !== data.password) {
      callError(t[configuration.no_match]);
      return false;
    }
    return true;
  };

  const processResponse = (res) => {
    if (!res.success || res.success === false) {
      callError(t[res.response]);
    } else {
      window.location = `${stagingEnvironment?"/marketplace":""}/login`;
    }
  };

  const updatePassword = () => {
    const information = {
      email: user.email,
      password: data.password,
      authenticity_token,
    };
    api.updatePassword(information, processResponse);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    /* Validations */
    if (!validate(e)) return;
    /* After Validations */
    setLoading(true);
    /* API CALLS */
    updatePassword();
  };

  return (
    <Reset>
      <ResetContainer>
        <ResetView>
          <ResetForm onSubmit={handleSubmit}>
            <ViewContain>
              <ViewContain $alignItems={"center"} $paddingY={"5%"}>
                <InfoTitle style={{margin: "0", fontSize: "4rem", fontWeight: "400", color: "var(--black2)"}} $textTransform={"uppercase"}>{t.form_title}</InfoTitle>
              </ViewContain>
              <ViewContain $paddingY={"5%"}>
                <LoginField name="password" type="password" label={t[configuration.password_placeholder]} value={data.password} onChange={handleInputs} minLength={8} required/>
                <LoginField name="password_confirmation" type="password" label={t[configuration.password_confirmation_placeholder]} value={data.password_confirmation} onChange={handleInputs} minLength={8} required/>
                <ViewContain $alignItems={"center"}>
                  <ResetButtonCont>
                    {loading ?
                    <LoadingBars/>
                    :
                    <GreenButton type="submit" $width={"19.7rem"} $height={"4rem"} >{t[configuration.login]}</GreenButton>}
                  </ResetButtonCont>
                </ViewContain>
              </ViewContain>
            </ViewContain>
          </ResetForm>
        </ResetView>
      </ResetContainer>
    </Reset>
  );
};

const Reset = styled.div`
  height: 67rem;
  position: relative;
`
const ResetContainer = styled.div`
  position: absolute;
  top: 0; 
  left: 50%;
  width: 100vw;
  height: 100%;
  overflow: hidden;
  transform: translate(-50%, 0);
  padding: 5% 0;
  background-color: #D9D9D9;
`
const ResetView = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
`
const ResetForm = styled.form`
  max-width: 700px;
  width: 100%;
  margin: auto 10%;
`
const ResetButtonCont = styled.div`
  height: 80px;
  display: flex;
  align-items: center;
`

export default ResetPassword;