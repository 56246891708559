import React from 'react'
import useAppController from '../../_hooks/useAppController'
import { LangContext } from '../../../../lib/Language'
import OrganizationView from '../_components/OrganizationView'
import OrganizationForm from '../_components/OrganizationForm'

const OrganizationViewController = ({ lang, organization, setOrganization }) => {
  const props = useAppController(lang, organization, "organizations", false, setOrganization)
  return <LangContext.Provider value={lang}>
    {props.loading || (props.view === "view" ? <OrganizationView {...props} /> : <OrganizationForm {...props} />)}
  </LangContext.Provider>
}

export default OrganizationViewController