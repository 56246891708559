import React from "react";
export const LangContext = React.createContext("es-es");

export const languageSelector = {
  "en-us": "English",
  "es-es": "Español",
  "fr-fr": "Français",
};

export const translations = {
  //ENGLISH
  "en-us": {
    general: {
      none: "None",
      sector: "Sector",
      session_expired: "Session expired. Log In again, please.",
      country: {
        AF: "Afghanistan",
        AL: "Albania",
        DZ: "Algeria",
        AD: "Andorra",
        AO: "Angola",
        AR: "Argentina",
        AM: "Armenia",
        AZ: "Azerbaijan",
        AI: "Anguilla",
        AG: "Antigua And Barbuda",
        AW: "Aruba",
        AU: "Australia",
        AT: "Austria",
        BS: "Bahamas",
        BH: "Bahrain",
        BD: "Bangladesh",
        BB: "Barbados",
        BT: "Bhutan",
        BW: "Botswana",
        BR: "Brasil",
        BI: "Burundi",
        BY: "Belarus",
        BE: "Belgium",
        BZ: "Belize",
        BJ: "Benin",
        BO: "Bolivia",
        BA: "Bosnia and Herzegovina",
        BG: "Bulgaria",
        BF: "Burkina Faso",
        BRN: "Brunei",
        CM: "Cameroon",
        CA: "Canada",
        CV: "Cape Verde",
        KY: "Cayman Islands",
        CF: "Central African Republic",
        CN: "China",
        CO: "Colombia",
        CG: "Congo",
        CR: "Costa Rica",
        KH: "Cambodia",
        TD: "Chad",
        CL: "Chile",
        CD: "Democratic Republic of the Congo",
        HR: "Croatia",
        CU: "Cuba",
        CY: "Cyprus",
        CZ: "Czech Republic",
        DK: "Denmark",
        DJ: "Djibouti",
        DM: "Dominica",
        DO: "Dominican Republic",
        EC: "Ecuador",
        EG: "Egypt",
        ET: "Ethiopia",
        SV: "El Salvador",
        GQ: "Equatorial Guinea",
        ER: "Eritrea",
        EE: "Estonia",
        FJ: "Fiji",
        FI: "Finland",
        FR: "France",
        GA: "Gabon",
        DE: "Germany",
        GH: "Ghana",
        GR: "Greece",
        GT: "Guatemala",
        GM: "Gambia",
        GE: "Georgia",
        GD: "Grenada",
        GN: "Guinea",
        GW: "Guinea-Bissau",
        GY: "Guyana",
        HT: "Haiti",
        HN: "Honduras",
        HU: "Hungary",
        IS: "Iceland",
        IN: "India",
        ID: "Indonesia",
        IE: "Ireland",
        IT: "Italy",
        IR: "Iran",
        IQ: "Iraq",
        IL: "Israel",
        CIV: "Ivory coast",
        JM: "Jamaica",
        JP: "Japan",
        JO: "Jordan",
        KE: "Kenya",
        KZ: "Kazakhstan",
        KI: "Kiribati",
        KR: "South Korea",
        KW: "Kuwait",
        KG: "Kyrgyzstan",
        LA: "Laos",
        LV: "Latvia",
        LB: "Lebanon",
        LR: "Liberia",
        LY: "Libya",
        LI: "Liechtenstein",
        LT: "Lithuania",
        LU: "Luxembourg",
        MY: "Malaysia",
        MU: "Mauritius",
        MX: "Mexico",
        MZ: "Mozambique",
        MK: "Macedonia",
        MW: "Malawi",
        MV: "Maldives",
        ML: "Mali",
        MT: "Malta",
        MH: "Marshall Islands",
        MR: "Mauritania",
        FM: "Micronesia",
        MD: "Moldova",
        MC: "Monaco",
        MN: "Mongolia",
        ME: "Montenegro",
        MA: "Morocco",
        MMR: "Myanmar",
        NA: "Namibia",
        NL: "Netherlands",
        NG: "Nigeria",
        NP: "Nepal",
        NZ: "New Zealand",
        NI: "Nicaragua",
        NE: "Niger",
        NOR: "Norway",
        OM: "Oman",
        PK: "Pakistan",
        PA: "Panama",
        PH: "Philippines",
        PT: "Portugal",
        PR: "Puerto Rico",
        PY: "Paraguay",
        PE: "Peru",
        PL: "Poland",
        PW: "Palau",
        QA: "Qatar",
        RU: "Russian Federation",
        RO: "Romania",
        RW: "Rwanda",
        SC: "Seychelles",
        SL: "Sierra Leone",
        SG: "Singapore",
        ZA: "South Africa",
        ES: "Spain",
        LK: "Sri Lanka",
        SZ: "Swaziland",
        SE: "Sweden",
        CH: "Switzerland",
        KN: "Saint Kitts and Nevis",
        LC: "Saint Lucia",
        VC: "Saint Vincent and the Grenadines",
        WS: "Samoa",
        SM: "San Marino",
        ST: "Sao Tome and Principe",
        SA: "Saudi Arabia",
        SN: "Senegal",
        RS: "Serbia",
        SK: "Slovakia",
        SI: "Slovenia",
        SB: "Solomon Islands",
        SO: "Somalia",
        SS: "South Sudan",
        SD: "Sudan",
        SR: "Suriname",
        SY: "Syrian",
        TZ: "Tanzania",
        TH: "Thailand",
        TT: "Trinidad And Tobago",
        TJ: "Tajikistan",
        TG: "Togo",
        TO: "Tonga",
        TN: "Tunisia",
        TR: "Turkey",
        TM: "Turkmenistan",
        TV: "Tuvalu",
        UG: "Uganda",
        UA: "Ukraine",
        AE: "United Arab Emirates",
        GB: "United Kingdom",
        US: "United States",
        UY: "Uruguay",
        UZ: "Uzbekistan",
        VU: "Vanuatu",
        VE: "Venezuela",
        VN: "Vietnam",
        YE: "Yemen",
        ZM: "Zambia",
        ZW: "Zimbabwe",
      },
      logoutBtn: "Close session",
      logout: "You have logged out from your session.",
      pais: "Country",
      keywords: "Keywords",
      whatOffer: "What do you offer or need?",
      marketBanner: "Find opportunities that deliver social impact.",
      organizacion: "Organization",
      oportunidades: "Oppotunities",
      proposals: "Proposals",
      communities: "Communities",
      oportunidad: "opportunity",
      solucion: "proposal",
      comunidad: "community",
      back: "Back",
      selected: "Selected",
      sobre: "About Minkadev",
      date: "Date creation",
      select: "Select",
      mailSent: "Mail sent",
      archivos: "Files",
      submittedproposals: "Submitted proposals",
      postProposal: "Submit a Proposal",
      closeOpportunity: "Close opportunity",
      contact: "Contact",
      contactTitle: "CONTACT",
      message: "Message",
      sendMessage: "Send",
      sdg: "SDG",
      message_empty: "The message cannot be empty",
      error_message: "Error sending message",
      success_message: "Message sent successfully",
    },
    login: {
      title: "Login or register",
      login: "Login",
      register: "Register",
      recovery: "Have you forgotten your password?",
      password: "Password",
      confirm: "Confirm password",
      click: "Click here.",
      registerTitle: "Register",
      names: "First names",
      lastNames: "Surnames",
      email: "Email",
      send_mail: "Send email",
      terminos: "I confirm that the information provided is true and responds to the interests and reality of my organization.",
      acept: "I have reviewed and accept the",
      politic: "privacy policy",
      minka: "from Minka-dev",
      close: "[X Close]",
      invalid_email: "Invalid email. Check again.",
      invalid_mail: "There is no user with this email.",
      password_empty: "Enter a password.",
      first_name_empty: "Enter your names",
      last_name_empty: "Enter your surnames",
      no_match: "Paswords don't match.",
      wrong_password: "Wrong password",
      too_short: "Password required 6 characters",
      error_registry: "There was an error. Contact us.",
      already_exists: "User already exists. Try logging in.",
    },
    cuenta: {
      organizacion: "Your Organization",
      opportunities: "Your Oppotunities",
      proposals: "Your Proposals",
      communities: "Community Proposals",
      noTienes: "You don´t have any ",
      proposal: "Proposal",
      community: "Community",
      publicada: "Published?",
      descripcion: "Description",
      fechaCracion: "Creation Date",
      expira: "Expires with the opportunity",
      moreButton: "See more",
      createOpportunity: "Create an opportunity",
      seeOpportunities: "See opportunities",
    },
    home: {
      banner1: "We develop sustainable businesses that create results for change",
      banner2: "We have built our experience in 3 types of business: green, inclusive, and circular economy.",
      impactA: "More than",
      impact1: "communities involved",
      impact2: "sectors generating business opportunities",
      marketplace: "An Online tool to create, identify and connect business opportunities with markets. It depends on the data of the actors in the ecosystem on a given period of time. It contributes to the development of a better structure and guidance",
      resultTitle: "RESULTS MD",
      stat1A: "OPPORTUNITIES",
      stat1B: "created",
      stat2A: "SECTORS",
      stat2B: "developed",
      stat3A: "CONECTIONS",
      stat3B: "in the market",
      stat4A: "BUSINESSES",
      stat4B: "structured",
      stat5A: "ACTIVE",
      stat5B: "users",
      servicesTitle: "SERVICES MD",
      services: "In MinkaDev we use 2 mechanisms that help us create the results needed by your organization: Marketplace and Think and do Tank.",
      knowMore: "Know more",
      mapTitle: "THINKING GLOBALLY, WE WORK LOCALLY",
      mapOp1: "",
      mapOp2: "opportunities.",
      mapSecLabel: "More impacted sector",
      mapOpLabel: "Main opportunities:",
      prensaTitle: "MINKA IN THE PRESS",
      prensa: ["A testimony made by this press media. Shuould be added through the Bakend in the future."],
      confiado: "HAVE TRUSTED US",
      cadena: "Sustainable sourcing offer",
      consumo: "Sustainable Consumption offers",
    },
    noticias: {
      title: "NEWS",
      ultimas: "LATEST NEWS",
      subtitle: "Know the lastest news on sustainable development with social impact. Follow us on our social networks to know more.",
    },
    privacidad: {
      tittle: "Privacy Policy",
      priva1: "through the current notice MINKA-DEV SOLUCIONES Y ESTUDIOS, SL. (from now on, minka dev), with social residence in Barcelona, Industria Street 137 - 141 btwn 1st Esc izq, informs the web site users about their",
      priva1a: "PRIVACY POLICY AND PROTECTION OF PERSONAL DATA.",
      priva2: "In compliance with Law 15/1999 of the 13th of December, of Protection of Personal Data, it is informed that personal data that, as it happens, are facilitated by the users through the present website and that are under the realm of application of the applicable legislation, will be included in a personal data file whose owner and holder is minka dev, and that is dully recorded before the Spanish Data Protection Agency. Furthermore, in case of filling out any of the forms with the requested personal data and accepting its submission, YOU EXPRESSLY ENABLE minka dev to treat and incorporate in their automated file all the facilitated personal data, as well as all the data generated in relation to your participation and/or use of the different services that are or could be offered in this website.",
      priva3: "In cases in which personal data is included in the forms refering to physical people different from those who are effectuating the application, the latter must inform said people of the extremes contained in the present document.",
      priva4: "Unless otherwise stated, it will be considered necessary to fill out all the spaces in each form, having the user to fill out the form with data that are truthful, exact, complete, and updated. The user will be the sole responsible for any damage, direct or indirect, that could be caused to minka dev or any third party due to completion of the forms with data that are false, inexact, incomplete, or not updated. In that regard, the user guarantees that the personal data facilitated are truthful and takes responsibility for notifying minka dev of any modification in them.",
      priva5: "The purposes to which the personal data gathered are collected in the present website can be one or several of the ones listed bellow by the record or contact effectuated by the User:",
      priva6a: "a) Register as Investor, Opportunity Identifier, or generic user: the management of the registered user condition;",
      priva6b: "b) Register Newsletter: the management of your subscription in the Newsletter Service accessible from the Website;",
      priva6c: "c) contact Think Tank Form: the management of the contact in relation to minka dev's Think Tank.",
      priva6d: "d) Likewise, the gathered data can be used for the submission of technical, informative and any other kind of information that minka dev deems interesting for their users.",
      priva7: "The facilitated data will never be used for any purpose different from the listed above, being immediately canceled when they stop being necessary for said purpose.",
      priva8: "In case minka dev would provide any kind of special service in which they determine some specific provisions different from these in regards to protection of personal data, the application of the indicated particular norms shall have primacy for said particular service above those present, in case of incongruity.",
      priva9: "All information related to personal data received by minka dev is treated with maximum confidentiality, respecting at all times the duty of professional secret, proceeding only to the strictly necessary communications for the achievement of the purposes above described.",
      priva10: "Likewise, through this Privacy Policy minka dev informs that they have adopted the levels of safety in matters of legal and regulatorily required Protection of Personal Data, and has installed all the means and technical measures at their disposal to avoid the loss, inadequate use, alteration, and unauthorized access to the personal data included in their files.",
      priva11: "The user has the right to access the information concerning them gathered in the minka dev files, to rectify if it is mistaken, to cancel it or oppose to its treatment via e-mail to",
      priva11a: ", or written communication signed (attaching a copy of DNI) directed to the owner of the file, that is, the MINKA-DEV SOLUCIONES Y ESTUDIOS, SL. society, with social residence in Barcelona, Industria 217-141 ent-1era (Subject. Data Protection).",
      priva12: "The registered user (excluding those who have registered only to the Newsletter service or have submitted a contact form for Think Tank services) uniquivocally grant their express consent to have their facilitated personal data be transferred, exclusively for the purposes described above, to the people who integrate the minka dev ecosystem (identifiers, promoters, sponsors, investors, evaluation committees) for the evaluation and study of the provided solutions, even though said transference leads to the international transference of personal data.",
      priva13: "The user's acceptance of their data being treated in the way established in this Privacy Policy, has a revocable character, without retroactive effects, in accordance to the stipulations in article 6 of the Organic Law 15/1999 of the 13th of December.",
      priva14: "minka dev reserves the right to modify their data protection policy according to their criteria, or due to a legislative, jurisprudencial, or corporate practice change. If minka dev were to introduce any modification, the new text will be published in this website, where the user can have knowledge of the data protection policy in force at every moment. In any case, the relationship with users will be guided by the rules laid down in the precise moment the website is accessed.",
    },
    odsExplication: {
      explication: "The Sustainable Development Goals (SDGs), also known as the Global Goals, were adopted by all United Nations Member States in 2015 as a universal call to action to end poverty, protect the planet and ensure that all people enjoy peace and prosperity by 2030.",
    },
    header: {
      Servicios: "Services",
      Noticias: "News",
      "Mi Cuenta": "My profile",
      Contacto: "Contact us",
      Nosotros: "About us",
      Ingresar: "Log In",
    },
    footer: {
      oportunidades: "Opportunities",
      servicios: "Services",
      acerca: "About minka dev",
      contacto: "Contact Us",
      privacidad: "Privacy",
      participa: "PARTICIPATE",
      institucion: "INSTITUTION",
      actividad: "ACTIVITY",
    },
    forms: {
      errors: {
        callToAction: "You need to fill the following fields: ",
        name: "Name",
        website: "Website",
        sector: "Sector",
        nif: "Company's Identification Number (NIE or NIF)",
        phone: "Phone",
        type: "You need to select an opportunity type",
        nb_affected: "You need to select a number of impacted persons",
        deadline: "The deadline format is YYYY/MM/DD",
      },
      messages: {
        error: "There was an error. Contact us.",
        savedSuccesfully: "Saved successfully",
      },
      controls: {
        guardar: "Save",
        editar: "Edit",
        return: "Return",
        publicar: "Publish",
        click_edit: "Click to edit the title",
        selectSectors: "Sectors to which the opportunity currently belongs",
        addSector: "Add a sector",
        addSgd: "Add a SGD",
        selectSgd: "Select one or more SDGs",
        add: "Add text",
        urlVideos: "Embed YouTube videos",
        urlVideosView: "Videos",
        backOpportunity: "Back to Opportunity",
        selectFile: "Select File",
        sendFile: "Send",
        info: "*Press \"Save\" after completing the information. You can edit it later by pressing \"Edit\" again"
      },
      opportunities: {
        addTitle: "What is the phrase that best describes the product or service you offer?",
        viewTitle: "Phrase that best describes the product or service you offer",
        anexos: "Attach one by one any document you consider important to understand your proposal. Format: PDF files not exceeding 4MB in size.",
        anexosView: "Related documents (PDF)",
        uploadImageMessage: "Attach one by one the images representing your organization. Format: PNG, JPEG, not exceeding a total weight of 4MB. Maximum 8 images.",
        attachedLinks: "In case it's a link to a webpage or drive, please include it in the following space: *Maximum 3 links.",
        attachedLinksView: "Links",
        oppDescription: "Please provide a brief description of the service you are offering. This will help potential customers understand what you can provide and whether it meets their needs. Please be as specific as possible, including any relevant details about the scope, requirements, and desired outcomes of the service. ",
        oppMotivation: "This field is an opportunity for you to tell us a little more about your needs and expectations in relation to the service you are looking for. We want to understand your motivation for acquiring our services and how we can help you achieve your goals. Please be as detailed as possible so that we can provide you with the best solution for your needs. ",
        oppTypeFormTitle: "Opportunity type",
        oppBasicFormTitle: "Basic information",
        oppAttachmentsFormTitle: "Attachments",
        welcomeTitle: "We welcome you to create your opportunity",
        welcomeInfo: "Please complete the following information clearly and honestly. You can track the progress of the process in the following bar."
      },
      organization: {
        country: "Country where your activities are carried out",
        city: "City",
        address: "Address",
        //lat and lng?
        name: "Name of the organization you're applying for",
        type: "Type of organization",
        website: "Organization's website",
        sector: "Sector you work in",
        sector_OTRO_TEXT: "Specify the sector:",
        nif: "Company's Identification Number (NIF, etc)",
        nifhelp: "Identification number of your company, according to your country, it is likely that this code contains letters or only numbers.",
        phone: "Organization's phone number",
        age: "Organization's years of existence",
        billing: "Annual turnover USD",
        employees: "Number of employees",
        sustainability: "Main line of economic sustainability",
        sus: "Other secondary lines of economic sustainability",

        role: "What is your role in the organization?",
        department: "Department you belong to in the organization",
        representation: "Do I have the capability to represent my organization in the minka dev platform?",

        //activities
        act: "Type of activities carried out, and dedication percentage",
        _per: ", with a dedication (%):",

        industry: "Industry you work in",
        act_otha: "Specify the industry",
        pr: "Principles the organization deems vital to collaborate with other organizations",

        logo: "Add your organization's logo. Upload image",
        image: "Select File",
        Intro: "The following information is used to determine the compatibility of this organization with different nominated opportunities in minka dev's platform.",
      },
    },
    organizations: {
      title: "Your Organization",
      nocomplete: "You have not completed your Organization. You need it to create opportunities and solutions.",
      define: "Define your organization's profile",
      adicional: "Additional information",
      role: "Your Role",
      sostenibilidad: "Sustainability",
      information: "Basic Information",
    },
    opportunities: {
      adExp: "Add a new Opportunity",
      consumo: "Sustainable Consumption and Production",
      information_consumo: "Opportunities aimed at the acquisition of products and/or services to satisfy consumption and production needs that generate greater social well-being and care for the environment.",
      cadena: "Sustainable sourcing",
      information_cadena: "Opportunities related to the supply of products that are produced with sustainability criteria (creation of social welfare, care for the environment).",
      offer: "We offer products or services",
      information_offer: "Opportunities offered by a product or service (related to sustainable supply, consumption or sustainable consumption). They look for a market connection that can acquire them.",
      need: "We need productos or services",
      information_need: "Opportunities created to search for a product or service (related to a sustainable supply chain, sustainable consumption or production). They look for an organization that can solve the stated need.",
      offerExplain: "Opportunities created to search for a product or service (related to sourcing, sustianable consumption and production). They are looking for an organization that can solve the need raised",
      needExplain: "Opportunities that offer a product or service (related to sourcing, sustianable consumption or production). They are looking for a market connection that can acquire them",
      consumoExplain: "Opportunities aimed at the acquisition of products and / or services to satisfy the consumption and production needs that generate greater social welfare and care for the environment",
      cadenaExplain: "Opportunities related to sourcing products that are produced with sustainability criteria (creation of social welfare, care for the environment)",
      anexos: "Related documents (PDF): Provide any information you consider important to know your proposal.",
      anexosView: "Related documents (PDF)",
      closeFilter: "Our trajectory: view our closed opportunites.",
    },
    servicios: {
      invite: "We are here to help transform an idea or problem into an opportunity with social impact.",
      invite2: "Discover our services",
      algunas: "Some of our experiences",
      titleBanner: "SERVICES",
      subTitleBanner: "We achieve concrete results in the development of sustainable businesses",
      title: "What do we do?",
      subTitle: "Minka dev has built its experience in 3 types of businesses: green businesses, inclusive businesses and businesses for the circular economy.",
    },
    //end servicios
    nosotros: {
      title: "ABOUT US",
      subTitle: "MANIFEST",
      about: "At minka dev we generate and develop sustainable business models that help solve a social and / or environmental problem.",
      developed: "We develop sustainable businesses that generate results for change.",
      graymessages: {
        m1: "We believe in the potential to transform social and environmental needs into sustainable businesses.",
        m2: "We develop innovative models and methodologies, focused on people.",
      },
      business: {
        title1: "GREEN BUSINESSES",
        title2: "INCLUSIVE BUSINESSES",
        title3: "BUSINESS FOR CIRCULAR ECONOMY",
        text1: "We contribute to the development of businesses that generate direct environmental benefits, adding value and contributing to the conservation of the environment.",
        text2: "We contribute to the development of Inclusive Businesses that directly affect the reduction of poverty and access to opportunities for populations in contexts of vulnerability.",
        text3: "We contribute to the development of businesses that promote the production of sustainable goods and services, reducing consumption, energy sources, waste and time.",
      },
      reality: {
        title: "WE MAKE IT HAPPEN",
        text: "We turn problems and inefficiencies into viable, innovative and sustainable businesses. We have started from problems such as food waste or a threatened natural species and have transformed them into viable businesses, developed with public-private partnerships catalyzed by minka-dev. The businesses that have been promoted with our accompaniment have allowed the companies involved to innovate and expand their markets.",
      },
      work: {
        title: "WORK WITH US",
        text: "Are you interested in joining our team to promote sustainable businesses in social change? Send us your resume now.",
        button: "Write to us",
      },
      testimonies: {
        title: "TESTIMONIES",
      },
      messajeAlly: {
        title: "ALLIANCES",
      },
    },
    contacto: {
      title: "Do you need to contact us?",
      text: "Fill out this form or contact us through our social networks.",
      button: "Send",
    },
    definition: {
      newButtons: {
        cancel: "Cancel",
        save: "Save",
      },
      flash: {
        close_opportunity: "The opportunity is closed",
        msg1: "Opportunities created to search for a product or service (related to sourcing, sustianable consumption and production). They are looking for an organization that can solve the need raised",
        msg2: "Opportunities that offer a product or service (related to sourcing, sustianable consumption or production). They are looking for a market connection that can acquire them",
        msg3: "Opportunities related to the supply and demand of products or services that are carried out in accordance with sustainability criteria (creation of social welfare, care for the environment and good economic performance)",
        msg4: "Opportunities aimed at the acquisition of products and / or services to satisfy the consumption and production needs that generate greater social welfare and care for the environment",
      },
      button: {
        propouse: "Create Proposal",
        youPropouse: "Create your proposal",
        notOrganization: "You don't have an organization",
        closePropouse: "Close proposal",
        close: "Closed",
      },
      type: "Opportunity Type",
      filters: {
        offer: "I offer products or services",
        need: "Looking for Products or services",
        consumo: "Sustainable consumption and production",
        cadena: "Sustainable sourcing",
      },
      sectors: "Sector(s)",
      impact: "Number of people seeking to benefit from the opportunity",
      hectaresPreservation: "Number of hectares to be preserved with the opportunity",
      dateClose: "Opportunity's closing date",
      concept: "Keywords ",
      description: "Description",
      conditions: "Other features ",
      motivation: "Motivation",
      idoneo: "The organization linked to this opportunity and the context in which it finds those it wishes to benefit seem to be ideal for advancing in the development of inclusive and sustainable business.",
      definition: "Definition of the product or service",
      country: "Country",
      partner: "Opportunity developed with the support of: ",
    },
    newPanel: {
      mode: "Mode",
      publish: "PUBLISH",
      publishButton: "PUBLISH OPPORTUNITY",
      propPublishButton: "PUBLISH PROPOSAL",
      publishing: "Publishing...",
      save: "Save",
      saving: "Saving...",
      saved: "Saved",
      edit: "Edit",
      preview: "Preview",
      blankTitle: "Opportunity must have a title ",
      publishMessage: "*Press 'PUBLISH OPPORTUNITY' only when you are sure you have correctly filled out all the fields. You won't be able to edit after publishing.",
      propPublishMessage: "*Press 'PUBLISH PROPOSAL' only when you are sure you have correctly filled out all the fields. You won't be able to edit after publishing.",
      publishModalTitle: "Confirm publication",
      publishModalText: "I confirm that I have provided the information accurately and completely. I wish to proceed to publish the opportunity, understanding that I will not be able to make edits once published."
    },
    opportunity: {
      accordion: {
        select: "Select an Opportunity Type",
        details: "Details",
        impact: "Impact",
        organization: "Organization",
        haveCommunity: "I Have a Community",
        customerProfile: "Customer profile ",
        findCommunity: "Find ",
        territory: "Territory",
        community: "Community",
      },
      basicCommunity: {
        income: "Indicate the community average daily income (US DOLLARS)",
        etnia: "You represent / seek communities with the presence of the following ethnic groups.",
        etniaWitch: "Other ethnicities:",
        assets: "List the main assets the community has or should have.",
        services: "Services to which the community has stable access.",
        services_text: "Other services:",
        incomeView: "Community monthly income",
        etniaView: "Ethnic groups seeking",
        etniaWitchView: "Ethnic groups seeking",
        assetsView: "Main assets of the community",
        servicesView: "Services",
        services_textView: "Services",
      },
      detail1: {
        cause: "Describe the cause of this need",
        solutions: "Do you know other existing solutions for the identified need?",
        solutions_which: "Which?",
        value: "Why do you want to identify a different type of solution than the existing ones?",
        value_OTHER_TEXT: "Which?",
        causeView: "Description of the need",
        solutionsView: "Existing solutions for the identified need",
        solutions_whichView: "Other existing solutions for the identified need",
        valueView: "Different solution than the existing ones",
        value_OTHER_TEXTView: "Type",
      },
      detail2: {
        description: "Product / service description",
        capacity: "What is the current capacity to offer the product / service?",
        capacity_quantity: "Quantity",
        capacity_quantity_validation: "Quantity must be greater than zero ",
        capacity_frequency: "Frequency",
        capacity_unit: "Unit",
        markets: "In which markets has this service or product been commercialized?",
        markets_OTHER_TEXT: "Another market",
        development_level: "Level of development in which the product / service is located",
        innovation: "What characteristics does the service / product offered to the market have?",
        innovation_OTHER_TEXT: "Other features",
        descriptionView: "Product / service description",
        capacityView: "Current ability to offer the product",
        capacity_quantityView: "Quantity",
        capacity_frequencyView: "Frequency",
        capacity_unitView: "Unit",
        marketsView: "Markets in which this product has been commercialized",
        markets_OTHER_TEXTView: "Other market",
        development_levelView: "Level of development in which the product is located",
        innovationView: "Characteristics of the product offered to the market",
        innovation_OTHER_TEXTView: "Other features",
      },
      detail3: {
        description: "Product description",
        vulnerable: "Is the product or service you offer marketed in some vulnerable populations?",
        age_range: "What age range is your product aimed at?",
        affordability: "Strategy to facilitate product affordability",
        affordability_OTHER_TEXT: "What strategies?",
        development: "Level of development in which the product is located",
        value: "Added value of your product comparatively with other options in the market.",
        value_OTHER_TEXT: "Which?",
        action: "What action do you expect the community to take within the framework of the proposed solution?",
        activity: "In addition to the purchase of your product or service, what other activity would you like the organization to participate in? ",
        activity_OTHER_TEXT: "Which activities?",
        descriptionView: "Product description",
        vulnerableView: "Commercialization of the product in other vulnerable populations",
        age_rangeView: "Age range to which the product is directed",
        affordabilityView: "Strategy to facilitate the affordability of the product or service",
        affordability_OTHER_TEXTView: "Other strategies",
        developmentView: "Level of development in which the product is located",
        valueView: "Added value of your product or service compared to other options on the market.",
        value_OTHER_TEXTView: "Type",
        actionView: "Actions the community will carry out within the framework of the proposed solution",
        activityView: "Activity in which the community will be integrated and in which it hopes to generate social impact",
        activity_OTHER_TEXTView: "Other activities",
      },
      detail4: {
        capacity_quantity_validation: "Quantity must be greater than zero ",
        description: "Product / service description",
        buy_capacity: "What is the current purchasing / contracting capacity?",
        buy_capacity_quantity: "Quantity",
        buy_capacity_frequency: "Frequency",
        buy_capacity_unit: "Unit",
        innovation: "Regarding the product you offer, what is its main difference?",
        value: "Added value of the link that you propose comparatively with other similar processes.",
        value_OTHER_TEXT: "Which?",
        sails_capacity: "Minimum capacity expected to be provided by the community",
        sales_capacity_quantity: "Quantity",
        sales_capacity_frequency: "Frequency",
        sales_capacity_unit: "Unit",
        descriptionView: "Product / service description",
        buy_capacityView: "Current purchasing / contracting capacity",
        buy_capacity_quantityView: "Quantity",
        buy_capacity_frequencyView: "Frequency",
        buy_capacity_unitView: "Unit",
        innovationView: "Main differences of the product / service offered",
        valueView: "Added value of the proposal comparatively with other similar processes.",
        value_OTHER_TEXTView: "Type",
        sails_capacityView: "Minimum capacity expected to be provided by the community",
        sales_capacity_quantityView: "Quantity",
        sales_capacity_frequencyView: "Frequency",
        sails_capacity_unitView: "Unit",
      },
      hasCommunity: {
        name: "Organization name ",
        country: "Country",
        city: "City",
        address: "Address",
        role: "Role with which you identify yourself regarding your relationship with the organization you want to benefit. ",
        affected: "Number of people that could profit from it",
        antiguity: "How long has the organization you collaborate with been established? ",
        characteristic: "Select the characteristics that describe the organization ",
        association: "Type of association represented by the organization ",
        association_OTRO_TEXT: "Other forms of associaton:",
        experience_management:
          "Experience of your organization in project formulation and management",
        experience_development:
          "Experience of your organization in economic development projects",
        credibility_ong:
          "Level of credibility of your organization before NGOs",
        financing:
          "What type of financing has the organization received? ",
        financing_type:
          "What type of financing has the organization received? ",
        other_financing: "Other types of financing ",
        access: "What type of access routes does the organization have? ",
        time: "How long have you been working with this organization? ",
        nameView: "Organization name ",
        countryView: "Country",
        cityView: "City",
        addressView: "Address",
        rolView: "Role with the organization. ",
        organization_roleView: "Relation with the organization ",
        affectedView: "Number of people that could profit from it",
        antiguityView:
          "Time since the organization with which it collaborates was established. ",
        characteristicView:
          "Characteristics that describe the organization ",
        associationView:
          "Type of association represented by the organization ",
        experience_managementView:
          "Experience of the organization in project formulation and management",
        experience_developmentView:
          "Experience of the organization in economic development projects",
        credibility_ongView:
          "Level of credibility of the organization before NGOs",
        financingView: "Type of financing received by the organization ",
        financing_typeView: "Type of financing received by the organization ",
        other_financingView: "Others types of financing ",
        accessView: "Type of access routes the organization has ",
        timeView: "Time worked with this organization ",
        populationView: "Population and its dispersion",
      },
      impact: {
        sector: "Sector to impact ",
        sector_OTRO_TEXT: "Other sector",
        participation: "Your organization's contribution",
        other_participation: "Which is it? ",
        environmental: "Main Environmental Indicators",
        other_environmental: "Which is it? ",
        social: "Main Social Indicators",
        sectorView: "Sector",
        sector_OTRO_TEXTView: "Other sector",
        participationView: "Your organization's contribution",
        other_participationView: "Other Contribution ",
        environmentalView: "Main Environmental Indicators",
        other_environmentalView: "Other Environmental Indicators ",
        socialView: "Main Social Indicators",
      },
      customerProfile: {
        description: "Describe the desired profile of the communities to be linked",
        location: "Preferred location of the organizations to link",
        population: "Type of population and dispersion of the customers you wish to link. ",
        infrastructure_type: "Type of infrastructure that your client would need to acquire your product/service ",
        other_infrastructure_type: "Which is it? ",
        unfavorable: "The populations to be identified must be in one of the following unfavorable conditions.",
        unfavorable_which: "Relate other conditions that your consider important",
        other_conditions: "Relate other conditions that you consider important to this opportunity",
        descriptionView: "Desired profile desired of the communities to be linked",
        locationView: "Preferred location of the organizations to link",
        populationView: "Type of population and dispersion of the customers you wish to link. ",
        unfavorableView: "The populations to be identified must be in one of the following unfavorable conditions.",
        unfavorable_whichView: "Other conditions:",
        other_conditionsView: "Other conditions important to this opportunity",
      },
      needsCommunity: {
        description: "Describe the desired profile of the communities to be linked",
        location: "Preferred location of the supply chain to be linked (only the geographic area) ",
        association: "The form of association that you prefer with the supply chain. ",
        association_OTRO_TEXT: "Other forms of associaton:",
        population: "Type of population and dispersion of the supply chain that you want to link. ",
        unfavorable: "The populations to be identified must be in one of the following unfavorable conditions.",
        unfavorable_which: "Relate other conditions that your consider important",
        other_conditions: "Relate other conditions that you consider important to this opportunity",
        descriptionView: "Desired profile desired of the communities to be linked",
        locationView: "Preferred location of the supply chain to be linked. ",
        associationView: "The form of association that you prefer with the supply chain. ",
        association_OTRO_TEXTView: "Other forms of associaton::",
        populationView: "Type of population and dispersion of the supply chain that you want to link. ",
        unfavorableView: "The populations to be identified must be in one of the following unfavorable conditions.",
        unfavorable_whichView: "Other conditions:",
        other_conditionsView: "Other conditions important to this opportunity",
      },
      organization: {
        organization_type: "Please indicate what best describes your relationship with the organization: ",
        challenges: "Areas in which the organization faces challenges. ",
        other_challenges: "Other areas. ",
        projects_budget:
          "Billing range of your organization in the last two years (USD) ",
        nb_benefited:
          "Approximate number of people who have benefited from your organization in the last 2 years ",
        partipation_network:
          "Participation of your organization in social networks, national and international",
        influence:
          "Level of influence of your organization before other organizations",
        leadership: "Leadership of your company in the corporate sector",
        credibility_people:
          "Level of credibility of your organization before the population",
        market_orientation:
          "Level of orientation of the organization to market conections",
        decision_tools:
          "What is the effectiveness of the mechanisms for decision making in the organization",
        organization_typeView: "Relation with the organization ",
        challengesView: "Areas in which the organization faces challenges. ",
        other_challengesView: "Other areas. ",
        projects_budgetView:
          "Billing range of your organization in the last two years (US DOLLARS) ",
        nb_benefitedView:
          "Approximate number of people who have benefited from your organization in the last 2 years ",
        partipation_networkView:
          "Participation of the organization in social networks, national and international",
        influenceView:
          "Level of influence of the organization before other organizations",
        leadershipView: "Leadership of the company in the corporate sector",
        credibility_peopleView:
          "Level of credibility of the organization before the population",
        market_orientationView:
          "Level of orientation of the organization to market conections",
        decision_toolsView:
          "Effectiveness of the mechanisms for decision making in the organization",
      },
    },
    territory: {
      territory_population: "Type of population and its dispersion",
      work: "There are works in the territory carried out jointly between the private and the public sector and NGOs",
      trust: "There is an atmosphere of mistrust towards institutions in the zone",
      presence: "In the territory diverse organizations are present that tend to local development",
      public: "In the territory the public sector has an effective role of promoting economic and social development",
      corporative: "In the territory the corporate sector is seen as an agent of development",
      communities: "The communities to which your organization relates in the territory are empowered and promote their own development",
      order: "There are public order problems in the zone",
      alliances: "The experience the local communities have allows them to be allies in the search for corporate solutions to their needs",
      convoke: "Your organization can convene public and private actors around corporate solutions that approach social problems",
      collaboration: "Your organization and other local organizations have a trajectory or joint work that allows you to be allies in corporate solutions",
      sinergy: "In the zone it is possible to generate sigenries of public and private actors around corporate solutions that approach social problems",
      condition: "Select the conditions that you consider may be barriers to the implementation of the business opportunity:",
      condition_OTRO_TEXT: "Other conditions",
      territory_populationView: "Population and its dispersion",
      workView: "There are works jointly carried out between the private, public sector, and NGOs in the zone",
      trustView: "There is an atmosphere of mistrust towards institutions in the zone",
      presenceView: "Diverse organizations are present in the territory seeking for local development",
      publicView: "The public sector has an effective role of promoting economic and social development in the territory",
      corporativeView: "The corporate sector is seen as an agent of development",
      communitiesView: "The communities to which the organization relates in the territory are empowered and promote their own development",
      orderView: "There are public order problems in the zone",
      alliancesView: "The experience the local communities have allows them to be allies in the search for corporate solutions to their needs",
      convokeView: "Your organization can convene public and private actors around corporate solutions that approach social problems",
      collaborationView: "The organization which represents this community and other local organizations have a trajectory or joint work that allows you to be allies in corporate solutions",
      sinergyView: "In the zone it is possible to generate sinergies of public and private actors around corporate solutions that approach social problems",
      conditionView: "Conditions that you consider may be barriers to the implementation of the business opportunity:",
      condition_OTRO_TEXTView: "Other conditions",
    },
    solution: {
      accordion: {
        select: "Select an Opportunity Type",
        details: "Details",
        impact: "Impact",
        organization: "Organization",
        haveCommunity: "I Have a Community",
        customerProfile: "Customer profile ",
        findCommunity: "Find ",
        territory: "Territory",
        community: "Community",
      },
      solution1: {
        title: "Title",
        description: "Description",
        sector: "Sector in which your proposal is related",
        other_sector: "Other Sector", //no View
        Motivacion: "Tell us your motivation",
        nb_affected: "Number of people who will benefit from this solution",
        activity: "In what activity do you expect the community to be integrated?",
        action_solution: "Action you expect the community to execute",
        development_level: "Level of development is currently the product or service",
        affordability: "Regarding the strategies to facilitate the affordability of the community to the product or service, which of the following options do you plan to use with the communities related in the opportunity:",
        experience: "Have you had a work experience with other communities generating social impact:",
        innovationsolution: "About the product or service you offer, which of the following options best differentiate it?",
        condition: "Additional conditions to be met by the community to have access to the product or service",
        environmental: "What environmental indicators do you expect to impact? ",
        other_environmental: "Which is it? ",
        participation: "Your organization's contribution",
        other_participation: "Which is it? ",
        titleView: "Title",
        descriptionView: "Description",
        sectorView: "Sector",
        MotivacionView: "Motivation",
        nb_affectedView: "Number of people who will benefit from this solution",
        activityView: "Activities in which it is expected to link to the community",
        action_solutionView: "Action you expect the community to execute",
        development_levelView: "Level of development is currently the product or service",
        affordabilityView: "Regarding the strategies to facilitate the affordability of the community to the product or service, which of the following options do you plan to use with the communities related in the opportunity:",
        experienceView: "Experience with other communities generating social impact:",
        innovationsolutionView: "About the product or service you offer, which of the following options best differentiate it",
        conditionView: "Additional conditions to be met by the community to have access to the product or service",
        environmentalView: "Environmental indicators to impact. ",
        other_environmentalView: "Other environmental indicators to impact. ",
        participationView: "Organization's contribution",
        other_participationView: "Other organization's contribution ",
      },
      solution2: {
        description: "Describe briefly how you would link the product or service from this community to your value chain",
        sector: "Sector in which the need/opportunity is entailed",
        other_sector: "Other Sector", //no View
        motivation: "What is your motivation to link vulnerable communities to your value chain?",
        experience: "Would your organization like to make a contribution that facilitates the linking of your organization to this value chain? ",
        buy_capacity: "What is the current purchasing / contracting capacity?",
        buy_quantity: "Minimum capacity (Quantity) ",
        buy_frequency: "Minimum capacity (Frequency) ",
        buy_unit: "Minimum capacity (Unit) ",
        expected_quantity: "Minimum labor expected capacity in the value chain Quantity",
        expected_frequency: "Minimum labor expected capacity in the value chain  Frequency",
        expected_unit: "Minimum labor expected capacity in the value chain Unit",
        environmental: "What environmental indicators do you expect to impact? ",
        other_environmental: "Which is it? ",
        participation: "Would you like to make a contribution that facilitates the linking of this Organization to your value chain? ",
        other_participation: "Which is it? ",
        descriptionView: "Description on how to link the service / product of this community to the value chain",
        sectorView: "Sector in which the need/opportunity is entailed",
        motivationView: "Motivation to link vulnerable communities to the value chain",
        experienceView: "Contribution that facilitates the linking of your organization to this value chain ",
        buy_capacityView: "Current purchasing / contracting capacity",
        buy_quantityView: "Minimum capacity expected to be provided by the community Quantity",
        buy_frequencyView: "Minimum capacity expected to be provided by the community Frequency",
        buy_unitView: "Minimum capacity expected to be provided by the community Unit",
        environmentalView: "Environmental indicators you expect to impact ",
        other_environmentalView: "Other environmental indicators you expect to impact ",
        participationView: "Contribution that facilitates the linking of this Organization to your value chain",
        other_participationView: "Other contribution",
      },
      solution3: {
        description: "Description of the need/opportunity you want to solve",
        Motivacion: "What is your motivation to bring corporate development regarding the identified need?",
        nb_affected: "Number of people that could profit from it",
        descriptionView: "Description of the need/opportunity you want to solve",
        MotivacionView: "Motivation to bring corporate development regarding the identified need",
        nb_affectedView: "Number of people that could profit from it",
        aporteView: "Contribution to the process",
      },
      solution4: {
        description: "Briefly describe the product and/or service. Highlight the differential value",
        Motivacion: "What is your motivation to connect this product or service to new markets?",
        capacity_quantity_validation: "Quantity must be greater than zero ",
        capacity_quantity: "What is the minimum capacity you can offer? (Quantity) ",
        capacity_frequency: "What is the minimum capacity you can offer? (Frequency)",
        capacity_unit: "What is the minimum capacity you can offer? (Unit)",
        development: "Level of development of product or service",
        market: "In what type of markets have you comercialized this product and / or service",
        descriptionView: "Description of the product and/or service.",
        MotivacionView: "Motivation to connect this product or service to new markets",
        capacity_quantityView: "Minimum capacity texpected from the community that offer of the product or service (Quantity)",
        capacity_frequencyView: "Minimum capacity expected from the community that offer of the product or service (Frequency)",
        capacity_unitView: "Minimum capacity expected from the community that offer of the product or service (Frequency)",
        developmentView: "Level of development of product or service",
        marketView: "Type of markets the product has been comercialized",
        aporteView: "Contribution to the process",
      },
      communitySolution: {
        country: "Country",
        city: "City",
        address: "Address",
        assets: "Contribution to the proposal as an organization",
        income: "Contributions that the locals must make daily",
        services: "Services the community has",
        population: "Type of population and its dispersion",
        etnia: "Ethnicity",
        etnia_which: "Which ethnicity",
        organization_role: "What type of organization is:",
        contactname: "Contact name",
        contactemail: "Contact email",
        contactphone: "Contact number",
        name: "Community name",
        afected: "Community involved",
        age_range: "Type of population involved",
        antiguity: "Antiquity",
        characteristic: "Characteristics",
        association: "Associate types",
        financing: "Financing",
        access: "Type of access road",
        tipo_necesidad: "Meeting need",
        rol: "Role",
        time: "Weather",
        countryView: "Country",
        cityView: "City",
        addressView: "Address",
        assetsView: "Contribution to the proposal as an organization",
        incomeView: "Contributions that the locals must make daily",
        servicesView: "Services the community has",
        populationView: "Type of population and its dispersion",
        etniaView: "Ethnicity",
        etnia_whichView: "Which ethnicity",
        organization_roleView: "What type of organization is:",
        contactnameView: "Contact name",
        contactemailView: "Contact email",
        contactphoneView: "Contact number",
        nameView: "Community name",
        afectedView: "Community involved",
        age_rangeView: "Type of population involved",
        antiguityView: "Antiquity",
        characteristicView: "Characteristics",
        associationView: "Associate types",
        financingView: "Financing",
        accessView: "Type of access road",
        tipo_necesidadView: "Need to supply",
        rolView: "Role",
        timeView: "Weather",
      },
      communitypropouse: {
        understand: "The community is the one who seeks to benefit from the opportunity in Minka-dev. Through the following information is inquired about its characteristics.",
        name: "Community name",
        country: "Country",
        city: "City",
        address: "Address",
        assets: "Contribution to the proposal as an organization",
        income: "Contributions that the locals must make daily",
        services: "Services the community has",
        population: "Type of population",
        etnia: "Ethnicity",
        etnia_which: "Which ethnicity",
        organization_role: "What type of organization is it?",
        contactname: "Contact name",
        contactemail: "Contact email",
        contactphone: "Contact number",
        afected: "Community involved",
        age_range: "Type of population involved",
        antiguity: "Antiquity",
        characteristic: "Characteristics",
        association: "Associate types",
        financing: "Financing",
        access: "Type of access road",
        tipo_necesidad: "Meeting need",
        rol: "Role",
        time: "Weather",
        nameView: "Community name",
        countryView: "Country",
        cityView: "City",
        addressView: "Address",
        assetsView: "Contribution to the proposal as an organization",
        incomeView: "Contributions that the locals must make daily",
        servicesView: "Services the community has",
        populationView: "Type of population",
        etniaView: "Ethnicity",
        etnia_whichView: "Which ethnicity",
        organization_roleView: "Type of organization",
        contactnameView: "Contact name",
        contactemailView: "Contact email",
        contactphoneView: "Contact number",
        afectedView: "Community involved",
        age_rangeView: "Type of population involved",
        antiguityView: "Antiquity",
        characteristicView: "Characteristics",
        associationView: "Associate types",
        financingView: "Financing",
        accessView: "Type of access road",
        tipo_necesidadView: "Meeting need",
        rolView: "Role",
        timeView: "Weather",
      },
      hasCommunity: {
        name: "Organization name ",
        country: "Country",
        city: "City",
        address: "Address",
        role: "Role with which you identify yourself regarding your relationship with the organization you want to benefit. ",
        affected: "Number of people that could profit from it",
        antiguity: "How long has the organization you collaborate with been established? ",
        characteristic: "Select the characteristics that describe the organization ",
        association: "Type of association represented by the organization ",
        association_OTRO_TEXT: "Other forms of associaton:",
        experience_management:
          "Experience of your organization in project formulation and management",
        experience_development:
          "Experience of your organization in economic development projects",
        credibility_ong:
          "Level of credibility of your organization before NGOs",
        financing:
          "What type of financing has the organization received? ",
        financing_type:
          "What type of financing has the organization received? ",
        other_financing: "Other types of financing ",
        access: "What type of access routes does the organization have? ",
        time: "How long have you been working with this organization? ",
        nameView: "Organization name ",
        countryView: "Country",
        cityView: "City",
        addressView: "Address",
        rolView: "Role with the organization. ",
        organization_roleView: "Relation with the organization ",
        affectedView: "Number of people that could profit from it",
        antiguityView:
          "Time since the organization with which it collaborates was established. ",
        characteristicView:
          "Characteristics that describe the organization ",
        associationView:
          "Type of association represented by the organization ",
        experience_managementView:
          "Experience of the organization in project formulation and management",
        experience_developmentView:
          "Experience of the organization in economic development projects",
        credibility_ongView:
          "Level of credibility of the organization before NGOs",
        financingView: "Type of financing received by the organization ",
        financing_typeView: "Type of financing received by the organization ",
        other_financingView: "Other types of financing ",
        accessView: "Type of access routes the organization has ",
        timeView: "Time worked with this organization ",
        populationView: "Population and its dispersion",
      },
      impact: {
        sector: "Sector to impact ",
        sector_OTRO_TEXT: "Other sector",
        participation: "Your organization's contribution",
        other_participation: "Which is it? ",
        environmental: "Main Environmental Indicators",
        other_environmental: "Which is it? ",
        social: "Main Social Indicators",
        sectorView: "Sector",
        sector_OTRO_TEXTView: "Other sector",
        participationView: "Your organization's contribution",
        other_participationView: "Other Contribution ",
        environmentalView: "Main Environmental Indicators",
        other_environmentalView: "Other Environmental Indicators ",
        socialView: "Main Social Indicators",
      },
      organization: {
        organization_type: "Please indicate what best describes your relationship with the organization: ",
        challenges: "Areas in which the organization faces challenges. ",
        other_challenges: "Other areas. ",
        projects_budget:
          "Billing range of your organization in the last two years (USD) ",
        nb_benefited:
          "Approximate number of people who have benefited from your organization in the last 2 years ",
        partipation_network:
          "Participation of your organization in social networks, national and international",
        influence:
          "Level of influence of your organization before other organizations",
        leadership: "Leadership of your company in the corporate sector",
        credibility_people:
          "Level of credibility of your organization before the population",
        market_orientation:
          "Level of orientation of the organization to market conections",
        decision_tools:
          "What is the effectiveness of the mechanisms for decision making in the organization",
        organization_typeView: "Relation with the organization ",
        challengesView: "Areas in which the organization faces challenges. ",
        other_challengesView: "Other areas. ",
        projects_budgetView:
          "Billing range of your organization in the last two years (US DOLLARS) ",
        nb_benefitedView:
          "Approximate number of people who have benefited from your organization in the last 2 years ",
        partipation_networkView:
          "Participation of the organization in social networks, national and international",
        influenceView:
          "Level of influence of the organization before other organizations",
        leadershipView: "Leadership of the company in the corporate sector",
        credibility_peopleView:
          "Level of credibility of the organization before the population",
        market_orientationView:
          "Level of orientation of the organization to market conections",
        decision_toolsView:
          "Effectiveness of the mechanisms for decision making in the organization",
      },
    },
    modals: {
      modalOpportunity: {
        needHelp: "Do you need help?",
        checkServices: "Check out the services of minkadev",
        acept: "I Accept",
        services: "Services",
        publish: "Publish",
        titleProp: "Post a proposal independently.",
        labelProp: "Post a Proposal",
        titleOpp: "Post an opportunity independently.",
        labelOpp: "Post an opportunity",
      },
      modalPost: {
        publish: "Your opportunity has been published",
        congratulations: "Congratulations!",
        vinculation: "The organization linked to this opportunity and the context in which it finds those it wishes to benefit seem to be ideal for advancing in the development of inclusive and sustainable business.",
        analitic: "From the information you have provided us, minka dev tools produce an analysis that allows determining the current conditions in the context of the organization and the community to develop a business with social impact. A higher score indicates a higher level of suitability and feasibility to connect to this type of business development.",
        evaluation: "Through this tool, the following aspects are evaluated:",
        track: "Background of the organization.",
        impact: "Impact generated.",
        model: "Operation model.",
        capacity: "Relationship capacity of the applicant organization.",
        viability: "Feasibility of the territory in which the organization is found.",
        capacityReprecentation: "Ability to represent the population in which you want to impact.",
        wish: "If you want advice and support to overcome these challenges contact us at:",
        evaluationRisk: "Evaluation of the functioning, soundness of the organization that proposes the opportunity, and the risks.",
        services: "Services related to this product that can be selected:",
      },
      modalDetail: {
        result: "Result",
        who: "Who is it targeted to?",
        requestInfo: "Ask for more information",
        registerInfo: "Register to request more information",
      },
      modalContact: {
        contact: "Contact the organization",
        message: "You can leave a message to the organization",
        responseMail: "Your email address (optional)",
      },
      modalConditions: {
        confirm: "I confirm that the information provided is true and responds to the interests and reality of my organization.",
        check: "I have reviewed and accept the",
        politics: "Privacy Policy",
        ofMinka: "from minka dev",
        acept: "I Accept",
      },
      modalOrganization: {
        type: "Type",
        sector: "Sector",
        activities: "Activities",
        values: "Business values",
      },
      modalPlus: {
        opportunity: "Opportunities minka dev",
        verify: "Verified and analized opportunities by minka dev",
        text: "The minka dev Plus opportunities are those where minka dev has verified the local capacity, feasibility, market routes with the greatest potential. These are high quality opportunities due to the analysis and support they have had to be identified. minka dev plus opportunities tend to have a greater potential for development success. Do you want to contact the services of minka dev to identify and develop a business opportunity with a high environmental social impact? Contact us",
      },
    },
    help: {
      detail1: {
        description: "Try to summarize the need you have identified, the communities with which you work or are expected to work and all the information you consider will provide details to anyone who wants to participate in solving the problem (max 250 words).",
        pais: "It refers to the country in which you want to create the opportunity",
      },
      detail2: {
        description: "Try to summarize the need you have identified, the communities with which you work or are expected to work and all the information you consider will provide details to anyone who wants to participate in solving the problem (max 250 words).",
        frequency: "Add a frequency. It can be Annual, Weekly or another temporary period",
        capacity: "Units can be appropriate measurements, such as tons, cubic meters, etc.",
      },
      impact: {
        social: "On September 25, 2015, world leaders adopted a set of global goals to eradicate poverty, protect the planet and ensure prosperity for all as part of a new sustainable development agenda. Each objective has specific goals that must be achieved in the next 15 years.",
      },
      solution2: {
        expected: 'If you require labor, please complete. Otherwise write "not applicable"',
      },
    },
  },
  //SPANISH
  "es-es": {
    general: {
      none: "Ninguno",
      sector: "Sector",
      session_expired: "Sesión expirada. Ingrese de nuevo.",
      country: {
        AF: "Afghanistan",
        AL: "Albania",
        DZ: "Algeria",
        AD: "Andorra",
        AO: "Angola",
        AR: "Argentina",
        AM: "Armenia",
        AZ: "Azerbaijan",
        AI: "Anguilla",
        AG: "Antigua And Barbuda",
        AW: "Aruba",
        AU: "Australia",
        AT: "Austria",
        BS: "Bahamas",
        BH: "Bahrain",
        BD: "Bangladesh",
        BB: "Barbados",
        BT: "Bhutan",
        BW: "Botswana",
        BR: "Brasil",
        BI: "Burundi",
        BY: "Belarus",
        BE: "Belgium",
        BZ: "Belize",
        BJ: "Benin",
        BO: "Bolivia",
        BA: "Bosnia and Herzegovina",
        BG: "Bulgaria",
        BF: "Burkina Faso",
        BRN: "Brunei",
        CM: "Cameroon",
        CA: "Canada",
        CV: "Cape Verde",
        KY: "Cayman Islands",
        CF: "Central African Republic",
        CN: "China",
        CO: "Colombia",
        CG: "Congo",
        CR: "Costa Rica",
        KH: "Cambodia",
        TD: "Chad",
        CL: "Chile",
        CD: "Democratic Republic of the Congo",
        HR: "Croatia",
        CU: "Cuba",
        CY: "Cyprus",
        CZ: "Czech Republic",
        DK: "Denmark",
        DJ: "Djibouti",
        DM: "Dominica",
        DO: "Dominican Republic",
        EC: "Ecuador",
        EG: "Egypt",
        ET: "Ethiopia",
        SV: "El Salvador",
        GQ: "Equatorial Guinea",
        ER: "Eritrea",
        EE: "Estonia",
        FJ: "Fiji",
        FI: "Finland",
        FR: "France",
        GA: "Gabon",
        DE: "Germany",
        GH: "Ghana",
        GR: "Greece",
        GT: "Guatemala",
        GM: "Gambia",
        GE: "Georgia",
        GD: "Grenada",
        GN: "Guinea",
        GW: "Guinea-Bissau",
        GY: "Guyana",
        HT: "Haiti",
        HN: "Honduras",
        HU: "Hungary",
        IS: "Iceland",
        IN: "India",
        ID: "Indonesia",
        IE: "Ireland",
        IT: "Italy",
        IR: "Iran",
        IQ: "Iraq",
        IL: "Israel",
        CIV: "Ivory coast",
        JM: "Jamaica",
        JP: "Japan",
        JO: "Jordan",
        KE: "Kenya",
        KZ: "Kazakhstan",
        KI: "Kiribati",
        KR: "South Korea",
        KW: "Kuwait",
        KG: "Kyrgyzstan",
        LA: "Laos",
        LV: "Latvia",
        LB: "Lebanon",
        LR: "Liberia",
        LY: "Libya",
        LI: "Liechtenstein",
        LT: "Lithuania",
        LU: "Luxembourg",
        MY: "Malaysia",
        MU: "Mauritius",
        MX: "Mexico",
        MZ: "Mozambique",
        MK: "Macedonia",
        MW: "Malawi",
        MV: "Maldives",
        ML: "Mali",
        MT: "Malta",
        MH: "Marshall Islands",
        MR: "Mauritania",
        FM: "Micronesia",
        MD: "Moldova",
        MC: "Monaco",
        MN: "Mongolia",
        ME: "Montenegro",
        MA: "Marruecos",
        MMR: "Myanmar",
        NA: "Namibia",
        NL: "Netherlands",
        NG: "Nigeria",
        NP: "Nepal",
        NZ: "New Zealand",
        NI: "Nicaragua",
        NE: "Niger",
        NOR: "Norway",
        OM: "Oman",
        PK: "Pakistan",
        PA: "Panama",
        PH: "Philippines",
        PT: "Portugal",
        PR: "Puerto Rico",
        PY: "Paraguay",
        PE: "Peru",
        PL: "Poland",
        PW: "Palau",
        QA: "Qatar",
        RU: "Russian Federation",
        RO: "Romania",
        RW: "Rwanda",
        SC: "Seychelles",
        SL: "Sierra Leone",
        SG: "Singapore",
        ZA: "South Africa",
        ES: "España",
        LK: "Sri Lanka",
        SZ: "Swaziland",
        SE: "Sweden",
        CH: "Switzerland",
        KN: "Saint Kitts and Nevis",
        LC: "Saint Lucia",
        VC: "Saint Vincent and the Grenadines",
        WS: "Samoa",
        SM: "San Marino",
        ST: "Sao Tome and Principe",
        SA: "Saudi Arabia",
        SN: "Senegal",
        RS: "Serbia",
        SK: "Slovakia",
        SI: "Slovenia",
        SB: "Solomon Islands",
        SO: "Somalia",
        SS: "South Sudan",
        SD: "Sudan",
        SR: "Suriname",
        SY: "Syrian",
        TZ: "Tanzania",
        TH: "Thailand",
        TT: "Trinidad And Tobago",
        TJ: "Tajikistan",
        TG: "Togo",
        TO: "Tonga",
        TN: "Tunisia",
        TR: "Turkey",
        TM: "Turkmenistan",
        TV: "Tuvalu",
        UG: "Uganda",
        UA: "Ukraine",
        AE: "United Arab Emirates",
        GB: "United Kingdom",
        US: "United States",
        UY: "Uruguay",
        UZ: "Uzbekistan",
        VU: "Vanuatu",
        VE: "Venezuela",
        VN: "Vietnam",
        YE: "Yemen",
        ZM: "Zambia",
        ZW: "Zimbabwe",
      },
      logoutBtn: "Cerrar sesión",
      logout: "Has cerrado tu sesión.",
      pais: "País",
      keywords: "Palabras claves",
      whatOffer: "¿Qué ofreces o necesitas?",
      marketBanner: "Encuentra y publica oportunidades de negocio con impacto social.",
      organizacion: "Organización",
      oportunidades: "Oportunidades",
      proposals: "Propuestas",
      communities: "Comunidades",
      oportunidad: "oportunidad",
      solucion: "solución",
      comunidad: "comunidad",
      back: "Regresar",
      selected: "Seleccionada",
      sobre: "Sobre Minkadev",
      date: "Fecha creación",
      select: "Seleccionar",
      mailSent: "Mail enviado",
      archivos: "Archivos",
      submittedproposals: "Propuestas presentadas",
      postProposal: "Postular una propuesta",
      closeOpportunity: "Cerrar oportunidad",
      contact: "Contactar",
      contactTitle: "CONTACTAR",
      message: "Mensaje",
      sendMessage: "Enviar",
      sdg: "ODS",
      message_empty: "El mensaje no puede estar vacío",
      error_message: "Error al enviar el mensaje",
      success_message: "Mensaje enviado correctamente",
    },
    login: {
      title: "Iniciar sesión o registrarse",
      login: "Ingresa",
      register: "Regístrate",
      recovery: "¿Has olvidado tu contraseña?",
      password: "Contraseña",
      confirm: "Confirma la contraseña",
      click: "Click aqui.",
      registerTitle: "Registrarse",
      names: "Nombres",
      lastNames: "Apellidos",
      email: "Email",
      send_mail: "Envía el Email",
      terminos: "Confirmo que la información aportada es veraz y responde a los intereses y realidad de mi organización.",
      acept: "He revisado y acepto la",
      politic: "política de privacidad",
      minka: "de Minka-dev",
      close: "[X Cerrar]",
      invalid_email: "Email inválido. Verificar.",
      invalid_mail: "No hay usuarios con este email.",
      password_empty: "Ingresa una constraseña.",
      first_name_empty: "Ingresa nombres",
      last_name_empty: "Ingresa apellidos",
      no_match: "Las constraseñas no coinciden.",
      wrong_password: "Contraseña",
      too_short: "La contraseña debe tener 6 caractéres",
      error_registry: "Hubo un error. Contáctanos.",
      already_exists: "El usuario ya existe. Intenta ingresar.",
    },
    cuenta: {
      organizacion: "Tu Organización",
      opportunities: "Tus Oportunidades",
      proposals: "Tus Propuestas",
      communities: "Comunidades Propuestas",
      noTienes: "No tienes ninguna ",
      proposal: "Propuesta",
      community: "Comunidad",
      publicada: "Publicada?",
      descripcion: "Descripción",
      fechaCracion: "Fecha Creación",
      expira: "Expira con la oportunidad",
      moreButton: "Ver más",
      createOpportunity: "Crear oportunidad",
      seeOpportunities: "Ver oportunidades",
    },
    home: {
      banner1: "Desarrollamos negocios sostenibles que generan resultados para el cambio",
      banner2: "Hemos construido nuestra experiencia en 3 tipos de negocio: negocios verdes, negocios inclusivos, negocios para economia circular",
      impactA: "Más de",
      impact1: "comunidades involucradas",
      impact2: "sectores generando oportunidades de negocio",
      marketplace: "Es una herramienta online para generar, identificar oportunidades de negocio y conectarlas con mercados de manera agregada. Depende de los datos de los actores del ecosistema en un tiempo definido. Ayuda al desarrollo de una mejor estructura y acompañamiento.",
      resultTitle: "RESULTADOS MINKA DEV",
      stat1A: "OPORTUNIDADES",
      stat1B: "creadas",
      stat2A: "SECTORES",
      stat2B: "desarrollados",
      stat3A: "CONEXIONES",
      stat3B: "al mercado",
      stat4A: "NEGOCIOS",
      stat4B: "estructurados",
      stat5A: "USUARIOS",
      stat5B: "activos",
      servicesTitle: "SERVICIOS MINKA DEV",
      services: "En minka dev trabajamos con 2 procesos que nos ayudan a generar los resultados que su organización necesita: Marketplace y Think and do Tank.",
      knowMore: "Conoce más",
      mapTitle: "PENSAMOS GLOBAL, TRABAJAMOS EN TU LOCALIDAD",
      mapOp1: "",
      mapOp2: "oportunidades.",
      mapSecLabel: "Sector más impactado",
      mapOpLabel: "Oportunidades principales:",
      prensaTitle: "MINKA DEV EN LA PRENSA",
      prensa: ["Un testimonio hecho por este medio de prensa a agregar. Eventualmente debería parametrizarse en el Bakend."],
      confiado: "HAN CONFIADO EN NOSOTROS",
      cadena: "Oferta de aprovisionamiento sostenible",
      consumo: "Oferta de consumo sostenible",
    },
    noticias: {
      title: "NOTICIAS",
      ultimas: "ÚLTIMAS NOTICIAS",
      subtitle: "Conoce lo último que ha sucedido en desarrollo economico sostenible. Síguenos en redes sociales para saber más.",
    },
    privacidad: {
      title: "Política de privacidad",
      priva1: "A través del presente aviso, MINKA-DEV SOLUCIONES Y ESTUDIOS, SL. (en adelante, minka dev), con domicilio social en Barcelona, Calle Industria 217-141 ent-1era Esc F, informa a los usuarios del web site acerca de su ",
      priva1a: "POLÍTICA DE PRIVACIDAD Y PROTECCIÓN DE DATOS DE CARÁCTER PERSONAL",
      priva2: "En cumplimiento de la Ley 15/1999, de 13 de diciembre, de Protección de Datos de Carácter Personal, se informa que los datos de carácter personal que, en su caso, sean facilitados por los usuarios a través del presente web site y que se encuentren bajo el ámbito de aplicación de la legislación aplicable, se incluirán en un fichero de datos personales cuyo responsable y titular es minka dev y que se encuentra debidamente inscrito ante la Agencia Española de Protección de Datos. Asimismo, en caso de rellenar cualquiera de los formularios con los datos personales solicitados y aceptar su envío, AUTORIZA EXPRESAMENTE a minka dev para que trate e incorpore en el fichero automatizado de su propiedad aquellos datos personales facilitados, así como todos los datos que se generen en relación con su participación y/o uso de los distintos servicios que se ofrecen o pudieran ofrecerse en este web site.",
      priva3: "En el caso de que en los formularios se incluyeran datos de carácter personal referentes a personas físicas distintas de quién efectúa la solicitud, deberá ésta última informar a dichas personas de los extremos contenidos en el presente documento.",
      priva4: "Salvo que específicamente se establezca lo contrario, se considerará necesario completar todos los datos de los campos de cada formulario, debiendo el usuario rellenar los formularios con datos verdaderos, exactos, completos y actualizados. El usuario será el único responsable de cualquier daño o perjuicio, directo o indirecto, que pudiera ocasionar a minka dev o a cualquier tercero a causa de la cumplimentación de los formularios con datos falsos, inexactos, incompletos o no actualizados. En tal sentido, el usuario garantiza que los datos personales en su caso facilitados son veraces y se hace responsable de comunicar a minka dev cualquier modificación en los mismos.",
      priva5: "Las finalidades para las que son almacenados los datos personales recabados en el entorno del presente web site puede ser una o varias de las que a continuación se relacionan en función del registro o contacto efectuado por el Usuario:",
      priva6a: "a) Registro como Inversor, Identificador de Oportunidades o como usuario genérico: La gestión de la condición de usuario registrado;",
      priva6b: "b) Registro Newsletter: La gestión de su inscripción en el Servicio de Newsletter accesible desde el Website;",
      priva6c: "c) Formulario contacto Think Tank: La gestión del contacto en relación al servicio minka dev Think Tank.",
      priva6d: "d) Asimismo, los datos recabados podrán ser utilizados para el envío de información técnica, informativa y cualquier otra que minka dev considere de interés para sus usuarios.",
      priva7: "Los datos facilitados nunca serán usados para finalidad distinta a las anteriormente descritas, siendo inmediatamente cancelados cuando dejen de ser necesarios para tal fin.",
      priva8: "En caso de que minka dev prestara algún tipo de servicio especial en el que determine unas previsiones específicas diferentes a éstas en lo relativo a la protección de datos personales, tendrá primacía la aplicación de las normas particulares indicadas para ese servicio en particular por encima de las presentes, en caso de incongruencia.",
      priva9: "Toda la información relativa a datos de carácter personal recibida por minka dev, es tratada con la máxima confidencialidad, respetando en todo momento el deber de secreto profesional, procediendo únicamente a las comunicaciones estrictamente necesarias para la consecución de las finalidades anteriormente descritas.",
      priva10: "Asimismo, mediante la presente Política de Privacidad minka dev informa que ha adoptado los niveles de seguridad en materia de Protección de Datos Personales legal y reglamentariamente requeridos, y ha instalado todos los medios y medidas técnicas a su alcance para evitar la pérdida, mal uso, alteración y acceso no autorizado a los datos personales incluidos en sus ficheros.",
      priva11: "El usuario tiene derecho a acceder a la información que le concierne recopilada en los ficheros de minka dev, rectificarla de ser errónea, cancelarla u oponerse a su tratamiento a través del correo electrónico a ",
      priva11a: ", o comunicación escrita y firmada (adjuntando copia del DNI) dirigida al responsable del fichero, esto es, la sociedad MINKA-DEV SOLUCIONES Y ESTUDIOS, SL., con domicilio social en Barcelona, calle Industria 217-141 ent-1era (Ref. Protección de Datos).",
      priva12: "El usuario registrado (excluyendo aquellos que se hayan suscrito únicamente al servicio Newsletter o hayan remitido formulario de contacto por los Servicios Think Tank) otorgan de forma inequívoca su consentimiento expreso a que sus datos personales facilitados puedan ser cedidos, exclusivamente para las finalidades descritas con anterioridad, a las personas que integran el ecosistema minka dev (identificadores, impulsores, patrocinadores, inversores, comités de evaluación) para la valoración y estudio de las soluciones aportadas, aunque dicha cesión conlleve la transferencia internacional de datos personales.",
      priva13: "La aceptación del usuario para que sus datos puedan ser tratados en la forma establecida en la presente Política de Privacidad, tiene siempre carácter revocable, sin efectos retroactivos, conforme a lo que dispone el artículo 6 de la Ley Orgánica 15/1999 de 13 de Diciembre.",
      priva14: "minka dev se reserva el derecho a modificar su política de protección de datos de acuerdo a su criterio, o a causa de un cambio legislativo, jurisprudencial o en la práctica empresarial. Si minka dev introdujera alguna modificación, el nuevo texto será publicado en este mismo web site, donde el usuario podrá tener conocimiento de la política de protección de datos vigente en cada momento. En cualquier caso, la relación con los usuarios se regirá por las normas previstas en el momento preciso en que se accede al web site.",
    },
    odsExplication: {
      explication: "Los Objetivos de Desarrollo Sostenible, también conocidos como Objetivos Mundiales, se adoptaron por todos los Estados Miembros en 2015 como un llamado universal para poner fin a la pobreza, proteger el planeta y garantizar que todas las personas gocen de paz y prosperidad para 2030. las cuales se deben cumplir por organizaciones tanto publicas como privadas.",
    },
    header: {
      Servicios: "Servicios",
      Noticias: "Noticias",
      "Mi Cuenta": "Mi Cuenta",
      Contacto: "Contacto",
      Nosotros: "Nosotros",
      Ingresar: "Ingresar",
    },
    footer: {
      oportunidades: "Oportunidades",
      servicios: "Servicios",
      acerca: "Acerca de minka dev",
      contacto: "Contáctanos",
      privacidad: "Privacidad",
      participa: "PARTICIPA",
      institucion: "INSTITUCIÓN",
      actividad: "ACTIVIDAD",
    },
    forms: {
      errors: {
        callToAction: "Necesitas llenar los siguientes campos: ",
        name: "Nombre",
        website: "Página Web",
        sector: "Sector",
        nif: "Número de identificación de la empresa (NIE o NIF)",
        phone: "Teléfono",
        type: "No has seleccionado un tipo de oportunidad",
        nb_affected: "Debes escoger un número de personas impactadas",
        deadline: "El formato de la fecha de cierre es AAAA/MM/DD",
      },
      messages: {
        error: "Hubo un error. Contáctenos.",
        savedSuccesfully: "Guardado correctamente",
      },
      controls: {
        guardar: "Guardar",
        editar: "Editar",
        return: "Volver",
        publicar: "Publicar",
        click_edit: "Haz clic para editar el título",
        selectSectors: "Sectores al que pertenece actualmente la oportunidad",
        addSector: "Agregar un sector",
        addSgd: "Agregar un ODS",
        selectSgd: "Selecciona uno o varios ODS",
        add: "Agrega texto",
        urlVideos: "Incrustar videos de YouTube",
        urlVideosView: "Videos",
        backOpportunity: "Volver a Oportunidad",
        selectFile: "Seleccionar Archivo",
        sendFile: "Enviar",
        info: "*Presiona \"Guardar\" después de completar la información. Puedes editarla más tarde al volver a presionar \"Editar\"."
      },
      opportunities: {
        addTitle: "¿Cuál es la frase que mejor describe el producto o servicio que ofreces?",
        viewTitle: "Frase que mejor describe el producto o servicio que ofreces",
        anexos: "Adjunta uno a uno cualquier documento que consideres de importancia para conocer tu propuesta. Formato: Archivos PDF no superiores a 4MB de peso.",
        anexosView: "Documentos relacionados (PDF)",
        attachedLinks: "En el caso que sea un enlace a una página web o drive por favor inclúyelo en el siguiente espacio: *Máximo 3 enlaces",
        attachedLinksView: "Enlaces",
        uploadImageMessage: "Adjunta una a una las imágenes que representen a tu organización. Formato: PNG, Jpeg, no superior a un peso total de 4MB. Máximo 8 imágenes",
        oppDescription: "Por favor, proporciona una breve descripción del servicio que estás ofreciendo. Esto ayudará a los potenciales clientes a entender lo que puedes proporcionarle y si cumple con sus necesidades. Por favor, sé lo más específico posible, incluyendo cualquier detalle relevante sobre el alcance, los requisitos y los resultados deseados del servicio.",
        oppMotivation: "Este campo es una oportunidad para que nos cuentes un poco más acerca de tus necesidades y expectativas en relación al servicio que estás buscando. Queremos entender cuál es tu motivación para adquirir nuestros servicios y cómo podemos ayudarte a alcanzar tus objetivos. Por favor, sé tan detallado como sea posible para que podamos proporcionarte la mejor solución para tus necesidades.",
        oppTypeFormTitle: "Tipo de oportunidad",
        oppBasicFormTitle: "Información básica",
        oppAttachmentsFormTitle: "Adjuntos",
        welcomeTitle: "Te damos la bienvenida a la creación de tu oportunidad",
        welcomeInfo: "Por favor, completa la siguiente información de manera clara y sincera. En la siguiente barra, podrás seguir el progreso del proceso."
      },
      organization: {
        country: "País en el que desarrolla sus actividades",
        city: "Ciudad",
        address: "Dirección",
        //lat and lng?
        name: "Nombre de la organización en la que postulas",
        type: "Tipo de organización",
        website: "Sitio web de la organización",
        sector: "Sector en que trabaja",
        sector_OTRO_TEXT: "Otro sector:",
        nif: "Número de identificación de la empresa (NIE o NIF)",
        nifhelp: "Número de identificación de tu empresa. De acuerdo a tu país, es probable que este código contenga letras o sólo números.",
        phone: "Teléfono de la organización",
        age: "Años de existencia de la organización",
        billing: "Monto de ingresos anuales USD",
        employees: "Número de empleados",
        sustainability: "Principal línea de sostenibilidad económica",
        sus: "Otras líneas de sostenibilidad económica secundarias",
        role: "¿Cuál es tu rol en de la organización?",
        department: "Departamento al que perteneces en la organización",
        representation: "Tengo capacidad para representar a mi organización en la plataforma minka dev",

        //activities
        act: "Tipo de actividades que desarrolla y porcentajes de dedicación",
        _per: ", con dedicación (%):",

        industry: "Industria en la que trabaja:",
        act_otha: "Especifica la industria",
        pr: "Principios que la organización considera vitales para colaborar con otras organizaciones",

        logo: "Añade el logo de tu organización",
        image: "Seleccionar Archivo",
        Intro: "La siguiente información se usa para valorar la compatibilidad de esta organización con diferentes oportunidades postuladas en la plataforma de minka dev.",
      },
    },
    organizations: {
      title: "Tu Organización",
      nocomplete: "No has completado  tu organización. La necesitas para crear oportunidades y proponer soluciones.",
      define: "Define el perfil de tu organización",
      adicional: "Información complementaria",
      role: "Tu Rol",
      sostenibilidad: "Sostenibilidad",
      information: "Información Básica",
    },
    opportunities: {
      adExp: "Agrega una oportunidad",
      consumo: "Consumo y producción sostenible",
      information_consumo: "Oportunidades dirigidas a la adquisición de productos y/o servicios para satisfacer las necesidades de consumo y producción que generan mayor bienestar social y cuidado del medioambiente.",
      cadena: "Aprovisionamiento sostenible",
      information_cadena: "Oportunidades relacionadas a el aprovisionamiento de productos que se producen con criterios de sostenibilidad (creación de bienestar social, cuidado del medioambiente).",
      offer: "Ofrecemos productos o servicios",
      information_offer: "Oportunidades que ofrecen un producto o servicio (relacionadas a aprovisionamiento sostenible,consumo o consumo sostenible). Buscan una conexión de mercado que las pueda adquirir.",
      need: "Necesitamos productos o servicios",
      information_need: "Oportunidades creadas para buscar un producto o servicio (relacionadas a una cadena de aprovisionamiento sostenible, consumo o producción sostenible). Buscan una organización que pueda resolver la necesidad planteada",
      offerExplain: "Oportunidades creadas para buscar  un producto o servicio (relacionadas a una cadena de aprovisionamiento sostenible, consumo o producción sostenible). Buscan una organización que pueda resolver la necesidad planteada",
      needExplain: "Oportunidades que ofrecen un producto o servicio (relacionadas a aprovisionamiento sostenible,consumo o consumo sostenible). Buscan una conexión de mercado que las pueda adquirir",
      consumoExplain: "Oportunidades dirigidas a la adquisición de productos y/o servicios para satisfacer las necesidades de consumo y producción que generan mayor bienestar social y cuidado del medioambiente",
      cadenaExplain: "Oportunidades relacionadas a el aprovisionamiento de productos que se producen con criterios de sostenibilidad (creación de bienestar social, cuidado del medioambiente)",
      anexos: "Documentos relacionados (PDF): Relaciona cualquier información que consideres de importancia para conocer tu propuesta.",
      anexosView: "Documentos relacionados (PDF)",
      closeFilter: "Nuestra trayectoria: ver las oportunidades cerradas",
    },
    servicios: {
      invite: "Estamos aquí para ayudar a convertir una idea o problema en una oportunidad con impacto social.",
      invite2: "Infórmate sobre nuestros servicios",
      algunas: "Algunas de nuestras experiencias",
      titleBanner: "SERVICIOS",
      subTitleBanner: "Logramos resultados concretos en el desarrollo de negocios sostenibles",
      title: "¿Qué hacemos?",
      subTitle: "Minka dev ha construido su experiencia en 3 tipos de negocios: negocios verdes, negocios inclusivos y negocios para la economia circular.",
    },
    nosotros: {
      title: "NOSOTROS",
      subTitle: "MANIFIESTO",
      about: "En minka dev generamos y desarrollamos modelos de negocios sostenibles que ayudan a resolver un problema social y/o ambiental.",
      developed: "Transformamos retos sociales, medioambientales en negocios sostenibles",
      graymessages: {
        m1: "Creemos en el potencial de transformar necesidades sociales y ambientales en negocios sostenibles.",
        m2: "Desarrollamos modelos y metodologías innovadoras, centrados en las personas.",
      },
      business: {
        title1: "NEGOCIOS VERDES",
        title2: "NEGOCIOS INCLUSIVOS",
        title3: "NEGOCIOS PARA LA ECONOMÍA CIRCULAR",
        text1: "Contribuimos a el desarrollo de negocios que generan beneficios ambientales directos, agregando valor y contribuyendo a la conservación del medioambiente.",
        text2: "Contribuimos a el desarrollo de Negocios Inclusivos que inciden directamente en la reducción de pobreza y acceso a oportunidades de poblaciones en contextos de vulnerabilidad.",
        text3: "Contribuimos a el desarrollo de negocios que promueven la producción de bienes y servicios sostenibles, reduciendo el consumo, las fuentes de energía, los desperdicios y el tiempo.",
      },
      reality: {
        title: "LO HACEMOS REALIDAD",
        text: "Convertimos problemas e ineficiencias en negocios viables, innovadores y sostenibles. Hemos partido de problemáticas como el desperdicio de alimentos o una especie natural amenazada y los hemos transformado en negocios viables, desarrollados con alianzas público-privadas catalizadas por minka dev. Los negocios que se han impulsado con nuestro acompañamiento les han permitido a las empresas involucradas innovar y ampliar sus mercados.",
      },
      testimonies: {
        title: "TESTIMONIOS",
      },
      work: {
        title: "NUESTRO EQUIPO",
        text: "¿Tienes interés en sumarte a nuestro equipo para impulsar negocios sostenibles en el cambio social?",
        button: "Escríbenos",
      },
      messajeAlly: {
        title: "ALIANZAS",
      },
      workTeam: {
        position1: "Co fundadora y Directora",
        description1: "Tiene más de 11 años de experiencia en el diseño, evaluación e investigación de modelos de negocio de alto impacto social en América Latina, India, Uganda. Ha trabajado en sectores como agua, energía, vivienda, agricultura, alimentación y microfinanzas, para empresas privadas, asociaciones empresariales y organismos internacionales.",
        position2: "Analista de oportunidades en Minkadev.",
        description2: "Ha participado en proyectos de desarrollo de negocios sostenibles en el sector agrícola - forestal y en  estrategias de sostenibilidad en empresas de consumo. Msc en Innovación y emprendimiento cuenta con experiencia en planificación estratégica, metodologías ágiles, e investigación de mercados.",
        position3: "Miembro del consejo asesor",
        description3: "Director de los Juegos Olímpicos de Barcelona 92, amplia experiencia en el sector energético (Director de Organización y Informática Enher) y en el de tecnología (Director general de Indra). Ha estado vinculado a 22@Network, presidente; CEOE; miembro del Consejo Empresarial de la Cámara de Comercio de Barcelona, sectoriales (Consorcio Digital, Presidente; AMETIC, Vicepresidente).",
        position4: "Ingeniero Forestal.",
        description4: "Máster en Gerencia Ambiental, con más de 10 años de experiencia en proyectos para el desarrollo de cadenas de abastecimiento y estructuración de negocios verdes de impacto social y ambiental. Ha desarrollado proyectos en Colombia, Ecuador y Perú en sectores forestales, agrícolas, pesqueros y ganaderos, para empresas privadas, organizaciones sociales y organismos internacionales.",
        position5: "Miembro del consejo asesor",
        description5: "Ingeniero Industrial (Universitat Politècnica de Catalunya-UPC), Ph.D. in Managerial Economics and Decision Sciences (Northwestern University) y Doctor en Ciencias Económicas y Empresariales (Universitat Autónoma de Barcelona- UAB). Titular de la Cátedra Carl Schrøder de Dirección Estratégica, Director del Departamento de Dirección Estratégica y Director Asociado para Investigación y Docencia (IESE Business School). Fue Presidente de Strategic Management Society, Vice-Presidente de la Iberoamerican Academy of Management, y Presidente Fundador de European Academy of Management.",
        position6: "Business Builder España",
        description6: "Tiene más de 20 años de experiencia en proyectos de investigación de mercados, data science, evaluación de impacto social y medioambiental y planificación estratégica de triple impacto. Ha desarrollado más de 100 proyectos en España, Colombia, Ecuador, India, Portugal y Andorra, para empresas de gran consumo, alimentación, retail, energía, automoción, agricultura, laboratorios, publicidad, medios de comunicación, así como para el tercer sector, asociaciones empresariales, sector público, bancos de desarrollo y organismos internacionales.",
      },
    },
    contacto: {
      title: "¿Necesitas contactarte con nosotros?",
      text: "Llena este formulario o contáctanos por nuestras redes sociales.",
      button: "Enviar",
    },
    definition: {
      newButtons: {
        cancel: "Cancelar",
        save: "Guardar",
      },
      flash: {
        close_opportunity: "La oportunidad a cerrado",
        msg1: "Oportunidades creadas para buscar  un producto o servicio (relacionadas a una cadena de aprovisionamiento sostenible, consumo o producción sostenible). Buscan una organización que pueda resolver la necesidad planteada",
        msg2: "Oportunidades que ofrecen un producto o servicio (relacionadas a aprovisionamiento sostenible,consumo o consumo sostenible). Buscan una conexión de mercado que las pueda adquirir",
        msg3: "Oportunidades relacionadas a la oferta y demanda de productos o servicios que se llevan a cabo de acuerdo con criterios de sostenibilidad (creación de bienestar social, cuidado del medioambiente y buen desempeño económico)",
        msg4: "Oportunidades dirigidas a la adquisición de productos y/o servicios para satisfacer las necesidades de consumo y producción que generan mayor bienestar social y cuidado del medioambiente",
      },
      button: {
        propouse: "Haz Propuesta",
        youPropouse: "Haz tu propuesta",
        notOrganization: "No tienes organización",
        closePropouse: "Cerrar propuesta",
        close: "Cerrada",
      },
      type: "Tipo de Oportunidad",
      filters: {
        offer: "Ofrezco productos o servicios",
        need: "Busco Productos o servicios",
        consumo: "Consumo y producción sostenible",
        cadena: "Aprovisionamiento sostenible",
      },
      sectors: "Sectores",
      impact: "Número de personas que se buscan beneficiar con la oportunidad",
      hectaresPreservation: "Número de hectáreas que se buscan preservar con la oportunidad",
      dateClose: "Fecha de Cierre de Oportunidad",
      concept: "Palabras Clave",
      description: "Descripción",
      conditions: "Otras características",
      motivation: "Motivación",
      idoneo: "La organización vinculada a esta oportunidad y el contexto en el cual se encuentra a quienes desea beneficiar parecen ser idóneas para avanzar en el desarrollo de negocio inclusivos y sostenibles.",
      definition: "Definición del producto o servicio",
      country: "País",
      partner: "Oportunidad desarrollada con el apoyo de:",
    },
    newPanel: {
      mode: "Modo",
      publish: "PUBLICAR",
      publishButton: "PUBLICAR OPORTUNIDAD",
      propPublishButton: "PUBLICAR PROPUESTA",
      publishing: "Publicando...",
      save: "Guardar",
      saving: "Guardando...",
      saved: "Guardado",
      edit: "Editar",
      preview: "Previsualizar",
      blankTitle: "La oportunidad debe tener un título",
      publishMessage: "*Presiona 'PUBLICAR OPORTUNIDAD' solo cuando estés seguro de haber completado correctamente todos los campos. No podrás editar después de publicar.",
      propPublishMessage: "*Presiona 'PUBLICAR PROPUESTA' solo cuando estés seguro de haber completado correctamente todos los campos. No podrás editar después de publicar.",
      publishModalTitle: "Confirmar publicación",
      publishModalText: "Confirmo que he proporcionado la información de manera precisa y completa. Deseo proceder a publicar la oportunidad, entendiendo que no podré realizar ediciones una vez publicada."
    },
    opportunity: {
      accordion: {
        select: "Selecciona un tipo de oportunidad",
        details: "Detalles",
        impact: "Impacto",
        organization: "Organización",
        haveCommunity: "Tengo Comunidad",
        customerProfile: "Perfil de cliente",
        findCommunity: "Busco",
        territory: "Territorio",
        community: "Comunidad",
      },
      basicCommunity: {
        income: "Indica el nivel promedio de ingresos diarios de la comunidad (US DOLLARS)",
        etnia: "Representas/Buscas comunidades con presencia de las siguientes etnias.",
        etniaWitch: "Otras etnias:",
        assets: "Señale los principales activos que tiene o debe tener la comunidad.",
        services: "Servicios a los cuales la comunidad tiene acceso de manera estable.",
        services_text: "Otros servicios:",
        incomeView: "Ingresos mensuales de la comunidad",
        etniaView: "Etnias que buscan",
        etniaWitchView: "Etnias que buscan",
        assetsView: "Principales activos de la comunidad",
        servicesView: "Servicios",
        services_textView: "Servicios",
      },
      detail1: {
        cause: "Describe la causa de esta necesidad",
        solutions: "¿Conoces soluciones existentes para la necesidad identificada?",
        solutions_which: "¿Cuáles?",
        value: "¿Para qué deseas identificar otro tipo de solución diferente a las existentes?",
        value_OTHER_TEXT: "Específica el tipo",
        causeView: "Descripcion de la necesidad",
        solutionsView: "Soluciones existentes para la necesidad identificada",
        solutions_whichView: "Cuales soluciones existentes para la necesidad identificada",
        valueView: "Tipo de solución diferente a las existentes",
        value_OTHER_TEXTView: "Otro tipo de solución",
      },
      detail2: {
        description: "Descripción del producto/servicio",
        capacity: "¿Cuál es la capacidad actual de ofrecer el producto/servicio?",
        capacity_quantity: "Cantidad",
        capacity_quantity_validation: "La cantidad debe ser mayor a cero",
        capacity_frequency: "Frecuencia",
        capacity_unit: "Unidad",
        markets: "En qué mercados se ha comercializado este servicio o producto",
        markets_OTHER_TEXT: "Otro mercado",
        development_level: "Nivel de desarrollo en el que se encuentra el producto/servicio",
        innovation: "¿Cuáles características tiene el servicio/producto que se ofrece al mercado?",
        innovation_OTHER_TEXT: "Otra característica",
        descriptionView: "Descripción del producto/servicio",
        capacityView: "Capacidad actual de ofrecer el producto/servicio",
        capacity_quantityView: "Cantidad",
        capacity_frequencyView: "Frecuencia",
        capacity_unitView: "Unidad",
        marketsView: "Mercados en los que se ha comercializado este servicio o producto",
        markets_OTHER_TEXTView: "Otros mercado",
        development_levelView: "Nivel de desarrollo en el que se encuentra el producto/servicio",
        innovationView: "Características que tiene el servicio/producto que se ofrece al mercado",
        innovation_OTHER_TEXTView: "Otras característica",
      },
      detail3: {
        description: "Descripción del producto",
        vulnerable: "¿El producto o servicio que ofreces se comercializa en algunas poblaciones vulnerables?",
        age_range: "¿A qué rango de edades se dirige tu  producto?",
        affordability: "Estrategia para facilitar la asequibilidad del producto",
        affordability_OTHER_TEXT: "¿Cuáles estrategias?",
        development: "Nivel de desarrollo en el que se encuentra el producto",
        value: "Valor agregado de tu producto comparativamente con otras opciones en el mercado.",
        value_OTHER_TEXT: "¿Cuáles?",
        action: "¿Qué acción esperas que ejecute la comunidad en el marco de la solución planteada?",
        activity: "Además de la compra de su producto o servicio, ¿en qué otra actividad le gustaría que la organización participara?",
        activity_OTHER_TEXT: "¿Cuáles actividades?",
        descriptionView: "Descripción del producto",
        vulnerableView: "Comercialización del productos en otras poblaciones vulnerables",
        age_rangeView: "Rango de edades al cual se dirige el producto",
        affordabilityView: "Estrategia para facilitar la asequibilidad del producto o servicio",
        affordability_OTHER_TEXTView: "Otras estrategias",
        developmentView: "Nivel de desarrollo en el que se encuentra el producto ",
        valueView: "Valor agregado de tu producto comparativamente con otras opciones en el mercado.",
        value_OTHER_TEXTView: "Tipo",
        actionView: "Acciones que ejecutara la comunidad en el marco de la solución planteada",
        activityView: "Actividad en la cual se integrara la comunidad y en la que espera generar impacto social",
        activity_OTHER_TEXTView: "Otras actividades",
      },
      detail4: {
        capacity_quantity_validation: "La cantidad debe ser mayor a cero",
        description: "Descripción del producto/servicio",
        buy_capacity: "¿Cuál es la capacidad actual de compra/contratación?",
        buy_capacity_quantity: "Cantidad",
        buy_capacity_frequency: "Frecuencia",
        buy_capacity_unit: "Unidad",
        innovation: "Con respecto al servicio/producto que ofreces, ¿cuál es su diferencia principal?",
        value: "Valor agregado de la vinculación que propones comparativamente con otros procesos similares.",
        value_OTHER_TEXT: "¿Cuáles?",
        sails_capacity: "Capacidad mínima esperada que provea la comunidad",
        sales_capacity_quantity: "Cantidad",
        sales_capacity_frequency: "Frecuencia",
        sales_capacity_unit: "Unidad",
        descriptionView: "Descripción del producto/servicio",
        buy_capacityView: "Capacidad actual de compra/contratación?",
        buy_capacity_quantityView: "Cantidad",
        buy_capacity_frequencyView: "Frecuencia",
        buy_capacity_unitView: "Unidad",
        innovationView: "Diferencia principal con respecto a el producto / servicio que ofrece",
        valueView: "Valor agregado de lo que propone comparativamente con otros procesos similares.",
        value_OTHER_TEXTView: "Tipo",
        sails_capacityView: "Capacidad mínima esperada que provea la comunidad",
        sales_capacity_quantityView: "Cantidad",
        sales_capacity_frequencyView: "Frecuencia",
        sails_capacity_unitView: "Unidad",
      },
      hasCommunity: {
        name: "Nombre de la organización.",
        country: "País",
        city: "Ciudad",
        address: "Dirección",
        role: "Rol con el que te identificas sobre tu relación con la organización que quieres beneficiar",
        affected: "Número de personas que se espera beneficiar",
        antiguity: "¿Hace cuánto está constituida la organización con la que colabora?",
        characteristic: "Señala las características con las que describirías la organización.",
        association: "Tipo de asociación que representa la organización.",
        association_OTRO_TEXT: "Otras formas de asociación:",
        experience_management:
          "Nivel de Experiencia de la organización en formulación y gestión de proyectos",
        experience_development:
          "Nivel de experiencia de la organización en proyectos de desarrollo social-económico",
        credibility_ong: "Nivel de Credibilidad de tu organización ante ONGs",
        financing: "¿Qué tipo de financiamiento ha recibido la organización?",
        financing_type: "¿Qué tipo de financiamiento ha recibido la organización?",
        other_financing: "¿Cuáles?",
        time: "¿Hace cuánto trabajas con esta organización?",
        nameView: "Nombre de la organización.",
        countryView: "País",
        cityView: "Ciudad",
        addressView: "Dirección",
        rolView: "Rol con la organización",
        organization_roleView: "Relación con la organización:",
        affectedView: "Número de personas que se espera beneficiar",
        antiguityView: "Tiempo desde que está constituida la organización con la que colabora.",
        characteristicView: "Características que describen la organización.",
        associationView: "Tipo de asociación que representa la organización.",
        experience_managementView:
          "Nivel de Experiencia de la organización en formulación y gestión de proyectos",
        experience_developmentView:
          "Nivel de experiencia de la organización en proyectos de desarrollo social-económico",
        credibility_ongView:
          "Nivel de Credibilidad de tu organización ante ONGs",
        financingView: "Tipo de financiamiento que ha recibido la organización",
        financing_typeView: "Tipo de financiamiento que ha recibido la organización",
        other_financingView: "Otro tipo de financiamiento",
        accessView: "Tipo de vías que tiene acceso la organización",
        timeView: "Tiempo trabajado con esta organización",
        populationView: "Población y su dispersión",
      },
      impact: {
        sector: "Sector a impactar",
        sector_OTRO_TEXT: "Otro sector",
        participation: "Aporte de tu Organización",
        other_participation: "¿Cuáles?",
        environmental: "Principales Indicadores Ambientales",
        other_environmental: "¿Cuáles?",
        social: "Principales Indicadores Sociales",
        sectorView: "Sector",
        sector_OTRO_TEXTView: "Otro sector",
        participationView: "Aporte de tu Organización",
        other_participationView: "Otro Aporte",
        environmentalView: "Principales Indicadores Ambientales",
        other_environmentalView: "Otros indicadores ambientales",
        socialView: "Principales Indicadores Sociales",
      },
      customerProfile: {
        description: "Describe el perfil deseado de las comunidades a ser vinculadas:",
        location: "Ubicación preferida de las organizaciones a vincular",
        population: "Tipo de población y dispersión de los clientes que desea vincular.",
        infrastructure_type: "Tipo infraestructura que su cliente necesitaría para adquirir su producto/ servicio",
        other_infrastructure_type: "¿Cuáles?",
        unfavorable: "Las poblaciones a ser identificadas deben estar en una de las siguientes condiciones desfavorables.",
        unfavorable_which: "Otras condiciones desfavorables:",
        other_conditions: "Relaciona otras condiciones que considere de importancia:",
        descriptionView: "Perfil deseado de las comunidades a ser vinculadas:",
        locationView: "Ubicación preferida de las organizaciones a vincular",
        populationView: "Tipo de población y dispersión de los clientes que desea vincular.",
        unfavorableView: "Las poblaciones a ser identificadas deben estar en una de las siguientes condiciones desfavorables.",
        unfavorable_whichView: "Otras condiciones desfavorables:",
        other_conditionsView: "Relaciona otras condiciones que considere de importancia:",
      },
      needsCommunity: {
        description: "Describe el perfil deseado de las comunidades a ser vinculadas:",
        location: "Ubicación preferida de la cadena de aprovisionamiento a vincular (sólo la zona geográfica)",
        association: "La forma de asociación que prefieres con la cadena de aprovisionamiento.",
        association_OTRO_TEXT: "Otras formas de asociación:",
        population: "Tipo de población y dispersión de la cadena de aprovisionamiento que desea vincular.",
        unfavorable: "Las poblaciones a ser identificadas deben estar en una de las siguientes condiciones desfavorables.",
        unfavorable_which: "Otras condiciones desfavorables:",
        other_conditions: "Relaciona otras condiciones que considere de importancia:",
        descriptionView: "Perfil deseado de las comunidades a ser vinculadas:",
        locationView: "Ubicación preferida de la cadena de aprovisionamiento a vincular.",
        associationView: "Asociación preferida con la cadena de aprovisionamiento.",
        association_OTRO_TEXTView: "Otras formas de asociación:",
        populationView: "Tipo de población y dispersión de la cadena de aprovisionamiento a vincular que desea vincular.",
        unfavorableView: "Las poblaciones a ser identificadas deben estar en una de las siguientes condiciones desfavorables.",
        unfavorable_whichView: "Otras condiciones desfavorables:",
        other_conditionsView: "Relaciona otras condiciones que considere de importancia:",
      },
      organization: {
        organization_type: "Señala lo que mejor describe tu relación con la organización:",
        challenges: "Ámbitos en los que la organización enfrenta desafíos",
        other_challenges: "Otros ámbitos",
        projects_budget:
          "Rango de facturación de su organización en los últimos dos años (USD)",
        nb_benefited:
          "Número aproximado de personas que se han beneficiado por su organización en los últimos 2 años",
        partipation_network:
          "Nivel de participación de tu organización en redes locales, nacionales, e internacionales",
        influence:
          "Nivel de influencia de tu organización ante otras organizaciones",
        leadership: "Nivel de Liderazgo de la organización en el sector",
        credibility_people:
          "Nivel de Credibilidad tu organización ante la población",
        market_orientation:
          "Nivel de orientación de la organización a conexiones de mercado",
        decision_tools:
          "Cual es la efectividad de los mecanismos para la toma de decisiones en la organización",
        organization_typeView: "Relación con la organización:",
        challengesView: "Ámbitos en los que la organización enfrenta desafíos",
        other_challengesView: "Otros ámbitos",
        projects_budgetView:
          "Rango de facturación de su organización en los últimos dos años (US DOLLARS)",
        partipation_networkView:
          "Nivel de participación de tu organización en redes locales, nacionales, e internacionales",
        influenceView:
          "Nivel de influencia de la organización ante otras organizaciones",
        leadershipView: "Nivel de Liderazgo de la organización en el sector",
        credibility_peopleView:
          "Nivel de Credibilidad tu organización ante la población",
        market_orientationView:
          "Nivel de orientación de la organización a conexiones de mercado",
        decision_toolsView:
          "Efectividad de los mecanismos para la toma de decisiones en la organización",
      },
    },
    territory: {
      territory_population: "Tipo de población y su dispersión",
      territory_access: "¿A qué tipo de vías tiene acceso la organización?",
      work: "Exiten trabajos desarrollados conjuntamente entre el sector privado, el público y ONGs",
      trust: "En la zona hay un ambiente de desconfianza hacia las instituciones.",
      presence: "Hay presencia de diversas organizaciones que propenden por el desarrollo local.",
      public: "El sector público tiene un rol efectivo en promover el desarrollo económico y social en el territorio.",
      corporative: "El sector empresarial es visto como agente de desarrollo",
      communities: "Las comunidades con las que su organización se relaciona en el territorio están empoderadas e impulsan su propio desarrollo",
      order: "En la zona hay problemas de orden público.",
      alliances: "La experiencia con la que cuentan las comunidades locales les permite ser aliadas en la búsqueda de soluciones empresariales a sus necesidades.",
      convoke: "Tu organización puede convocar actores públicos y privados en torno a soluciones empresariales que aborden problemas sociales.",
      collaboration: "La organización que representa esta comunidad y otras organizaciones locales tienen una trayectoria de trabajo conjunto que les permite ser aliados en soluciones empresariales.",
      sinergy: "En la zona es posible generar sinergias de actores públicosy privados en torno a soluciones empresariales que aborden problemas sociales.",
      condition: "Selecciona las condiciones que consideras pueden ser barreras para la implementación de la oportunidad de negocio:",
      condition_OTRO_TEXT: "Otras condiciones",
      territory_populationView: "Población y su dispersión",
      territory_accessView: "Tipo de vías que tiene acceso la organización",
      workView: "Exiten trabajos desarrollados conjuntamente entre el sector privado, el público y ONGs",
      trustView: "En la zona hay un ambiente de desconfianza hacia las instituciones.",
      presenceView: "Hay presencia de diversas organizaciones que propenden por el desarrollo local.",
      publicView: "El sector público tiene un rol efectivo en promover el desarrollo económico y social en el territorio.",
      corporativeView: "El sector empresarial es visto como agente de desarrollo",
      communitiesView: "Las comunidades con las que su organización se relaciona en el territorio están empoderadas e impulsan su propio desarrollo",
      orderView: "En la zona hay problemas de orden público.",
      alliancesView: "La experiencia con la que cuentan las comunidades locales les permite ser aliadas en la búsqueda de soluciones empresariales a sus necesidades.",
      convokeView: "Tu organización puede convocar actores públicos y privados en torno a soluciones empresariales que aborden problemas sociales.",
      collaborationView: "La organización que representa esta comunidad y otras organizaciones locales tienen una trayectoria de trabajo conjunto que les permite ser aliados en soluciones empresariales.",
      sinergyView: "En la zona es posible generar sinergias de actores públicos y privados en torno a soluciones empresariales que aborden problemas sociales.",
      conditionView: "Condiciones que consideras pueden ser barreras para la implementación de la oportunidad de negocio:",
      condition_OTRO_TEXTView: "Otras condiciones",
    },
    solution: {
      accordion: {
        select: "Selecciona un tipo de oportunidad",
        details: "Detalles",
        impact: "Impacto",
        organization: "Organización",
        haveCommunity: "Tengo Comunidad",
        customerProfile: "Perfil de cliente",
        findCommunity: "Busco",
        territory: "Territorio",
        community: "Comunidad",
      },
      solution1: {
        title: "Título",
        description: "Descripción",
        sector: "Sector al que vincula su propuesta",
        other_sector: "Otro sector", //no View
        motivation: "Cuéntanos tu motivacion",
        experience: "Would your organization want to make any contribution that facilitates the entailment of communities to your value chain?",
        nb_affected: "Número de personas que se beneficiarán de esta solución",
        activity: "¿En qué actividad espera se integre la comunidad donde esperar generar impacto social?",
        action_solution: "Acción que esperas que ejecute la comunidad",
        development_level: "Nivel de desarrollo o mercado en el que se encuentra actualmente el producto o servicio",
        affordability: "Con respecto a las estrategias para facilitar la asequibilidad de la comunidad al producto o al servicio, cual de las siguientes opciones tiene proyectado utilizar con la comunidades relacionadas con la oportunidad",
        experience: "Has tenido experiencia de trabajo con otras comunidades generando impacto social:",
        innovationsolution: "Con respecto al producto o servicio que ofreces, ¿cuál de las siguientes opciones mejor lo diferencia?",
        conditions: "Condiciones adicionales a cumplir por parte de la comunidad para acceder al producto o servicio",
        environmental: "¿En qué indicadores ambientales esperas impactar?",
        other_environmental: "¿Cuáles?",
        participation: "Aporte de tu organizacion",
        other_participation: "¿Cuáles?",
        titleView: "Título",
        descriptionView: "Descripción",
        sectorView: "Sector al que vincula su propuesta",
        motivationView: "Motivación",
        nb_affectedView: "Número de personas que se beneficiaran de esta solución",
        activityView: "Actividades en las cuales se espera vincular a la comunicidad",
        action_solutionView: "Acción que esperas que ejecute la comunidad",
        development_levelView: "Nivel de desarrollo o mercado en el que se encuentra actualmente el producto o servicio",
        affordabilityView: "Con respecto a las estrategias para facilitar la asequibilidad de la comunidad al producto o al servicio, cual de las siguientes opciones tiene proyectado utilizar con la comunidades relacionadas con la oportunidad",
        experienceView: "Experiencia de trabajo con otras comunidades generando inpacto social",
        innovationsolutionView: "Con respecto al producto o servicio que ofreces, cual de las siguientes opciones mejor lo representa.",
        conditionsView: "Condiciones adicionales a cumplir por parte de la comunidad para acceder al producto o al servicio",
        environmentalView: "Indicadores ambientales a impactar",
        other_environmentalView: "Otros indicadores ambientales a impactar",
        participationView: "Aporte de tu organizacion",
        other_participationView: "Otro aporte",
      },
      solution2: {
        description: "Describe brevemente cómo vincularías el producto servicio de esta comunidad en tu cadena de valor",
        sector: "Sector al que deseas vincular este producto/servicios",
        other_sector: "Otro sector", //no View
        motivation: "¿Cuál es tu motivación para vincular esta comunidad en tu cadena de valor?",
        experience: "¿Tu organización quisiera hacer algún aporte que facilite la vinculación de tu organización a esta cadena de valor?",
        buy_capacity: "¿Cuál es la capacidad actual de compra/contratación?",
        buy_quantity: "Capacidad mínima (Cantidad)",
        buy_frequency: "Capacidad mínima (Frecuencia)",
        buy_unit: "Capacidad mínima (Unidad)",
        expected_quantity: "Capacidad mínima esperada de mano de obra en cadena de valor Cantidad",
        expected_frequency: "Capacidad mínima esperada de mano de obra en cadena de valor Frecuencia",
        expected_unit: "Capacidad mínima esperada de mano de obra en cadena de valor Unidad",
        environmental: "¿En qué indicadores ambientales esperas impactar?",
        other_environmental: "¿Cuáles?",
        participation: "¿Quisiera realizar algún aporte que facilite la vinculación de esta Organización a tu cadena de valor?",
        other_participation: "¿Cuáles?",
        descriptionView: "Descripción de cómo vincular el producto/ servicio de esta comunidad a la cadena de valor",
        sectorView: "Sector al que deseas vincular este producto/servicios",
        motivationView: "Motivación para vincular esta comunidad a la cadena de valor",
        experienceView: "Aporte que facilite la vinculación de tu organización a esta cadena de valor",
        buy_capacityView: "Capacidad actual de compra/contratación?",
        buy_quantityView: "Capacidad mínima esperada que provea la comunidad Cantidad",
        buy_frequencyView: "Capacidad mínima esperada que provea la comunidad Frecuencia",
        buy_unitView: "Capacidad mínima esperada que provea la comunidad Unidad",
        expected_quantityView: "Capacidad mínima esperada de mano de obra en cadena de valor Cantidad",
        expected_frequencyView: "Capacidad mínima esperada de mano de obra en cadena de valor Frecuencia",
        expected_unitView: "Capacidad mínima esperada de mano de obra en cadena de valor Unidad",
        environmentalView: "Indicadores ambientales esperas impactar",
        other_environmentalView: "Otros indicadores ambientales que esperas impactar",
        participationView: "Aporte que facilita la vinculación de esta Organización a tu cadena de valor",
        other_participationView: "Otro aporte",
      },
      solution3: {
        description: "Descripción de la necesidad/oportunidad que quieres resolver",
        motivation: "¿Cuál es tu motivación para atraer desarrollo empresarial entorno a la necesidad identificada?",
        nb_affected: "Número de personas que se benefician de esta solución",
        descriptionView: "Descripción de la necesidad/oportunidad que quieres resolver",
        motivationView: "Motivacion para atraer desarrollo empresarial entorno a la necesidad identificada",
        nb_affectedView: "Número de personas que se benefician de esta solucion",
        aporteView: "Aporte al proceso",
      },
      solution4: {
        description: "Describe brevemente el producto y/o servicio. Destaca el valor diferencial.",
        motivation: "¿Cuál es tu motivación para conectar este producto o servicio con nuevos mercados?",
        capacity_quantity_validation: "La cantidad debe ser mayor a cero",
        capacity_quantity: "¿Cuál es la capacidad mínima que puede ofrecer? (cantidad)",
        capacity_frequency: "¿Cuál es la capacidad mínima que puede ofrecer? (frecuencia)",
        capacity_unit: "¿Cuál es la capacidad mínima que puede ofrecer? (unidad)",
        development: "Nivel de desarrollo en que se encuentra actualmente el producto o servicio",
        market: "En qué tipo de mercados has comercializado este producto y/o servicio",
        descriptionView: "Descripcion del producto y/o servicio.",
        motivationView: "Motivación para conectar este producto o servicio con nuevos mercados",
        capacity_quantityView: "Capacidad minima esperada que la comunidad ofrezca del producto o servicio (cantidad)",
        capacity_frequencyView: "Capacidad minima esperada que la comunidad ofrezca del producto o servicio (frecuencia)",
        capacity_unitView: "Capacidad minima esperada que la comunidad ofrezca del producto o servicio (unidad)",
        developmentView: "Nivel de desarrollo en que se encuentra actualmente el producto o servicio",
        marketView: "Tipos de mercados has comercializado este producto y/o servicio",
        aporteView: "Aporte al proceso",
      },
      communitySolution: {
        country: "País",
        city: "Ciudad",
        address: "Dirección",
        assets: "Contribución de la organización a la propuesta",
        income: "Ingresos diarios de la comunidad",
        services: "Servicios con los que cuenta la comunidad",
        population: "Tipo de población y su dispersión",
        etnia: "Etnia",
        etnia_which: "Cuál etnia",
        organization_role: "Qué tipo de organizacion es:",
        contactname: "Nombre de contacto",
        contactemail: "Email de contacto",
        contactphone: "Número de contacto",
        name: "Nombre de comunidad",
        afected: "Comunidad involucrada",
        age_range: "Tipo de poblacion involucrada",
        antiguity: "Antigüedad",
        characteristic: "Características",
        association: "Tipos de asociados",
        financing: "Financiación",
        access: "Tipo de vía de acceso",
        tipo_necesidad: "Necesidad que satisface",
        rol: "Rol",
        time: "Tiempo",
        countryView: "País",
        cityView: "Ciudad",
        addressView: "Dirección",
        assetsView: "Contribución a la propuesta como organización",
        incomeView: "Ingresos diarios de la comunidad",
        servicesView: "Servicios con los que cuenta la comunidad",
        populationView: "Tipo de población",
        etniaView: "Etnia",
        etnia_whichView: "Cuál etnia",
        organization_roleView: "Qué tipo de organizacion es:",
        contactnameView: "Nombre de contacto",
        contactemailView: "Email de contacto",
        contactphoneView: "Número de contacto",
        nameView: "Nombre de comunidad",
        afectedView: "Comunidad involucrada",
        age_rangeView: "Tipo de población involucrada",
        antiguityView: "Antigüedad",
        characteristicView: "Características",
        associationView: "Tipos de asociados",
        financingView: "Financiación",
        accessView: "Tipo de vía de acceso",
        tipo_necesidadView: "Necesidad que satisface",
        rolView: "Rol",
        timeView: "Tiempo",
      },
      communitypropouse: {
        understand: "La comunidad es a quien se busca beneficiar con la oportunidad en Minka-dev. A través de la siguiente información se indaga sobre sus características.",
        name: "Nombre de comunidad",
        country: "País",
        city: "Ciudad",
        address: "Direccion",
        assets: "Contribucion a la propuesta como organizacion",
        income: "Ingresos diarios de la comunidad",
        services: "Servicios con los que cuenta la comunidad",
        population: "Tipo de población",
        etnia: "Etnia",
        etnia_which: "Cuál etnia",
        organization_role: "Señala lo que mejor describa tu relación con la comunidad",
        contactname: "Nombre de contacto",
        contactemail: "Email de contacto",
        contactphone: "Número de contacto",
        afected: "Comunidad involucrada",
        age_range: "Tipo de población involucrada",
        antiguity: "Antigüedad",
        characteristic: "Características",
        association: "Tipos de asociados",
        financing: "Financiación",
        access: "Señala a qué tipo de vías tiene acceso la comunidad",
        tipo_necesidad: "Necesidad que satisface",
        rol: "Rol con el que te identificas sobre tu relación con la comunidad que quieres beneficiar",
        time: "¿Hace cuánto estás trabajando con esa comunidad?",
        nameView: "Nombre de comunidad",
        countryView: "País",
        cityView: "Ciudad",
        addressView: "Dirección",
        assetsView: "Contribución a la propuesta como organización",
        incomeView: "Ingresos diarios de la comunidad",
        servicesView: "Servicios con los que cuenta la comunidad",
        populationView: "Tipo de población",
        etniaView: "Etnia",
        etnia_whichView: "Cuál etnia",
        organization_roleView: "Relación con la comunidad",
        contactnameView: "Nombre de contacto",
        contactemailView: "Email de contacto",
        contactphoneView: "Número de contacto",
        afectedView: "Comunidad involucrada",
        age_rangeView: "Tipo de población involucrada",
        antiguityView: "Antiguedad",
        characteristicView: "Características",
        associationView: "Tipos de asociados",
        financingView: "Financiación",
        accessView: "Vías de acceso que tiene la comunidad",
        tipo_necesidadView: "Necesidad que satisface",
        rolView: "Rol con la comunidad",
        timeView: "Tiempo trabajando con esa comunidad",
      },
      hasCommunity: {
        name: "Nombre de la organización.",
        country: "País",
        city: "Ciudad",
        address: "Dirección",
        role: "Rol con el que te identificas sobre tu relación con la organización que quieres beneficiar",
        affected: "Número de personas que se espera beneficiar",
        antiguity: "¿Hace cuánto está constituida la organización con la que colabora?",
        characteristic: "Señala las características con las que describirías la organización.",
        association: "Tipo de asociación que representa la organización.",
        association_OTRO_TEXT: "Otras formas de asociación:",
        experience_management:
          "Nivel de Experiencia de la organización en formulación y gestión de proyectos",
        experience_development:
          "Nivel de experiencia de la organización en proyectos de desarrollo social-económico",
        credibility_ong: "Nivel de Credibilidad de tu organización ante ONGs",
        financing: "¿Qué tipo de financiamiento ha recibido la organización?",
        financing_type: "¿Qué tipo de financiamiento ha recibido la organización?",
        other_financing: "¿Cuáles?",
        time: "¿Hace cuánto trabajas con esta organización?",
        nameView: "Nombre de la organización.",
        countryView: "País",
        cityView: "Ciudad",
        addressView: "Dirección",
        rolView: "Rol con la organización",
        organization_roleView: "Relación con la organización:",
        affectedView: "Número de personas que se espera beneficiar",
        antiguityView: "Tiempo desde que está constituida la organización con la que colabora.",
        characteristicView: "Características que describen la organización.",
        associationView: "Tipo de asociación que representa la organización.",
        experience_managementView:
          "Nivel de Experiencia de la organización en formulación y gestión de proyectos",
        experience_developmentView:
          "Nivel de experiencia de la organización en proyectos de desarrollo social-económico",
        credibility_ongView:
          "Nivel de Credibilidad de tu organización ante ONGs",
        financingView: "Tipo de financiamiento que ha recibido la organización",
        financing_typeView: "Tipo de financiamiento que ha recibido la organización",
        other_financingView: "Otros tipos de financiamiento",
        accessView: "Tipo de vías que tiene acceso la organización",
        timeView: "Tiempo trabajado con esta organización",
        populationView: "Población y su dispersión",
      },
      impact: {
        sector: "Sector a impactar",
        sector_OTRO_TEXT: "Otro sector",
        participation: "Aporte de tu Organización",
        other_participation: "¿Cuáles?",
        environmental: "Principales Indicadores Ambientales",
        other_environmental: "¿Cuáles?",
        social: "Principales Indicadores Sociales",
        sectorView: "Sector",
        sector_OTRO_TEXTView: "Otro sector",
        participationView: "Aporte de tu Organización",
        other_participationView: "Otro Aporte",
        environmentalView: "Principales Indicadores Ambientales",
        other_environmentalView: "Otros indicadores ambientales",
        socialView: "Principales Indicadores Sociales",
      },
      organization: {
        organization_type: "Señala lo que mejor describe tu relación con la organización:",
        challenges: "Ámbitos en los que la organización enfrenta desafíos",
        other_challenges: "Otros ámbitos",
        projects_budget:
          "Rango de facturación de su organización en los últimos dos años (USD)",
        nb_benefited:
          "Número aproximado de personas que se han beneficiado por su organización en los últimos 2 años",
        partipation_network:
          "Nivel de participación de tu organización en redes locales, nacionales, e internacionales",
        influence:
          "Nivel de influencia de tu organización ante otras organizaciones",
        leadership: "Nivel de Liderazgo de la organización en el sector",
        credibility_people:
          "Nivel de Credibilidad tu organización ante la población",
        market_orientation:
          "Nivel de orientación de la organización a conexiones de mercado",
        decision_tools:
          "Cual es la efectividad de los mecanismos para la toma de decisiones en la organización",
        organization_typeView: "Relación con la organización:",
        challengesView: "Ámbitos en los que la organización enfrenta desafíos",
        other_challengesView: "Otros ámbitos",
        projects_budgetView:
          "Rango de facturación de su organización en los últimos dos años (US DOLLARS)",
        partipation_networkView:
          "Nivel de participación de tu organización en redes locales, nacionales, e internacionales",
        influenceView:
          "Nivel de influencia de la organización ante otras organizaciones",
        leadershipView: "Nivel de Liderazgo de la organización en el sector",
        credibility_peopleView:
          "Nivel de Credibilidad tu organización ante la población",
        market_orientationView:
          "Nivel de orientación de la organización a conexiones de mercado",
        decision_toolsView:
          "Efectividad de los mecanismos para la toma de decisiones en la organización",
      },
    },
    modals: {
      modalOpportunity: {
        needHelp: "¿Necesitas ayuda?",
        checkServices: "Revisa los servicios que ofrece minka dev.",
        acept: "Acepto",
        services: "Servicios",
        publish: "Publica",
        titleProp: "Publica una propuesta de manera independiente",
        labelProp: "Postular una propuesta",
        titleOpp: "Publica una oportunidad de manera independiente",
        labelOpp: "Postular una oportunidad",
      },
      modalPost: {
        publish: "Tu oportunidad ha sido publicada",
        congratulations: "¡Felicidades!",
        vinculation: "La organización vinculada a esta oportunidad y el contexto en el cual  se encuentra a quienes desea beneficiar parecen ser idóneas para avanzar en el desarrollo de negocio inclusivos y sostenibles.",
        analitic: "A partir de la información que nos has aportado, las herramientas de minka dev producen un análisis que permite determinar las condiciones actuales del contexto de la organización y la comunidad para desarrollar negocios de impacto social. Una puntuación más alta indica un mayor nivel de idoneidad y viabilidad para conectarse a este tipo de desarrollo empresarial.",
        evaluation: "A través de esta herramienta se evalúan los siguientes aspectos:",
        track: "Trayectoria de la organización.",
        impact: "Impacto generado.",
        model: "Modelo de operación.",
        capacity: "Capacidad de relacionamiento de la organización postulante.",
        viability: "Viabilidad del territorio en el cual se encuentra la organización que se postula.",
        capacityReprecentation: "Capacidad para representar a la población en la población en la que quiere impactar.",
        wish: "Si deseas asesoría y acompañamiento para superar estos desafíos contáctanos a:",
        evaluationRisk: "Evaluación del funcionamiento y solidez de la organización que propone la oportunidad y el riesgo.",
        services: "Servicios asociados a este producto, que se puede seleccionar",
      },
      modalDetail: {
        result: "Resultado",
        who: "¿A quien va dirigido?",
        requestInfo: "Solicitar más información",
        registerInfo: "Regístrate para solicitar más información",
      },
      modalContact: {
        contact: "Contacta a la organizacion",
        message: "Puedes dejar un mensaje a la organización",
        responseMail: "Dirección de correo al cual quieres que el destinatario te responda (opcional)",
      },
      modalConditions: {
        confirm: "Confirmo que la información aportada es veraz y responde a los intereses y realidad de mi organización.",
        check: "He revisado y acepto la",
        politics: "política de privacidad",
        ofMinka: "de minka dev",
        acept: "Acepto",
      },
      modalOrganization: {
        type: "Tipo",
        sector: "Sector",
        activities: "Actividades",
        values: "Principios",
      },
      modalPlus: {
        opportunity: "Oportunidades minka dev",
        verify: "Oportunidades verificadas y analizadas por minka dev",
        text: "Las oportunidades minka dev Plus son aquellas en las que minka dev a verificado la capacidad local, la viabilidad y la rutas de mercado con el potencial más alto. Son oportunidades con una alta calidad debido al análisis y acompañamiento que han tenido para ser identificadas. Las oportunidades minka dev plus acostumbran a tener un mayor potencial de éxito en su desarrollo ¿Deseas contactar los servicios de minka dev para identificar y desarrollar una oportunidad de negocio con alto impacto social medioambiental? Contáctanos",
      },
    },
    help: {
      detail1: {
        description: "Intenta resumir la necesidad que has identificado, las comunidades con que se trabaja o se espera trabajar y toda la información que consideres brindará detalles a quien quiera participar en la solución del problema (máx. 250 palabras).",
        pais: "Hace referencia al país en el cual se desea crea la oportunidad",
      },
      detail2: {
        description: "Intenta resumir la necesidad que has identificado, las comunidades con que se trabaja o se espera trabajar y toda la información que consideres brindará detalles a quien quiera participar en la solución del problema (máx. 250 palabras).",
        frequency: "Agrega una frecuencia. Puede ser Anuales, Semanales u otro periodo temporal",
        capacity: "La unidades pueden ser medidas apropiadas, como toneladas, metros cúbicos, etc",
      },
      impact: {
        social: "El 25 de septiembre de 2015, los líderes mundiales adoptaron un conjunto de objetivos globales para erradicar la pobreza, proteger el planeta y asegurar la prosperidad para todos como parte de una nueva agenda de desarrollo sostenible. Cada objetivo tiene metas específicas que deben alcanzarse en los próximos 15 años.",
      },
      solution2: {
        expected: 'Si requieres mano de obra por favor completa. De lo contrario escribe "no aplica"',
      },
    },
  }, //END SPANISH

  //FRENCH
  "fr-fr": {
    general: {
      none: "Acun",
      sector: "Secteur",
      session_expired: "La session a expiré. Veuillez vous reconnecter.",
      country: {
        AF: "Afghanistan",
        AL: "Albanie",
        DZ: "Algérie",
        AD: "Andorre",
        AO: "Angola",
        AR: "Argentine",
        AM: "Arménie",
        AZ: "Azerbaïdjan",
        AI: "Anguilla",
        AG: "Antigua et Barbuda",
        AW: "Aruba",
        AU: "Australie",
        AT: "Autriche",
        BS: "Bahamas",
        BH: "Bahreïn",
        BD: "Bangladesh",
        BB: "Barbade",
        BT: "Bhoutan",
        BW: "Botswana",
        BR: "Brésil",
        BI: "Burundi",
        BY: "Belarus",
        BE: "Belgique",
        BZ: "Belize",
        BJ: "Bénin",
        BO: "Bolivie",
        BA: "Bosnie-Herzégovine",
        BG: "Bulgarie",
        BF: "Burkina Faso",
        BRN: "Brunei",
        CM: "Cameroun",
        CA: "Canada",
        CV: "Cap-Vert",
        KY: "Îles Caïmans",
        CF: "République centrafricaine",
        CN: "Chine",
        CO: "Colombie",
        CG: "Congo",
        CR: "Costa Rica",
        KH: "Cambodge",
        TD: "Tchad",
        CL: "Chili",
        CD: "République Démocratique du Congo",
        HR: "Croatie",
        CU: "Cuba",
        CY: "Chypre",
        CZ: "République tchèque",
        DK: "Danemark",
        DJ: "Djibouti",
        DM: "Dominique",
        DO: "République Dominicaine",
        EC: "Equateur",
        EG: "Egypte",
        ET: "Ethiopie",
        SV: "El Salvador",
        GQ: "Guinée équatoriale",
        ER: "Erythrée",
        EE: "Estonie",
        FJ: "Fidji",
        FI: "Finlande",
        FR: "France",
        GA: "Gabon",
        DE: "Allemagne",
        GH: "Ghana",
        GR: "Grèce",
        GT: "Guatemala",
        GM: "Gambie",
        GE: "Géorgie",
        GD: "Grenade",
        GN: "Guinée",
        GW: "Guinée-Bissau",
        GY: "Guyana",
        HT: "Haïti",
        HN: "Honduras",
        HU: "Hongrie",
        IS: "Islande",
        IN: "Inde",
        ID: "Indonésie",
        IE: "Irlande",
        IT: "Italie",
        IR: "Iran",
        QI: "Irak",
        IL: "Israël",
        CIV: "Côte d'Ivoire",
        JM: "Jamaïque",
        JP: "Japon",
        JO: "Jordan",
        KE: "Kenya",
        KZ: "Kazakhstan",
        KI: "Kiribati",
        KR: "Corée du Sud",
        KW: "Koweït",
        KG: "Kirghizistan",
        LA: "Laos",
        LV: "Lettonie",
        LB: "Liban",
        LR: "Liberia",
        LY: "Libye",
        LI: "Liechtenstein",
        LT: "Lituanie",
        LU: "Luxembourg",
        MY: "Malaisie",
        MU: "Ile Maurice",
        MX: "Mexique",
        MZ: "Mozambique",
        MK: "Macédoine",
        MW: "Malawi",
        MV: "Maldives",
        ML: "Mali",
        MT: "Malte",
        MH: "Îles Marshall",
        MR: "Mauritanie",
        FM: "Micronésie",
        MD: "Moldavie",
        MC: "Monaco",
        MN: "Mongolie",
        ME: "Monténégro",
        MA: "Maroc",
        MMR: "Myanmar",
        NA: "Namibie",
        NL: "Pays-Bas",
        NG: "Nigeria",
        NP: "Népal",
        NZ: "Nouvelle-Zélande",
        NI: "Nicaragua",
        NE: "Niger",
        NOR: "Norvège",
        OM: "Oman",
        PK: "Pakistan",
        PA: "Panama",
        PH: "Philippines",
        PT: "Portugal",
        PR: "Porto Rico",
        PY: "Paraguay",
        PE: "Pérou",
        PL: "Pologne",
        PW: "Palau",
        QA: "Qatar",
        RU: "Fédération de Russie",
        RO: "Roumanie",
        RW: "Rwanda",
        SC: "Seychelles",
        SL: "Sierra Leone",
        SG: "Singapour",
        ZA: "Afrique du Sud",
        ES: "Espagne",
        LK: "Sri Lanka",
        SZ: "Swaziland",
        SE: "Suède",
        CH: "Suisse",
        KN: "Saint-Kitts-et-Nevis",
        LC: "Sainte Lucie",
        VC: "Saint Vincent et les Grenadines",
        WS: "Samoa",
        SM: "Saint-Marin",
        ST: "Sao Tomé et Principe",
        SA: "Arabie Saoudite",
        SN: "Sénégal",
        RS: "Serbie",
        SK: "Slovaquie",
        OUI: "Slovénie",
        SB: "Îles Salomon",
        SO: "Somalie",
        SS: "Soudan du Sud",
        SD: "Soudan",
        SR: "Suriname",
        SY: "syrien",
        TZ: "Tanzanie",
        TH: "Thaïlande",
        TT: "Trinité-et-Tobago",
        TJ: "Tadjikistan",
        TG: "Togo",
        TO: "Tonga",
        TN: "Tunisie",
        TR: "Turquie",
        TM: "Turkménistan",
        TV: "Tuvalu",
        UG: "Ouganda",
        UA: "Ukraine",
        AE: "Emirats Arabes Unis",
        GB: "Royaume-Uni",
        US: "États-Unis",
        UY: "Uruguay",
        UZ: "Ouzbékistan",
        VU: "Vanuatu",
        VE: "Venezuela",
        VN: "Vietnam",
        YE: "Yémen",
        ZM: "Zambie",
        ZW: "Zimbabwe",
      },
      logoutBtn: "Fermer session",
      logout: "Vous avez clôturé votre session.",
      pais: "Pays",
      keywords: "Mots clés",
      whatOffer: "Que proposez-vous ou avez-vous besoin?",
      marketBanner: "Trouvez des opportunités à impact social.",
      organizacion: "Organisation",
      oportunidades: "Opportunités",
      proposals: "Propositions",
      communities: "Communautés",
      oportunidad: "opportunité",
      solucion: "proposition",
      comunidad: "communauté",
      back: "Retourner",
      selected: "Sélectionnés",
      sobre: "À propos de Minkadev",
      date: "Date de création",
      select: "Pour sélectionner",
      mailSent: "Email envoyé",
      archivos: "Des dossiers",
      submittedproposals: "Propositions soumises",
      postProposal: "Publier une proposition",
      closeOpportunity: "Opportunité proche",
      contact: "Contacter",
      contactTitle: "CONTACTER",
      message: "Message",
      sendMessage: "Envoyer",
      sdg: "ODD",
      message_empty: "Le message ne peut pas être vide",
      error_message: "Erreur lors de l'envoi du message",
      success_message: "Message envoyé avec succès",
    },
    login: {
      title: "Connectez-vous ou inscrivez-vous",
      login: "Connectez-vous",
      register: "Inscrivez-vous",
      password: "Le mot de passe",
      confirm: "Confirmez le mot de passe",
      recovery: "Avez-vous oublié votre mot de passe?",
      click: "Cliquez ici.",
      registerTitle: "S'inscrire",
      names: "Noms",
      lastNames: "Nom de Famillie",
      email: "Email",
      send_mail: "Envoyer un e-mail",
      terminos: "Je confirme que les informations fournies sont véridiques et répondent aux intérêts et à la réalité de mon organisation.",
      acept: "J'ai examiné et j'accepte les",
      politic: "politique de confidentialité",
      minka: "de Minka-dev",
      close: "[X Fermer]",
      invalid_email: "Email invalide. Chèque.",
      invalid_mail: "Il n'y a aucun utilisateur avec cette adresse e-mail",
      password_empty: "Entrer un mot de passe.",
      first_name_empty: "Entrer noms",
      last_name_empty: "Entrer Nom de Famillie",
      no_match: "Le mot de passe ne correspond pas.",
      wrong_password: "Mot de passe incorrect",
      too_short: "Le mot de passe doit contenir 6 caractères",
      error_registry: "Il y avait une erreur. Contactez-nous.",
      already_exists: "L'utilisateur existe déjà. Essayez de vous connecter.",
    },
    cuenta: {
      organizacion: "Votre organisation",
      opportunities: "Votre Opportunités",
      proposals: "Votre propositions",
      communities: "Communautés proposées",
      noTienes: "Vous n'en avez pas ",
      proposal: "proposition",
      community: "Communauté",
      publicada: "Publiée?",
      descripcion: "Description",
      fechaCracion: "Date de creation",
      expira: "Expire avec l'opportunité",
      moreButton: "Voir plus",
      createOpportunity: "Créer une opportunité",
      seeOpportunities: "Voir les opportunités",
    },
    home: {
      banner1: "Nous développons des affaires durables qui génèrent des résultats pour le changement",
      banner2: "Nous avons construit notre expérience dans 3 types d'entreprises: entreprise verte, entreprise inclusive, entreprise pour l'économie circulaire",
      impactA: "Plus de",
      impact1: "communautés impliquées",
      impact2: "secteurs ont généré des opportunités commerciales",
      marketplace: "Il s'agit d'un outil en ligne pour générer, identifier des opportunités commerciales et les connecter aux marchés de manière agrégée. Cela dépend des données des acteurs de l'écosystème dans un temps défini. Aide au développement d'une meilleure structure et accompagnement.",
      resultTitle: "RÉSULTATS MINKA DEV",
      stat1A: "OPPORTUNITÉS",
      stat1B: "créé",
      stat2A: "SECTEURS",
      stat2B: "développés",
      stat3A: "CONNEXIONS",
      stat3B: "au marché",
      stat4A: "ENTREPRISE",
      stat4B: "structurés",
      stat5A: "UTILISATEURS",
      stat5B: "actifs",
      servicesTitle: "SERVICES MINKA DEV",
      services: "Chez minka dev, nous travaillons avec 2 processus qui nous aident à générer les résultats dont votre organisation a besoin: Marketplace et Think and do Tank.",
      knowMore: "En savoir plus",
      mapTitle: "NOUS PENSONS GLOBAL, NOUS TRAVAILLONS DANS VOTRE LOCALISATION",
      mapOp1: "",
      mapOp2: "opportunités.",
      mapSecLabel: "Secteur le plus touché",
      mapOpLabel: "Opportunités principales:",
      prensaTitle: "MINKA DEV DANS LA PRESSE",
      prensa: ["Un témoignage rendu par ce média à ajouter. Finalement, il devrait être paramétré dans le Bakend."],
      confiado: "ILS NOUS ONT CONFIÉ",
      cadena: "Offre d'approvisionnement durable",
      consumo: "Offre de consommation durable",
    },
    noticias: {
      title: "NOUVELLES",
      ultimas: "DERNIÈRES NOUVELLES",
      subtitle: "Découvrez les dernières avancées en matière de développement économique durable. Suivez-nous sur les réseaux sociaux pour en savoir plus.",
    },
    privacidad: {
      title: "Politique de confidentialité",
      priva1: "Par ce document, MINKA-DEV SOLUCIONES Y ESTUDIOS, SL. (ci-après désignée 'minka dev'), ayant son siège social  à Barcelone, Calle Industria 137-141 ent-1era, Esc. Izq., informe les utilisateurs de ce site internet de sa",
      priva1a: "POLITIQUE DE CONFIDENTIALITÉ ET DE PROTECTION DES DONNÉES PERSONNELLES.",
      priva2: "Conformément à la loi 15/1999 du 13 décembre, relative à la protection des données à caractère personnel, les données personnelles transmises par les utilisateurs à travers le présente site internet et comprises dans le champ d'application de la législation applicable sont intégrées dans un fichier de données personnelles dont minka dev est titulaire et assume la pleine responsabilité, et qui se trouve dument inscrit à l'Agence espagnole de protection des données. Ainsi, en remplissant un ou plusieurs formulaires avec les données personnelles demandées et en approuvant son enregistrement, vous AUTORISEZ EXPRESSÉMENT minka dev à traiter et intégrer dans son fichier automatique les données personnelles transmises, ainsi que toutes les données générées en lien avec votre participation et/ou utilisation des divers services offerts ou susceptibles d'être offerts sur ce site internet.",
      priva3: "Dans les cas où des données personnelles incluses dans les formulaires concernent des personnes physiques autres que celles qui font la demande, ces dernières ont l'obligation d'informer les personnes concernées des termes du présent document.",
      priva4: "Sauf mention spécifique contraire, il est requis de fournir toutes les données demandées dans tous les champs de chaque formulaire, l'usager étant tenu de fournir des données vraies, exactes, complètes et à jour. L'usager sera le seul responsable pour tout dommage ou préjudice, direct ou indirect, causé à minka dev ou à un tiers en raison du remplissage des formulaires avec des données fausses, inexactes, incomplètes ou pas à jour. À cette fin, l'usager certifie que les données personnelles qu'il fournit pour lui-même sont véridiques et prend la responsabilité de communiquer à minka dev toute modification ultérieure de ces données.",
      priva5: "Les données personnelles conservées dans l'environnement du présent site internet sont récoltées à l'une ou plusieurs fins parmi celles énumérées ci-après, en fonction de l'inscription ou contact pris par l'usager:",
      priva6a: "a) Inscription comme Investisseur, Identificateur d'opportunités ou Simple usager : la gestion de la condition d'usager inscrit;",
      priva6b: "b) Inscription Newsletter: la gestion de votre inscription au service de Newsletter accessible depuis le site internet;",
      priva6c: "c) Formulaire de contact Think Tank: la gestion du contact en rapport au service minka dev Think Tank.",
      priva6d: "d) Par ailleurs, les données collectées pourront être utilisées pour l'envoi d'information technique, de communication ou de tout autre type que minka dev aura déterminé de l'intérêt de ses usagers.",
      priva7: "Les données fournies ne seront jamais utilisées à des fins autres que celles décrites ci-dessus, et elles seront immédiatement supprimées lorsqu'elles ne seront plus requises aux fins mentionnées.",
      priva8: "Dans le cas où minka dev fournirait un type de service spécial pour lequel des dispositions spécifiques autres que celles-ci s'avéreraient nécessaires en matière de protection des données personnelles, les règles particulières requises pour ledit service s'appliqueraient en priorité par rapport aux présentes en cas d'incompatibilité.",
      priva9: "Toute information relative à des données personnelles reçue par minka dev sera traitée avec la plus grande confidentialité, dans le respect permanent du secret professionnel, en procédant uniquement aux communications strictement nécessaires en vue d'atteindre les fins précédemment décrites.",
      priva10: "Par ailleurs, par la présente Politique de confidentialité, minka dev informe avoir mis en place les niveaux de sécurité en matière de protection des données personnelles requis par la loi et la règlementation, et avoir adopté tous les moyens et mesures techniques à sa disposition pour éviter la perte, l'utilisation indue, l'altération et l'accès non autorisé aux données personnelles comprises dans ses fichiers.",
      priva11: "L'usager a le droit d'accéder à l'information qui le concerne et qui se trouve stockée dans les fichiers de minka dev, de la rectifier en cas d'erreur, de la supprimer ou de s'opposer à son traitement en adressant un email à ",
      priva11a: ", ou par courrier écrit et signé (en joignant une copie de son document d'identité) adressé au responsable du fichier, soit la société MINKA-DEV SOLUCIONES Y ESTUDIOS, SL., ayant son siège social sis à Barcelona, calle Industria 217-141 ent-1era (Réf. Protection des données).",
      priva12: "L'usager inscrit (à l'exclusion de ceux ayant souscrit uniquement le service de Newsletter ou ayant transmis un formulaire de contact via les services du Think Tank) consent expressément et de façon univoque à la cession des données personnelles qu'il a fournies, exclusivement aux fins susmentionnées, au bénéfice des personnes qui conforment l'écosystème minka dev (identificateurs, promoteurs, parrains, investisseurs, comités d'évaluation) pour l'évaluation et l'étude des solutions proposées, même si ladite cession entraîne le partage des données personnelles à l'international.",
      priva13: "L'accord donné par l'usager pour le traitement de ses données de la façon établie dans la présente Politique de confidentialité revêt en permanence un caractère révocable, sans effets rétroactifs, conformément aux termes de l'article 6 de la Loi organique 15/1999 du 13 décembre.",
      priva14: "minka dev se réserve le droit de modifier sa politique de confidentialité des données suivant son propre critère ou suite à toute modification de la loi, de la jurisprudence ou des pratiques d'entreprise. Dans le cas où minka dev apporterait une modification, la nouvelle politique serait publiée sur le même site internet, où les usagers pourront prendre connaissance à tout moment de la politique de protection des données en vigueur. Dans tous les cas, la relation avec les usagers obéit aux normes prévues au moment de l'accès au site internet.",
    },

    odsExplication: {
      explication: "Les objectifs de développement durable, également appelés objectifs mondiaux, ont été adoptés par tous les États membres en 2015 comme un appel universel à mettre fin à la pauvreté, à protéger la planète et à garantir à tous la paix et la prospérité d'ici 2030.",
    },
    header: {
      Servicios: "Services",
      Noticias: "Actualités",
      "Mi Cuenta": "Mon compte",
      Contacto: "Contactez-nous",
      Nosotros: "Nous",
      Ingresar: "Se connecter",
    },
    footer: {
      oportunidades: "Opportunités",
      servicios: "Services",
      acerca: "À propos de minka dev",
      contacto: "Contactez-nous",
      privacidad: "Confidentialité",
      participa: "PARTICIPEZ",
      institucion: "INSTITUTION",
      actividad: "ACTIVITÉ",
    },
    sectors: {
      SA: {
        label: "Secteur agricole",
        subs: {
          AG: "Agriculture",
          AF: "Exploitation forestière",
          GA: "Élevage",
          PE: "Pêche",
        },
      },
      SB: {
        label: "Services de base",
        subs: {
          SS: "Services sociaux (assistance sociale) et santé",
          AE: "Services de protection de l'environnement et d'élimination des déchets",
          EE: "Energie électrique",
          GD: "Gaz Domiciliaire",
          AG: "Eau",
        },
      },
      SE: {
        label: "Services",
        subs: {
          HR: "Hôtel et restaurant",
          AE: "Associations et loisirs-culturels-sports et autres services",
          RA: "Réparation automobile",
          CT: "Postes et télécommunications",
          IF: "Intermédiation financière",
          IN: "Immobiliers",
          AP: "Administration publique et défense",
          EN: "Enseignement",
          SD: "Services domestiques",
        },
      },
      IN: {
        label: "Industrie",
        subs: {
          DD: "Déchets et débris",
          AB: "Nourriture et boissons",
          TX: "Textile",
          MP: "Bois et / ou Papier",
          PA: "Produits artisanaux",
          RP: "Raffinage du pétrole",
          SP: "Substances et produits chimiques",
          CP: "Caoutchouc et / ou plastique",
          PN: "Produits minéraux non métalliques",
          PM: "Produits métalliques",
          ME: "Machines et équipements",
          CC: "Tannage et préparation du cuir",
          CU: "Produits en cuir et en chaussures",
          ET: "Matériel de transport",
          MU: "Meubles",
          MI: "Mines",
          EE: "Energie électrique",
          GD: "Gaz Domiciliaire",
          AG: "Eau",
        },
      },
      TR: {
        label: "Transport",
        subs: {
          TR: "Transport terrestre",
          TW: "Transport par eau",
          TA: "Transport aérien",
          SC: "Services complémentaires et auxiliaires de transport",
        },
      },
      SM: {
        label: "Secteur minier et énergétique",
        subs: {
          CM: "Charbon minéral",
          PM: "Pétrole brut - gaz naturel et minéraux d'uranium et de thorium",
          MM: "Minéraux métalliques",
          MN: "Minéraux non métalliques",
        },
      },
      CO: {
        label: "Communications",
        subs: {
          IN: "Internet",
          TE: "Téléphonie mobile ou cellulaire",
          OT: "Autres",
        },
      },
    },
    forms: {
      errors: {
        callToAction: "Vous devez remplir les champs suivants:",
        name: "Nom",
        website: "Site internet",
        sector: "Secteur",
        nif: "Numéro d'identification de l'entreprise (NIE, NIF, SIRET, SIREN, etc)",
        phone: "Numéro de téléphone",
        type: "Vous n'avez pas sélectionné de type d'opportunité",
        nb_affected: "Vous devez choisir un certain nombre de personnes impactées",
        deadline: "Le format de la date de clôture est AAAA / MM / JJ",
      },
      messages: {
        error: "Il y avait une erreur. Contactez-nous.",
        savedSuccesfully: "Enregistré avec succès",
      },
      controls: {
        guardar: "Record",
        editar: "Modifier",
        return: "Revenir",
        publicar: "Publier",
        click_edit: "Cliquez pour modifier le titre",
        selectSectors: "Secteurs auxquels appartient actuellement l'opportunité",
        addSector: "Ajouter un secteur",
        addSgd: "Ajouter un ODD",
        selectSgd: "Sélectionnez un ou plusieurs ODD",
        add: "Ajouter du texte",
        urlVideos: "Intégrer des vidéos YouTube",
        urlVideosView: "Vidéos",
        backOpportunity: "Retour à l'opportunité",
        selectFile: "Choisir le dossier",
        sendFile: "Envoyer",
        info: "*Appuyez sur \"Enregistrer\" après avoir complété les informations. Vous pouvez les modifier ultérieurement en appuyant à nouveau sur \"Modifier\"."
      },
      opportunities: {
        addTitle: "Quelle est la phrase qui décrit le mieux le produit ou le service que vous proposez ?",
        viewTitle: "Phrase qui décrit le mieux le produit ou le service que vous proposez",
        anexos: "Joignez un par un tout document que vous considérez important pour comprendre votre proposition. Format : fichiers PDF ne dépassant pas 4 Mo.",
        anexosView: "Documents liés (PDF)",
        attachedLinks: "Si c'est un lien vers une page Web ou un lecteur, veuillez l'inclure dans l'espace suivant : *Maximum 3 liens.",
        attachedLinksView: "Liens",
        uploadImageMessage: "Attachez une par une les images représentant votre organisation. Format : PNG, JPEG, ne dépassant pas un poids total de 4 Mo. Maximum 8 images.",
        oppDescription: "Veuillez fournir une brève description du service que vous offrez. Cela aidera les clients potentiels à comprendre ce que vous pouvez fournir et si cela répond à leurs besoins. Veuillez être aussi spécifique que possible, en incluant tous les détails pertinents sur la portée, les exigences et les résultats souhaités du service. ",
        oppMotivation: "Ce champ est une occasion pour vous de nous en dire un peu plus sur vos besoins et attentes par rapport au service que vous recherchez. Nous souhaitons comprendre votre motivation pour acquérir nos services et comment nous pouvons vous aider à atteindre vos objectifs. Veuillez être aussi précis que possible afin que nous puissions vous fournir la meilleure solution pour vos besoins. ",
        oppTypeFormTitle: "Type d'opportunité",
        oppBasicFormTitle: "Informations de base",
        oppAttachmentsFormTitle : "Pièces jointes",
        welcomeTitle: "Nous vous souhaitons la bienvenue pour créer votre opportunité",
        welcomeInfo: "Veuillez compléter les informations suivantes de manière claire et sincère. Vous pouvez suivre l'avancement du processus dans la barre suivante."
      },
      organization: {
        country: "Pays où se déroulent les activités de votre organisation",
        city: "Ville",
        address: "Adresse",
        //lat and lng?
        name: "Nom de l'organisation présentée",
        type: "Type d'organisation",
        website: "Site internet de votre organisation",
        sector: "Secteur d'activité",
        sector_OTRO_TEXT: "Spécifier le secteur:",
        nif: "Numéro d'identification de l'entreprise (NIE, NIF, SIRET, SIREN, etc)",
        nifhelp: "Numéro d'identification de l'entreprise.Selon votre pays,il est probable que ce code contient des lettres ou seulement des chiffres",
        phone: "Numéro de téléphone de l'organisation",
        age: "Années d'existence de l'organisation",
        billing: "Chiffre d'affaires annuel USD",
        employees: "Effectifs",
        sustainability: "Activité principale de durabilité économique ",
        sus: "Autres activités secondaires de durabilité économique ",
        role: "¿Quel est votre rôle au sein de votre organisation?",
        department: "¿À quel département appartenez-vous au sein de votre organisation?",
        representation: "Est-ce que je remplis les conditions pour représenter mon organisation sur le portail minka dev?",

        //activities
        act: "Type d'activités conduites et pourcentages de dédication",
        _per: ", avec un dévouement (%):",
        pr: "Principes considérés comme essentiels pour travailler avec d'autres organisation",
        logo: "Ajouter le logo de votre organisation Télécharger image",
        image: "Sélectionnez Fichier",
        Intro: "Les informations suivantes seront utilisées aux fins d'évaluer la compatibilité de l'organisation intéressée avec les diverses opportunités publiées sur la plateforme de Minka-Dev.",
      },
    },
    organizations: {
      title: "Votre Organisation",
      nocomplete: "Vous n'avez pas terminé votre organisation. Vous en avez besoin pour créer des opportunités et proposer des solutions.",
      define: "Définir le profil de votre organisation",
      adicional: "Plus d'informations",
      role: "Votre rôle",
      sostenibilidad: "Développement Durable",
      information: "Information de base"
    },
    opportunities: {
      adExp: "Créer une nouvelle opportunité",
      consumo: "Consommation et production durables",
      information_consumo: "Opportunités visant l'acquisition de produits et/ou services pour satisfaire les besoins de consommation et de production qui génèrent un plus grand bien-être social et le respect de l'environnement.",
      cadena: "Sourcing durable",
      information_cadena: "Opportunités liées à la fourniture de produits fabriqués avec des critères de durabilité (création de bien-être social, respect de l'environnement).",
      offer: "Nous proposons des produits ou services",
      information_offer: "Opportunités offertes par un produit ou un service (lié à l'approvisionnement durable, à la consommation ou à la consommation durable). Ils recherchent une connexion au marché qui peut les acquérir.",
      need: "Nous avons besoin de produits ou de services",
      information_need: "Opportunités créées pour rechercher un produit ou un service (lié à une chaîne d'approvisionnement durable, une consommation ou une production durable). Ils recherchent une organisation capable de résoudre le besoin soulevé.",
      offerExplain: "Opportunités créées pour rechercher un produit ou un service (lié à une chaîne d'approvisionnement durable, la consommation ou la production durable). Ils recherchent une organisation capable de résoudre le besoin soulevé",
      needExplain: "Opportunités qui offrent un produit ou un service (lié à un approvisionnement, une consommation ou une consommation durable). Ils recherchent un lien avec le marché qui peut les acquérir",
      consumoExplain: "Opportunités visant l'acquisition de produits et / ou services pour satisfaire les besoins de consommation et de production qui génèrent un plus grand bien-être social et respect de l'environnement",
      cadenaExplain: "Opportunités liées à la fourniture de produits fabriqués selon des critères de durabilité (création de protection sociale, respect de l'environnement)",
      anexos: "Documents liés (PDF): Associez toute information que vous considérez importante pour connaître votre proposition.",
      anexosView: "Documents liés (PDF)",
      closeFilter: "Notre bilan: voir les opportunités fermées",
    },
    servicios: {
      invite: "Nous sommes là pour vous aider à transformer un besoin ou un problème en une opportunité d'affaires créatrice d'une plus grande valeur ajoutée sociale.",
      invite2: "Découvrez nos services",
      algunas: "Quelques unes de nos expériences",
      titleBanner: "PRESTATIONS DE SERVICE",
      subTitleBanner: "Nous obtenons des résultats concrets dans le développement d'entreprises durables",
      title: "Que faisons nous?",
      subTitle: "Minka dev a construit son expérience dans 3 types d'entreprises: les entreprises vertes, les entreprises inclusives et les entreprises pour l'économie circulaire.",
    },
    nosotros: {
      title: "NOUS",
      subTitle: "MANIFESTE",
      about: "Chez minka dev, nous générons et développons des modèles commerciaux durables qui aident à résoudre un problème social et / ou environnemental.",
      developed: "Nous développons des entreprises durables qui génèrent des résultats pour le changement.",
      graymessages: {
        m1: "Nous croyons au potentiel de transformer les besoins sociaux et environnementaux en entreprises durables.",
        m2: "Nous développons des modèles et des méthodologies innovantes, centrées sur les personnes.",
      },
      business: {
        title1: "ENTREPRISES VERTES",
        title2: "ENTREPRISES INCLUSIVES",
        title3: "AFFAIRES POUR L'ÉCONOMIE CIRCULAIRE",
        text1: "Nous contribuons au développement d'entreprises générant des bénéfices environnementaux directs, ajoutant de la valeur et contribuant à la conservation de l'environnement.",
        text2: "Nous contribuons au développement d'entreprises inclusives qui affectent directement la réduction de la pauvreté et l'accès aux opportunités pour les populations dans des contextes de vulnérabilité.",
        text3: "Nous contribuons au développement des entreprises qui favorisent la production de biens et services durables, en réduisant la consommation, les sources d'énergie, les déchets et le temps.",
      },
      reality: {
        title: "NOUS EN FAISONS UNE RÉALITÉ",
        text: "Nous transformons les problèmes et les inefficacités en entreprises viables, innovantes et durables. Nous sommes partis de problèmes tels que le gaspillage alimentaire ou une espèce naturelle menacée et nous les avons transformés en entreprises viables, développées grâce à des partenariats public-privé catalysés par minka dev. Les entreprises qui ont été promues avec notre accompagnement ont permis aux entreprises impliquées d'innover et d'élargir leurs marchés.",
      },
      work: {
        title: "TRAVAILLE AVEC NOUS",
        text: "Êtes-vous intéressé à rejoindre notre équipe pour promouvoir les entreprises durables dans le changement social?",
        button: "Écrivez-nous",
      },
      testimonies: {
        title: "TÉMOIGNAGES",
      },
      messajeAlly: {
        title: "ALLIANCES",
      },
    },
    contacto: {
      title: "Avez-vous besoin de nous contacter?",
      text: "Remplissez ce formulaire ou contactez-nous via nos réseaux sociaux.",
      button: "Envoyer",
    },
    definition: {
      newButtons: {
        cancel: "Annuler",
        save: "Sauvegarder",
      },
      flash: {
        close_opportunity: "L'opportunité est fermée",
        msg1: "Opportunités créées pour rechercher un produit ou un service (lié à une chaîne d'approvisionnement durable, la consommation ou la production durable). Ils recherchent une organisation capable de résoudre le besoin soulevé",
        msg2: "Opportunités qui offrent un produit ou un service (lié à un approvisionnement, une consommation ou une consommation durable). Ils recherchent un lien avec le marché qui peut les acquérir",
        msg3: "Opportunités liées à l'offre et à la demande de produits ou de services réalisées selon des critères de durabilité (création de bien-être social, respect de l'environnement et bonnes performances économiques)",
        msg4: "Opportunités visant l'acquisition de produits et / ou services pour satisfaire les besoins de consommation et de production qui génèrent un plus grand bien-être social et respect de l'environnement",
      },
      button: {
        propouse: "Faire une proposition",
        youPropouse: "Faites votre proposition",
        notOrganization: "Vous n'avez pas d'organisation",
        closePropouse: "Fermer la proposition",
        close: "Fermé",
      },
      type: "Type d'opportunité",
      filters: {
        offer: "J'offre des produits ou services",
        need: "Recherche de produits ou services",
        consumo: "Consommation et production durables",
        cadena: "Sourcing durable",
      },
      sectors: "Les secteurs",
      impact: "Nombre de personnes cherchant à bénéficier de l'opportunité",
      hectaresPreservation: "Nombre d'hectares à préserver avec l'opportunité",
      dateClose: "Date de clôture de l'opportunité",
      concept: "Mots-clés ",
      description: "Description",
      conditions: "Autres caractéristiques ",
      motivation: "Motivation",
      idoneo: "L’organisation liée à cette opportunité et le contexte dans lequel se trouvent ceux qui souhaitent en bénéficier paraissent être appropriés pour pouvoir progresser vers le développement d’entreprises inclusives et durables.",
      definition: "Définition du produit ou service",
      country: "Pays",
      partner: "Opportunité développée avec le soutien de : ",
    },
    newPanel: {
      mode: "Mode",
      publish: "PUBLIER",
      publishButton: "PUBLIER L'OPPORTUNITÉ",
      propPublishButton: "PUBLIER L'PROPOSITION",
      publishing: "Publier...",
      save: "Enregistrer",
      saving: "Enregistrement...",
      saved: "Enregistré",
      edit: "Modifier",
      preview: "Aperçu",
      blankTitle: "L'opportunité doit avoir un titre ",
      publishMessage: "*Appuyez sur 'PUBLIER L'OPPORTUNITÉ' uniquement lorsque vous êtes sûr d'avoir correctement rempli tous les champs. Vous ne pourrez pas modifier après publication.",
      propPublishMessage: "*Appuyez sur 'PUBLIER L'PROPOSITION' uniquement lorsque vous êtes sûr d'avoir correctement rempli tous les champs. Vous ne pourrez pas modifier après publication.",
      publishModalTitle: "Confirmation de publication",
      publishModalText: "Je confirme avoir fourni les informations de manière exacte et complète. Je souhaite procéder à la publication de l'opportunité, sachant que je ne pourrai pas apporter de modifications une fois publiée."
    },
    opportunity: {
      accordion: {
        select: "Sélectionnez un type d'opportunité",
        details: "Détails",
        impact: "Impact",
        organization: "Organisation",
        haveCommunity: "Avoir une communauté",
        customerProfile: "Profil client ",
        findCommunity: "Chercher ",
        territory: "Territoire",
        community: "Communauté",
      },
      basicCommunity: {
        income: "Indique le niveau moyen du revenu communautaire journalie (DOLLARS AMÉRICAINS)",
        etnia: "Vous représentez / Vous recherchez des communautés avec la présence des groupes ethniques suivants.",
        etniaWitch: "Autres ethnies:",
        assets: "Énumérez les principaux atouts que la communauté possède ou devrait posséder.",
        services: "Services auxquels la communauté a un accès stable.",
        services_text: "Autres services:",
        incomeView: "Revenu mensuel communautaire",
        etniaView: "Groupes ethniques recherchant",
        etniaWitchView: "Groupes ethniques recherchant",
        assetsView: "Principaux atouts de la communauté",
        servicesView: "Services",
        services_textView: "Services",
      },
      detail1: {
        cause: "Décrivez la cause du besoin",
        solutions: "Connaissez-vous des solutions existantes au besoin identifié?",
        solutions_which: "Lesquelles?",
        value: "Pourquoi voulez-vous identifier un type de solution différent des solutions existantes?",
        value_OTHER_TEXT: "Quelle?",
        causeView: "Description du besoin",
        solutionsView: "Solutions existantes pour le besoin identifié",
        solutions_whichView: "Autres solutions existantes pour le besoin identifié",
        valueView: "Solution différente de celles existantes",
        value_OTHER_TEXTView: "Type",
      },
      detail2: {
        description: "Description du produit / service",
        capacity: "Quelle est la capacité actuelle d'offrir le produit / service?",
        capacity_quantity: "Quantité",
        capacity_quantity_validation: "La quantité doit être supérieure à zéro ",
        capacity_frequency: "La fréquence",
        capacity_unit: "Unité",
        markets: "Sur quels marchés ce service ou produit a-t-il été commercialisé?",
        markets_OTHER_TEXT: "Un autre marché",
        development_level: "Niveau de développement dans lequel se situe le produit / service",
        innovation: "Quelles sont les caractéristiques du service / produit proposé au marché?",
        innovation_OTHER_TEXT: "Outres characteristiques",
        descriptionView: "Description du produit ou service",
        capacityView: "Capacité actuelle d'offrir le produit",
        capacity_quantityView: "Quantité",
        capacity_frequencyView: "Fréquence",
        capacity_unitView: "Unité",
        marketsView: "Marchés dans lesquels ce produit a été commercialisé",
        markets_OTHER_TEXTView: "Autre marché",
        development_levelView: "Niveau de développement dans lequel se trouve le produit",
        innovationView: "Caractéristiques du produit proposé au marché",
        innovation_OTHER_TEXTView: "Autres caractéristiques",
      },
      detail3: {
        description: "Description du produit",
        vulnerable: "Le produit ou le service que vous offrez est-il commercialisé dans certaines populations vulnérables?",
        age_range: "À quelle tranche d'âge votre produit est-il destiné?",
        affordability: "Stratégie pour faciliter l'abordabilité des produits",
        affordability_OTHER_TEXT: "Quelles stratégies?",
        development: "Niveau de développement dans lequel se trouve le produit",
        value: "Valeur ajoutée de votre produit par rapport aux autres options du marché.",
        value_OTHER_TEXT: "Quelle?",
        action: "Quelle action attendez-vous de la communauté dans le cadre de la solution proposée?",
        activity: "En plus de l'achat de votre produit ou service, à quelle autre activité souhaiteriez-vous que l'organisation participe ? ",
        activity_OTHER_TEXT: "Quelles activités?",
        descriptionView: "Description du produit ou service",
        vulnerableView: "Le produit ou service que vous proposez est déjà en vente parmi certaines populations vulnérables",
        age_rangeView: "Tranche d'âge à laquelle le produit est destiné",
        affordabilityView: "Stratégie pour faciliter l'abordabilité du produit ou du service",
        affordability_OTHER_TEXTView: "Autres stratégies",
        developmentView: "Niveau de développement dans lequel se trouve le produit",
        valueView: "Valeur ajoutée de votre produit par rapport aux autres options du marché.",
        value_OTHER_TEXTView: "Type",
        actionView: "Actions à mener par la communauté dans le cadre de la solution proposée",
        activityView: "Activité dans laquelle la communauté sera intégrée et dans laquelle elle espère générer un impact social",
        activity_OTHER_TEXTView: "Autres activités",
      },
      detail4: {
        capacity_quantity_validation: "La quantité doit être supérieure à zéro ",
        description: "Description du produit ou service",
        buy_capacity: "Quelle est la capacité d'achat / de contratation actuelle?",
        buy_capacity_quantity: "Quantité",
        buy_capacity_frequency: "Fréquence",
        buy_capacity_unit: "Unité",
        innovation: "Concernant le produit que vous proposez, quelle est sa principale différence?",
        value: "Valeur ajoutée du lien que vous proposez comparativement à d'autres processus similaires.",
        value_OTHER_TEXT: "Quelle?",
        sails_capacity: "Capacité minimale attendue de la communauté",
        sales_capacity_quantity: "Quantité",
        sales_capacity_frequency: "Fréquence",
        sales_capacity_unit: "Unité",
        descriptionView: "Description du produit ou service",
        buy_capacityView: "Quelle est la capacité d'achat / de contratation actuelle?",
        buy_capacity_quantityView: "Quantité",
        buy_capacity_frequencyView: "Fréquence",
        buy_capacity_unitView: "Unité",
        innovationView: "Différence principale concernant le produit / service que vous proposez",
        valueView: "Valeur ajoutée de ce qu'elle propose comparativement à d'autres processus similaires.",
        value_OTHER_TEXTView: "Type",
        sails_capacityView: "Capacité minimale attendue de la communauté",
        sales_capacity_quantityView: "Quantité",
        sales_capacity_frequencyView: "Fréquence",
        sails_capacity_unitView: "Unité",
      },
      hasCommunity: {
        name: "Nom de l'organisation. ",
        country: "Pays",
        city: "Ville",
        address: "Adresse",
        role: "Rôle auquel vous vous identifiez concernant votre relation avec l'organisation dont vous souhaitez bénéficier. ",
        affected: "Nombre de personnes censées en bénéficier",
        antiguity: "Depuis combien de temps l'organisation avec laquelle vous collaborez est-elle établie ? ",
        characteristic: "Sélectionnez les caractéristiques qui décrivent l'organisation. ",
        association: "Type d'association représenté par l'organisation ",
        association_OTRO_TEXT: "Autres formes d'association:",
        experience_management:
          "Niveau d'expérience de votre organisation dans la formulation et gestion de projets",
        experience_development:
          "Niveau d'expérience de votre organisation dans l'exécution de projets de développement socio-économique",
        credibility_ong:
          "Degré de crédibilité de votre organisation auprès d'autres ONG",
        financing:
          "Quel type de financement l'organisation a-t-elle reçu? ",
        financing_type:
          "Quel type de financement l'organisation a-t-elle reçu? ",
        other_financing: "Autres financements ",
        time: "Depuis combien de temps travaillez-vous avec cette organisation? ",
        nameView: "Nom de l'organisation. ",
        countryView: "Pays",
        cityView: "Ville",
        addressView: "Adresse",
        rolView: "Rôle auprès de l'organisation. ",
        organization_roleView: "Relation avec l'organisation: ",
        affectedView: "Nombre de personnes censées en bénéficier",
        antiguityView: "Temps écoulé depuis la création de l'organisation avec laquelle il collabore. ",
        characteristicView: "Caractéristiques qui décrivent l'organisation",
        associationView:
          "Type d'association représenté par l'organisation ",
        experience_managementView:
          "Niveau d'expérience de votre organisation dans la formulation et gestion de projets",
        experience_developmentView:
          "Niveau d'expérience de votre organisation dans l'exécution de projets de développement socio-économique",
        credibility_ongView:
          "Degré de crédibilité de votre organisation auprès d'autres ONG",
        financingView: "Type de financement reçu par l'organisation ",
        financing_typeView: "Type de financement reçu par l'organisation ",
        other_financingView: "Autres financements ",
        accessView: "Type de routes d'accès que l'organisation a ",
        timeView: "Temps travaillé avec cette organisation ",
        populationView: "Population et sa dispersion",
      },
      impact: {
        sector: "Secteur à impacter ",
        sector_OTRO_TEXT: "Autre secteur",
        participation: "Contribution de votre organisation",
        other_participation: "Lequel est-ce? ",
        environmental: "Principaux indicateurs environnementaux",
        other_environmental: "Lequel est-ce? ",
        social: "Principaux indicateurs sociaux",
        sectorView: "Secteur",
        sector_OTRO_TEXTView: "Un autre secteur",
        participationView: "Contribution de votre organisation",
        other_participation: "Autre contribution ",
        environmentalView: "Principaux indicateurs environnementaux",
        other_environmentalView: "Autres indicateurs environnementaux ",
        socialView: "Principaux indicateurs sociaux",
      },
      customerProfile: {
        description: "Décrivez le profil souhaité des communautés à affilier",
        location: "Emplacement préféré des organisations à lier ",
        population: "Type de population et dispersion des clients que vous souhaitez lier. ",
        infrastructure_type: "Type d'infrastructure dont votre client aurait besoin pour acquérir votre produit/service ",
        other_infrastructure_type: "¿Cuáles? ",
        unfavorable: "Les populations à identifier doivent se trouver dans l'une des conditions défavorables suivantes",
        unfavorable_which: "Décrivez d'autres conditions qui vous semblent importantes",
        other_conditions: "Indiquez les autres conditions qui vous semblent importantes dans le cadre de cette opportunité",
        descriptionView: "Décrivez le profil souhaité des communautés à affilier",
        locationView: "Emplacement préféré des organisations à lier ",
        associationView: "Type d'association que vous préférez dans la communauté",
        association_OTRO_TEXTView: "Autres formes d'association:",
        populationView: "Type de population et dispersion des clients que vous souhaitez lier. ",
        unfavorableView: "Les populations à identifier doivent se trouver dans l'une des conditions défavorables suivantes",
        unfavorable_whichView: "Autres conditions:",
        other_conditionsView: "Indiquez les autres conditions qui vous semblent importantes dans le cadre de cette opportunité",
      },
      needsCommunity: {
        description: "Décrivez le profil souhaité des communautés à affilier",
        location: "Emplacement préféré de la chaîne d'approvisionnement à relier (uniquement la zone géographique) ",
        association: "La forme d'association que vous préférez avec la chaîne d'approvisionnement. ",
        association_OTRO_TEXT: "Autres formes d'association:",
        population: "Type de population et dispersion de la chaîne d'approvisionnement que vous souhaitez relier. ",
        unfavorable: "Les populations à identifier doivent se trouver dans l'une des conditions défavorables suivantes",
        unfavorable_which: "Décrivez d'autres conditions qui vous semblent importantes",
        other_conditions: "Indiquez les autres conditions qui vous semblent importantes dans le cadre de cette opportunité",
        descriptionView: "Décrivez le profil souhaité des communautés à affilier",
        locationView: "Localisation préférée des communautés que vous souhaitez affilier. ",
        associationView: "La forme d'association que vous préférez avec la chaîne d'approvisionnement. ",
        association_OTRO_TEXTView: "Autres formes d'association:",
        populationView: "Type de population et dispersion de la chaîne d'approvisionnement que vous souhaitez relier. ",
        unfavorableView: "Les populations à identifier doivent se trouver dans l'une des conditions défavorables suivantes",
        unfavorable_whichView: "Autres conditions:",
        other_conditionsView: "Indiquez les autres conditions qui vous semblent importantes dans le cadre de cette opportunité",
      },
      organization: {
        organization_type:
          "Veuillez indiquer ce qui décrit le mieux votre relation avec l'organisation.  :",
        challenges:
          "Domaines dans lesquels l'organisation fait face à des défis. ",
        other_challenges: "Autres domaines. ",
        projects_budget:
          "Fourchette de facturation de votre organisation au cours des deux dernières années (USD) ",
        nb_benefited:
          "Nombre approximatif de personnes ayant bénéficié de votre organisation au cours des 2 dernières années ",
        partipation_network:
          "Niveau de participation de votre organisation à des réseaux locaux, nationaux et internationaux",
        influence:
          "Capacité d'influence de votre organisation sur d'autres organismes",
        leadership:
          "Niveau de leadership de votre organisation dans le secteur d'activité",
        credibility_people:
          "Degré de crédibilité de votre organisation auprès de la population",
        market_orientation:
          "Degré d'orientation clientèle et marché de votre organisation",
        decision_tools:
          "Efficacité des processus de prise de décision au sein de l'organisation",
        organization_typeView: "Relation avec l'organisation: ",
        challengesView: "Domaines dans lesquels l'organisation fait face à des défis. ",
        other_challengesView: "Autres domaines. ",
        projects_budgetView:
          "Fourchette de facturation de votre organisation au cours des deux dernières années (dollars américains) ",
        nb_benefitedView:
          "Nombre approximatif de personnes ayant bénéficié de votre organisation au cours des 2 dernières années ",
        partipation_networkView:
          "Niveau de participation de votre organisation à des réseaux locaux, nationaux et internationaux",
        influenceView:
          "Capacité d'influence de votre organisation sur d'autres organismes",
        leadershipView:
          "Niveau de leadership de votre organisation dans le secteur d'activité",
        credibility_peopleView:
          "Degré de crédibilité de votre organisation auprès de la population",
        market_orientationView:
          "Degré d'orientation clientèle et marché de votre organisation",
        decision_toolsView:
          "Efficacité des processus de prise de décision au sein de l'organisation",
      },
    },
    territory: {
      territory_population: "Type de population et dispersion",
      territory_access: "Quel type de routes d'accès l'organisation a-t-elle? ",
      work: "Des projets menés conjointement par les secteurs privé et public avec des ONG existent sur le territoire",
      trust: "Il y a une méfiance à l'égard des institutions sur ce territoire",
      presence: "Il existe diverses organisations présentes sur le territoire qui travaillent pour le développement local",
      public: "Le secteur public a un rôle effectif dans le développement économique et social dans ce territoire",
      corporative: "Le secteur privé est perçu de manière favorable pour le développement du territoire",
      communities: "Les communautés habitant le territoire auxquelles est liée votre organisation ont la capacité de stimuler leur propre développement",
      order: "Il y a des problèmes d'ordre public dans le territoire concerné",
      alliances: "Les communautés locales ont une expérience suffisante pour s'associer à la recherche de solutions entrepreneuriales à leurs besoins",
      convoke: "Votre organisation a la capacité de fédérer des acteurs privés et publics autour de solutions entrepreneuriales qui abordent des problèmes sociaux",
      collaboration: "L'organisation en charge de représenter la communauté ainsi que d'autres organisations locales ont une expérience du travail concerté qui leur permet d'être partenaires dans la proposition de solutions entrepreneuriales",
      sinergy: "Il est possible de générer dans cette zone des synergies entre acteurs publics et privés autour de solutions entrepreneuriales abordant des problèmes sociaux",
      condition: "Sélectionnez les conditions qui, selon vous, peuvent constituer des obstacles à la mise en œuvre de l'opportunité commerciale:",
      condition_OTRO_TEXT: "Autres conditions",
      territory_populationView: "Population et sa dispersion",
      territory_accessView: "Type de routes d'accès que l'organisation a ",
      workView: "Des projets menés conjointement par les secteurs privé et public avec des ONG existent sur le territoire",
      trustView: "Il y a une méfiance à l'égard des institutions sur ce territoire",
      presenceView: "Il existe diverses organisations présentes sur le territoire qui travaillent pour le développement local",
      publicView: "Le secteur public a un rôle effectif dans le développement économique et social dans ce territoire",
      corporativeView: "Le secteur privé est perçu de manière favorable pour le développement du territoire",
      communitiesView: "Les communautés habitant le territoire auxquelles est liée votre organisation ont la capacité de stimuler leur propre développement",
      orderView: "Il y a des problèmes d'ordre public dans le territoire concerné",
      alliancesView: "Les communautés locales ont une expérience suffisante pour s'associer à la recherche de solutions entrepreneuriales à leurs besoins",
      convokeView: "Votre organisation a la capacité de fédérer des acteurs privés et publics autour de solutions entrepreneuriales qui abordent des problèmes sociaux",
      collaborationView: "L'organisation en charge de représenter la communauté ainsi que d'autres organisations locales ont une expérience du travail concerté qui leur permet d'être partenaires dans la proposition de solutions entrepreneuriales",
      sinergyView: "Il est possible de générer dans cette zone des synergies entre acteurs publics et privés autour de solutions entrepreneuriales abordant des problèmes sociaux",
      conditionView: "Des conditions que vous considérez peuvent constituer des obstacles à la mise en œuvre de l'opportunité commerciale:",
      condition_OTRO_TEXTView: "Autres conditions",
    },
    solution: {
      accordion: {
        select: "Sélectionnez un type d'opportunité",
        details: "Détails",
        impact: "Impact",
        organization: "Organisation",
        haveCommunity: "Avoir une communauté",
        customerProfile: "Profil client ",
        findCommunity: "Chercher ",
        territory: "Territoire",
        community: "Communauté",
      },
      solution1: {
        title: "Titre",
        description: "Description",
        sector: "Secteur auquel se réfère votre offre",
        other_sector: "Autre Secteur", //no View
        Motivacion: "Quelles sont vos motivations?",
        nb_affected: "Nombre de personnes qui vont bénéficier de cette solution",
        activity: "Dans quelle activité envisagez-vous d'intégrer la communauté pour créer un impact social?",
        action_solution: "Actions que vous espérez faire exécuter par la communauté",
        development_level: "Étape de développement à laquelle se trouve actuellement le produit ou service",
        affordability: "Concernant les stratégies visant à faciliter l’accessibilité du produit ou service pour la communauté, laquelle des options suivantes allez-vous utiliser avec les communautés associées à l’opportunité:",
        experience: "Avez-vous déjà eu des expériences de travail avec d’autres communautés ayant généré un impact social:",
        innovationsolution: "Concernant le produit ou service que vous proposez, laquelle des options suivantes le qualifie le mieux?",
        condition: "Conditions additionnelles que la communauté doit remplir pour avoir accès au produit ou service",
        environmental: "Quels indicateurs environnementaux pensez-vous avoir un impact? ",
        other_environmental: "Lequel est-ce? ",
        participation: "Contribution de votre organisation",
        other_participation: "Lequel est-ce? ",
        titleView: "Titre",
        descriptionView: "Description",
        sectorView: "Secteur auquel se réfère votre offre",
        MotivacionView: "Quelles sont vos motivations?",
        nb_affectedView: "Nombre de personnes qui vont bénéficier de cette solution",
        activityView: "Activités dans lesquelles on espère établir un lien avec la communauté",
        action_solutionView: "Actions que vous espérez faire exécuter par la communauté",
        development_levelView: "Niveau de développement ou de marché où se trouve actuellement le produit ou le service",
        affordabilityView: "Concernant les stratégies visant à faciliter l’accessibilité du produit ou service pour la communauté, laquelle des options suivantes allez-vous utiliser avec les communautés associées à l’opportunité:",
        experienceView: "Expérience de travail avec d'autres communautés générant un impact social",
        innovationsolutionView: "En ce qui concerne le produit ou le service que vous proposez, laquelle des options suivantes le représente le mieux.",
        conditionView: "Conditions additionnelles que la communauté doit remplir pour avoir accès au produit ou service",
        environmentalView: "Indicateurs environnementaux à impacter. ",
        other_environmentalView: "Autre indicateurs environnementaux. ",
        participationView: "Contribution de votre organisation",
        other_participationView: "Autre contribution",
      },
      solution2: {
        description: "Décrivez brièvement la façon dont vous envisagez d’insérer le produit ou service de cette communauté dans votre chaine de valeur",
        sector: "Secteur dans lequel s’inscrit le besoin/l’opportunité",
        other_sector: "Autre Secteur", //no View
        motivation: "Quelle est votre motivation pour insérer des communautés vulnérables dans votre chaîne de valeur?",
        experience: "Votre organisation souhaite-t-elle apporter une contribution qui facilite le rattachement de votre organisation à cette chaîne de valeur? ",
        buy_capacity: "Quelle est la capacité d'achat / de contratation actuelle?",
        buy_quantity: "Capacité minimale (Quantité) ",
        buy_frequency: "Capacité minimale (Fréquence) ",
        buy_unit: "Capacité minimale (Unité) ",
        expected_quantity: "Capacité minimale escomptée en termes de main d’œuvre dans la chaîne de valeur Quantité",
        expected_frequency: "Capacité minimale escomptée en termes de main d’œuvre dans la chaîne de valeur Fréquence",
        expected_unit: "Capacité minimale escomptée en termes de main d’œuvre dans la chaîne de valeur Unité",
        environmental: "Quels indicateurs environnementaux pensez-vous avoir un impact? ",
        other_environmental: "Lequel est-ce? ",
        participation: "Vous souhaitez apporter une contribution qui facilite le rattachement de cette Organisation à votre chaîne de valeur? ",
        other_participation: "Lequel est-ce? ",
        descriptionView: "Description de la façon de lier le produit / service de cette communauté à la chaîne de valeur",
        sectorView: "Secteur dans lequel s’inscrit le besoin/l’opportunité",
        motivationView: "Quelle est votre motivation pour insérer des communautés vulnérables dans votre chaîne de valeur?",
        experienceView: "Contribution qui facilite le rattachement de votre organisation à cette chaîne de valeur",
        buy_capacityView: "Quelle est la capacité d'achat / de contratation actuelle?",
        buy_quantityView: "Capacité minimale attendue de la communauté Quantité",
        buy_frequencyView: "Capacité minimale attendue de la communauté Fréquence",
        buy_unitView: "Capacité minimale attendue de la communauté Unité",
        environmentalView: "Indicateurs environnementaux sur lesquels vous pensez avoir un impact ",
        other_environmentalView: "Autres indicateurs environnementaux ",
        participationView: "Contribution qui facilite le rattachement de cette Organisation à votre chaîne de valeur ",
        other_participationView: "Autre contribution ",
      },
      solution3: {
        description: "Description du besoin/de l’opportunité que vous souhaitez résoudre",
        Motivacion: "Quelle est votre motivation pour amener un développement corporatif sur le besoin identifié?",
        nb_affected: "Nombre de personnes qui pourraient en bénéficier",
        descriptionView: "Description du besoin/de l’opportunité que vous souhaitez résoudre",
        MotivacionView: "Motivation pour amener un développement corporatif sur le besoin identifié",
        nb_affectedView: "Nombre de personnes qui pourraient en bénéficier",
        aporteView: "Votre contribution au processus",
      },
      solution4: {
        description: "Décrivez brièvement le produit et/ou le service. Mettez en relief la valeur différentielle",
        Motivacion: "Quelle est votre motivation pour insérer ce produit ou service sur de nouveaux marchés?",
        capacity_quantity_validation: "La quantité doit être supérieure à zéro ",
        capacity_quantity: "Quelle est la capacité minimale que vous pouvez offrir? (Qguantité) ",
        capacity_frequency: "Quelle est la capacité minimale que vous pouvez offrir? (Fréquence) ",
        capacity_unit: "Quelle est la capacité minimale que vous pouvez offrir? (Unité) ",
        development: "Stade actuel de développement du produit ou service",
        market: "Dans quel type de marchés avez-vous commercialisé ce produit et/ou service",
        descriptionView: "Décrivez brièvement le produit et/ou le service.",
        MotivacionView: "Quelle est votre motivation pour insérer ce produit ou service sur de nouveaux marchés?",
        capacity_quantityView: "Quelle est la capacité minimale que vous attendez de la communauté offrant le produit ou service? (Qguantité)",
        capacity_frequencyView: "Quelle est la capacité minimale que vous attendez de la communauté offrant le produit ou service? (Fréquence)",
        capacity_unitView: "Quelle est la capacité minimale que vous attendez de la communauté offrant le produit ou service? (Unité)",
        developmentView: "Stade actuel de développement du produit ou service",
        marketView: "Dans quel type de marchés avez-vous commercialisé ce produit et/ou service",
        aporteView: "Contribution au processus",
      },
      communitySolution: {
        country: "Pays",
        city: "Ville",
        address: "Adresse",
        assets: "Contribution à la proposition en tant qu'organisation",
        income: "Contributions que les locaux doivent apporter quotidiennement",
        services: "Services offerts à la communauté",
        population: "Type de population et dispersion",
        etnia: "Ethnicité",
        etnia_which: "Quelle ethnie",
        organization_role: "Quel type d'organisation est:",
        contactname: "Nom du contact",
        contactemail: "Email du contact",
        contactphone: "Numéro de contact",
        name: "Nom de la communauté",
        afected: "Communauté impliquée",
        age_range: "Type de population impliquée",
        antiguity: "Antiquité",
        characteristic: "Caractéristiques",
        association: "Types d'associés",
        financing: "Le financement",
        access: "Type de route d'accès",
        tipo_necesidad: "répondre au besoin",
        rol: "Rôle",
        time: "Conditions météorologiques",
        countryView: "Pays",
        cityView: "Ville",
        addressView: "Adresse",
        assetsView: "Contribution à la proposition en tant qu'organisation",
        incomeView: "Contributions que les locaux doivent apporter quotidiennement",
        servicesView: "Services offerts à la communauté",
        populationView: "Type de population et sa dispersion",
        etniaView: "Ethnicité",
        etnia_whichView: "Quelle ethnie",
        organization_roleView: "Quel type d'organisation est:",
        contactnameView: "Nom du contact",
        contactemailView: "Email du contact",
        contactphoneView: "Numéro de contact",
        nameView: "Nom de la communauté",
        afectedView: "Communauté impliquée",
        age_rangeView: "Type de population impliquée",
        antiguityView: "Antiquité",
        characteristicView: "Caractéristiques",
        associationView: "Types d'associés",
        financingView: "Le financement",
        accessView: "Besoin fourni",
        tipo_necesidadView: "necesidad que suple",
        rolView: "Rôle",
        timeView: "Conditions météorologiques",
      },
      communitypropouse: {
        understand: "Nous entendons par territoire le lieu où vit la communauté qui bénéficiera de l'opportunité. Cette information nous permet de connaître l'aptitude du territoire à attirer un développement entrepreneurial d'impact social.",
        name: "Nom de la communauté",
        country: "Pays",
        city: "Ville",
        address: "Adresse",
        assets: "Contribution à la proposition en tant qu'organisation",
        income: "Contributions que les locaux doivent apporter quotidiennement",
        services: "Services offerts à la communauté",
        population: "Type de population",
        etnia: "Ethnicité",
        etnia_which: "Quelle ethnie",
        organization_role: "De quel type d'organisation s'agit-il?",
        contactname: "Nom du contact",
        contactemail: "Email du contact",
        contactphone: "Numéro de contact",
        afected: "CCommunauté impliquée",
        age_range: "Type de population impliquée",
        antiguity: "Antiquité",
        characteristic: "Les caractéristiques",
        association: "Les caractéristiques",
        financing: "Le financement",
        access: "Type de route d'accès",
        tipo_necesidad: "Besoin de fournir",
        rol: "Rôle",
        time: "Conditions météorologiques",
        nameView: "Nom de la communauté",
        countryView: "Pays",
        cityView: "Ville",
        addressView: "Adresse",
        assetsView: "Contribution à la proposition en tant qu'organisation",
        incomeView: "Contributions que les locaux doivent apporter quotidiennement",
        servicesView: "Services offerts à la communauté",
        populationView: "Type de population",
        etniaView: "Ethnicité",
        etnia_whichView: "Quelle ethnie",
        organization_roleView: "De quel type d'organisation s'agit-il?",
        contactnameView: "Nom du contact",
        contactemailView: "Email du contact",
        contactphoneView: "Numéro de contact",
        afectedView: "Communauté impliquée",
        age_rangeView: "Type de population impliquée",
        antiguityView: "Antiquité",
        characteristicView: "Les caractéristiques",
        associationView: "Types d'associés",
        financingView: "Le financement",
        accessView: "Type de route d'accès",
        tipo_necesidadView: "Besoin de fournir",
        rolView: "Rôle",
        timeView: "Conditions météorologiques",
      },
      hasCommunity: {
        name: "Nom de l'organisation. ",
        country: "Pays",
        city: "Ville",
        address: "Adresse",
        role: "Rôle auquel vous vous identifiez concernant votre relation avec l'organisation dont vous souhaitez bénéficier. ",
        affected: "Nombre de personnes censées en bénéficier",
        antiguity: "Depuis combien de temps l'organisation avec laquelle vous collaborez est-elle établie ? ",
        characteristic: "Sélectionnez les caractéristiques qui décrivent l'organisation. ",
        association: "Type d'association représenté par l'organisation ",
        association_OTRO_TEXT: "Autres formes d'association:",
        experience_management:
          "Niveau d'expérience de votre organisation dans la formulation et gestion de projets",
        experience_development:
          "Niveau d'expérience de votre organisation dans l'exécution de projets de développement socio-économique",
        credibility_ong:
          "Degré de crédibilité de votre organisation auprès d'autres ONG",
        financing:
          "Quel type de financement l'organisation a-t-elle reçu? ",
        financing_type:
          "Quel type de financement l'organisation a-t-elle reçu? ",
        other_financing: "Autres financements ",
        time: "Depuis combien de temps travaillez-vous avec cette organisation? ",
        nameView: "Nom de l'organisation. ",
        countryView: "Pays",
        cityView: "Ville",
        addressView: "Adresse",
        rolView: "Rôle auprès de l'organisation. ",
        organization_roleView: "Relation avec l'organisation: ",
        affectedView: "Nombre de personnes censées en bénéficier",
        antiguityView: "Temps écoulé depuis la création de l'organisation avec laquelle il collabore. ",
        characteristicView: "Caractéristiques qui décrivent l'organisation",
        associationView:
          "Type d'association représenté par l'organisation ",
        experience_managementView:
          "Niveau d'expérience de votre organisation dans la formulation et gestion de projets",
        experience_developmentView:
          "Niveau d'expérience de votre organisation dans l'exécution de projets de développement socio-économique",
        credibility_ongView:
          "Degré de crédibilité de votre organisation auprès d'autres ONG",
        financingView: "Type de financement reçu par l'organisation ",
        financing_typeView: "Type de financement reçu par l'organisation ",
        other_financing: "Autres financements ",
        accessView: "Type de routes d'accès que l'organisation a ",
        timeView: "Temps travaillé avec cette organisation ",
        populationView: "Population et sa dispersion",
      },
      impact: {
        sector: "Secteur à impacter ",
        sector_OTRO_TEXT: "Autre secteur",
        participation: "Contribution de votre organisation",
        other_participation: "Lequel est-ce? ",
        environmental: "Principaux indicateurs environnementaux",
        other_environmental: "Lequel est-ce? ",
        social: "Principaux indicateurs sociaux",
        sectorView: "Secteur",
        sector_OTRO_TEXTView: "Un autre secteur",
        participationView: "Contribution de votre organisation",
        other_participation: "Autre contribution ",
        environmentalView: "Principaux indicateurs environnementaux",
        other_environmentalView: "Autres indicateurs environnementaux ",
        socialView: "Principaux indicateurs sociaux",
      },
      organization: {
        organization_type:
          "Veuillez indiquer ce qui décrit le mieux votre relation avec l'organisation.  :",
        challenges:
          "Domaines dans lesquels l'organisation fait face à des défis. ",
        other_challenges: "Autres domaines. ",
        projects_budget:
          "Fourchette de facturation de votre organisation au cours des deux dernières années (USD) ",
        nb_benefited:
          "Nombre approximatif de personnes ayant bénéficié de votre organisation au cours des 2 dernières années ",
        partipation_network:
          "Niveau de participation de votre organisation à des réseaux locaux, nationaux et internationaux",
        influence:
          "Capacité d'influence de votre organisation sur d'autres organismes",
        leadership:
          "Niveau de leadership de votre organisation dans le secteur d'activité",
        credibility_people:
          "Degré de crédibilité de votre organisation auprès de la population",
        market_orientation:
          "Degré d'orientation clientèle et marché de votre organisation",
        decision_tools:
          "Efficacité des processus de prise de décision au sein de l'organisation",
        organization_typeView: "Relation avec l'organisation: ",
        challengesView: "Domaines dans lesquels l'organisation fait face à des défis. ",
        other_challengesView: "Autres domaines. ",
        projects_budgetView:
          "Fourchette de facturation de votre organisation au cours des deux dernières années (dollars américains) ",
        nb_benefitedView:
          "Nombre approximatif de personnes ayant bénéficié de votre organisation au cours des 2 dernières années ",
        partipation_networkView:
          "Niveau de participation de votre organisation à des réseaux locaux, nationaux et internationaux",
        influenceView:
          "Capacité d'influence de votre organisation sur d'autres organismes",
        leadershipView:
          "Niveau de leadership de votre organisation dans le secteur d'activité",
        credibility_peopleView:
          "Degré de crédibilité de votre organisation auprès de la population",
        market_orientationView:
          "Degré d'orientation clientèle et marché de votre organisation",
        decision_toolsView:
          "Efficacité des processus de prise de décision au sein de l'organisation",
      },
    },
    modals: {
      modalOpportunity: {
        needHelp: "Tu as besoin d'aide?",
        checkServices: "Vérifiez les services offerts par minkadev.",
        acept: "Je suis d'accord",
        services: "Prestations de service",
        publish: "Publier",
        titleProp: "Publier une proposition indépendamment",
        labelProp: "Publier une proposition",
        titleOpp: "Publier une proposition indépendamment",
        labelOpp: "Publier une proposition",
      },
      modalPost: {
        publish: "Votre opportunité a été publiée",
        congratulations: "Toutes nos félicitations!",
        vinculation: "L'organisation liée à cette opportunité et le contexte dans lequel se trouvent ceux qui souhaitent en bénéficier semblent être idéales pour avancer dans le développement d'une entreprise inclusive et durable.",
        analitic: "À partir des informations que vous nous avez fournies, les outils de développement minka produisent une analyse qui nous permet de déterminer les conditions actuelles dans le contexte de l'organisation et de la communauté pour développer des affaires à impact social. Un score plus élevé indique un niveau plus élevé d'adéquation et de faisabilité pour se connecter à ce type de développement commercial.",
        evaluation: "Les aspects suivants sont évalués grâce à cet outil:",
        track: "Trajectoire organisationnelle.",
        impact: "Impact généré.",
        model: "Modèle d'opération.",
        capacity: "Capacité relationnelle de l'organisation candidate.",
        viability: "Viabilité du territoire sur lequel se trouve l'organisation candidate.",
        capacityReprecentation: "Capacité à représenter la population dans la population dans laquelle vous souhaitez avoir un impact.",
        wish: "Si vous souhaitez des conseils et du soutien pour surmonter ces défis, contactez-nous au:",
        evaluationRisk: "Évaluation du fonctionnement et de la solidité de l'organisation qui propose l'opportunité et le risque.",
        services: "Services associés à ce produit, qui peuvent être sélectionnés",
      },
      modalDetail: {
        result: "Résultat",
        who: "À qui il est adressé?",
        requestInfo: "Demandez plus d'informations",
        registerInfo: "Inscrivez-vous pour demander plus d'informations",
      },
      modalContact: {
        contact: "Contacter l'organisation",
        message: "Vous pouvez laisser un message à l'organisation",
        responseMail: "Votre adresse email",
      },
      modalConditions: {
        confirm: "Je confirme que les informations fournies sont véridiques et répondent aux intérêts et à la réalité de mon organisation.",
        check: "J'ai lu et j'accepte les",
        politics: "politique de confidentialité",
        ofMinka: "de minka dev",
        acept: "Je suis d'accord",
      },
      modalOrganization: {
        type: "Type",
        sector: "Secteur",
        activities: "Activités",
        values: "Début",
      },
      modalPlus: {
        opportunity: "Opportunités minka dev",
        verify: "Opportunités vérifiées et analysées par minka dev",
        text: "Dans les opportunités minka dev Plus, nous avons vérifié la capacité locale, la viabilité et les routes du marché avec le plus grand potentiel. Ce sont des opportunités de grande qualité en raison de l'analyse et du soutien qu'elles ont dû être identifiées. Les opportunités Minka dev plus ont tendance à avoir un plus grand potentiel de réussite de développement Vous souhaitez contacter les services de minka dev pour identifier une opportunité commerciale à fort impact social environnemental? Contactez-nous ",
      },
    },
    help: {
      detail1: {
        description: "Essayez de résumer le besoin que vous avez identifié, les communautés avec lesquelles vous comptez travailler et toutes les informations que vous estimerez utiles aux personnes ou entités qui souhaiteront participer à résoudre ce problème (250 mots maximum).",
        pais: "Fait référence au pays dans lequel vous souhaitez créer l'opportunité",
      },
      detail2: {
        description: "Essayez de résumer le besoin que vous avez identifié, les communautés avec lesquelles vous comptez travailler et toutes les informations que vous estimerez utiles aux personnes ou entités qui souhaiteront participer à résoudre ce problème (250 mots maximum).",
        frequency: "Ajoutez une fréquence. Cela peut être annuel, hebdomadaire ou une autre période temporaire",
        capacity: "Les unités peuvent être des mesures appropriées, telles que des tonnes, des mètres cubes, etc.",
      },
      impact: {
        social: "Le 25 septembre 2015, les dirigeants de nombreux pays ont adopté un ensemble d’objectifs mondiaux visant à éradiquer la pauvreté, protéger la planète et assurer la prospérité de tous dans le cadre d’un nouveau programme de développement durable. Chacun de ces objectifs possède des cibles spécifiques qui doivent être atteintes dans les 15 prochaines années.",
      },
      solution2: {
        expected: "Si vous avez besoin de main-d'œuvre, veuillez compléter. Sinon, écrivez «sans objet»",
      },
    },
  }, //END FRENCH
};
