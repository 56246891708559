import React from 'react'

const CirclePlus = ({ width = "3.4rem", height = "3.4rem", color }) => {
  return (
    <svg width={width} height={height} viewBox="0 0 1024 1024" xmlns="http://www.w3.org/2000/svg">
      <path fill={color || "#D9D9D9"} d="M512 64a448 448 0 1 1 0 896 448 448 0 0 1 0-896zm-38.4 409.6H326.4a38.4 38.4 0 1 0 0 76.8h147.2v147.2a38.4 38.4 0 0 0 76.8 0V550.4h147.2a38.4 38.4 0 0 0 0-76.8H550.4V326.4a38.4 38.4 0 1 0-76.8 0v147.2z"/>
    </svg>
  )
}

export default CirclePlus