import React, { useEffect, useState } from 'react'
import styled from 'styled-components';
import { getPercentage, getTotalFields } from '../../../lib/validators';

const FormProgress = ({ data }) => {
  const [percentage, setPercentage] = useState(0);
  const [totalFields, setTotalFields] = useState(getTotalFields(data));
  
  useEffect(() => {
    setTotalFields(getTotalFields(data))
  }, [data.type.has_community])

  useEffect(() => {
    setPercentage(getPercentage(totalFields, data))
  }, [data, totalFields])
  
  return (
    <FormProgressCont id="infoProgress">
      <ProgressBarCont>
        <ProgressBar percentage={percentage} height={"1.6rem"} bgColor="var(--purple4)" barColor="var(--purple3)" borderRadius={10} />
      </ProgressBarCont>
      <FormProgressInfo $percentage={percentage}>
        <span>{percentage}%</span>
      </FormProgressInfo>
    </FormProgressCont>
  )
}

const ProgressBar = ({height = 10, bgColor = "grey", borderRadius = 0, percentage = 0, barColor = "green"}) => {
	return (
		<div>
			<div style={{position:"relative", height, backgroundColor: bgColor, borderRadius}}>
				<div style={{ width: percentage + "%", position:"absolute", height, backgroundColor: barColor, borderRadius}}/>
			</div>
		</div>
	)
}

const FormProgressCont = styled.div`
  position: sticky;
  display: flex;
  justify-content: center;
  padding: 2rem 0;
  top: 0;
  background-color: white;
  z-index: 10;
`
const ProgressBarCont = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 100%;
`
const FormProgressInfo = styled.div`
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 6.8rem;
  height: 6.6rem;
  background-color: ${({$percentage}) => $percentage >= 50 ? "var(--purple3)":"var(--grey1)"};
  border-radius: 50%;
  z-index: 1;

  span{
    font-size: 2.5rem;
    color: ${({$percentage}) => $percentage >= 50 ? "var(--grey1)" : "var(--purple3)"};
    font-family: var(--BarlowCondensed);
    font-weight: 600;
  }
`

export default FormProgress