import React, { useState, useEffect, useContext, Fragment } from "react";
import styled from "styled-components";
import { LangContext, translations } from "../../../lib/Language";
import useUploader from "../_hooks/useUploader";
import Modal from "./Modal";
import api from "../../../lib/api";
import RoundFlag from "../../_components/RoundFlag";
import PlaceholderImage from "./PlaceholderImage";
import DownIcon from "../../_components/icons/DownIcon";
import LoadingBars from "../../_components/LoadingBars";
import CirclePlus from "../../_components/icons/CirclePlusIcon";
import CloseIcon from "../../_components/icons/CloseIcon";
import helpIcon from "../../../images/icons/help.png"

const FormField = ({ data, label, labelCenter, field = "", t, f, options = false, selectors, saveData, addImage, selector = false, errors, help, include_blank, small, getOnly, include, flag = true, accept, noLabel = false, fieldRequired, initValue }) => {
  label = label || f[field];
  let required = fieldRequired ? fieldRequired : t === "boolean" ? false : errors[field] ? true : false;

  if (
    field === "capacity_quantity" ||
    field === "buy_capacity_quantity" ||
    field === "sales_capacity_quantity"
  ) {
    if (data[field] === "0" || data[field] === 0) {
      required = true;
    }
  }
  
  const image = { data, field, addImage, f, required };
  const text = { data, field, saveData, required };
  const check = { ...text, options, selectors, selector, include, label, required };
  const drop = { ...check, include_blank, getOnly, initValue, required };

  const generic = ["text", "number", "email", "password"];
  const noLabelFields = ["files", "videos", "links"]

  return (
    <InputGroup>
      {!noLabel && !noLabelFields.includes(t) && <Label {...{ help, label, required, small, labelCenter }}>{help}</Label>
      }
      {generic.includes(t) && <GenericField ftype={t} {...text} />}
      {t === "textarea" && <TextArea {...text} />}
      {t === "select" && <Dropdown {...drop} />}
      {t === "sectors" && <SectorDrop {...drop} />}
      {t === "countries" && <CountryDrop {...drop} flag={flag} />}
      {t === "checkboxes" && <Checkboxes {...check} />}
      {t === "boolean" && <Boolean {...check} />}
      {t === "image" && <Image {...image} />}
      {t === "sectorform" && <SectorForm {...{ data, saveData }} />
      }
      {t === "tags" && <Tags {...{ data, field, saveData }} />}
      {t === "videos" && <Videos {...{ data, field, saveData, label }} />}
      {t === "files" && <Files {...{ data, field, saveData, selector, accept, label }} />}
      {t === "links" && <Links {...{ data, field, saveData, label }} />}
    </InputGroup>
  );
};

export default FormField;

export const Label = ({ label, isfor = false, required, selection, children, orgInfo }) => {
  const [open, setOpen] = useState(false);

  let forField = isfor === "none" || isfor === "" ? false : isfor;
  if (typeof forField == "object" && forField !== null)
    forField = Object.entries(forField).length === 0 ? false : true;
  if (!forField === false) {
    forField = true;
  }

  return (
    <>
      <LabelInfo>
        <LabelText>
          {label}
          {required && !forField && <span>{" *"}</span>}
          {orgInfo && ":"}
        </LabelText>
        {children && (
          <img onClick={() => setOpen(true)} src={helpIcon} alt="help" />
        )}
      </LabelInfo>
      {selection && <p>{selection}</p>}
      {open && (
        <Modal title={label} close={() => setOpen(false)} width={"91.6rem"} height={"49.1rem"} >
          <LabelModalInfo>{children}</LabelModalInfo>
        </Modal>
      )}
    </>
  );
};

export const FormSection = ({ label, fields }) => {
  return (
    <>
      {label && <Label {...label}>{label.help}</Label>}
      {fields.map((f) => (
        <FormField key={f.field} {...f} />
      ))}
    </>
  );
};

const saveDirect = (saveData, n, value) =>
  saveData({ target: { name: n, value, getAttribute: () => false } });

export const Image = ({ data, field, addImage, f }) => {
  const [fileName, setFileName] = useState("");
  const [fileImageUrl, setFileImageUrl] = useState("");

  const selectedFiles = () => {
    let fileInput = document.querySelector(`#imageField_`+field).files[0];
    let objectURL = URL.createObjectURL(fileInput);
    setFileName(fileInput.name);
    setFileImageUrl(objectURL)
  };
  return (
    <ImageInputCont>
      {(data.image_url || fileImageUrl) && 
        <ImageCont>
          <PlaceholderImage image={fileImageUrl ? fileImageUrl : data.image_url?.includes("missing") ? null : data.image_url} />
        </ImageCont>}
      {fileName && <span>{fileName}</span>}
      <label>
        <input id={"imageField_"+field} type="file" name={field} onChange={addImage} onInput={() => selectedFiles()} accept=".png, .jpg, .jpeg, .webp" />
        {f[field]}
      </label>
    </ImageInputCont>
  );
};

export const GenericField = ({ data, field, saveData, ftype, required }) => (
  <GenericInput {...{required}} min="0" type={ftype} name={field} value={data[field] ? (data[field] === 0 || data[field] === "0") ? "" : data[field] : ""}  onChange={saveData} />
);

export const TextArea = ({ data, field, saveData, required }) => {
  return (
    <Textarea
      {...{required}}
      name={field}
      value={data[field] || ""}
      onChange={saveData}
    />
  );
};

export const Errors = ({ errors, field }) => (
  <div>
    {errors.map((error) => (
      <div key={field + "-error"} className="error">
        {error}
      </div>
    ))}
  </div>
);

//Send options for direct [{tag: "tag", name: "name"}]
export const Dropdown = ({ data, field, selector, selectors, options, saveData, initValue, include_blank = true, required }) => {
  const model = selector || field;
  const toMap = options || selectors[model];
  if (!toMap) return <div />;
  return (
    <SelectContainer className="select_cont">
      <DownIcon color={"#D9D9D9"}/>
      <Select {...{ required }} name={field} value={data[field] || ""} onChange={saveData}>
        {include_blank && <option value=""></option>}
        {initValue && <option value="">{initValue}{required&&" *"}</option>}
        {toMap.map((e) => (
          <option key={field + "_" + e.tag} value={e.tag}>
            {e.name}
          </option>
        ))}
      </Select>
    </SelectContainer>
  );
};

export const SectorDrop = ({data,field,saveData,include_blank = true,getOnly = false,small = false,initValue, required}) => {
  const [options, setOptions] = useState(window.ops || []);
  useEffect(() => {
    let signal;
    if (!window.ops) {
      signal = api.getSectors((r) => {
        let ops = [];
        for (const sector of r)
          for (const sub of sector.sub_sectors)
            ops.push({ tag: sub.id, name: `${sector.name} - ${sub.name}` });
        window.ops = ops;
        setOptions(ops);
      }, getOnly);
    }
    return () => signal && signal.abort();
  }, []);

  return (
    <Dropdown
      {...{ data, field, options, include_blank, small, saveData, initValue, required }}
    />
  );
};

//Use if no selectors... to Refactor to get selectors if non send
export const CountryDrop = ({ data, field, selectors, saveData, include_blank = true, small = false, flag = true, initValue, required }) => {
  const lang = useContext(LangContext);
  const [options, setOptions] = useState(selectors ? selectors.country : []);

  useEffect(() => {
    var signal;
    if (!selectors)
      signal = api.getSelectors(lang, (r) => {
        setOptions(r.country);
      });
    return () => {
      if (signal) signal.abort();
    };
  }, []);
  return (
    <div style={{ display: "flex", width: "100%" }}>
      <Dropdown {...{ data, field, options, include_blank, small, saveData, initValue, required }}/>
      {flag && (
        <div style={{ margin: "0 2% 0" }}>
          <RoundFlag id={data[field]} />
        </div>
      )}
    </div>
  );
};

export const Checkboxes = ({ data, field, selectors, options, include, selector, saveData, required }) => {
  const [remainingPercentage, setRemainingPercentage] = useState(100) //fields with include
  const [toMap, setToMap] = useState([]);
  const [checkRequired, setCheckRequired] = useState(required)

  const model = selector || field;

  const manageRequiredCheckboxes = (event) => {
    let isChecked = false;
    const checkboxes = document.getElementsByName(field);
    for (let i = 0; i < checkboxes.length; i++) {
      if (checkboxes[i].checked) {
          isChecked = true;
          break;
      }
    }
    if (required) setCheckRequired(!isChecked)
    if (saveData) saveData(event)
  }

  const handleMarkets = () => {
    if(toMap.length === 0) return setToMap(selectors[model])
    //validation for field "markets"(oppType2) when select first option hide other options
    if (data[field].includes("not_yet")) {
      setToMap(toMap.filter((e) => e.tag === "not_yet"));
    } else if (!data[field].includes("not_yet")) {
      setToMap(toMap.filter((e) => e.tag !== "not_yet"));
    }
  };

  useEffect(() => {
    if (field === "markets" && data[field].length > 0) {
      handleMarkets();
    } else if (field === "detail_value_multiple" && data["type"].number === 3) {
      setToMap(selectors[model].filter((e) => e.tag !== "NCOS"));
    } else {
      setToMap(options || selectors[model]);
    }
  }, [data[field]]);

  useEffect(() => {
    if (include) {
      setRemainingPercentage(100 - (Object.values(data[field]).reduce((a, b) => a + b, 0)))
    }
  }, [data])

  if (!toMap) return <div />;
  return (
    <CheckboxContainer>
      {toMap.map((check) => (
        <Checkbox key={"check_" + check.tag} selector={check} {...{ data, field, include, remainingPercentage, required }} saveData={manageRequiredCheckboxes} required={checkRequired}/>
      ))}
      {(include && remainingPercentage !== 0)&& <span style={{color: "red", textAlign: "end"}}>{remainingPercentage || 100}%</span>}
    </CheckboxContainer>
  );
};

const Checkbox = ({ data, field, selector, include, saveData, remainingPercentage, required }) => {
  const arr = data[field] || (include ? {} : []);
  const checked = include
    ? Object.keys(arr).includes(selector.tag)
    : arr.includes(selector.tag);

  return (
    <CheckboxField>
      <input type="checkbox" data-include={include} name={field} value={selector.tag} checked={checked} onChange={saveData} required={required}/>
      <label>{selector.name}</label>
      {checked && include && (
        <CheckboxPercentage>
          <input data-include={selector.tag} type="number" min={0} max={(arr[selector.tag] || 0) + remainingPercentage} name={field} value={arr[selector.tag] || ""} onChange={saveData} required/>
          <span> %</span>
        </CheckboxPercentage>
      )}
    </CheckboxField>
  );
};

const Boolean = ({ data, field, selectors, saveData }) => {
  const val = data[field] || false;
  return (
    <CheckboxField>
      <input type="checkbox" data-boolean={true} name={field} value={val} checked={val} onChange={saveData} />
      <label>
        {selectors.boolean.find((e) => e.tag === "true").name}
      </label>
    </CheckboxField>
  );
};

export const DeadlineField = ({ data, field, saveData, deadline }) => {
  const formatDate = (date) => {
    date.setFullYear(date.getFullYear() + (deadline ? 10 : 0));
    const [ formattedDate ] = date.toISOString().split('T')
    return formattedDate;
  };

  const listener = (date) => saveDirect(saveData, field, formatDate(date));

  useEffect(() => {
    if (!data[field] || deadline) {
      let d = new Date();
      d.setDate(d.getDate() + 30);
      listener(d);
    }
  }, []);
  return <></>;
};

const Tags = (props) => {
  const f = Array.isArray(props.data[props.field]) ? props.data[props.field] : [];
  return (
    <ArrayField {...props}>
      {(manage) => (
        <TagsContainer>
          {f.map((tag) => (
            <Tag key={tag + "_tag"}>
              <span>{tag}</span>
              <div style={{cursor: "pointer"}} onClick={() => manage("remove", tag)}>
                <CloseIcon width="3rem" height="3rem"/>
              </div>
            </Tag>
          ))}
        </TagsContainer>
      )}
    </ArrayField>
  );
};

const Videos = (props) => {
  const f = Array.isArray(props.data[props.field]) ? props.data[props.field] : [];

  //Validate youtube url
  let regex = /^((?:https?:)?\/\/)?((?:www|m)\.)?((?:youtube(-nocookie)?\.com|youtu.be))(\/(?:[\w\-]+\?v=|embed\/|v\/)?)([\w\-]+)(\S+)?$/img
  
  const showYoutubeIframe = (url) => {
    const regx = /^.*(?:(?:youtu\.be\/|v\/|vi\/|u\/\w\/|embed\/|shorts\/)|(?:(?:watch)?\?v(?:i)?=|\&v(?:i)?=))([^#\&\?]*).*/;
    let testUrl = regx.test(url)
    if (testUrl) {
      const videoId = url.match(regx);
      return <iframe width="100%" src={`https://www.youtube.com/embed/${videoId[1]}`} title="YouTube video player" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen />;
    }else{
      return <div>Invalid video</div>
    } 
  }

  return (
    <AttachmentFiles>
      <AttachmentInfo>
        <span>{props.label}</span>
      </AttachmentInfo>
      <ArrayField data={props.data} field={props.field} saveData={props.saveData} regex={regex}>
        {(manage) => (
          <>
            <AttachmentVideoCont>
              {f.map((url) => (
                <AttachmentVideo key={url + "_url"}>
                  {showYoutubeIframe(url)}
                  <DeleteButton onClick={() => manage("remove", url)}><CloseIcon/></DeleteButton>
                </AttachmentVideo>
              ))}
            </AttachmentVideoCont>
          </>
        )}
      </ArrayField>
    </AttachmentFiles>
  );
};

const Links = (props) => {
  const f = Array.isArray(props.data[props.field]) ? props.data[props.field] : [];

  return (
    <AttachmentFiles>
      <AttachmentInfo>
        <span>{props.label}</span>
      </AttachmentInfo>
      <ArrayField data={props.data} field={props.field} saveData={props.saveData} disabled={f.length >= 3}>
        {(manage) => (
          <>
            <AttachmentFilesContainer>
              {f.map((url) => (
                <AttachmentFile key={"file-id-" + url}>
                  <a href={url} target="_blank" rel="noopener noreferrer">{url}</a>
                  <div onClick={() => manage("remove", url)}><CloseIcon/></div>
                </AttachmentFile>
              ))}
            </AttachmentFilesContainer>
          </>
        )}
      </ArrayField>
    </AttachmentFiles>
  );
};

const ArrayField = ({ data, field, saveData, children, regex, disabled }) => {
  const [current, setCurrent] = useState("");
  const manage = (action, tag) => (action === "add" ? add() : remove(tag));

  const add = () => {
    if (current === "") return
    if (regex && !regex.test(current)) return alert("invalid data")
    let tags = data[field] ? [...data[field]] : [];
    tags.push(current);
    saveDirect(saveData, field, tags);
    setCurrent("");
  }

  const remove = (tag) => {
    let tags = [...data[field]];
    let index = tags.indexOf(tag);
    tags.splice(index, 1);
    saveDirect(saveData, field, tags);
  };

  return (
    <>
      <ArrayFieldContainer className="arr_field_container">
        <GenericInput value={current} onChange={(e) => setCurrent(e.target.value)} onKeyDown={(e)=> { if (e.key === "Enter" && !disabled) manage("add"); }} disabled={disabled} onBlur={()=>manage("add")}/>
        <ArrayFieldIcon onClick={()=>manage("add")}>
          <CirclePlus color={"#D9D9D9"}/>
        </ArrayFieldIcon>
      </ArrayFieldContainer>
      {children(manage)}
    </>
  );
};

//data.sub_sectors : [{id: x, name: "Industria - Agricultura"}]
const SectorForm = ({ data, saveData }) => {
  const c = translations[useContext(LangContext)].forms.controls;
  const [selector, setSelector] = useState(false);
  const [options, setOptions] = useState({});
  const [selectedIds, setSelectedIds] = useState(
    data.sub_sectors.map((ss) => ss.id)
  );
  const [loading, setLoading] = useState(false);
  const [selectedSector, setSelectedSector] = useState("");

  useEffect(() => {
    if (data.sub_sectors.length > 0) setSelectedSector("true")
  }, [data]);
  
  useEffect(() => {
    const signal = api.getSectors((r) => setOptions(r), false);
    return () => signal.abort();
  }, []);

  const close = () => {
    setLoading(true);
    api.saveSubSector({ id: data.id, ids: selectedIds }, (r) => {
      setLoading(false);
      if (r.success === false) {
        return;
      } else {
        setSelector(false);
        saveDirect(saveData, "sub_sectors", r.object);
        if (r.object.length > 0) setSelectedSector("")
      }
    });
  };

  const manage = (id) => {
    let n = [...selectedIds];
    const index = n.indexOf(id);
    const rem = (index) => {
      n.splice(index, 1);
      return n;
    };
    setSelectedIds(index > -1 ? rem(index) : [...n, id]);
  };

  return (
    <>
      {data.sub_sectors.length>0 && <SectorList>
        {data.sub_sectors.map((sector) => (
          <li key={"sector_" + sector.name}>
            <span>{sector.name}</span>
          </li>
        ))}
      </SectorList>}
      {(!options[0] || loading) ? 
        <LoadingBars color={"#fff"}/>
      :
        <SectorField onClick={() => setSelector(true)}>
          <select value={selectedSector} onChange={()=>selectedSector("true")} required>
            <option value="" disabled></option>
            <option value="true"></option>
          </select>
          <div >
            <CirclePlus width={"4rem"} height={"4rem"}/>
            <span>{c.addSector}</span>
          </div>
        </SectorField>
      }
      {selector && (
        <SectorSelector {...{ close, options, selectedIds, manage}} />
      )}
    </>
  );
};

const SectorSelector = ({ close, options, selectedIds, manage}) => {
  const c = translations[useContext(LangContext)].forms.controls;
  const [sector, setSector] = useState(false);

  const showSubMenu = (i) => {
    let element = document.getElementById("sectorSelector_subsector_" + i);
    let height = element ? `${element.scrollHeight}px` : "";
    return height;
  };

  return (
    <Modal title={c.selectSectors} width={"112.8rem"} {...{close}}>
      <>
        {options.map((sect, i) => (
          <Fragment key={sect.tag + "Selector"}>
            <SectorSelectorItem $active={sect.id === sector} onClick={() => setSector((e) => (sect.id === e ? false : sect.id)) } $mainSector>
              {sect.name} ({sect.sub_sectors.filter((sub) =>selectedIds.includes(sub.id)).length})
              <DownIcon rotate={sect.id === sector}/>
            </SectorSelectorItem>
            <SectorSelectorSubsectorCont id={"sectorSelector_subsector_" + i} style={{ height: `${sect.id === sector ? showSubMenu(i) : "0px"}`, }} >
              {sect.sub_sectors.map((sub) => (
                <SectorSelectorItem key={sect.tag + sub.tag + "SubSelector"} $active={selectedIds.includes(sub.id)} onClick={() => manage(sub.id)} >
                  {sub.name}
                </SectorSelectorItem>
              ))}
            </SectorSelectorSubsectorCont>
          </Fragment>
        ))}
      </>
    </Modal>
  );
};

const Files = ({ data, saveData, selector, accept, label }) => {
  const inputButtonText =
    translations[useContext(LangContext)].forms.controls.selectFile;
  const { submit, destroy, loading } = useUploader( data.id, selector, "files", "AgentFile", saveData );

  const submitFile = (e) => {
    if (e.target.files && e.target.files[0]){
      if (e.target.files[0].size > 4 * 1000 * 1024) {
        return alert("File with maximum size of 4MB is allowed")
      }
      submit(e.target.files[0])
    }
  }

  return (
    <AttachmentFiles>
      <AttachmentInfo>
        <span>{label}</span>
        <AttachmentButton>
          <input className="inputFile" type="file" name="file" accept={accept} onChange={(e) => submitFile(e)} />
          {inputButtonText}
        </AttachmentButton>
      </AttachmentInfo>
      <AttachmentFilesContainer>
        {data.files.map((item) => (
          <AttachmentFile key={"file-id-" + item.file.id}>
            <a href={item.document} target="_blank" rel="noopener noreferrer"> {item.file.document_file_name}</a>
            <div onClick={() => destroy(item)}><CloseIcon/></div>
          </AttachmentFile>
        ))}
      </AttachmentFilesContainer>
      {loading && <LoadingCont><LoadingBars color={"#FFF"}/></LoadingCont>}
    </AttachmentFiles>
  );
};

export const Images = ({ data, saveData, selector, }) => {
  const [uploadButton, setUploadButton] = useState(true)
  
  const inputButtonText = translations[useContext(LangContext)].forms.controls.selectFile;
  const uploadImageMessage = translations[useContext(LangContext)].forms.opportunities.uploadImageMessage;
  const {submit, destroy, loading} = useUploader( data.id, selector, "images", "Image", saveData );

  const requiredImage = data.images.length === 0

  const submitImage = (e) => {
    if (e.target.files && e.target.files[0]){
      if (e.target.files[0].size > 4 * 1000 * 1024) {
        return alert("File with maximum size of 4MB is allowed")
      }
      submit(e.target.files[0])
    }
  }

  const handleDelete = (image) => {
    destroy(image)
  }

  useEffect(() => {
    if (data.images.length >= 8) {
      setUploadButton(false)
    }else{
      setUploadButton(true)
    }
  }, [data.images])
  
  
  return (
    <AttachmentFiles>
      <AttachmentInfo>
        <span>{uploadImageMessage}<div>{requiredImage&&" *"}</div></span>
        {uploadButton && <AttachmentButton>
          <input type="file" name="image" accept="image/png, image/jpeg" onChange={(e) => submitImage(e)} required={requiredImage}/>
          {inputButtonText}
        </AttachmentButton>}
      </AttachmentInfo>
      <ImagesCont>
        {data.images.map((item, i) => (
          <AttachmentImage key={"image-id-" + item.file.id}>
            <img src={item.image} alt={"image"} />
            <DeleteButton onClick={() => handleDelete(item)}><CloseIcon width="3rem" height="3rem"/></DeleteButton>
          </AttachmentImage>
        ))}
      </ImagesCont>
      {loading && <LoadingCont><LoadingBars color={"#FFF"}/></LoadingCont>}
    </AttachmentFiles>
  );
};

// Label
const LabelInfo = styled.div`
  display: flex;
  align-items: center;
  gap: 1rem;
  img{
    width: 2rem;
    cursor: pointer;
  }
`
const LabelText = styled.h4`
  font-size: 2.5rem;
  margin: 0;
  color: white;
  span{
    color: var(--pink) !important;
  }
`
const LabelModalInfo = styled.div`
  padding: 5% 10%;
  font-family: var(--BarlowCondensed);
  font-size: 2.5rem;
  text-align: left;
  color: white;
  height: 100%;
  display: flex;
  align-items: center;
`

//Inputs
const InputGroup = styled.div`
  font-family: var(--Roboto);
  font-size: 2.5rem;
  font-weight: 400;
  color: var(--grey5);
  display: flex;
  flex-direction: column;
  gap: 2rem;
  input, textarea, select{
    scroll-margin-top: 18rem;
  }
`
//General
const GenericInput = styled.input`
  width: 100%;
  height: 4.8rem;
  padding: 0 3rem;
  font-size: inherit;
  font-weight: inherit;
  color: inherit;
  border: none;
`
//Textarea
const Textarea = styled.textarea`
  width: 100%;
  height: 12rem;
  padding: .5rem 3rem;
  font-size: inherit;
  font-weight: inherit;
  color: inherit;
  border: none;
  resize: none;
`
//Image
const ImageInputCont = styled.div`
  display: flex;
  align-items: center;
  gap: 2rem;

  span{
    color: white;
    font-size: 1.8rem;
  }

  label{
    appearance: none;
    font-size: 1.8rem;
    font-weight: 600;
    font-family: var(--Roboto);
    border-radius: 6px;
    border: 1px solid #FFF;
    padding: 1.5rem 1rem 1.1rem;
    text-align: center;
    cursor: pointer;
    width: 24rem;
    max-width: 100%;
    color: white;
    background: #474747;
  }
  input{
    display: none;
  }
`
const ImageCont = styled.div`
  width: 10rem;
  aspect-ratio: 1 / 1;
  border-radius: 50%;
  overflow: hidden;
  background-color: white;
  padding: .5rem;
  display: flex;
  align-items: center;
  justify-content: center;
`
//select field
const SelectContainer = styled.div`
  width: 100%;
  height: 4.8rem;
  font-size: inherit;
  font-weight: inherit;
  color: inherit;
  border: none;
  position: relative;
  background-color: white;
  
  svg{
    position: absolute;
    top: 40%;
    right: 1rem;
    z-index: 0;
  }
  `
const Select = styled.select`
  text-transform: none;
  appearance: none;
  background-color: #ffffff00;
  width: 100%;
  height: 100%;
  font-family: inherit;
  font-weight: inherit;
  font-size: inherit;
  padding: 0 5rem 0 3rem;
  border: none;
  color: var(--grey2);
  position: absolute;
  cursor: pointer;
`
//Checboxes
const CheckboxContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 2rem;
`
const CheckboxField = styled.div`
  displaY: flex;
  align-items: center;
  justify-content: center;
  gap: 2rem;
  input{
    margin: 0;
    width: 25px;
    height: 25px;
  }
  label{
    flex: 1;
    font-size: inherit;
  }
`
const CheckboxPercentage = styled.div`
  input{
    width: 7rem;
    height: auto;
    color: inherit;
    font-size: inherit;
  }
`
//Sector field
const SectorList = styled.ul`
  padding-inline-start: 2.4rem;
  margin: 0;
  li::marker{
    color: white;
  }
`
const SectorField = styled.div`
  position: relative;
  width: fit-content;
  select{
    width: 2px;
    height: 2px;
    position: absolute;
    bottom: 0;
    left: 45%;
    opacity: 0;
    z-index: -1;
  }
  div{
    padding: .5rem;
    width: fit-content;
    display: flex;
    align-items: center;
    gap: 1rem;
    cursor: pointer;
    display: flex;
  }
  select:invalid:focus{
    border: none;
  }
  select:invalid:focus ~ div{
    border: red 2px solid;
  }
`

const SectorSelectorSubsectorCont = styled.div`
  overflow: hidden;
  transition: all .5s;
`
const SectorSelectorItem = styled.div`
  background: ${({$active, $mainSector})=>$active ? "var(--grey6)" : $mainSector ? "var(--grey3)" : "none"} ;
  color: white ;
  border: solid 1px white;
  margin: 1rem;
  font-size: 2.5rem;
  font-weight: 400;
  font-family: var(--BarlowCondensed);
  padding: 1rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
  text-align: left;
`

//Tags
const ArrayFieldContainer = styled.div`
  position: relative;
  color: var(--grey2);
  max-height: 4.8rem;
`
const ArrayFieldIcon = styled.div`
  position: absolute;
  right: 1.3rem;
  top: 15%;
  cursor: pointer;
`
const TagsContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 2rem;
`
const Tag = styled.div`
  padding: .5rem;
  display: flex;
  gap: .5rem;
  align-items: flex-start;
  font-size: 2.5rem;
`
//Atachment Images
const AttachmentFiles = styled.div`
  display: grid;
  grid-template-columns: 30% 1fr;
  column-gap: 10%;
  position: relative;
  @media only screen and (orientation: portrait) {
    grid-template-columns: 1fr;
    grid-template-rows: 1fr;
    row-gap: 2rem;
  }
`
const AttachmentInfo = styled.div`
  display: flex;
  flex-direction: column;
  gap: 2rem;
  grid-row: 1 / 3;
  span{
    font-size: 2.5rem;
    font-weight: 400;
  }
  div{
    color: var(--pink) !important;
  }
`
const AttachmentButton = styled.label`
  display: flex;
  position: relative;
  color: white !important;
  font-size: 1.8rem;
  font-weight: 500;
  background-color: var(--grey2);
  max-width: 31.5rem;
  height: 3.7rem;
  border-radius: 6px;
  border: 1px solid white;
  justify-content: center;
  align-items: center;
  text-transform: uppercase;
  cursor: pointer;
  padding: .2rem 0 0;

  input{
    width: 2px;
    height: 2px;
    position: absolute;
    bottom: 0;
    left: 45%;
    opacity: 0;
    z-index: -1;
  }
  input:invalid:focus{
    border: none;
  }
`
const ImagesCont = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fill, 14rem);
  grid-auto-rows: 14rem;
  gap: 1rem;
  justify-content: center;
  align-items: center;
  justify-items: center;
  @media only screen and (orientation: portrait) {
    justify-content: flex-start;
  }
`
const AttachmentImage = styled.div`
  width: 100%;
  height: 100%;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  
  img{
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
`
const DeleteButton = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  background-color: #5E5E5E80;
  cursor: pointer;
  color: white;
  font-weight: 600;
  display: flex;
  align-items: center;
  justify-content: center;

  &:hover{
    opacity: 0.8;
  }
`
const LoadingCont = styled.div`
  width: 100%;
  height: 100%;
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #5E5E5E80;
`
//AttachmentFiles
const AttachmentFilesContainer = styled.div`
  display: flex;
  flex-direction: column;
`
const AttachmentFile = styled.div`
  display: flex;
  gap: 1rem;
  align-items: center;

  a{
    font-weight: 400;
    color: black;
    font-size: 2.5rem;
    word-break: break-all;
    text-decoration: underline;
  }
  div{
    background-color: #5E5E5E80;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    color: white;
  }
`
//Attachment Videos
const AttachmentVideoCont = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(25rem, 1fr));
  grid-auto-flow: row;
  gap: 2rem;
  justify-content: center;
  margin: 3rem 0 0;
`
const AttachmentVideo = styled.div`
  width: 100%;
  position: relative;
`