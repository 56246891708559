import React from "react";
import MainCarouselBanner from "../_components/MainCarouselBanner";
import { ViewContain } from "../_components/ViewContain";
import { InfoTitle } from "../_components/InfoTitle";
import ServicesInfoCard from "./_components/ServicesInfoCard";

import diagnosticIcon from "../../images/icons/diagnostic.webp"
import strategyIcon from "../../images/icons/strategy.webp"
import implementationIcon from "../../images/icons/implementation.webp"
import evaluationIcon from "../../images/icons/evaluation.webp"

const ServicesIndex = ({ texts, bannerData }) => {
  const servicesInfo = [{image: diagnosticIcon, title: texts.services_info_1_title, text: texts.services_info_1_description, target: texts.services_info_1_target, actions: [texts.services_info_1_action_1, texts.services_info_1_action_2, texts.services_info_1_action_3]}, { image: strategyIcon, title: texts.services_info_2_title, text: texts.services_info_2_description, target: texts.services_info_2_target, actions:[texts.services_info_2_action_1, texts.services_info_2_action_2, texts.services_info_2_action_3, texts.services_info_2_action_4]}, { image: implementationIcon, title: texts.services_info_3_title, text: texts.services_info_3_description, target: texts.services_info_3_target}, { image: evaluationIcon, title: texts.services_info_4_title, text: texts.services_info_4_description, target: texts.services_info_4_target, actions:[texts.services_info_4_action_1, texts.services_info_4_action_2, texts.services_info_4_action_3, texts.services_info_4_action_4]}]
  
  const implementationActions = [{title: texts.services_implementation_1_title, description: texts.services_implementation_1_description, actions: [texts.services_implementation_1_action_1, texts.services_implementation_1_action_2, texts.services_implementation_1_action_3, texts.services_implementation_1_action_4]},{title: texts.services_implementation_2_title, description: texts.services_implementation_2_description, actions: [texts.services_implementation_2_action_1, texts.services_implementation_2_action_2, texts.services_implementation_2_action_3, texts.services_implementation_2_action_4]},{title: texts.services_implementation_3_title, description: texts.services_implementation_3_description, actions: [texts.services_implementation_3_action_1, texts.services_implementation_3_action_2, texts.services_implementation_3_action_3, texts.services_implementation_3_action_4]},{title: texts.services_implementation_4_title, description: texts.services_implementation_4_description, actions: [texts.services_implementation_4_action_1, texts.services_implementation_4_action_2, texts.services_implementation_4_action_3, texts.services_implementation_4_action_4]}]

  return (
    <>
      <MainCarouselBanner {...{ bannerData }} smallText/>
      <ViewContain $paddingY={"5%"} $alignItems={"center"}>
				<ViewContain $paddingX={"10%"} $alignItems={"center"}>
          <InfoTitle $textTransform={"uppercase"}>
            {texts.services_info_title_1}
          </InfoTitle>
        </ViewContain>
        <ViewContain $alignItems={"center"} $gap={"1rem"}>
					{servicesInfo.map((service, i)=>
						<ServicesInfoCard {...{texts, service, i, implementationActions }} key={"Card"+i}/>
					)}
        </ViewContain>
      </ViewContain>
    </>
  )
}

export default ServicesIndex