import ReactOnRails from 'react-on-rails';

import Flash from '../bundles/_components/Flash';
import Header from '../bundles/_components/mainLayout/Header';
import Footer from '../bundles/_components/mainLayout/Footer';
import HomeIndex from '../bundles/home/HomeIndex';
import ServicesIndex from '../bundles/services/ServicesIndex';
import BridgeIndex from '../bundles/bridge/BridgeIndex';
import PrivacyIndex from '../bundles/privacy/PrivacyIndex';
import BussinesIndex from '../bundles/bussines/BussinesIndex';
import ProjectShow from '../bundles/bussines/ProjectsShow';
import AboutUsIndex from '../bundles/about_us/AboutUsIndex';
import NewsIndex from '../bundles/blogentries/NewsIndex';
import NewsShow from '../bundles/blogentries/NewsShow';
import MarketplaceIndex from '../bundles/marketplace/MarketplaceIndex';
import MarketplacePlus from '../bundles/marketplace/MarketplacePlus';
import Login from '../bundles/marketplace/login/Login';
import Signin from '../bundles/marketplace/login/Signin';
import ResetPassword from '../bundles/marketplace/login/ResetPassword';
import NewOpportunityView from '../bundles/marketplace/opportunities/NewOpportunityView';
import UserShow from '../bundles/marketplace/user/UserShow';
import ShowOpportunityView from '../bundles/marketplace/opportunities/ShowOpportunityView';
import EditOpportunityView from '../bundles/marketplace/opportunities/EditOpportunityView';
import ShowProposalView from '../bundles/marketplace/proposals/ShowProposalView';
import EditProposalView from '../bundles/marketplace/proposals/EditProposalView';

ReactOnRails.register({
  Header,
  Footer,
  Flash,
  HomeIndex,
  PrivacyIndex,
  ServicesIndex,
  BridgeIndex,
  BussinesIndex,
  ProjectShow,
  AboutUsIndex,
  NewsIndex,
  NewsShow,
  MarketplaceIndex,
  MarketplacePlus,
  Login,
  Signin,
  ResetPassword,
  NewOpportunityView,
  UserShow,
  ShowOpportunityView,
  EditOpportunityView,
  ShowProposalView,
  EditProposalView
});
