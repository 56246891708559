import React, { useContext, useState } from "react";
import { LangContext, translations } from "../../../lib/Language";
import FormField from "../_components/FormField";
import ViewField, { ViewSection } from "../_components/ViewField";
import LoadingBars from "../../_components/LoadingBars";
import api from "../../../lib/api";
// import { Organization, Territory, HasCommunity } from "./useOpController";

const usePrController = (common) => {
  const l = useContext(LangContext);
  const a = translations[l].solution.accordion;
  const d = translations[l].solution[`solution${common.data.type.number}`];
  const i = translations[l].solution.impact;
  const org = translations[l].solution.organization;
  const ter = translations[l].territory;
  const has = translations[l].solution.hasCommunity;

  if (!common.selectors) return [{ title: "...", component: <div /> }];
  let acc = [
    { title: a.details, component: <Details {...common} f={d} i={i} /> },
  ];

  //Si la oportunidad tiene comunidad, la propuesta no tiene comunidad
  if (!common.data.type.has_community) {
    if (!common.view) {
      acc.push({
        title: a.organization,
        component: (
          <OrganizationType {...common} d={d} trOrg={org} trHas={has} />
        ),
      });
      acc.push({
        title: a.territory,
        component: (
          <Territory
            {...common}
            f={ter}
            data={common.data.territory}
            errors={common.data.territory.errors}
            saveData={(e) => common.saveData(e, "territory")}
          />
        ),
      });
    }
  }

  return acc;
};

export default usePrController

const check = (data, field) => {
  return data.details[`otype${data.type.number}`].includes(field);
};

//aporte y participacion son lo mismo. Unificado
//los que chequean props.f.market es porque son detalles4 con info diferente
const Details = (props) => {
  console.log("props :>> ", props);
  return props.view ? (
    <>
      {check(props.data, "sub_sector_id") && (
        <ViewField
          {...props}
          object={props.data}
          t="sector"
          field="sub_sector_id"
          label={props.i.sector}
        />
      )}
      {check(props.data, "other_sector") && (
        <ViewField {...props} t="text" field="other_sector" />
      )}
      {check(props.data, "nb_affected") && (
        <ViewField
          {...props}
          object={props.data}
          t="selector"
          field="nb_affected"
          label={props.f.nb_affectedView}
          getSelector={props.getSelector}
        />
      )}
      {check(props.data, "activity") && (
        <ViewField
          {...props}
          object={props.data}
          t="selector"
          field="activity"
          label={props.f.activityView}
          getSelector={props.getSelector}
        />
      )}
      {check(props.data, "action") && (
        <ViewField
          {...props}
          object={props.data}
          t="selector"
          field="action"
          label={props.f.action_solutionView}
          getSelector={props.getSelector}
        />
      )}
      {check(props.data, "development") && (
        <ViewField
          {...props}
          object={props.data}
          t="selector"
          field="development"
          label={props.f.development_levelView}
          secondary={"development_level"}
          getSelector={props.getSelector}
        />
      )}
      {check(props.data, "affordability") && (
        <ViewField
          {...props}
          object={props.data}
          t="selector"
          field="affordability"
          label={props.f.affordabilityView}
          getSelector={props.getSelector}
        />
      )}
      {check(props.data, "innovation") && (
        <ViewField
          {...props}
          object={props.data}
          t="selector"
          field="innovation"
          label={props.f.innovationsolutionView}
          getSelector={props.getSelector}
        />
      )}
      {check(props.data, "experience") && (
        <ViewField
          {...props}
          object={props.data}
          t="boolean"
          field="experience"
          label={props.f.experienceView}
          getSelector={props.getSelector}
        />
      )}
      {check(props.data, "buy_quantity") && (
        <ViewSection
          fields={[
            {
              ...props,
              t: "text",
              object: props.data,
              field: "buy_quantity",
              label: props.f.buy_quantityView,
            },
            {
              ...props,
              t: "text",
              object: props.data,
              field: "buy_unit",
              label: props.f.buy_quantityView,
            },
            {
              ...props,
              t: "text",
              object: props.data,
              field: "buy_frequency",
              label: props.f.buy_quantityView,
            },
          ]}
        />
      )}

      {check(props.data, "capacity_quantity") && (
        <ViewSection
          fields={[
            {
              ...props,
              t: "text",
              field: "capacity_quantity",
              label: props.f.capacity_quantityView,
            },
            {
              ...props,
              t: "text",
              field: "capacity_unit",
              label: props.f.capacity_quantityView,
            },
            {
              ...props,
              t: "text",
              field: "capacity_frequency",
              label: props.f.capacity_quantityView,
            },
          ]}
        />
      )}
      {check(props.data, "market") && (
        <ViewField
          {...props}
          t="selector"
          field="market"
          secondary="market_solution_4"
          getSelector={props.getSelector}
        />
      )}

      {check(props.data, "participation") && (
        <ViewField
          {...props}
          object={props.data}
          t="selector"
          field="participation"
          label={props.f.participationView}
          getSelector={props.getSelector}
        />
      )}
      {check(props.data, "other_participation") && (
        <ViewField
          {...props}
          object={props.data}
          t="text"
          field="other_participation"
          label={props.f.other_participationView}
        />
      )}
      {check(props.data, "environmental") && (
        <ViewField
          {...props}
          object={props.data}
          t="list"
          field="environmental"
          label={props.f.environmentalView}
        />
      )}
      {check(props.data, "other_environmental") && (
        <ViewField
          {...props}
          object={props.data}
          t="text"
          field="other_environmental"
          label={props.f.other_environmentalView}
        />
      )}
    </>
  ) : (
    <>
      {check(props.data, "sub_sector_id") && (
        <FormField
          {...props}
          t="sectors"
          field="sub_sector_id"
          label={props.f.sector}
        />
      )}
      {check(props.data, "other_sector") && (
        <FormField {...props} t="text" field="other_sector" />
      )}
      {check(props.data, "nb_affected") && (
        <FormField {...props} t="select" field="nb_affected" />
      )}
      {check(props.data, "activity") && (
        <FormField {...props} t="select" field="activity" />
      )}
      {check(props.data, "action") && (
        <FormField
          {...props}
          t="select"
          field="action"
          label={props.f.action_solution}
        />
      )}
      {check(props.data, "development") && (
        <FormField
          {...props}
          t="select"
          field="development"
          label={props.f.development_level}
          selector={"development_level"}
        />
      )}
      {check(props.data, "affordability") && (
        <FormField {...props} t="select" field="affordability" />
      )}
      {check(props.data, "innovation") && (
        <FormField
          {...props}
          t="select"
          field="innovation"
          label={props.f.innovationsolution}
        />
      )}
      {check(props.data, "experience") && (
        <FormField {...props} t="select" field="experience" />
      )}
      {check(props.data, "buy_quantity") && (
        <FormSection
          fields={[
            { ...props, t: "number", field: "buy_quantity" },
            { ...props, t: "text", field: "buy_unit" },
            { ...props, t: "text", field: "buy_frequency" },
          ]}
        />
      )}

      {check(props.data, "capacity_quantity") && (
        <FormSection
          fields={[
            { ...props, t: "number", field: "capacity_quantity" },
            { ...props, t: "text", field: "capacity_unit" },
            { ...props, t: "text", field: "capacity_frequency" },
          ]}
        />
      )}
      {check(props.data, "market") && (
        <FormField
          {...props}
          t="select"
          field="market"
          selector="market_solution_4"
        />
      )}

      {check(props.data, "participation") && (
        <FormField {...props} t="select" field="participation" />
      )}
      {props.data.participation === "OTH" && (
        <FormField {...props} t="text" field="other_participation" />
      )}
      {check(props.data, "environmental") && (
        <FormField {...props} t="checkboxes" field="environmental" />
      )}
      {props.data.environmental.includes("OOTH") && (
        <FormField {...props} t="textarea" field="other_environmental" />
      )}
    </>
  );
};

export const OrganizationType = (props) => {
  const [loading, setLoading] = useState(false);

  const organizationFields = {
    challenges: [],
    other_challenges: null,
    credibility_people: null,
    decision_tools: null,
    influence: null,
    leadership: null,
    market_orientation: null,
    nb_benefited: null,
    partipation_network: null,
    projects_budget: null,
  };
  
  const communityFields = {
    address: null,
    affected: null,
    antiguity: null,
    associate: null,
    other_association: null,
    experience_development: null,
    experience_management: null,
    credibility_ong: null,
    characteristic: null,
    city: null,
    country: null,
    financing: null,
    financing_type: [],
    other_financing: null,
    name: null,
    role: null,
    time: null,
  };

  const setFormValues = (data) => {
    const selectedForm =
      data.organization_type === "PROSE"
        ? [organizationFields, "agent_organization"]
        : data.organization_type === "MISMC"
        ? [communityFields, "community"]
        : null;
    if (selectedForm) {
      let tempData = { ...data };
      for (const key in selectedForm[0]) {
        if (Object.hasOwnProperty.call(selectedForm[0], key)) {
          const element = selectedForm[0][key];
          tempData[selectedForm[1]][key] = element;
        }
      }
      return tempData;
    }
  };

  const handleOrganizationType = (e) => {
    let updateData = { ...props.data, organization_type: e.target.value };
    updateData = setFormValues(updateData);
    setLoading(true);
    api.save(updateData, "proposals", (response) => {
      props.setData(response.object);
      setLoading(false);
    });
  };

  return props.view ? (
    <>
      <ViewField
        {...props}
        t="select"
        f={props.trOrg}
        field="organization_type"
        selector="orgrol"
      />
      {props.data.organization_type === "MISMC" && <Organization {...props} />}
      {props.data.organization_type === "PROSE" && <HasCommunity {...props} />}
    </>
  ) : (
    <>
      {loading ? (
        <LoadingBars />
      ) : (
        <>
          <FormField
            {...props}
            t="select"
            f={props.trOrg}
            field="organization_type"
            selector="orgrol"
            saveData={handleOrganizationType}
          />
          {props.data.organization_type === "MISMC" && (
            <Organization
              {...props}
              data={props.data.agent_organization}
              errors={props.data.agent_organization.errors}
              saveData={(e) => props.saveData(e, "agent_organization")}
              f={props.trOrg}
            />
          )}
          {props.data.organization_type === "PROSE" && (
            <HasCommunity
              {...props}
              data={props.data.community}
              errors={props.data.community.errors}
              saveData={(e) => props.saveData(e, "community")}
              f={props.trHas}
            />
          )}
        </>
      )}
    </>
  );
};

export const Organization = (props) => {
  return (
    <>
      <FormField {...props} t="checkboxes" field="challenges" />
      {props.data.challenges.includes("ot") && (
        <FormField {...props} t="textarea" field="other_challenges" />
      )}
      <FormField {...props} t="select" field="projects_budget" />
      <FormField
        {...props}
        t="select"
        field="nb_benefited"
        selector="nb_affected"
      />
      <FormField
        {...props}
        t="select"
        field="partipation_network"
        selector="null_high"
      />
      <FormField {...props} t="select" field="influence" selector="null_high" />
      <FormField
        {...props}
        t="select"
        field="leadership"
        selector="null_high"
      />
      <FormField
        {...props}
        t="select"
        field="credibility_people"
        selector="null_high"
      />
      <FormField
        {...props}
        t="select"
        field="market_orientation"
        selector="null_high"
      />
      <FormField
        {...props}
        t="select"
        field="decision_tools"
        selector="null_high"
      />
    </>
  );
};

export const HasCommunity = (props) => {
  // console.log("props :>> ", props);
  return props.view ? (
    <>
      <ViewField {...props} t="text" field="city" f={props.f} />
    </>
  ) : (
    <>
      <FormField {...props} t="select" field="role" selector="rol" />
      <FormField {...props} t="select" field="antiguity" />
      <FormField {...props} t="select" field="characteristic" />
      <FormField
        {...props}
        t="select"
        field="associate"
        label={props.f.association}
        selector="association"
      />
      {props.data.associate === "OTHS" && (
        <FormField
          {...props}
          t="text"
          field="other_association"
          label={props.f.association_OTRO_TEXT}
        />
      )}
      <FormField
        {...props}
        t="select"
        field="experience_management"
        selector="null_high"
      />
      <FormField
        {...props}
        t="select"
        field="experience_development"
        selector="null_high"
      />
      <FormField
        {...props}
        t="select"
        field="credibility_ong"
        selector="null_high"
      />
      <FormField
        {...props}
        t="checkboxes"
        field="financing_type"
        selector={"financing"}
      />
      {props.data.financing_type.includes("OTHS") && (
        <FormField {...props} t="textarea" field="other_financing" />
      )}
      <FormField {...props} t="select" field="time" />
    </>
  );
};

export const Territory = (props) => {
  const agrees = [
    "work",
    "trust",
    "presence",
    "public",
    "corporative",
    "communities",
    "order",
    "alliances",
    "convoke",
    "collaboration",
    "sinergy",
  ];
  return (
    <>
      <FormField
        {...props}
        t="select"
        field="territory_population"
        selector="population"
      />
      <FormField {...props} t="checkboxes" field="territory_access" />
      {agrees.map((e) => (
        <FormField
          {...props}
          t="select"
          key={"agree" + e}
          field={e}
          selector="agree"
        />
      ))}
      <FormField {...props} t="checkboxes" field="condition" />
      {props.data.condition.includes("ot") && (
        <FormField
          {...props}
          t="text"
          field="other_condition"
          label={props.f.condition_OTRO_TEXT}
        />
      )}
    </>
  );
};
