import React from 'react'
import OpportunityViewController from './_controllers/OpportunityViewController'
import useBackButtonConfirmation from '../_hooks/useBackButtonConfirmation'

const EditOpportunityView = ({ opportunity, ods, identify, lang, currentUser, currentUserOrg, adminUser }) => { 
  const { removeBackEvent } = useBackButtonConfirmation()
  
  return(
    <OpportunityViewController {...{ opportunity, ods, identify, lang, currentUser, currentUserOrg, adminUser, removeBackEvent }} fromShowComponent={false}/>
  )
}

export default EditOpportunityView