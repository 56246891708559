import styled from "styled-components";

export const YellowButton = styled.button`
  width: 37.8rem;
  height: 3.6rem;
  padding: 0.2rem 0 0;
  border-radius: 6px;
  border: solid 1px #FFF;
  background-color: #FBCF0D;
  font-family: var(--Roboto);
  font-size: 1.8rem;
  font-weight: 400;
  color: #383232;
  cursor: pointer;
  transition: all .5s;
  filter: grayscale(${({$disable}) => $disable ? "1" : "0"});
  text-transform: ${({$textTransform}) => $textTransform || ""};

  &:hover{
    color: white;
    background-color: #696863;
  }
  
  &:active{
    transform: scale(1.1);
  }

  @media (max-width: 440px) {
    width: 51rem;
    height: 4.6rem;
    font-size: 3rem;
  }
`