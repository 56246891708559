import styled from "styled-components";
import React from 'react'

const NewOpportunityCard = ({ offerOpp, cardTitle, cardDescription, cardOption1, cardOption2, handleData }) => {
  const requirementTag = offerOpp ? "offer" : "need";
  
  return (
    <NewOpportunityCardCont $bgColor={offerOpp ? "#361A3C" : "#7E143A"}>
      <h2>{cardTitle}</h2>
      <span>{cardDescription}</span>
      <CardLabel><CardRadio name="type" value={"cadena"} onChange={(e)=>{handleData(e, requirementTag)}}/><span>{cardOption1}</span></CardLabel>
      <CardLabel><CardRadio name="type" value={"consumo"} onChange={(e)=>{handleData(e, requirementTag)}}/><span>{cardOption2}</span></CardLabel>
    </NewOpportunityCardCont>
  )
}

export default NewOpportunityCard

const NewOpportunityCardCont = styled.div`
  width: 54.8rem;
  height: 57.9rem;
  background-color: ${({$bgColor}) => $bgColor} ;
  padding: 5% 4%;
  color: #fff;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 3rem;

  &:hover {
    opacity: 0.8;
  }
  
  h2{
    font-family: var(--BarlowCondensed);
    font-size: 5rem;
    font-weight: 600;
    text-transform: uppercase;
    margin: 2%;
  }
  span{
    font-size: 2.5rem;
    text-align: center;
    font-family: var(--Roboto);
    margin: 0;
    font-weight: 500;
  }
`

const CardLabel = styled.label`
  display: flex;
  width: 100%;
  gap: 2rem;
  align-items: center;
  cursor: pointer;
  
  span{
    font-family: var(--Roboto);
    font-weight: 300;
    font-size: 2.5rem;
    text-align: left;
  }
` 

const CardRadio = styled.input.attrs({type: "radio"})`

  &::before{
    content: "";
    background-color: white;
    display: flex;
    width: 3rem;
    height: 3rem;
    border-radius: 50%;
  }
  
  cursor: pointer;
  appearance: none;
  
  &:checked {
    &::before{
      background-color: orange;
    }
  }

  &:checked ~ span {
    color: orange;
  }
`