import styled from "styled-components"

export const CardsContain = styled.div`
  width: auto;
  margin: 5% 0;
  display: grid;
  place-items: center;
  grid-template-columns: repeat(auto-fit, minmax(36.7rem, 1fr));
  gap: 5rem;
  @media (max-width: 1688px) {
    margin: 5% 10%;
  }
  @media (max-width: 768px) {
    grid-template-columns: repeat(auto-fit, minmax(234px, 1fr));
  }
`

export default CardsContain