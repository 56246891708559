import React, { useEffect, useState } from "react";
import styled from "styled-components";
import Modal from "../../_components/Modal";
import EditProfile from "./EditProfile";
import RoundFlag from "../../../_components/RoundFlag";
import { translations } from "../../../../lib/Language";
import { GreyButton } from "../../../_components/GreyButton";
import api from "../../../../lib/api";
import userMarketplace from "../../../../images/icons/user_marketplace.png"

const ShowInfoUser = ({ user, lang, authenticity_token, t }) => {
  const countries = translations[lang].general.country;
  const [viewMobile, setViewMobile] = useState(null)
  const [edit, setEdit] = useState(false);
  const email = user.email.split("@")

  useEffect(() => {
    const updateSize = ()=>{
      let width = window.innerWidth
      if (width > 720 && viewMobile) {
        setViewMobile(false)
      }else if (width <= 720 && !viewMobile) {
        setViewMobile(true)
      } 
    }
    if (viewMobile === null) updateSize()

    window.addEventListener('resize', updateSize)
    return () => {
      window.removeEventListener('resize', updateSize)
    }
  })

  return (
    <>
      <InfoUserContain id="InfoUser">
        <InfoUserAvatar>
          <img src={userMarketplace} alt="User"/>
          {/* Only desktop view */}
          <ButtonsDesktopCont>
            <GreyButton $width={"25rem"} $height={"4.8rem"} $textTransform={"uppercase"} onClick={() => setEdit(!edit)}>{t.edit_button}</GreyButton>
            <GreyButton $width={"25rem"} $height={"4.8rem"} onClick={() => api.logOut(() => window.location = "/")} $textTransform={"uppercase"}>{t.log_out}</GreyButton>
          </ButtonsDesktopCont>
        </InfoUserAvatar>
        <InfoUserCont>
          <InfoName>{`${user.first_name} ${user.last_name}`}</InfoName>
          <InfoCountry>
            <RoundFlag id={user.country} width={"6.4rem"} height={"6.4rem"}/>
            <div >{countries[user.country]}</div>
          </InfoCountry>
          <InfoUser >{user.post}</InfoUser>
          <InfoUser>
            <div ><span>{email[0]}</span>
            <span>@{email[1]}</span></div>
          </InfoUser>
          <InfoUser>{user.phone}</InfoUser>
          {/* Only mobile view */}
          <ButtonsMobileCont>
            <GreyButton $width={"25rem"} $height={"4.8rem"} $textTransform={"uppercase"} onClick={() => setEdit(!edit)}>{t.edit_button}</GreyButton>
            <GreyButton $width={"25rem"} $height={"4.8rem"} onClick={() => api.logOut(() => window.location = "/")} $textTransform={"uppercase"}>{t.log_out}</GreyButton>
          </ButtonsMobileCont>
        </InfoUserCont>
      </InfoUserContain>
      {edit&&(
        <Modal title={"Editar Perfil"} close={() => setEdit(false)}>
          <EditProfile {...{user,authenticity_token, lang, t}}/>
        </Modal>
      )}
    </>
  );
};

const InfoUserContain = styled.div`
  width: 100%;
  padding: 4% 10%;
  display: flex;
  flex-direction: row;
  background-color: #D9D9D980;
  margin: 5% 0;
  gap: 5rem;
  scroll-margin-top: 10.6rem;
  
  @media only screen and (orientation: portrait) {
    flex-direction: column;
    gap: 2rem;
    padding: 6% 10%;
  }
`

const ButtonsDesktopCont = styled.div`
  display: flex;
  gap: 2rem;
  flex-wrap: wrap;
  justify-content: center;

  @media only screen and (orientation: portrait) {
    display: none;
  }
`

const ButtonsMobileCont = styled.div`
  display: none;

  @media only screen and (orientation: portrait) {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 2rem;
  }
`

const InfoUserAvatar = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 2rem;
  img{
    width: 16rem;
    height: 16rem;
  }
`
const InfoUserCont = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  justify-content: space-between;
  @media only screen and (orientation: portrait) {
    align-items: center;
    gap: 2rem;
  }
`
const InfoName = styled.div`
  font-family: var(--BarlowCondensed);
  font-size: 4rem;
  font-weight: 600;
`
const InfoCountry = styled.div`
  display: flex;
  align-items: center;
  gap: 1rem;
  div{
    font-family: var(--BarlowCondensed);
    font-size: 4rem;
    text-transform: uppercase;
    font-weight: 600;
    color: var(--redWine);
  }
`
const InfoUser = styled.div`
  font-size: 3rem;
  font-weight: 300;
  color: var(--purple);
`

export default ShowInfoUser