import React, { useEffect, useState } from 'react'
import styled from "styled-components";
import halfCircle1 from "../../../images/images/half_circle1.png"
import halfCircle2 from "../../../images/images/half_circle2.png"
import halfCircle3 from "../../../images/images/half_circle3.png"
import halfCircle4 from "../../../images/images/half_circle4.png"
import halfCircle5 from "../../../images/images/half_circle5.png"
import RandomDigitIndicator from '../../_components/RandomNumberIndicator';
import FadeInSection from '../../_components/FadeInSection';

const MarketplaceIndicator = ({index, cant, text}) => {
  const [initAnimation, setInitAnimation] = useState(false)

  return (
    <FadeInSection inViewAction={()=>setInitAnimation(true)}>
      <MarketplaceIndicatorCont $index={index}>
        <div>
          <RandomDigitIndicator targetNumber={cant} startAnimation={initAnimation}/>
        </div>
        <span>{text}</span>
      </MarketplaceIndicatorCont>
    </FadeInSection>
  )
}

const images = [halfCircle1, halfCircle2, halfCircle3, halfCircle4, halfCircle5]
const theme = ["#361A3C", "#ED245D", "#7E143A", "#ED6B33", "#361A3C"]

const MarketplaceIndicatorCont = styled.div`
  &::before{
    content: "";
    position: absolute;
    top: 50%; 
    left: ${({$index}) => $index%2 === 0 ? "4%": "96%"};
    transform: translate(-50%, -50%) scaleX(${({$index}) => $index%2 === 0 ? "-1": "1"});
    width: 17.5rem;
    height: 35.7rem;
    background-image: url(${({$index}) => images[$index%5]});
    background-repeat: no-repeat;
    background-size: cover;
  }

  position: relative;
  display: flex;
  flex-direction: ${({$index}) => $index%2 === 0 ? "row": "row-reverse"};
  align-items: center;
  gap: 5rem;
  font-family: var(--BarlowCondensed);
  margin: 5rem 0;

  div{
    font-size: 20rem;
    font-weight: 600;
    display: flex;
    margin: ${({$index}) => $index%2 === 0 ? "0 0 0 12%": "0 12% 0 0"} ;
    color: ${({$index}) => theme[$index%5]};
  }

  span{
    font-size: 6rem;
    text-transform: uppercase;
    font-weight: 600;
    width: min-content;
    white-space: pre-line;
    overflow-wrap: break-word;
    text-align: ${({$index}) => $index%2 === 0 ? "start": "end"};
    color: ${({$index}) => theme[$index%5]};
  }

  @media (max-width: 900px) {
    margin: 3rem 0;
    &::before{
      width: 9rem;
      height: 19rem;
    }
    div {
      font-size: 10rem;
    }
    span{
      font-size: 5rem;
    }
  }
`

export default MarketplaceIndicator