import React from 'react'
import styled from "styled-components";
import { LazyLoadImage } from 'react-lazy-load-image-component';
import 'react-lazy-load-image-component/src/effects/blur.css';

const BusinessInfoCardHomePage = ({image, title, text, i}) => {
  return (
    <BusinessInfo>
      <BusinesImageBg>
        <LazyLoadImage src={image} alt={`business image ${i+1}`} effect="blur" width="100%" height="100%" style={{position: 'absolute', top: "0", left: "0", objectFit: "cover"}}/>
      </BusinesImageBg>
      <BusinessInfoCont $index={i}>
        <BusinessInfoTitle dangerouslySetInnerHTML={{__html: title}}/>
        <BusinessInfoDescription>{text}</BusinessInfoDescription>
      </BusinessInfoCont>
    </BusinessInfo>
  )
}

const BusinessInfo = styled.div`
  position: relative;
  display: flex;
  width: 100%;
  height: 28.4rem;
  margin: 1rem 0;
  gap: 5%;
  user-select: none;

  @media only screen and (orientation: portrait) {
    height: 48rem;
    flex-direction: column;
    align-items: center;
  }
`

const BusinesImageBg = styled.div`
  width: 100%;
  height: 100%;
  position: absolute;
  object-fit: cover;
`

const BusinessInfoCont = styled.div`
  height: 100%;
  flex: 1;
  display: flex;
  flex-direction: column;
  overflow: auto;
  position: relative;
  color: white;
  backdrop-filter: brightness(0.6);
  -webkit-backdrop-filter: brightness(0.6);
  padding: 2rem 5rem;
  
  @media (max-width: 900px) {
    padding: 2rem 2rem
  }
  @media (max-width: 440px) {
    padding: 1rem;
  }
`

const BusinessInfoTitle = styled.span`
  position: absolute;
  transform: translate(-50%, -50%);
  left: 50%;
  top: 50%;
  font-family: var(--BarlowCondensed);
  font-size: 5rem;
  font-weight: 600;
  text-transform: uppercase;
  visibility: visible;
  white-space: nowrap;
  
  span{
    font-weight: 300;
  }
  ${BusinessInfo}:hover & {
    visibility: hidden;
  }
  @media (max-width: 440px) {
    text-align: center;
  }
  `

const BusinessInfoDescription = styled.span`
  flex: 1;
  position: relative;
  font-family: var(--BarlowCondensed);
  font-size: 3rem;
  font-weight: 400;
  visibility: hidden;
  display: flex;
  align-items: center;
  height: 100%;
  text-align: center;
  padding: 0 8%;

  ${BusinessInfo}:hover & {
    visibility: visible;
  }
  @media (max-width: 900px) {
    font-size: 2.5rem;
  }
  @media (max-width: 440px) {
    font-size: 3.5rem;
    padding: 0;
  }
`

export default BusinessInfoCardHomePage