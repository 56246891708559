import React, { useState } from 'react'
import api from '../../../lib/api'

const useUploader = (id, model, field, upload_type, saveData) => {
  const [file, setFile] = useState(false)
  const [loading, setLoading] = useState(false)

  const destroy = (item) => {
    setLoading(true)
    api.destroyFile(model, upload_type, id, item.file.id, response => {
      if (!response.status) {
        saveData({ target: { name: field, value: response, getAttribute: () => false } });
      } else {
        alert(JSON.stringify(response.errors))
      }
      setLoading(false)
    })
  }

  const submit = (file) => {
    if (!file) return
    setLoading(true)
    api.upload(model, upload_type, file, { id, authenticity_token: window.at },
      response => {
        if (!response.status) {
          saveData({ target: { name: field, value: response, getAttribute: () => false } });
          setFile(false)
        } else {
          alert(JSON.stringify(response.errors))
        }
        setLoading(false)
      }
    )
  }

  return {file, setFile, submit, destroy, loading}
}

export default useUploader