import React from 'react'
import { translations } from '../../../lib/Language';
import RoundFlag from '../../_components/RoundFlag';
import DownIcon from '../../_components/icons/DownIcon';
import styled from 'styled-components';

const CountrySelector = ({iconCountry, selectCountry, countries, lang, texts}) => {
  const countriesLabel = translations [lang].general.country
  
  return (
    <CountrySelectorCont >
      <RoundFlag id={iconCountry} width={"11.8rem"} height={"11.8rem"}/>
      <SelectCont>
        <select aria-label={texts.homepage_map_country_label} onChange={(e)=>selectCountry(e.target.value)} value={iconCountry} style={{textTransform: "uppercase"}}>
          {countries.map((country) => <option key={country} value={country}>{countriesLabel[country]}</option>)}
        </select>
        <DownIcon color={"#7E143B"}/>
      </SelectCont>
    </CountrySelectorCont>
  )
}

const CountrySelectorCont = styled.div`
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: var(--BarlowCondensed);

  @media only screen and (orientation: portrait) {
    width: auto;
  }
`

const SelectCont = styled.div`
  margin: 0 0 0 3rem;
  position: relative;
  cursor: pointer;

  select{
    font-size: 6rem;
    font-weight: 600;
    color: #7E143B;
    border: none;
    padding: 0 4rem 0 0;
    background: none;
  }
  option{
    font-size: 4rem;
  }
  svg{
    position: absolute;
    bottom: 0;
    right: 0;
    z-index: -1;
  }
`

export default CountrySelector