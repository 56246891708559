import React, { useEffect, useState } from "react";
import { styled } from 'styled-components';
import DownIcon from "../../icons/DownIcon";

import userIcon from "../../../../images/icons/user_icon_tablet_mobile.png"

const HeaderMenuMobile = ({ texts, currentUser, isMarketplace, marketPlaceLink }) => {
  const [showMenu, setShowMenu] = useState(false);
  const [servicesMenu, setServicesMenu] = useState(false);
  const [consultingMenu, setConsultingMenu] = useState(false)
  const [businessMenu, setBusinessMenu] = useState(false);
  const [marketplaceMenu, setMarketplaceMenu] = useState(false)

  const navigate = (url) => {
    window.location = url;
  };

  useEffect(() => {
    let element2 = document.querySelector(".app_content");
    const clickEvent = () => {
      setShowMenu(!showMenu);
    };
    if (showMenu) {
      element2.addEventListener("click", clickEvent);
    }
    return () => {
      if (element2) {
        element2.removeEventListener("click", clickEvent);
      }
    };
  }, [showMenu]);

  return (
    <>
      <HeaderMenuMobileCont onMouseLeave={() => setShowMenu(false)}>
        <HeaderMenuMobileIcon className={`${showMenu ? " menu_open" : ""}`} onClick={() => setShowMenu(!showMenu)} >
          <IconLine className="menu-icon-line"></IconLine>
          <IconLine className="menu-icon-line"></IconLine>
          <IconLine className="menu-icon-line"></IconLine>
        </HeaderMenuMobileIcon>
        <MenuMobile className={`${showMenu?" is-open": ""}`}>
          <MenuMobileInfo>
            <MenuMobileInfoCont>
              {isMarketplace && <MenuMobileUser>
                <MenuMobileUserCont href={currentUser ? `${marketPlaceLink}/profile/` + currentUser.id : `${marketPlaceLink}/login`}>
                  <MenuMobileUserIcon src={userIcon} alt="User"/>
                  <span>{currentUser ? `${currentUser.first_name} ${currentUser.last_name}` : texts.header_log_in}</span>
                </MenuMobileUserCont>
              </MenuMobileUser>}
              <div className="header_menu_services">
                <nav style={{cursor: "pointer", display: "flex"}} onClick={()=>setServicesMenu(!servicesMenu)} >
                  <span style={{color: servicesMenu?"#FBCF0D":"#D9D9D9"}}>{texts.header_services.toUpperCase()}</span>
                  <IconCont>
                    <DownIcon width={"2.8rem"} height={"1rem"} color={servicesMenu?"#FBCF0D":"#D9D9D9"} rotate={servicesMenu}/>
                  </IconCont>
                </nav>
                <MenuMobileSubmenu className={`${servicesMenu?" is-open": ""}`} >
                  <MenuMobileSubmenuCont>
                    <nav style={{cursor: "pointer", display: "flex"}} onClick={()=>setConsultingMenu(!consultingMenu)}>
                      <span style={{color: consultingMenu?"#FBCF0D":"#D9D9D9"}}>{texts.header_consulting.toUpperCase()}</span>
                      <IconCont>
                        <DownIcon width={"2.8rem"} height={"1rem"} color={consultingMenu?"#FBCF0D":"#D9D9D9"} rotate={consultingMenu}/>
                      </IconCont>
                    </nav>
                    <MenuMobileSubmenu className={`${consultingMenu?" is-open": ""}`}>
                      <MenuMobileSubmenuCont style={{left: "10%"}}>
                        <a href='/services' style={{cursor: "pointer"}}>
                          <span>{texts.header_diagnosis.toUpperCase()}</span>
                        </a>
                        <a href='/services' style={{cursor: "pointer"}}>
                          <span>{texts.header_strategy.toUpperCase()}</span>
                        </a>
                        <a href='/services' style={{cursor: "pointer"}}>
                          <span>{texts.header_implementation.toUpperCase()}</span>
                        </a>
                        <a href='/services' style={{cursor: "pointer"}}>
                          <span>{texts.header_evaluation.toUpperCase()}</span>
                        </a>
                      </MenuMobileSubmenuCont>
                    </MenuMobileSubmenu>
                    <a href='/bridge2zero' style={{cursor: "pointer", display: "flex"}}>
                      <span>BRIDGE<span style={{ color: "#7DA03C", margin: "0" }}>2</span>ZERO</span>
                    </a>
                  </MenuMobileSubmenuCont>
                </MenuMobileSubmenu>
              </div>
              <div className="header_subMenu-mobile">
                <nav style={{cursor: "pointer", display: "flex"}} onClick={()=>setBusinessMenu(!businessMenu)}>
                  <span style={{color: businessMenu?"#FBCF0D":"#D9D9D9"}}>{texts.header_business.toUpperCase()}</span>
                  <IconCont>
                    <DownIcon width={"2.8rem"} height={"1rem"} color={businessMenu?"#FBCF0D":"#D9D9D9"} rotate={businessMenu}/>
                  </IconCont>
                </nav>
                <MenuMobileSubmenu className={`${businessMenu?" is-open": ""}`}>
                  <MenuMobileSubmenuCont>
                    <a href='/business' style={{cursor: "pointer"}}>
                      <span>{texts.header_green.toUpperCase()}</span>
                    </a>
                    <a href='/business' style={{cursor: "pointer"}}>
                      <span>{texts.header_inclusive.toUpperCase()}</span>
                    </a>
                    <a href='/business' style={{cursor: "pointer"}}>
                      <span>{texts.header_cicular.toUpperCase()}</span>
                    </a>
                  </MenuMobileSubmenuCont>
                </MenuMobileSubmenu>
              </div>
              <div className="header_subMenu-mobile">
                <nav style={{cursor: "pointer", display: "flex"}} onClick={()=>setMarketplaceMenu(!marketplaceMenu)}>
                  <span style={{color: marketplaceMenu?"#FBCF0D":"#D9D9D9"}}>{texts.header_marketplace.toUpperCase()}</span>
                  <IconCont>
                    <DownIcon width={"2.8rem"} height={"1rem"} color={marketplaceMenu?"#FBCF0D":"#D9D9D9"} rotate={marketplaceMenu}/>
                  </IconCont>
                </nav>
                <MenuMobileSubmenu className={`${marketplaceMenu?" is-open": ""}`}>
                  <MenuMobileSubmenuCont>
                    <a href={marketPlaceLink} style={{cursor: "pointer"}}>
                      <span>{texts.header_marketplace.toUpperCase()}</span>
                    </a>
                    <a href={marketPlaceLink + "/plus"} style={{cursor: "pointer"}}>
                      <span>MINKADEV PLUS</span>
                    </a>
                  </MenuMobileSubmenuCont>
                </MenuMobileSubmenu>
              </div>
              <a href='/about_us' style={{cursor: "pointer"}}>
                <span>{texts.header_about_us.toUpperCase()}</span>
              </a>
              <a href='/blogentries' style={{cursor: "pointer"}}>
                <span>{texts.header_news.toUpperCase()}</span>
              </a>
            </MenuMobileInfoCont>
          </MenuMobileInfo>
        </MenuMobile>
      </HeaderMenuMobileCont>
    </>
  );
};

const HeaderMenuMobileCont = styled.nav`
  display: none;
  height: 100%;
  align-items: center;

  @media (max-width: 720px) {
    display: flex;
  }
`

const HeaderMenuMobileIcon = styled.div`
  width: 5rem;
  height: 5rem;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;

  &.menu_open .menu-icon-line:nth-child(2) {
    opacity: 0;
  }
  &.menu_open .menu-icon-line:nth-child(1) {
      transform: translateY(1.8rem) rotate(45deg);
  }
  &.menu_open .menu-icon-line:nth-child(3) {
      transform: translateY(-.9rem) rotate(-45deg);
  }
`

const IconLine = styled.span`
  height: 2px;
  background-color: #867F7F;
  border-radius: 1px;
  opacity: 1;
  transform: translateY(0) rotate(0);
  transition: all 0.3s;
`

const MenuMobile = styled.div`
  display: grid;
  grid-template-rows: 0fr;
  transition: grid-template-rows 0.5s ease-out;
  font-family: var(--BarlowCondensed);
  font-size: 4rem;
  font-weight: 400;
  color: var(--grey1);
  position: absolute;
  top: 100%;
  right: 0;
  z-index: 5;
  max-width: 55rem;
  width: 100%;
  background-color: #000000cc;

  &.is-open {
    grid-template-rows: 1fr;
  }
`

const MenuMobileInfo = styled.div`
  display: flex;
  flex-direction: column;
  overflow: hidden;
`

const MenuMobileInfoCont = styled.div`
  display: flex;
  flex-direction: column;
  padding: 4rem;
`

const MenuMobileUser = styled.div`
  display: flex;
  padding: 3rem 0;
  align-items: center;

  img{
    width: 3.1rem;
    height: 3.1rem;
    margin: 0 1rem 0 0;
  }
`

const MenuMobileUserCont = styled.a`
  display: flex;
  align-items: center;
`

const MenuMobileUserIcon = styled.img`
  width: 2.1rem;
  height: 2.1rem;
  margin: 0 .5rem 0 0;
`

const IconCont = styled.div`
  margin: 0 0 0 .5rem;
`

const MenuMobileSubmenu = styled.div`
  display: grid;
  grid-template-rows: 0fr;
  transition: grid-template-rows 0.5s ease-out;
  position: relative;
  overflow: hidden;

  &.is-open{
    grid-template-rows: 1fr;
  }
`

const MenuMobileSubmenuCont = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;
  left: 5%;
  padding: 0 5% 0 0;
  overflow: hidden;
`

export default HeaderMenuMobile;
