import React from 'react'
import styled from 'styled-components'
import noImageIcon from '../../../images/icons/noImage.png'

const PlaceholderImage = ({image, listener, style, width, height}) => (
  <PlaceholderImg alt="icon" src={image || noImageIcon} onClick={(e)=>listener ? listener(e) : undefined} style={style} $width={width} $height={height}/>
)

const PlaceholderImg = styled.img`
  max-width: ${({$width}) => $width || "100%"};
  max-height: ${({$height}) => $height || "100%"};
`

export default PlaceholderImage