import React from 'react'
import styled from 'styled-components'
import NewsCarouselBanner from '../_components/NewsCarouselBanner';
import { ViewContain } from '../_components/ViewContain';

import  linkedinIcon from "../../images/svg/linkedin_icon.svg"
import  xIcon from "../../images/icons/x_icon.webp"
import  facebookIcon from "../../images/svg/fecebook_icon.svg"

const NewsShow = ({ blog, texts }) => {

	const bannerData = [{title: blog.title, banner_image: blog.image, title_color: blog.title_color}]

	var dateObject = new Date(blog.publication_date);
	var day = dateObject.getUTCDate();
	var month = dateObject.getUTCMonth() + 1;
	var year = dateObject.getUTCFullYear();
	var formattedDate = day + '-' + (month < 10 ? '0' + month : month) + '-' + year;

	return (
		<>
			<NewsCarouselBanner {...{ bannerData }} showNew/>
			<ViewContain $paddingX={"5%"} $paddingY={"5%"} $alignItems={"center"}>
				<PublicationDate>
					{formattedDate}
				</PublicationDate>
				<ViewContain $paddingY={"5%"} $gap={"4rem"}>
					<NewContainer>
						<div dangerouslySetInnerHTML={{__html: blog.text}}/>
					</NewContainer>
					{blog.author && <AutorCont>{texts.news_author}: {blog.author}</AutorCont>}
				</ViewContain>
				<ViewContain $alignItems={"center"}>
					<h3>{texts.news_social_buttons}</h3>
					<SocialIcons>
						<a href={`https://www.linkedin.com/shareArticle?mini=true&url=https://www.minka-dev.com/blogentries/${blog.info_link}&title=${blog.title}&summary=${blog.description.substring(0, 150) + "..."}&source=minkadev`} target="_blank" onclick="window.open(this.href, 'linkedin-share', 'width=550, height=235'); return false;"><img src={linkedinIcon} alt="linkedin" /></a>
						<a href={`https://twitter.com/intent/tweet?text=${blog.title}&url=https://www.minka-dev.com/blogentries/${blog.info_link}`} target="_blank" onclick="window.open(this.href, 'twitter-share', 'width=550, height=235'); return false;"><img src={xIcon} alt="x" /></a>
						<a href={`https://www.facebook.com/sharer/sharer.php?u=https://www.minka-dev.com/blogentries/${blog.info_link}`} target="_blank" onclick="window.open(this.href, 'facebook-share','width=580,height=296'); return false;"><img src={facebookIcon} alt="facebook" /></a>
					</SocialIcons>
				</ViewContain>
				
			</ViewContain>
		</>
	)
}

const SocialIcons = styled.div`
  display: flex;
  gap: 1rem;

	img{
  	height: 6rem;
	}
`
const NewContainer = styled.div`
	color: var(--purple2);
	text-align: justify;
	font-size: 3rem;
	font-weight: 400;

	strong{
		font-family: var(--BarlowCondensed);
		font-weight: 600;
		font-size: 5rem;
		text-align: left;
	}
	img{ 
		width: 100%
	}
`
const AutorCont = styled.div`
	color: var(--purple2);
	text-align: justify;
	font-size: 3rem;
	font-weight: 400;
	`
const PublicationDate = styled.div`
	font-family: var(--BarlowCondensed);
	font-weight: 400;
	font-size: 5rem;
`

export default NewsShow