import React from 'react'
import { styled } from 'styled-components'
import PlaceholderImage from '../../_components/PlaceholderImage'
import RoundFlag from '../../../_components/RoundFlag'

const InfoOrganization = ({ org }) => {
  return (
    <InfoOrgCont>
      <LogoCont>
        <PlaceholderImage image={org.image_url?.includes("missing") ? null : org.image_url} />
      </LogoCont>
      <InfoCont>
        <InfoOrgName>
          <RoundFlag id={org.country} width={"6.4rem"} height={"6.4rem"}/>
          <h3>{org.name}</h3>
        </InfoOrgName>
        <InfoData>{org.city}</InfoData>
        <InfoData>{org.address}</InfoData>
        <InfoData>{org.phone}</InfoData>
        {org.website && org.website.length > 4 && <InfoData><a href={org.website} rel="noopener noreferrer" target="_blank">{org.website}</a></InfoData>}  
      </InfoCont>
    </InfoOrgCont>
  )
}

const InfoOrgCont = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  gap: 5rem;
  margin: 0 0 5rem;
  
  @media only screen and (orientation: portrait) {
    flex-direction: column;
    align-items: flex-start;
    margin: 0;
    gap: 3rem;
  }
`
const LogoCont = styled.div`
  width: 20rem;
  aspect-ratio: 1 / 1;
  border-radius: 50%;
  overflow: hidden;
  background-color: white;
  padding: .5rem;
  display: flex;
  align-items: center;
  justify-content: center;
`
const InfoCont = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  `
const InfoOrgName = styled.div`
  display: flex;
  gap: 2rem;
  align-items: center;
  margin: 0 0 2rem;

  h3{
    font-size: 4rem;
    font-weight: 500;
    margin: 1rem 0;
    color: var(--grey5);
  }
`
const InfoData = styled.div`
  font-size: 2.5rem;
  font-weight: 400;
  color: var(--grey5);
`

export default InfoOrganization