import styled from "styled-components";

export const ViewContain = styled.div`
  width: 100%;
  padding: ${({$paddingY}) => $paddingY || 0} ${({$paddingX}) => $paddingX || 0};
  display: flex;
  flex-direction: ${({$flexDirection}) => $flexDirection || "column"};
  align-items: ${({$alignItems}) => $alignItems || ""};
  justify-content: ${({$justifyContent}) => $justifyContent || ""};
  background-color: ${({$bgColor}) => $bgColor || ""};
  gap: ${({$gap}) => $gap || ""};
  counter-reset: section;
  flex-wrap: ${({$flexWrap}) => $flexWrap ? "wrap" : ""};
`

export const FiltersContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(4,1fr);
  width: 100%;
  column-gap: 2.5rem;
  margin: 2% auto 0;
  padding: 0 5%;

  @media (max-width: 768px) {
    display: grid;
    grid-template-columns: auto;
    column-gap: unset;
    row-gap: 1rem;
  }
`

export const InfoContain = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 5%;
  margin: ${({$margin})=>$margin || "5%"};

  h2{
    font-family: var(--BarlowCondensed);
    font-size: 5rem;
    font-weight: 600;
    color: var(--grey2);
    text-align: center;
  }
  span{
    font-family: var(--Roboto);
    font-size: 3rem;
    font-weight: 400;
    color: var(--grey2);
    margin: 0;
  }
`

export const CardContain = styled.div`
  display: flex;
  justify-content: space-around;
  gap: 3rem;

  @media (max-width: 1000px) {
    flex-direction: column;
    align-items: center;
  }
`