import React, { useState } from 'react'
import styled from "styled-components";
import RandomDigitIndicator from '../../_components/RandomNumberIndicator';
import FadeInSection from '../../_components/FadeInSection';

export default ({ numOpportunity, numProjects, opportunityText, opportunityTextSingular, projectsText, projectsTextSingular}) => {
  const [firstAnimationStarted, setFirstAnimationStarted] = useState(false)
  const [secondAnimationStarted, setSecondAnimationStarted] = useState(false);
	
	return( 
    <MapIndicatorsCont>
      <FadeInSection inViewAction={()=>setFirstAnimationStarted(true)}>
        <MapIndicatorsItem>
          <RandomDigitIndicator 
            targetNumber={numOpportunity} 
            startAnimation={firstAnimationStarted}/> <span>{numOpportunity === 1 ? opportunityTextSingular : opportunityText}</span>
        </MapIndicatorsItem>
      </FadeInSection>
      {numProjects > 0 &&
      <FadeInSection inViewAction={()=>setSecondAnimationStarted(true)}>
        <MapIndicatorsItem>
          +<RandomDigitIndicator 
            targetNumber={numProjects} 
            startAnimation={secondAnimationStarted}/> <span>{numProjects === 1 ? projectsTextSingular : projectsText}</span>
        </MapIndicatorsItem>
      </FadeInSection>}
    </MapIndicatorsCont>
	)
}

const MapIndicatorsCont = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  color: #7E143B;
  font-family: var(--BarlowCondensed);
  gap: 5rem;
  
  @media only screen and (orientation: portrait) {
    flex-direction: column;
    gap: 2rem;
  }
`

const MapIndicatorsItem = styled.div`
  font-size: 15rem;
  font-weight: 600;
  display: flex;
  align-items: center;

  span{
    font-size: 6rem;
    margin: 0 0 0 2rem;
    text-transform: uppercase;
  }
  @media (max-width: 768px) {
    font-size: 12rem;
  }
`