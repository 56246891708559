import React, { useState, useEffect } from 'react';

function RandomDigitIndicator({ targetNumber, startAnimation = true, onAnimationEnd }) {
  const targetDigits = targetNumber.toString().split(''); // Divide el número objetivo en dígitos
  const [displayedDigits, setDisplayedDigits] = useState(
    Array(targetDigits.length).fill(0)
  ); // Estado para los dígitos mostrados
  const [isAnimating, setIsAnimating] = useState(true); // Control de animación
  const animationDuration = 1000; // Duración de la animación en ms
  const digitChangeInterval = 80; // Intervalo para cambiar cada dígito en ms

  useEffect(() => {
    if (isAnimating && startAnimation) {
      const intervalIds = []; // IDs de intervalos de cada dígito
      const animationTimeout = setTimeout(() => {
        intervalIds.forEach(clearInterval); // Limpia todos los intervalos
        setDisplayedDigits(targetDigits); // Muestra el número final
        setIsAnimating(false); // Detiene la animación
        if (onAnimationEnd) onAnimationEnd(); // Llama al callback para iniciar el siguiente indicador
      }, animationDuration);

      targetDigits.forEach((_, index) => {
        const intervalId = setInterval(() => {
          setDisplayedDigits((prevDigits) => {
            const newDigits = [...prevDigits];
            newDigits[index] = Math.floor(Math.random() * 10); // Genera un número aleatorio para el dígito
            return newDigits;
          });
        }, digitChangeInterval);

        intervalIds.push(intervalId);
      });

      return () => {
        intervalIds.forEach(clearInterval); // Limpia los intervalos al desmontar
        clearTimeout(animationTimeout); // Limpia el timeout
      };
    }
  }, [isAnimating, targetDigits.join(''), startAnimation]); // Solo se ejecuta si `isAnimating` o `targetDigits` cambian

  // Reinicia la animación cuando cambia el número objetivo
  useEffect(() => {
    setIsAnimating(true);
  }, [targetNumber]);

  return (
    <>
      {displayedDigits.map((digit, index) => (
        <div key={index} style={{ margin: '0 2px' }}>
          {digit}
        </div>
      ))}
    </>
  );
}

export default RandomDigitIndicator;
