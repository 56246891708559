import React from 'react'
import styled, { keyframes } from "styled-components"

const LoadingBars = ({color}) => {
  return (
    <Bars $color={color}><div></div><div></div><div></div></Bars>
  )
}

export default LoadingBars

const loader = keyframes`
  0% {
    top: .8rem;
    height: 6.4rem;
  }
  50%, 100% {
    top: 2.4rem;
    height: 3.2rem;
  }
`

const Bars = styled.div`
  display: inline-block;
  position: relative;
  width: 8rem;
  height: 8rem;

  div{
    display: inline-block;
    position: absolute;
    left: .8rem;
    width: 1.6rem;
    background: ${({$color})=>$color || "#361A3C"};
    animation: ${loader} 1.2s cubic-bezier(0, 0.5, 0.5, 1) infinite;
  }

  div:nth-child(1) {
    left: .8rem;
    animation-delay: -0.24s;
  }
  div:nth-child(2) {
    left: 3.2rem;
    animation-delay: -0.12s;
  }
  div:nth-child(3) {
    left: 5.6rem;
    animation-delay: 0;
  }
`