import React from 'react'
import styled from 'styled-components'
import mapImage from "../../../images/images/map.png"
import pinIcon from "../../../images/icons/locationIcon.png"
import { LazyLoadImage } from 'react-lazy-load-image-component';
import 'react-lazy-load-image-component/src/effects/blur.css';

const ImpactMap = ({pins, selectedCountry}) =>{
	const selectedPin = pins.find((pin)=> pin[0] === selectedCountry) || ["NA", 42, 29, "N/A"]
	return ( 
	<MapContent>
    <LazyLoadImage src={mapImage} alt="map" width={"100%"} height={"100%"} effect='blur'/>
    <PinIcon alt="pin" 
      src={pinIcon}
      style={{
        left: selectedPin[1]+"%",
        top: selectedPin[2]+"%",
      }}
    />
	</MapContent>
	)
}

const MapContent = styled.div`
  position: relative;
  aspect-ratio: 1185 / 593;
  width: 100%;
`

const PinIcon = styled.img`
  position: absolute;
  transform: translateY(-100%);
  width: 4%;
  height: auto;
  opacity: 1;
  z-index: 1;
`

export default ImpactMap