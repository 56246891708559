import React from "react";
import ProposalViewController from "./_controllers/ProposalViewController";
import useBackButtonConfirmation from "../_hooks/useBackButtonConfirmation";

const EditProposalView = ({ proposal, lang, currentUser, opportunityUser }) =>{
  const { removeBackEvent } = useBackButtonConfirmation()
  return (
    <ProposalViewController {...{ proposal, lang, currentUser, opportunityUser, removeBackEvent }} />
  )
};

export default EditProposalView;