import React, { useState } from 'react'
import styled from "styled-components";

const BusinessInfoCard = ({ text, business }) => {
  const [openCard, setOpenCard] = useState(false)

  return (
    <BusinessCard>
      <BusinessCardName $image={business.image} onClick={()=>setOpenCard(open=>!open)}>
        <BusinessCardTitleCont>
          <BusinessCardTitle dangerouslySetInnerHTML={{__html: business.title}}/>
        </BusinessCardTitleCont>
      </BusinessCardName>
      <BusinessCardInfo $openCard={openCard}>
        <BusinessCardInfoCont>
          <BusinessCardDataInfo>
            <BusinessCardInfoDescription>{business?.description}</BusinessCardInfoDescription>
            <BusinessCardDataTitle>{text.business_info_card_key_data}</BusinessCardDataTitle>
            <BusinessCardDataItemsCont>
              {business?.data?.map((data, i)=>
                <li key={"action"+i}>{data}</li>
              )}
            </BusinessCardDataItemsCont>
          </BusinessCardDataInfo>
        </BusinessCardInfoCont>
      </BusinessCardInfo>
    </BusinessCard>
  )
}

export default BusinessInfoCard

const BusinessCard = styled.div`
  width: 100%;
  height: auto;
  display: flex;
  flex-direction: column;
  margin: 1rem 0;
`

const BusinessCardName = styled.div`
  width: 100%;
  height: 28.4rem;
  background-image: url(${({$image}) => $image ? $image : "/images2/cards/green-business.webp"});
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  
  display: flex;
  gap: 5%;
  user-select: none;
  cursor: pointer;

  @media (max-width: 440px) {
    height: 48rem;
    flex-direction: column;
    align-items: center;
  }
`

const BusinessCardTitleCont = styled.div`
  flex: 1;
  width: 100%;
  display: flex;
  flex-direction: column;
  overflow: auto;
  position: relative;
  color: white;
  backdrop-filter: brightness(0.6);
  -webkit-backdrop-filter: brightness(0.6);
  padding: 2rem 5rem;
  
  @media (max-width: 900px) {
    padding: 2rem 2rem
  }
  @media (max-width: 440px) {
    padding: 5rem;
  }
`

const BusinessCardTitle = styled.span`
  position: absolute;
  transform: translate(-50%, -50%);
  left: 50%;
  top: 50%;
  font-family: var(--BarlowCondensed);
  font-size: 5rem;
  font-weight: 600;
  text-transform: uppercase;
  white-space: nowrap;
  span{
    font-weight: 300;
  }
  @media (max-width: 440px) {
    text-align: center;
  }
`

// info card
const BusinessCardInfo = styled.div`
  display: grid;
  grid-template-rows: ${({$openCard}) => $openCard ? "1fr" : "0fr"};
  transition: grid-template-rows 0.5s ease-out;
  background-color: var(--grey1);
`

const BusinessCardInfoCont = styled.div`
  display: flex;
  flex-direction: column;
  overflow: hidden;
`

const BusinessCardDataInfo = styled.div`
  width: 100%;
  height: auto;
  font-size: 3rem;
  padding: 5% 10%;
  user-select: none;
  color: #361A3C;
  display: flex;
  flex-direction: column;
  justify-content: center;

  @media (max-width: 500px) {
    max-height: max-content;
    height: auto;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 5rem;
  } 
`

const BusinessCardInfoDescription = styled.div`
  font-weight: 500;
`

const BusinessCardDataTitle = styled.h5`
  font-weight: 500;
  margin: 0;
  font-size: 3rem;
  margin: 3rem 0;
  text-transform: uppercase;
`

const BusinessCardDataItemsCont = styled.ul`
  width: 100%;
  display: flex;
  flex-direction: column;
  font-weight: 300;
  margin: 0;
`