import styled from 'styled-components';
import React, { useState } from "react";
import LoadingBars from "../../LoadingBars";
import api from "../../../../lib/api";
import { ContactButton } from "../../ContactButton";

// Change for setups
const configuration = {
  name_empty: "name_empty",
  post_empty: "post_empty",
  organization_empty: "organization_empty",
  invalid_email: "invalid_email",
  message_empty: "message_empty",
  check_terms: "check_terms",
  error_contact: "error_contact",

  message_color: "green",
  error_color: "red",
  bgColor: "green",
  labelColor: "white",

  fields: {
    name: "",
    post: "",
    email: "",
    organization: "",
    message: "",
  },
};

export default ({ texts }) => {
  const [data, setData] = useState(configuration.fields);
  const [loading, setLoading] = useState(false);

  const callError = (msg, info) => {
    alert(msg)
    setLoading(false);
  };

  const handleInputs = (e) => {
    let value = e.target.value;
    if (value === undefined || value === null) {
      value = e.target.getAttribute("value");
    }
    const keys = e.target.name.split(".");
    setData((prev) => {
      return { ...prev, [keys[0]]: value };
    });
  };

  const processResponse = (res) => {
    if (!res.success || res.success === false) {
      setLoading(false);
      callError(texts[configuration.error_contact]);
    } else {
      window.location.reload();
    }
  };

  const contact = () => {
    const information = {
      data: data,
    };
    api.contact(information, processResponse);
  };

  const handleSubmit = (e) => {
    setLoading(true);
    e.preventDefault();
    let element = document.getElementById("contact");
    element.scrollIntoView({ block: "start", behavior: "smooth" });
    /* API CALLS */
    contact();
  };

  return (
    <SectionContactCont id="contact">
      <ContactTitle>
        <h3>{texts.footer_contact_us}</h3>
        <span>{texts.footer_contact_connect}</span>
      </ContactTitle>
      <ContactForm onSubmit={handleSubmit}>
        <ContactInput placeholder={texts.footer_contact_name} type="text" name="name" value={data.name} autoComplete="name" onChange={handleInputs} required/>
        <ContactInput placeholder={texts.footer_contact_position} type="text" name="post" value={data.post} autoComplete="organization-title" onChange={handleInputs} required/>
        <ContactInput placeholder={texts.footer_contact_organization} type="text" name="organization" value={data.organization} autoComplete="organization" onChange={handleInputs} required/>
        <ContactInput placeholder={texts.footer_contact_email} type="email" name="email" autoComplete="email" value={data.email} onChange={handleInputs} required/>
        <ContactMessage placeholder={texts.footer_contact_message} name="message" value={data.message} onChange={handleInputs} required/>
        <ContactTerms>
          <TermsInfo>
            <input type="checkbox" required/>
            <span>
              {texts.footer_contact_terms1} <a href="/privacy" target="_blank" rel="noopener noreferrer">{texts.footer_contact_terms2}</a>
            </span>
          </TermsInfo>
          <ButtonCont>
            {!loading ?
              <ContactButton type="submit">{texts.footer_contact_send}</ContactButton>
            :
              <LoadingBars id="login_load" />}
          </ButtonCont>
        </ContactTerms>
      </ContactForm>
    </SectionContactCont>
  );
};

const SectionContactCont = styled.div`
  padding: 0 5%;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
`

const ContactTitle = styled.div`
  margin: 0 0 3rem;

  h3{
    font-family: var(--BarlowCondensed);
    font-size: 4rem;
    font-weight: 500;
  }
`

const ContactForm = styled.form`
  display: flex;
  flex-direction: column;
`

const ContactInput = styled.input`
  margin: 0 0 5%;
  padding: 0.5rem 1rem;
  font-size: 2.4rem;
  font-weight: 300;
  border: none;
  color: var(--darkPurple);
`

const ContactMessage = styled.textarea`
  padding: 3% 2% 2%;
  resize: none;
  margin: 0 0 5%;
  padding: 0.5rem 1rem;
  font-size: 2.4rem;
  font-weight: 300;
  border: none;
  color: var(--darkPurple);
  field-sizing: content;
  min-block-size: 10rem;
  min-inline-size: auto;
`

const ContactTerms = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`

const TermsInfo = styled.label`
  display: flex;
  flex: 1;
  gap: 2rem;

  span{
    font-size: 1.8rem;
    flex: 1;
  }
  a{
    text-decoration: underline;
    font-weight: 600;
    color: var(--darkPurple);
  }

  @media only screen and (orientation: portrait) {
    input{
      width: 18px;
    }
  }
`

const ButtonCont = styled.div`
  height: 8rem;
  display: flex;
  align-items: center;
`