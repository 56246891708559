import React, { useEffect, useState } from "react";
import styled from "styled-components";

import { languageSelector } from "../../../lib/Language";
import HeaderMenu, { HeaderSubMenu, HeaderSubMenuCont } from "./_components/HeaderMenu";
import HeaderMenuMobile from "./_components/HeaderMenuMobile";
import DownIcon from "../icons/DownIcon";

import imgLogo from "../../../images/images/logo.png";
import userIcon from "../../../images/icons/user_icon_desktop.png";

const Header = ({ lang, currentUser, texts }) => {
  const [lng, setLng] = useState("");
  const [isMarketplace, setIsMarketplace] = useState(false)
  const langSelector = Object.entries(languageSelector);

  const stagingEnvironment = process.env.REACT_APP_NODE_ENV === 'staging'

  let homeLink 
  if (!process.env.REACT_APP_NODE_ENV || process.env.REACT_APP_NODE_ENV === 'development') homeLink = "http://localhost:3000/" // development environment
  else if (process.env.REACT_APP_NODE_ENV === 'staging') homeLink = "http://minka-dev.onrender.com/" // staging environment
  else homeLink = "https://www.minka-dev.com/" // production environment

  let marketPlaceLink 
    if (!process.env.REACT_APP_NODE_ENV || process.env.REACT_APP_NODE_ENV === 'development') marketPlaceLink = "http://marketplace.minka.com:3000" // development environment
    else if (process.env.REACT_APP_NODE_ENV === 'staging') marketPlaceLink = "http://minka-dev.onrender.com/marketplace" // staging environment
    else marketPlaceLink = "https://marketplace.minka-dev.com" // production environment

  
  
  useEffect(() => {
    const selectInitials = (lang) => {
      const defaultInitial = "ESP"
      const initials = {
        "en-us": "ENG",
        "fr-fr": "FRA",
      }
      return initials[lang] ?? defaultInitial
    }
    setLng(selectInitials(lang));
  }, [lang]);

  useEffect(() => {
    if(window){
      const pathname = window.location.pathname
      setIsMarketplace(pathname.includes("marketplace") || (window.location.hostname.includes("marketplace") && !pathname.includes("services") && !pathname.includes("business") && !pathname.includes("about_us") && !pathname.includes("blogentries")))
    }
  })

  const showSubMenu = (classN, n) => {
    let element = document.getElementsByClassName(classN)[n];
    element.classList.add("is-open")
  };
  const hideMenu = (classN, n) => {
    let element = document.getElementsByClassName(classN)[n];
    element.classList.remove("is-open")
  };

  return (
    <HeaderCont>
      {stagingEnvironment&&<TestServerMessage>Servidor de prueba</TestServerMessage>}
      <HeaderLogo href={homeLink}>
        <img src={imgLogo} alt="logo"/>
      </HeaderLogo>
      <HeaderMenu texts={texts} isMarketplace={isMarketplace} marketPlaceLink={marketPlaceLink}/>
      <HeaderMenu2>
        {isMarketplace && 
          <HeaderLang >
            <HeaderLangCont>
              <HeaderUser href={currentUser ? `${marketPlaceLink}/profile/` + currentUser.id : `${marketPlaceLink}/login`}>
                <HeaderUserIcon src={userIcon} alt="User"/>
                <span>{currentUser ? `${currentUser.first_name} ${currentUser.last_name}` : texts.header_log_in}</span>
              </HeaderUser>
            </HeaderLangCont>
          </HeaderLang>
        }
        <HeaderLang onClick={() => showSubMenu("header_subMenu_lng", 0)} onMouseLeave={() => hideMenu("header_subMenu_lng", 0)} >
          <HeaderLangCont onMouseOver={() => showSubMenu("header_subMenu_lng", 0)}>
            <span>{lng}</span>
            <IconCont>
              <DownIcon width={"1.5rem"} height={".6rem"} color={"#D9D9D9"}/>
            </IconCont>
          </HeaderLangCont>
          <HeaderSubMenu className="header_subMenu_lng">
            <HeaderSubMenuCont>
              {langSelector.map((lang, i) => (
                <a href={'/lang/'+lang[0]} key={lang[0]} className={i === 0 ? "first_menu" : ""}>
                  <span>
                    {lang[1].toUpperCase()}
                  </span>
                </a>
              ))}
            </HeaderSubMenuCont>
          </HeaderSubMenu>
        </HeaderLang>
        <HeaderMenuMobile texts={texts} currentUser={currentUser} isMarketplace={isMarketplace} marketPlaceLink={marketPlaceLink}/>
      </HeaderMenu2>
    </HeaderCont>
  );
};

const HeaderCont = styled.header`
  position: relative;
  display: flex;
  justify-content: space-between;
  top: 0;
  left: 0;
  height: 108px;
  width: 100%;
  z-index: 5;
  background-color: var(--white2);

  @media (max-width: 720px) {
    height: 75px;
  }
`

const TestServerMessage = styled.div`
  position: fixed;
  background: #ff1717;
  padding: 1rem;
`

const HeaderLogo = styled.a`
  margin: 0 4%;
  display: flex;
  align-items: center;

  img{
    aspect-ratio: 103/76;
    height: 70%;
    width: auto;
  }
`

const HeaderMenu2 = styled.div`
  display: flex;
  margin: 0 4%;
  align-items: flex-end;
  font-family: var(--BarlowCondensed);
  gap: 3rem;
`

const HeaderLang = styled.nav`
  display: flex;
  align-items: flex-end;
  position: relative;
  cursor: pointer;
  z-index: 10;
  height: 100%;
  padding: 28px 0;
  font-size: 1.8rem;

  @media (max-width: 720px) {
    align-items: center;
    padding: 0;
    font-size: 3.5rem;
  }
`

const HeaderLangCont = styled.div`
  display: flex;
  cursor: pointer;

  span{
    text-transform: uppercase;
  }
`

const IconCont = styled.div`
  margin: 0 0 0 .5rem;
`

const HeaderUser = styled.a`
  display: flex;
  align-items: center;
`

const HeaderUserIcon = styled.img`
  width: 2.1rem;
  height: 2.1rem;
  margin: 0 .5rem 0 0;
`

export default Header;