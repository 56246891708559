import React from "react";
import { LangContext } from "../../../../lib/Language";
import useAppController from "../../_hooks/useAppController";
import ProposalForm from "../_components/ProposalForm";
import ProposalView from "../_components/ProposalView";

const ProposalViewController = ({ proposal, lang, fromShowComponent, currentUser, opportunityUser, removeBackEvent = ()=>{} }) => {
  let props = useAppController(lang, proposal, "proposals", fromShowComponent ? "view" : "form");
  props.currentUser = currentUser;
  props.opportunityUser = opportunityUser;

  return <LangContext.Provider value={lang}>{props.loading || (props.view === "view" ? <ProposalView {...props} hiddenPanelButtons={fromShowComponent} {...{removeBackEvent}} /> : <ProposalForm {...props} {...{removeBackEvent}}/>)}</LangContext.Provider>;
}

export default ProposalViewController