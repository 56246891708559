import styled from "styled-components";

export const ContactButton = styled.button`
  width: 12.3rem;
  height: 2.6rem;
  border-radius: 6px;
  border: solid 1px #FFF;
  background-color: var(--green2);
  font-family: var(--Roboto);
  font-size: 2rem;
  font-weight: 400;
  text-transform: uppercase;
  color: white;
  cursor: pointer;
  transition: all .5s;
  filter: grayscale(${({$disable}) => $disable ? "1" : "0"});
  text-transform: ${({$textTransform}) => $textTransform || ""};

  &:hover{
    color: white;
    background-color: #696863;
  }
  
  &:active{
    transform: scale(1.1);
  }
  @media (max-width: 800px) {
    height: auto;
    padding: 1rem 0;
  }
`