import { useEffect, useState } from "react";

const useBackButtonConfirmation = () => {
  const [confirmation, setConfirmation] = useState(true)
  const handleBeforeUnload = (e) => {
    e.preventDefault();
    e.returnValue = "";
  };

  const removeBackEvent = () => {
    setConfirmation(false)
  }

  useEffect(() => {
    if (confirmation) window.addEventListener("beforeunload", handleBeforeUnload);
    else window.removeEventListener("beforeunload", handleBeforeUnload);
    return () => {
      window.removeEventListener("beforeunload", handleBeforeUnload);
    }
  }, [confirmation]);

  return { removeBackEvent }
};

export default useBackButtonConfirmation;
