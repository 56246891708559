import React, { useState, useEffect, useRef } from "react";
import api from "../../lib/api";
import MainCarouselBanner from "../_components/MainCarouselBanner";
import { FiltersContainer, ViewContain } from "../_components/ViewContain";
import { InfoText } from "../_components/InfoText";
import { InfoTitle } from "../_components/InfoTitle";
import BenefitsAndAdvantagesCard from "./_components/BenefitsAndAdvantagesCard";
import { YellowButton } from "../_components/YellowButton";
import MarketplaceIndicator from "./_components/MarketplaceIndicator";
import InputField from "./_components/InputField";
import { GreenButton } from "../_components/GreenButton";
import OpportunityCard from "./_components/OpportunityCard";
import CardsContain from "../_components/CardsContain";
import ContentLoaderOpportunityCard from "./_components/ContentLoaderOpportunityCard";
import MarketplaceStep from "./_components/MarketplaceStep";
import { InfoSubtitle } from "./_components/InfoSubtitle";
import { AddFixedButton } from "./_components/AddFixedButton";

import supplyChainIcon from "../../images/icons/supply_chain.webp"
import productionModelsIcon from "../../images/icons/production_models.webp"
import diversificationIcon from "../../images/icons/diversification.webp"
import accessToMarketsIcon from "../../images/icons/access_to_markets.webp"
import synergiesIcon from "../../images/icons/synergies.webp"
import directContactIcon from "../../images/icons/direct_contact.webp"
import addCircleIcon from "../../images/svg/add_circle.svg"
import FadeInSection from "../_components/FadeInSection";

const MarketplaceIndex = ({ texts, users = 0, sectorCount = 0, opportunitiesCount = 0, solutions = 0, ods, bannerData }) => {
  const [loading, setLoading] = useState(false);
  const [opportunities, setOpportunities] = useState([]);
  const [params, setParams] = useState({});
  const [showMoreButton, setShowMoreButton] = useState(true)
  //Switched closed opportunities
  const [closed, setClosed] = useState(false);

  const stagingEnvironment = process.env.REACT_APP_NODE_ENV === 'staging'

  const opportunityTypeOptions = [{tag: "NS",name: texts.marketplace_sustainable_opportunities_find_option_1}, {tag: "NC",name: texts.marketplace_sustainable_opportunities_find_option_2}, {tag: "OS",name: texts.marketplace_sustainable_opportunities_find_option_3}, {tag: "OC",name: texts.marketplace_sustainable_opportunities_find_option_4}]
  
  const benefitsAndAdvantages = [{image: supplyChainIcon, title: texts.marketplace_benefits_advantages_1_title, text: texts.marketplace_benefits_advantages_1_description}, { image: productionModelsIcon, title: texts.marketplace_benefits_advantages_2_title, text: texts.marketplace_benefits_advantages_2_description}, { image: diversificationIcon, title: texts.marketplace_benefits_advantages_3_title, text: texts.marketplace_benefits_advantages_3_description}, { image: accessToMarketsIcon, title: texts.marketplace_benefits_advantages_4_title, text: texts.marketplace_benefits_advantages_4_description}, { image: synergiesIcon, title: texts.marketplace_benefits_advantages_5_title, text: texts.marketplace_benefits_advantages_5_description}, { image: directContactIcon, title: texts.marketplace_benefits_advantages_6_title, text: texts.marketplace_benefits_advantages_6_description}]

  const marketplaceIndicators = [{ text: texts.marketplace_indicators_opportunities, cant: opportunitiesCount}, { text: texts.marketplace_indicators_sectors, cant: sectorCount}, { text: texts.marketplace_indicators_connections, cant: solutions}, { text: texts.marketplace_indicators_business, cant: texts.marketplace_indicators_estructured_business}, { text: texts.marketplace_indicators_users, cant: users}]
  
  const stepsToCreateAOpportunity = [{ title: texts.marketplace_creation_item_1_title, description: texts.marketplace_creation_item_1_desc}, { title: texts.marketplace_creation_item_2_title, description: texts.marketplace_creation_item_2_desc}, { title: texts.marketplace_creation_item_3_title, description: texts.marketplace_creation_item_3_desc}, { title: texts.marketplace_creation_item_4_title, description: texts.marketplace_creation_item_4_desc}]

  const page = useRef(1);

  const getApiOpportunities = (fromButton) => {// call api "/api/opportunities/:page" for get other opportunities
		setLoading(true);
    if (fromButton) page.current = page.current + 1
    else {
      page.current = 1
      setOpportunities([])
    }
    api.getOpportunities(params, (r) => {
      setLoading(false);
      setOpportunities(opp =>{ return fromButton ? [...opp, ...r] : [...r]})
      if(r.length < 6) setShowMoreButton(false)
      else setShowMoreButton(true)
    },
    page.current);
	}

  //Trigger on parameter change for filters
  useEffect(() => {
    getApiOpportunities(false)
  }, [params]);

  const addParam = (field, value) => {
    if (field === "type"){
      const setType = (op) => {
        let defaultOption = [null, null]
        let options = {
          "NS": [false, "cadena"],
          "NC": [false, "consumo"],
          "OS": [true, "cadena"],
          "OC": [true, "consumo"],
        }
        return options[op] ?? defaultOption
      }
      return setParams((e) => ({ ...e, ["offers"]: setType(value)[0], ["otype"]: setType(value)[1], ["type"]: value }));
    }
    setParams((e) =>({ ...e, [field]: value }));
  };

  return (
    <>
      <MainCarouselBanner {...{ bannerData }} marketplaceBanner smallText/>
      <ViewContain $paddingX={"10%"} $alignItems={"center"}>
        <InfoText $textAlign={"justify"}>
        {texts.marketplace_info_intro}
        </InfoText>
        <FadeInSection>
          <InfoTitle $textTransform={"uppercase"}>
            {texts.marketplace_benefit_title}
          </InfoTitle>
        </FadeInSection>
        {benefitsAndAdvantages.map((item, i)=>
          <FadeInSection key={"benefitInfo" + i}>
            <BenefitsAndAdvantagesCard item={item} index={i}/>
          </FadeInSection>
        )}
        <FadeInSection>
          <InfoText $textAlign={"center"} $fontWeight={"700"} >
            {texts.marketplace_info_explore}
          </InfoText>
        </FadeInSection>
        <a href={`${stagingEnvironment?"/marketplace":""}/opportunities/new`}>
          <YellowButton $textTransform={"uppercase"}>{texts.marketplace_create_opportunity_button}</YellowButton>
        </a>
      </ViewContain>
      <ViewContain $paddingY={"5rem"}>
        {marketplaceIndicators.map((indicator, i)=>
          <MarketplaceIndicator key={"indicator" + i} index={i} cant={indicator.cant} text={indicator.text}/>
        )}
      </ViewContain>
      {/* <ViewContain $alignItems={"center"} $paddingY={"5%"}>
        <InfoTitle $textTransform={"uppercase"}>
          {texts.marketplace_featured_opportunities}
        </InfoTitle>
        <FeaturedBanner src="/images2/banners/outstanding_opportunity.png"/>
      </ViewContain> */}
      <FadeInSection>
        <ViewContain $bgColor={"#EFF3F6"} $paddingY={"2%"} id="oppFilter">
          <ViewContain $alignItems={"center"}>
            <InfoTitle $textTransform={"uppercase"}>
              {texts.marketplace_sustainable_opportunities}
            </InfoTitle>
          </ViewContain>
          <ViewContain $paddingX={"5%"}>
            <InputField t="select" data={params} field="type" initValue={texts.marketplace_sustainable_opportunities_find} options={opportunityTypeOptions} include_blank={false} saveData={(e) => addParam("type", e.target.value)} required={false} />
          </ViewContain>
          <FiltersContainer>
            <InputField t="sectors" data={params} field="sector" initValue={texts.marketplace_sustainable_opportunities_sector} include_blank={false} saveData={(e) => addParam("sector", e.target.value)} required={false} getOnly/>
            <InputField t="countries" data={params} field="country" initValue={texts.marketplace_sustainable_opportunities_country} include_blank={false} saveData={(e) => addParam("country", e.target.value)} required={false} />
            <InputField t="select" data={params} field="ods" options={ods} initValue={texts.marketplace_sustainable_opportunities_ods} include_blank={false} saveData={(e) => addParam("ods", e.target.value)} required={false} />
            <InputField t="debounced" data={params} field="q" saveData={(e) => addParam("q", e.target.value)} placeholder={texts.marketplace_sustainable_opportunities_keywords} value={params.q || ""} />
          </FiltersContainer>
        </ViewContain>
      </FadeInSection>
      <ViewContain $alignItems={"center"} style={{paddingTop: "5%"}}>
        <GreenButton style={{fontSize: "1.8rem"}} $width={"37.8rem"} $height={"3.69rem"} $textTransform={"uppercase"} onClick={() => setClosed((e) => { addParam("closed", !e); setClosed(!e); })} disabled={opportunities.length === 0}>{closed ? texts.marketplace_open_opportunities_button : texts.marketplace_closed_opportunities_button }</GreenButton>
      </ViewContain>
      {opportunities.length > 0 && <CardsContain>
        {opportunities.map((e) => (
          <FadeInSection key={e.id + "op"} cardContent>
            <OpportunityCard opportunity={e} suitableMessage={texts.marketplace_plus_suitable_message}/>
          </FadeInSection>
        ))}
      </CardsContain>}
      {showMoreButton && !loading &&
        <ViewContain $paddingY={"3%"} $alignItems={"center"}>
          <GreenButton style={{fontSize: "1.8rem"}} $width={"37.8rem"} $height={"3.69rem"} $textTransform={"uppercase"} onClick={()=>getApiOpportunities(true)}>{texts.marketplace_more_button}</GreenButton>
        </ViewContain>
      }
      {loading &&
        <CardsContain>
        {Array(6).fill().map((_, i) => (
          <ContentLoaderOpportunityCard key={"contentLoader"+i}/>
        ))}
      </CardsContain>}
      <ViewContain $paddingY={"5%"} $paddingX={"5%"} $alignItems={"center"}>
        <FadeInSection>
          <InfoTitle $textTransform={"uppercase"} >{texts.marketplace_creation_title}</InfoTitle>
        </FadeInSection>
        {stepsToCreateAOpportunity.map((step, i)=>
          <FadeInSection key={"step"+i}>
            <MarketplaceStep title={step.title} description={step.description}/>
          </FadeInSection>
        )}
        <FadeInSection>
          <InfoSubtitle>{texts.marketplace_creation_subtitle}</InfoSubtitle>
          <a href={`${stagingEnvironment?"/marketplace":""}/opportunities/new`} style={{paddingBottom: "5%"}}>
            <YellowButton $textTransform={"uppercase"}>{texts.marketplace_create_opportunity_button}</YellowButton>
          </a>
        </FadeInSection>
      </ViewContain>
      <AddFixedButton href={`${stagingEnvironment?"/marketplace":""}/opportunities/new`}>
        <img src={addCircleIcon} alt="Add Opportunity" />
        <span>{texts.marketplace_fixed_add_button}</span>
      </AddFixedButton>
    </>
  );
}

export default MarketplaceIndex