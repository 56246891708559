import React from 'react'
import { LazyLoadImage } from 'react-lazy-load-image-component';
import 'react-lazy-load-image-component/src/effects/blur.css';
import styled from "styled-components";

const ServicesInfoCardHomepage = ({ image, title, text, i}) => {
  return (
    <ServicesInfoCont>
      <ServicesInfoImg>
        <LazyLoadImage src={image} alt={`service image ${i+1}`} effect="blur" width="100%" height="100%" style={{position: 'absolute', top: "0", left: "0", objectFit: "cover"}}/>
      </ServicesInfoImg>
      <InfoCont>
        <ServicesInfoTitle>{title}</ServicesInfoTitle>
        <ServicesInfoDescription>{text}</ServicesInfoDescription>
      </InfoCont>
    </ServicesInfoCont>
  )
}

const ServicesInfoCont = styled.div`
  width: 100%;
  max-height: 25.6rem;
  display: flex;
  padding: 2rem 5rem;
  gap: 3rem;
  user-select: none;
  
  @media (max-width: 900px) {
    padding: 2rem 2rem
  }
  @media only screen and (orientation: portrait) {
    max-height: max-content;
    height: auto;
    flex-direction: column;
    align-items: center;
    padding: 5rem;
    text-align: center;
  }
`

const ServicesInfoImg = styled.div`
    position: relative;
    width: 21.4rem;
    height: 21.4rem;
    border-radius: 50%;
`

const InfoCont = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  overflow: auto;
  position: relative;
  color: var(--darkPurple);
`

const ServicesInfoTitle = styled.span`
  position: relative;
  font-family: var(--BarlowCondensed);
  font-size: 5rem;
  font-weight: 400;
  text-transform: uppercase;
  margin: 0 0 2rem;

  @media (max-width: 440px) {
    text-align: center;
  }
  `

const ServicesInfoDescription = styled.span`
  position: relative;
  font-family: var(--Roboto);
  font-size: 3rem;
  font-weight: 300;
  display: flex;
  align-items: center;

  @media (max-width: 900px) {
    font-size: 2.5rem;
  }
`

export default ServicesInfoCardHomepage