import React from "react";
import { LangContext } from "../../../../lib/Language";
import useAppController from "../../_hooks/useAppController";
import OpportunityView from "../_components/OpportunityView";
import OpportunityForm from "../_components/OpportunityForm";

const OpportunityViewController = ({ opportunity, ods, identify, lang, fromShowComponent, currentUser, currentUserOrg, adminUser, removeBackEvent = ()=>{}, loginTexts = {}}) => {
  let props = useAppController(lang, opportunity, "opportunities", fromShowComponent ? "view" : "form");
  props.currentUser = currentUser;
  props.currentUserOrg = currentUserOrg;
  props.adminUser = adminUser;
  props.loginTexts = loginTexts;

  return <LangContext.Provider value={lang}>{props.loading || (props.view === "view" ? <OpportunityView {...props} hiddenPanelButtons={fromShowComponent} {...{removeBackEvent}}/> : <OpportunityForm {...props} ods={ods} identify={identify} {...{removeBackEvent}} />)}</LangContext.Provider>;
}

export default OpportunityViewController