import React, { useState, useContext, useEffect } from "react";
import styled from "styled-components";
import { LangContext, translations } from "../../../../lib/Language";
import useFormUpdate from "../../_hooks/useFormUpdate";
import useOpController from "../../_hooks/useOpController";
import FormField, { DeadlineField, Images, Label } from "../../_components/FormField";
import ActionPanel from "../../_components/ActionPanel";
import Modal from "../../_components/Modal";
import ScoreModal from "../../_components/ScoreModal";
import Layout from "../../_components/Layout";
import api from "../../../../lib/api";
import InfoOrganizationContact from "../../_components/InfoOrganizationContact";
import { ViewContain } from "../../../_components/ViewContain";
import ViewField from "../../_components/ViewField";
import FormProgress from "../../_components/FormProgress";
import LoadingBars from "../../../_components/LoadingBars";
import CirclePlus from "../../../_components/icons/CirclePlusIcon";

const debug = false;
const stagingEnvironment = process.env.REACT_APP_NODE_ENV === 'staging'

const OpportunityForm = ({ object, selectors, errors, ods, dispatch, removeBackEvent, selectedForm }) => {
  const l = useContext(LangContext);
  const f = translations[l].forms.opportunities;
  const c = translations[l].forms.controls;
  const [data, saveData, setData] = useFormUpdate("opportunity", object);
  const [score, setScore] = useState(false);

  const common = { data, f, c, selectors, l, saveData, setData, errors };
  const accordion = useOpController(common);

  const guidedForm = data?.images.length === 0

  const saveForm = () => {
    api.save(data, "opportunities", (res) => {
      if(guidedForm) dispatch({ type: "save", object: res.object, view: "form", errors: res.object.errors, });
      else dispatch({type:"change", selectedForm: -1})
      alert(res.message);
    });
  }
  
  const changeForm = (i) => { dispatch({type:"change", selectedForm: i})}

  const props = {
    data: [
      {title: f.oppTypeFormTitle, component: <Definition {...common} ods={ods}/>},
      {title: f.oppBasicFormTitle, component:  <Contribution {...common}/>},
      ...accordion,
			{title: f.oppAttachmentsFormTitle, component: <Attachments {...common} />},
    ],
    c,
    saveForm,
    changeForm,
    selectedForm,
  };

  console.log('data', data)
  
  return (
    <>
      <ViewContain id="oppForm" $paddingY={"4%"} $paddingX={"10%"}>
        <InfoOrganizationContact org={data?.organization} />
        <WelcomeInfo id="welcomeInfo">
          <h3>{f.welcomeTitle}</h3>
          <p>{f.welcomeInfo}</p>
        </WelcomeInfo>
        <FormProgress {...{data}}/>
        <ViewContain $paddingY={"5%"}>
          <Layout {...props} form/>
        </ViewContain>
        <ActionPanel {...{ data, saveData, setScore, dispatch, setData, removeBackEvent }} mode="edit" model="opportunities" />
      </ViewContain>
      {score && (
        <Modal width={"916px"} height={"650px"} title={"Tu oportunidad ha sido publicada"} close={() => { window.location = `${stagingEnvironment?"/marketplace":""}/opportunities/${data.id}`; }} >
          <ScoreModal score={score} listener={() => { window.location = `${stagingEnvironment?"/marketplace":""}/opportunities/${data.id}`; }} />
        </Modal>
      )}
      {debug && <pre>{JSON.stringify(data, undefined, 4)}</pre>}
    </>
  );
};

const Definition = ({ l, f, data, color, saveData, selectors, errors, ods }) => {
  const [modal, setModal] = useState(false);
  const [selected, setSelected] = useState(data.ods.map((e) => e.id));
  const [loading, setLoading] = useState(false);

  const d = translations[l].definition;
  const c = translations[l].forms.controls;
  const g = translations[l].general;
  const props = { l, d, f, data, color, saveData, selectors, errors };

  const addOds = (elem) => {
    setLoading(true);
    let authenticity_token = document.querySelector('meta[name="csrf-token"]').content

    api.postOpOds(
      { id: data.id, ods_id: elem.id, authenticity_token },
      (response) => {
        setSelected(response.map((e) => e.id));
        setLoading(false);
        saveData({ target: { name: "ods", value: response, getAttribute: () => false } });
      }
    );
  };

  return (
    <>
      <ViewField object={data} t="booleanSub" field="type" subField="offers" label={d.type} />
      <ViewField object={data} t="subContent" field="type" subField="name" label={d.definition} />
      <FormField {...{ data, saveData }} t="sectorform" label={c.selectSectors} fieldRequired/>
      <FormField t="select" {...props} label={d.impact} field="nb_affected" />
      <FormField t="select" {...props} label={d.hectaresPreservation} field="hectares_preservation" />
      <DeadlineField {...{ data, saveData }} field="deadline" deadline />
      <OdsForm>
        <Label small={true} help={true} label={g.sdg}>
          {translations[l].odsExplication.explication}
        </Label>
        {data.ods.length > 0 && <OdsContainer>
          {data.ods.map((e) => ( <img key={"ODS_" + e.id} src={e.image} alt={e.number} />
          ))}
        </OdsContainer>}
        <OdsAddButton onClick={() => setModal(true)}>
          <CirclePlus width={"4rem"} height={"4rem"}/>
          <span>{c.addSgd}</span>
        </OdsAddButton>
        <Modal title={c.selectSgd} initialOpen={modal} close={() => setModal(false)} width={"916px"} height={"587px"} >
          <>
            <OdsContainerModal>
              {ods.map((e) => (
                <OdsImageModal $selected={selected.includes(e.id)} onClick={() => addOds(e)} src={e.image} alt={e.number} key={e.id}/>
              ))}
            </OdsContainerModal>
            {loading && (
              <OdsLoading>
                <LoadingBars color={"#361A3C"}/>
              </OdsLoading>
            )}
          </>
        </Modal>
      </OdsForm>
      <FormField t="tags" {...props} label={d.concept} small={true} field="tags" />
    </>
  );
};

const Contribution = ({ l, f, data, color, saveData, selectors, errors }) => {
  const d = translations[l].definition;
  const i = translations[l].opportunity.impact;

  const props = { l, d, f, data, color, saveData, selectors, errors };
  return (
    <>
      <FormField t="text" {...props} field="title" label={f.addTitle} />
      <FormField {...props} f={d} t="textarea" field="description" help={translations[props.l].forms.opportunities.oppDescription} />
      <FormField {...props} f={d} t="textarea" field="motivation" help={translations[props.l].forms.opportunities.oppMotivation} />
      <FormField {...props} f={d} t="countries" field="country" />
      <FormField t="select" {...props} field="participation" label={i.participation} />
      {props.data.participation === "OTH" && (
        <FormField {...props} t="textarea" field="other_participation" label={i.other_participation} />
      )}
    </>
  );
};

const Attachments = (props) => {
  const d = translations[props.l].definition;
  return(
    <>
      <Images {...props} f={d} field="images" selector="Opportunity" />
      <ViewContain $paddingY={"4%"}>
        <FormField {...props} t="files" field="files" label={props.f.anexos} selector="Opportunity" accept={".pdf"} />
      </ViewContain>
      <Label small={true} label="Videos" labelCenter />
      <FormField {...props} t="videos" field="urlVideos" f={props.c} />
      <ViewContain $paddingY={"4%"}>
        <FormField {...props} t="links" field="attached_links" label={props.f.attachedLinks} />
      </ViewContain>
    </>
  )
};

const WelcomeInfo = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: column;
  gap: 5rem;
  text-align: center;
  padding: 5%;
  h3{
    font-family: var(--BarlowCondensed);
    font-size: 5rem;
    color: var(--purple2);
    font-weight: 600;
    margin: 0;
  }
  p{
    font-family: var(--Roboto);
    font-size: 2.5rem;
    font-weight: 300;
    color: var(--grey2);
  }
`
//ODS
const OdsForm = styled.div`
  display: flex;
  flex-direction: column;
  gap: 2rem;
`
const OdsContainer = styled.div`
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  gap: 1rem;
  img{
    width: 80px;
  }
  @media only screen and (orientation: portrait) {
    img{
      width: 60px;
    }
  }
`
const OdsContainerModal = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  margin: auto;
  gap: 1rem;
`
const OdsImageModal = styled.img`
  width: 115px;
  cursor: pointer;
  filter: ${({$selected}) => $selected ? "brightness(1.3)" : "brightness(0.6)"};
`
const OdsLoading = styled.div`
  top: 0;
  left: 0;
  position: absolute;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`
const OdsAddButton = styled.div`
  padding: .5rem;
  width: fit-content;
  display: flex;
  align-items: center;
  gap: 1rem;
  cursor: pointer;
  display: flex;
  span{
    font-family: var(--Roboto);
    font-size: 2.5rem;
    font-weight: 400;
  }
`

export default OpportunityForm