import React, { useState, useEffect, useContext } from "react";
import styled from "styled-components";
import { LangContext, translations } from "../../../lib/Language";
import RoundFlag from "../../_components/RoundFlag";
import api from "../../../lib/api";

const ViewField = ({ data, object, secondary, selector, label, field = "", subField, t, f, getSelector, orgInfo, fromForm }) => {
  const g = translations[useContext(LangContext)].general;
  const def = translations[useContext(LangContext)].definition.filters;
  label = label || f[`${field}View`] || f[field];
  object = object || data;
  secondary = secondary || selector;
  const simple = { object, field };
  const _selector = { field, secondary, getSelector };
  const list = { ...simple, getSelector };
  const sContent = { object, field, subField, def, fromForm };
  const country = { ...simple, g };

  if (data && (!data[field] || data[field].length === 0 || data[field] === "0")) return <></>;

  return (
    <ViewCont $orgInfo={orgInfo} >
      <Label {...{ label }} orgInfo={orgInfo}/>
      {(t === "text" || t === "textarea") && <Simple {...simple} />}
      {t === "subContent" && <SubContent {...sContent} />}
      {t === "booleanSub" && <BooleanSub {...sContent} />}
      {t === "sub_sectors" && <Sub_sectors {...simple} />}
      {t === "contentArr" && <ContentArr {...simple} />}
      {t === "boolean" && <Boolean {...list} />}
      {(t === "selector" || t === "select") && <Selector {..._selector} />}
      {t === "sector" && <Sector {...simple} {..._selector} />}
      {(t === "list" || t === "checkboxes") && <List {...list} />}
      {(t === "listPercentage") && <ListPercentage {...list} />}
      {t === "files" && <Files {...simple} />}
      {t === "links" && <Links {...simple} />}
      {t === "videos" && <Videos {...simple} />}
      {t === "country" && <Country {...country} />}
      {t === "partner" && <Partner {...simple} />}
    </ViewCont>
  );
};

export default ViewField;

const Label = ({ label, isfor = false, required, selection, children, orgInfo }) => {
  const [open, setOpen] = useState(false);

  let forField = isfor === "none" || isfor === "" ? false : isfor;
  if (typeof forField == "object" && forField !== null)
    forField = Object.entries(forField).length === 0 ? false : true;
  if (!forField === false) {
    forField = true;
  }

  return (
    <>
      <LabelInfo>
        <LabelText>
          {label}
          {required && !forField && "*"}
          {orgInfo && ":"}
        </LabelText>
        {children && (
          <img onClick={() => setOpen(true)} src="/icons/help.png" alt="help" />
        )}
      </LabelInfo>
      {selection && <p>{selection}</p>}
      {open && (
        <Modal
          title={label}
          close={() => setOpen(false)}
          width={"916px"}
          height={"491px"}
        >
          <LabelModalInfo>{children}</LabelModalInfo>
        </Modal>
      )}
    </>
  );
};

const Simple = ({ object, field }) => <ViewData>{object[field]}</ViewData>;

const Boolean = ({ object, getSelector, field }) => {
  if (object[field]) {
    return <ViewData>{getSelector(field, "boolean", object[field].toString())}</ViewData>;
  } else {
    return null;
  }
};
const Selector = ({ field, secondary, getSelector }) => <ViewData>{getSelector(field, secondary || field)}</ViewData>;

const List = ({ object, field, getSelector }) => { //format [element1, element2, ...]
  const obj = Array.isArray(object[field]) ? object[field].map((e) => getSelector(field, field, e)) : JSON.parse(object[field]).map((e) => getSelector(field, field, e));
  return (
    <ViewData>
      <ListView>
        {obj.map((e, i) => (
          <li key={e + i}>{e}</li>
        ))}
      </ListView>
    </ViewData>
  );
};

const ListPercentage = ({ object, field, getSelector }) => { //format {element1: 20, element2: 50, ...}
  const obj = Object.entries(object[field]).map((e) => [getSelector(field, field, e[0]), e[1]]);
  return (
    <ViewData>
      <ListView>
        {obj.map((e, i) => (
          <li key={e[0] + i}>{e[0]} {"->"} {e[1]+"%"}</li>
        ))}
      </ListView>
    </ViewData>
  );
};

const SubContent = ({ object, field, subField, fromForm }) => <ViewData $fromForm={fromForm}><span>{object[field][subField]}</span></ViewData>;
const BooleanSub = ({ object, field, subField, def, fromForm }) => <ViewData $fromForm={fromForm}><span>{!object[field][subField] ? def.need : def.offer}</span></ViewData>;
const Sub_sectors = ({ object, field }) => {
  let obj = Array.isArray(object[field]) ? object[field] : [];
  return (
    <ViewData>
      <ListView>
        {obj.map((item) => (
          <li key={field + item.id}>{item.name}</li>
        ))}
      </ListView>
    </ViewData>
  );
};

const ContentArr = ({ object, field }) => {
  let obj = Array.isArray(object[field]) ? object[field] : [];
  return (
    <ViewData>
      <TagsContainer>
        {obj.map((item) => (
          <Tag key={field + (item.id || item)}>
            <span>{item.name || item}</span>
          </Tag>
        ))}
      </TagsContainer>
    </ViewData>
  );
};
const Country = ({ object, field, g }) => (
  <ViewCountry>
    <RoundFlag id={object[field]} width={"9.1rem"} height={"9.1rem"}/>
    <h4>{g.country[object[field]]}</h4>
  </ViewCountry>
);

export const Sector = ({ object, field }) => {
  const [options, setOptions] = useState(window.ops || []);

  useEffect(() => {
    let signal;
    if (!window.ops && object[field]) {
      signal = api.getSectors((r) => {
        let ops = [];
        for (const sector of r) for (const sub of sector.sub_sectors) ops.push({ tag: sub.id, name: `${sector.name} - ${sub.name}` });
        window.ops = ops;
        setOptions(ops);
      }, false);
    }
    return () => (signal ? signal.abort() : null);
  }, []);

  return object[field] && options[0] ? <ViewData>{options.find((e) => e.tag === parseInt(object[field]))?.name}</ViewData> : <ViewData>N/A</ViewData>;
};

const Files = ({ object, field }) => {
  return (
    <>
      {object[field].map((item, i) => (
        <ViewData key={"file-id-" + (item?.file?.id || i)}>
          <a href={item.document} target="_blank" rel="noopener noreferrer">
            {item.file.document_file_name}
          </a>
        </ViewData>
      ))}
    </>
  );
};

const Links = ({ object, field }) => {
  return (
    <>
      {object[field].map((item, i) => {
        function validateAndFormatURL(url) {
          const regex = /^https?:\/\//;
          if (regex.test(url)) {
            return url; // La URL ya tiene el formato correcto
          } else {
            return "https://" + url;
          }
        }
        let link = validateAndFormatURL(item);
        return(
          <ViewData key={"file-id-" + i}>
            <a href={link} target="_blank" rel="noopener noreferrer">
              {link}
            </a>
          </ViewData>
        )
      })}
    </>
  );
};

const Videos = ({ object, field }) => {
  let obj = Array.isArray(object[field]) ? object[field] : [];

  const showYoutubeIframe = (url) => {
    const regx = /^.*(?:(?:youtu\.be\/|v\/|vi\/|u\/\w\/|embed\/|shorts\/)|(?:(?:watch)?\?v(?:i)?=|\&v(?:i)?=))([^#\&\?]*).*/;
    let testUrl = regx.test(url)
    if (testUrl) {
      const videoId = url.match(regx);
      return <iframe width="100%" height="100%" src={`https://www.youtube.com/embed/${videoId[1]}`} title="YouTube video player" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen />;
    }else{
      return <div/>
    } 
  }

  return (
    <VideoContainer>
      {obj &&
        obj.map((url) => (
          <Video key={url + "_video"}>
            {showYoutubeIframe(url)}
          </Video>
        ))}
    </VideoContainer>
  );
};

export const Images = ({ object }) => {
  const [currentImage, setCurrentImage] = useState(0);

  return (
    <div>
      <MainImage>
        <img src={object.images[currentImage] ? object.images[currentImage].image : "/images/original/missing.png"} alt="Main Image" />
      </MainImage>
      <ImageCarrousel>
        {object.images.map((image, i) => (
          <CarrouselItem key={i + "imageMain"} onClick={() => setCurrentImage(i)} $selected={i === currentImage}>
            <img src={image.thumb} alt="Image" />
          </CarrouselItem>
        ))}
      </ImageCarrousel>
    </div>
  );
};

const Partner = ({ object }) => {
  return (
    <div>
      <div className="partnerImage">
        <img className="image" src={object.partner.logo ? object.partner.logo : "/images/original/missing.png"} alt="partner image" />
      </div>
    </div>
  );
};

export const ViewSection = ({ label, fields }) => {
  return (
    <>
      {label && <Label {...label}>{label.help}</Label>}
      {fields.map((f) => (
        <ViewField key={f.field} {...f} />
      ))}
    </>
  );
};

// Label
const LabelInfo = styled.div`
  display: flex;
  img{
    width: 2rem;
  }
`
const LabelText = styled.h4`
  font-size: 2.5rem;
  margin: 0;
  color: var(--grey5);
`
const LabelModalInfo = styled.div`
  padding: 5% 10%;
  font-size: 24px;
  text-align: left;
`

// View Data
const ViewCont = styled.div`
  font-size: 2.5rem;
  display: flex;
  gap: 1rem;
  flex-direction: ${({$orgInfo})=>$orgInfo?"row":"column"};
  @media only screen and (orientation: portrait) {
    flex-direction: column;
  }
`

const ViewData = styled.div`
  font-size: 2.5rem;
  font-weight: 400;
  color: var(--grey5);
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  a{
    word-break: break-all;
    text-decoration: underline;
  }
`
const ListView = styled.ul`
  padding: 0 4rem;
  margin: 0;
`

//Country
const ViewCountry = styled.div`
  display: flex;
  gap: 2rem;
  align-items: center;
  
  h4{
    font-size: 4rem;
    font-weight: 600;
    margin: 0;
    color: var(--redWine);
    text-transform: uppercase;
    font-family: var(--BarlowCondensed);
  }
`
//Images
const MainImage = styled.div`
  max-width: 100%;
  max-height: 100%;
  height: 45.8rem;
  width: 104.9rem;
  position: relative;
  display: flex;
  justify-content: flex-start;

  img{
    max-width: 100%;
    max-height: 100%;
    object-fit: contain;
  }
`
const ImageCarrousel = styled.div`
  margin: .5rem 0;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-wrap: wrap;
  position: relative;
`
const CarrouselItem = styled.div`
  flex-basis: 90px;
  width: 90px;
  height: 90px;
  overflow: hidden;
  position: relative;
  cursor: pointer;
  border: ${({$selected})=>$selected?"2px solid white":"none"};

  img{
    width: 100%;
    max-width: 100%;
    height: 100%;
    max-height: 100%;
    object-fit: cover;
  }
`

//Videos
const VideoContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 2rem;
`
const Video = styled.div`
  max-height: 68.1rem;
  aspect-ratio: 16 / 9
`

//Tags
const TagsContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 2rem;
`
const Tag = styled.div`
  margin: 0;
  padding: .5rem 0;
  display: flex;
  gap: 1rem;
  font-size: 2.5rem;
`