import React from 'react'
import MainCarouselBanner from '../_components/MainCarouselBanner'
import { ViewContain } from '../_components/ViewContain'
import { InfoTitle } from '../_components/InfoTitle'
import { InfoText } from '../_components/InfoText'
import WorkTeamCard from './_components/WorkTeamCard'
import TestimonyBanner from './_components/TestimonyBanner'
import FadeInSection from '../_components/FadeInSection'

const AboutUsIndex = ({ texts, work_team, testimonies, bannerData }) => {
  
	return(
		<>
			<MainCarouselBanner {...{ bannerData }} smallText/>
			<ViewContain $paddingX={"10%"} $paddingY={"5%"} $alignItems={"center"}>
				<InfoTitle dangerouslySetInnerHTML={{__html: texts.about_us_title_1}} />
				<FadeInSection>
					<InfoText dangerouslySetInnerHTML={{__html: texts.about_us_info_1}} $textAlign={"center"} $fontSize={"3.5rem"} $margin={"5rem 0"}/>
				</FadeInSection>
				<FadeInSection>
					<InfoText dangerouslySetInnerHTML={{__html: texts.about_us_info_2}} $textAlign={"center"} $fontSize={"5rem"} $fontFamily={"var(--BarlowCondensed)"} $color={"#ED6B33"} $margin={"5rem 0"}/>
				</FadeInSection>
				<FadeInSection>
					<InfoTitle dangerouslySetInnerHTML={{__html: texts.about_us_title_2}} $textTransform={"uppercase"} />
				</FadeInSection>
				<ViewContain $flexDirection={"row"} $flexWrap={"wrap"} $justifyContent={"center"}>
					{work_team.map((member, i)=>
						<FadeInSection key={"team_card"+i} cardContent>
							<WorkTeamCard {...{member, texts}}/>
						</FadeInSection>
						)}
				</ViewContain>
				<FadeInSection>
					<InfoTitle dangerouslySetInnerHTML={{__html: texts.about_us_title_3}} />
				</FadeInSection>
			</ViewContain>
			<FadeInSection>
				<TestimonyBanner testimonyData={testimonies}/>
			</FadeInSection>
		</>
	)
}

export default AboutUsIndex