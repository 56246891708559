import React, { useContext, useEffect, useState } from "react";
import { LangContext, translations } from "../../../../lib/Language";
import usePrController from "../../_hooks/usePrController";
import ViewField, { Images } from "../../_components/ViewField";
import Layout from "../../_components/Layout";
import ActionPanel from "../../_components/ActionPanel";
import useFormUpdate from "../../_hooks/useFormUpdate";
import Modal from "../../_components/Modal";
import ScoreModal from "../../_components/ScoreModal";
import { ViewContain } from "../../../_components/ViewContain";
import { GreyButton } from "../../../_components/GreyButton";
import InfoOrganizationContact from "../../_components/InfoOrganizationContact";

const stagingEnvironment = process.env.REACT_APP_NODE_ENV === 'staging'

const ProposalView = ({ object, selectors, dispatch, getSelector, hiddenPanelButtons, currentUser, opportunityUser, removeBackEvent }) => {
  const [data, saveData, setData] = useFormUpdate("proposal", object);
  const [score, setScore] = useState(false);
  const l = useContext(LangContext);
  const f = translations[l].forms.opportunities;
  const c = translations[l].forms.controls;
  const g = translations[l].general;
  const d = translations[l].definition;
  const common = { data, f, l, d, g, c, view: true, selectors, getSelector, };
  const accordion = usePrController(common);

  const selectProposal = () => {
    if (!data.selected) window.location = `${stagingEnvironment?"/marketplace":""}/proposals/${data.id}/select`;
  };

  const props = {
    data: [
      {title: f.oppBasicFormTitle, component: <Definition {...common} showBtn={currentUser?.id === opportunityUser} handleClick={selectProposal} />},
      ...accordion,
      {title: f.oppAttachmentsFormTitle, component: <Attachments {...common} />},
    ],
    c
  };

  useEffect(() => {
    const showCardTimer = setTimeout(() => {
      const content = document.getElementById(`proposalView`)
      content.scrollIntoView({ behavior: "smooth" })
      clearTimeout(showCardTimer)
    }, 300)
  }, [])

  return (
    <>
      <ViewContain id="proposalView" $paddingY={"5%"} $paddingX={"10%"}>
        <InfoOrganizationContact org={data.organization} view />
        {currentUser?.id === opportunityUser && (
          <GreyButton $width={"24.9rem"} $height={"4.3rem"} onClick={() => { window.location = `${stagingEnvironment?"/marketplace":""}/opportunities/${data.opportunity_id}`;}}>{c.backOpportunity}</GreyButton>
        )}
        <ViewContain $paddingY={"5%"}>
          <Layout {...props} />
        </ViewContain>
        {!hiddenPanelButtons && <ActionPanel {...{ data, saveData, setScore, dispatch, setData, removeBackEvent }} mode="preview" model="proposals" />}
      </ViewContain>
      {score && (
        <Modal>
          <ScoreModal
            score={score}
            listener={() =>
              api.publish(data.id, model, (res) => {
                if (res.success && res.success === true) {
                  setData(res.object);
                  window.location = `${stagingEnvironment?"/marketplace":""}/opportunities/` + data.id;
                }
              })
            }
          />
        </Modal>
      )}
    </>
  );
};

export default ProposalView;

const Definition = ({ data, showBtn, handleClick, d, f, c }) => (
  <>
    {showBtn && 
      <GreyButton $width={"24.9rem"} $height={"4.3rem"} onClick={data.selected ? undefined : handleClick} $disable={data.selected} >{c.backOpportunity}</GreyButton>
    }
    <ViewField {...{ data }} t="text" field="title" label={f.viewTitle}/>
    <ViewField {...{ data }} f={d} t="text" field="description" />
    <ViewField {...{ data }} f={d} t="text" field="conditions" />
    <ViewField {...{ data }} f={d} t="text" field="motivation" />
  </>
);

const Attachments = (props) => (
  <>
    <Images object={props.data} t="images" />
    {props.data.files.length > 0 &&<ViewContain $paddingY={"4%"} >
      <ViewField object={props.data} t="files" field="files" label={props.f.anexosView} />
    </ViewContain>}
    {props.data.urlVideos.length > 0 && <ViewField object={props.data} t="videos" field="urlVideos" label={props.c.urlVideosView} />}
    {props.data.attached_links.length > 0 && <ViewContain $paddingY={"4%"} >
      <ViewField object={props.data} t="links" field="attached_links" label={props.f.attachedLinksView} />
    </ViewContain>}
  </>
);
