import React, { useEffect, useState } from "react";
import styled from "styled-components";
import DownIcon from "../../icons/DownIcon";

const HeaderMenu = ({ texts, isMarketplace, marketPlaceLink }) => {
  const [pathname, setPathname] = useState("/")

  useEffect(() => {
    if(window){
      setPathname(window.location.pathname)
    }
  }, [])

  const showSubMenu = (classN, n) => {
    let element = document.getElementsByClassName(classN)[n];
    element.classList.add("is-open")
  };
  
  const hideMenu = (classN, n) => {
    let element = document.getElementsByClassName(classN)[n];
    element.classList.remove("is-open")
  };

  return (
    <HeaderMenuCont>
      <NavigationCont onMouseLeave={() =>{hideMenu("header_subMenu", 0); hideMenu("header_services_menu", 0)}} >
        <MainMenu onMouseOver={() => showSubMenu("header_subMenu", 0)}>
          <MainMenuText className="main_text" $selected={pathname.includes("services")}>{texts.header_services.toUpperCase()}</MainMenuText>
          <IconCont>
            <DownIcon width={"1.5rem"} height={".6rem"} color={"#D9D9D9"}/>
          </IconCont>
        </MainMenu>
        <HeaderSubMenu className="header_subMenu">
          <HeaderSubMenuCont>
            <div className="consulting_menu first_menu" onMouseOver={() => showSubMenu("header_services_menu", 0)}>
              <span className="consulting_text" style={{display: "flex"}}>
                {texts.header_consulting.toUpperCase()}
                <IconCont>
                  <DownIcon width={"1.5rem"} height={".6rem"} color={"#D9D9D9"}/>
                </IconCont>
              </span>
              <MenuServices className="header_services_menu">
                <MenuServicesCont href="/services">
                  <span>{texts.header_diagnosis.toUpperCase()}</span>
                  <span>{texts.header_strategy.toUpperCase()}</span>
                  <span>{texts.header_implementation.toUpperCase()}</span>
                  <span>{texts.header_evaluation.toUpperCase()}</span>
                </MenuServicesCont>
              </MenuServices>
            </div>
            <a href="/bridge2zero">
              <span>
                BRIDGE<span style={{ color: "#7DA03C", margin: "0" }}>2</span>
                ZERO
              </span>
            </a>
          </HeaderSubMenuCont>
        </HeaderSubMenu>
      </NavigationCont>
      <NavigationCont onMouseLeave={() => hideMenu("header_subMenu", 1)} >
        <MainMenu onMouseOver={() => showSubMenu("header_subMenu", 1)}>
          <MainMenuText className="main_text" $selected={pathname.includes("business")}>
            {texts.header_business.toUpperCase()}
          </MainMenuText>
          <IconCont>
            <DownIcon width={"1.5rem"} height={".6rem"} color={"#D9D9D9"}/>
          </IconCont>
        </MainMenu>
        <HeaderSubMenu className="header_subMenu">
          <HeaderSubMenuCont>
            <a href="/business" className="first_menu">
              <span style={{ width: "18rem" }}>{texts.header_green.toUpperCase()}</span>
              <span style={{ width: "18rem" }}>{texts.header_inclusive.toUpperCase()}</span>
              <span style={{ width: "18rem" }}>{texts.header_cicular.toUpperCase()}</span>
            </a>
          </HeaderSubMenuCont>
        </HeaderSubMenu>
      </NavigationCont>
      <NavigationCont onMouseLeave={() => hideMenu("header_subMenu", 2)}>
        <MainMenu onMouseOver={() => showSubMenu("header_subMenu", 2)}>
          <MainMenuText $selected={isMarketplace}>{texts.header_marketplace.toUpperCase()}</MainMenuText>
          <IconCont>
            <DownIcon width={"1.5rem"} height={".6rem"} color={"#D9D9D9"}/>
          </IconCont>
        </MainMenu>
        <HeaderSubMenu className="header_subMenu">
          <HeaderSubMenuCont>
            <a className="first_menu" href={marketPlaceLink}>
              <span style={{ width: "13rem" }}>{texts.header_marketplace.toUpperCase()}</span>
            </a>
            <a href={marketPlaceLink + "/plus"}>
              <span style={{ width: "13rem" }}>MINKADEV PLUS</span>
            </a>
          </HeaderSubMenuCont>
        </HeaderSubMenu>
      </NavigationCont>
      <NavigationCont className="pointer main_menu" >
        <a href="/about_us">
          <MainMenuText $selected={pathname.includes("about_us")}>{texts.header_about_us.toUpperCase()}</MainMenuText>
        </a>
      </NavigationCont>
      <NavigationCont className="pointer main_menu">
        <a href="/blogentries">
          <MainMenuText $selected={pathname.includes("blogentries")}>{texts.header_news.toUpperCase()}</MainMenuText>
        </a>
      </NavigationCont>
    </HeaderMenuCont>
  );
};

const HeaderMenuCont = styled.div`
  display: flex;
  gap: 5rem;
  width: 100%;
  max-width: 100rem;
  justify-content: center;

  @media (max-width: 720px) {
    display: none;
  }
`

const NavigationCont = styled.nav`
  display: flex;
  align-items: flex-end;
  height: 100%;
  position: relative;
  font-weight: 400;
  font-size: 2rem;
  font-family: var(--BarlowCondensed);
  padding: 28px 0;
  cursor: pointer;

  &:hover .main_text{
    color: var(--yellow2);
  }
`

const MainMenu = styled.div`
  display: flex;
`

const MainMenuText = styled.span`
  color: ${({$selected})=>$selected ? "var(--yellow2)" : "var(--black2)"} ;
`

const IconCont = styled.div`
  margin: 0 0 0 .5rem;
`

export const HeaderSubMenu = styled.div`
  display: grid;
  grid-template-rows: 0fr;
  transition: grid-template-rows 0.5s ease-out;
  font-family: var(--BarlowCondensed);
  font-size: 2rem;
  font-weight: 400;
  color: white;
  position: absolute;
  top: 100%;
  left: -10%;
  z-index: 5;
  width: max-content;
  background-color: #000000cc;

  &.is-open{
    grid-template-rows: 1fr;
  }

  a{
    display: flex;
    flex-direction: column;
  }

  @media (max-width: 720px) {
    font-size: 3.5rem;
  }
`

export const HeaderSubMenuCont = styled.div`
  display: flex;
  flex-direction: column;
  overflow: hidden;

  .first_menu{
    margin: 1rem 0 0;
  }

  span{
    margin: 0 1rem .5rem;
  }

  & .consulting_menu:hover .consulting_text, span:hover{
    color: var(--yellow2);
  }
`

const MenuServices = styled.div`
  display: grid;
  grid-template-rows: 0fr;
  transition: grid-template-rows 0.5s ease-out;
  position: relative;
  left: 5%;
  width: 15rem;

  &.is-open{
    grid-template-rows: 1fr;
  }
`

const MenuServicesCont = styled.a`
  display: flex;
  flex-direction: column;
  overflow: hidden;
`

export default HeaderMenu;
