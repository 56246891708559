const valDebug = false //change to eliminate console.logs

//Returns the total fields, including associations, to be accounted for the percentage
export const getTotalFields = (data) => {
  let count = 0
  let has_community 
  //if it is proposal, invert has_community validators
  if(valDebug) console.log("data",data)
  if(data.type) has_community = data.is && data.is === "proposal" ? !data.type.has_community : data.type.has_community
  const fields = getFields(data, "validators")
  for (const field of fields) count = count + getAttributes(field.validators, has_community, data.type)
  if(valDebug) console.log("Getting total Fields", count)
  return count
}

export const getPercentage = (total, data) => {
  if(valDebug) console.log("total", total, data)
  if (total == 0) return 0
  const errors = getErrors(data)
  if(valDebug) console.log("errors", errors)
  const done = total - errors
  const perc = Math.round(done * 100 / total)
  return perc
}


///PRIVATE:
//Returns the quantity of fields that should be accounted for the percentage
const getAttributes = (validators, has_community, type) => {
  if(valDebug) console.log(validators)
  let valid = validators.filter(validator => !validator.options.message)
  //Si tiene validador de comunidad, busca los que tiene if que se ajusten al tipo apropiado (más los que no tiene alguno de los dos tipos)
  if (has_community !== undefined) valid = valid.filter(validator => validator.options.if ? 
      validator.options.if == (has_community ? "has_community?" : "seeks_community?") || !validator.options.if.includes("has_community?", "seeks_community") : true)
  
  //No debe traer los validadores que estén filtrados por otype adecuado si hay data.type
  //primero revisa que sea del otype adecuado y lo pasa, si no, los que no tienen otype
  if(type) valid = valid.filter(validator => validator.options.if ?
    validator.options.if === `otype${type.number}` ? true : !validator.options.if.includes("otype") : true)
  
  if(valDebug) console.log("valid", valid)
  return valid.reduce((accumulator, current) => accumulator + current.attributes.length, 0)
}

// Gets fields with assigned key (errors or validators)
const getFields = (data, key) => {
  let fields = []
  if (data[key]) fields.push(data)
  for (const field in data) {
    if (typeof (data[field]) === "object" && data[field] && data[field][key]) {
      fields.push(data[field])
    }
  }
  return fields;
}



//Compares errors with actual data
const getErrors = (data) => {
  let errors = 0
  const fields = getFields(data, "errors")
  if(valDebug) console.log("fields", fields)
  for (const field of fields) {
    if(valDebug) console.log(fields, field.errors)
    for (const tag in field.errors) {
      //Validate can't be blank
      if ((field.errors[tag].includes("Por favor diligenciar este campo") || field.errors[tag].includes("Please fill out this field") || field.errors[tag].includes("Veuillez remplir ce champ") ) && !field[tag]) {
        if (!fields[tag]) errors++
      }
    }
  }

  return errors
}