import React, { useEffect, useState } from 'react'
import { styled } from 'styled-components'
import { GreyButton } from '../../_components/GreyButton'
import LoadingBars from '../../_components/LoadingBars'

const InfoGreyCard = ({ c, children, i, item, form, selectedForm, saveForm, changeForm }) => {
  const [saved, setSaved] = useState(false)
  const currentForm = selectedForm === i

  const handleForm = (e) => {
    e.preventDefault()
    if (currentForm) {
      saveForm()
      setSaved(true)
    } else {
      changeForm(i)
    }
  }

  useEffect(() => {
    if (!currentForm && saved) setSaved(false)
    if (currentForm) {
      const showCardTimer = setTimeout(() => {
        const content = document.getElementById(`info_card_`+i)
        content.scrollIntoView({ behavior: "smooth" })
        clearTimeout(showCardTimer)
      }, 300)
    }
  }, [selectedForm])

  return (
    <Card id={`info_card_`+i} $currentForm={currentForm} onSubmit={handleForm}>
      <FormTitleCont>
        <Title $form={form}>{i+1 + ". " + item.title}</Title>
        {form && !currentForm && <GreyButton $width={"31.5rem"} $height={"3.7rem"} $textTransform={"uppercase"} onClick={handleForm} type={"button"}>{c.editar}</GreyButton>}
      </FormTitleCont>
      <ChildrenInfo>
        {children}
        {form && selectedForm !== i && <ChildrenBlock/>}
      </ChildrenInfo>
      {form &&
      <FormInfo $currentForm={currentForm}>
        <p>{c.info}</p>
        <ButtonCont>
          {currentForm && 
            <>
              {!saved ?
                <GreyButton $width={"31.5rem"} $height={"3.7rem"} $textTransform={"uppercase"} type={"submit"}>{c.guardar}</GreyButton>
              :
                <LoadingBars color={"#fff"}/>
              }
            </>
          }
          
        </ButtonCont>
      </FormInfo>}
    </Card>
  )
}

const Card = styled.form`
  background-color: ${({$currentForm}) => $currentForm ? "var(--purple3)" : "var(--grey1)"};
  padding: 5rem 5%;
  scroll-margin-top: 10.6rem;
  h4, label, span{
    color: ${({$currentForm}) => $currentForm ? "white" : "var(--grey5)"};
  }
  input, textarea, .select_cont{
    background-color: ${({$currentForm}) => !$currentForm && "var(--grey1)"};
  }
  input:invalid:focus, select:invalid:focus, textarea:invalid:focus {
    border: red 2px solid;
  }
`
const FormTitleCont = styled.div`
  display: flex;
  gap: 2rem;
  justify-content: space-between;
`
const Title = styled.h2`
  font-family: var(--BarlowCondensed);
  font-weight: 600;
  font-size: 5rem;
  margin: 0 0 3rem;
  text-transform: uppercase;
  color: ${({$form}) => $form ? "white" : "var(--grey5)"};
`
const ChildrenInfo = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  gap: 3rem;
`
const ChildrenBlock = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
`
const FormInfo = styled.div`
  display: flex;
  height: 8rem;
  gap: 2rem;
  justify-content: space-between;
  align-items: center;
  margin: 5% 0 0;

  p {
    color: ${({$currentForm}) => $currentForm ? "white" : "var(--grey5)"};
  }
`
const ButtonCont = styled.div`
  width: 31.5rem;
  display: flex;
  align-items: center;
  justify-content: center;
`

export default InfoGreyCard