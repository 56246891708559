import React, {  useState } from 'react'
import styled from "styled-components";
import arrowIcon from "../../../images/svg/banner_arrow.svg"

const TestimonyBanner = ({ testimonyData = [] }) => {
  const [currentSlide, setCurrentSlide] = useState(0);

  const bannerSlider = testimonyData.length >= 2

  const nextSlide = () => {
    setCurrentSlide((currentSlide + 1) % testimonyData.length);
  };

  const prevSlide = () => {
    setCurrentSlide((currentSlide - 1 + testimonyData.length) % testimonyData.length);
  };

  // useEffect(() => {
  //   const interval = setInterval(() => {
  //     nextSlide()
  //   }, 3000);

  //   return () => clearInterval(interval);
  // }, [currentSlide]);

  return (
    <Banner>
      {bannerSlider && 
      <BannerArrowCont>
        <img src={arrowIcon} alt="arrow icon" onClick={prevSlide}/>
      </BannerArrowCont>}

      <BannerCont>
        <SliderCont $currentSlide={currentSlide}>
        {testimonyData.map((data, i)=>
          <SliderItem key={"slider" + i}>
            <SliderInfo>
              <p dangerouslySetInnerHTML={{__html: data.description}}/>
              <div>
                <span>{data.name}</span>
                <span>{data.position}</span>
              </div>
            </SliderInfo>
          </SliderItem>
        )}
        </SliderCont>
      </BannerCont>
      
      {bannerSlider && 
      <BannerArrowCont $right={"true"}>
        <img src={arrowIcon} alt="arrow icon" onClick={nextSlide}/>
      </BannerArrowCont>}
    </Banner>
  )
}

export default TestimonyBanner

const Banner = styled.div`
  height: 369px;
  position: relative;
  margin: 0 0 5% 0;

  @media (max-width: 1368px) {
    height:36.9rem;
  }
`

const BannerArrowCont = styled.div`
  display: flex;
  height: 100%;
  align-items: center;
  max-width: 4.5rem;
  position: absolute;
  left: ${({$right}) => !$right && "0"};
  right: ${({$right}) => $right && "0"};
  transform: scaleX(${({$right}) => $right ? "-1" : "1"});
  cursor: pointer;
  z-index: 1;

  img{
    width: 100%;
  }
  @media (max-width: 1688px) {
    left: ${({$right}) => $right !== "true" && "4%"};
    right: ${({$right}) => $right === "true" && "4%"};
  }
  @media (max-width: 1024px) {
    max-width: 4rem;
  }
  @media (max-width: 440px) {
    max-width: 3.5rem;
  }
`

const BannerCont = styled.div`
  position: absolute;
  top: 0; 
  left: 50%;
  width: 100vw;
  height: 100%;
  overflow: hidden;
  transform: translate(-50%, 0);
`

const SliderCont = styled.div`
  position: relative;
  display: flex;
  transition: transform 0.3s ease;
  transform: translateX(${({$currentSlide}) => -$currentSlide * 100}%);
  height: 100%;
`

const SliderItem = styled.div`
  &::before{
    content: "";
    position: absolute;
    top: 0; 
    left: 0;
    width: 100%;
    height: 100%;
    background-color: #7E143B;
    z-index: -1;
  }

  width: 100%;
  height: 100%;
  padding: 0 10%;
  flex-shrink: 0;
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`

const SliderInfo = styled.div`
  max-width: 1018px;
  display: flex;
  flex-direction: column;
  font-family: var(--BarlowCondensed);
  font-size: 3rem;
  color: white;
  
  p{
    margin: 2rem 0;
    text-align: center;
    font-weight: 400;
  }

  div{
    align-self: flex-end;
    display: flex;
    flex-direction: column;
    align-items: flex-end;
  }

  span{
    font-weight: 600;
  }
`