import React from 'react';
import { useInView } from 'react-intersection-observer';
import styled from 'styled-components';

const FadeInSection = ({ children, inViewAction, cardContent }) => {
  const { ref, inView } = useInView({
    triggerOnce: true,
    threshold: 0.1,
    rootMargin: '0px 0px -150px 0px',
    onChange: (inView) => {
      if (inView && inViewAction) {
        inViewAction();
      }
    }
  });

  return (
    <FadeInSectionCont ref={ref} className={`${inView ? ' is-visible' : ''}`} $cardContent={cardContent} >
      {children}
    </FadeInSectionCont>
  );
};

const FadeInSectionCont = styled.div`
  width: ${({ $cardContent }) => $cardContent ? 'fit-content' : 'inherit'};
  opacity: 0;
  transform: translateY(20px);
  transition: opacity 0.6s ease-out, transform 0.6s ease-out;
  display: inherit;
  flex-direction: inherit;
  align-items: inherit;
  justify-content: inherit;
  gap: inherit;
  margin: inherit;

  &.is-visible{
    opacity: 1;
    transform: translateY(0);
  }
`

export default FadeInSection;