import React, { useState } from 'react'
import styled from 'styled-components'
import api from '../../lib/api'
import NewsCarouselBanner from '../_components/NewsCarouselBanner'
import { ViewContain } from '../_components/ViewContain'
import { InfoTitle } from '../_components/InfoTitle'
import LoadingBars from '../_components/LoadingBars'
import NewsCard from './_components/NewsCard'
import { GreenButton } from '../_components/GreenButton'

import  linkedinIcon from "../../images/svg/linkedin_icon.svg"
import  xIcon from "../../images/icons/x_icon.webp"
import  instagramIcon from "../../images/svg/instagram_icon.svg"
import  facebookIcon from "../../images/svg/fecebook_icon.svg"
import  youtubeIcon from "../../images/svg/youtube_icon.svg"
import FadeInSection from '../_components/FadeInSection'

const NewsIndex = ({ texts, blogs = [] }) => {
	const [page, setPage] = useState(2)
	const [loading, setLoading] = useState(false)
	const [news, setNews] = useState(blogs)
	const [showMoreButton, setShowMoreButton] = useState(true)

	const lastNew = news[0]

	const bannerData = [{title: lastNew?.title, banner_image: lastNew.logo || lastNew.image, title_color: lastNew.title_color, button_action: {text: texts.news_banner_button, action: `/blogentries/${lastNew?.info_link || lastNew?.id}`}}]

	const getOtherNews = () => {// call api "/api/blogentries/:page" for get other news
		setLoading(true)
		api.getOtherNews(
			(r) => {
				setLoading(false);
				if (r.success) {
					setNews(news => [...news, ...r?.blogs])
					setPage((pg)=>pg + 1)
					if(r?.blogs.length < 6) setShowMoreButton(false)
				}
      },
			page
		)
	}

	return (
		<>
			<NewsCarouselBanner {...{ bannerData }}/>
			<ViewContain $paddingX={"5%"} $paddingY={"5%"} $alignItems={"center"}>
				<InfoTitle dangerouslySetInnerHTML={{__html: texts.news_filter_title}} />
				<ViewContain $paddingX={"5%"} $alignItems={"center"} $gap={"3rem"}>
					<NewsSubtitle>{texts.news_social_subtitle}</NewsSubtitle>
					<SocialIcons>
						<a href="https://www.linkedin.com/company/minkadev/" target="_blank" rel="noopener noreferrer"><img src={linkedinIcon} alt="linkedin" /></a>
						<a href="https://twitter.com/MinkaDev/" target="_blank" rel="noopener noreferrer"><img src={xIcon} alt="x" /></a>
						<a href="https://www.instagram.com/minkadev/" target="_blank" rel="noopener noreferrer"><img src={instagramIcon} alt="instagram" /></a>
						<a href="https://www.facebook.com/MinkaDev/" target="_blank" rel="noopener noreferrer"><img src={facebookIcon} alt="facebook" /></a>
						<a href="https://www.youtube.com/user/MinkaDev" target="_blank" rel="noopener noreferrer"><img src={youtubeIcon} alt="youtube" /></a>
					</SocialIcons>
				</ViewContain>
				<CardsContain>
					{news?.length === 0 ?
						<ViewContain $alignItems={"center"}><LoadingBars/></ViewContain>
					: 
						news.map((blog, i)=>
						<FadeInSection key={"news"+i} cardContent>
							<NewsCard {...{text: texts}} blogEntrie={blog}/>
						</FadeInSection>
					)}
				</CardsContain>
				{showMoreButton && !loading &&
					<ViewContain $paddingY={"3%"} $alignItems={"center"}>
						<GreenButton $textTransform={"uppercase"} onClick={getOtherNews} $width={"54.8rem"} $height={"5.5rem"}>{texts.news_more_button}</GreenButton>
					</ViewContain>}
				{showMoreButton && loading &&
					<ViewContain $paddingY={"3%"} $alignItems={"center"}>
						<LoadingBars/>
					</ViewContain>}
			</ViewContain>
		</>
	)
}

const SocialIcons = styled.div`
  display: flex;
  gap: 1rem;

	img{
  	height: 6.4rem;
	}
`
const NewsSubtitle = styled.h3`
	margin: 0;
	font-family: var(--BarlowCondensed);
	font-size: 5rem;
	color: var(--purple2);
	font-weight: 400;
	text-align: center;
`
const CardsContain = styled.div`
  width: 100%;
  margin: 5% 0;
  display: grid;
  place-items: center;
  grid-template-columns: repeat(auto-fit, minmax(36.7rem, 1fr));
  gap: 5rem;
  @media (max-width: 1688px) {
    margin: 5% 10%;
  }
  @media (max-width: 768px) {
    grid-template-columns: repeat(auto-fit, minmax(234px, 1fr));
  }
`

export default NewsIndex