import React, { useEffect, useRef, useState } from "react";
import CardsContain from "../../../_components/CardsContain";
import OpportunityCard from "../../_components/OpportunityCard";
import { ViewContain } from "../../../_components/ViewContain";
import LoadingBars from "../../../_components/LoadingBars";
import api from "../../../../lib/api";
import { GreyButton } from "../../../_components/GreyButton";

const stagingEnvironment = process.env.REACT_APP_NODE_ENV === 'staging'

const SectionOpPrCards = ({ proposals = [], currentUser, opportunityTab, c, opportunitiesCount = 0 }) => {
  const [showMoreButton, setShowMoreButton] = useState(false)
  const [loading, setLoading] = useState(false)
  const [data, setData] = useState([])

  const page = useRef(1);
  
  const getApiOpportunities = (fromButton) => {// call api "/api/opportunities/:page" for get other opportunities
		setLoading(true);
    if (fromButton) page.current = page.current + 1
    api.getUserOpportunities({user_id: currentUser.id}, (r) => {
      setLoading(false);
      setData(data => [...data, ...r])
      if (r.length < 6) setShowMoreButton(false)
    },
    page.current);
	}

  useEffect(() => {//TODO: Optimizar llamado al cambiar a propuestas
    getApiOpportunities()
  }, [])

  useEffect(() => {
    if (data.length < opportunitiesCount) setShowMoreButton(true)
  }, [data])

  return (
    <>
      <CardsContain>
        {opportunityTab ? 
          <>
            {opportunityTab && data.length === 0 && !loading &&
              <ViewContain $alignItems={"center"} style={{paddingBottom: "3%"}}>
                <a href={`${stagingEnvironment?"/marketplace":""}/opportunities/new`}>
                  <GreyButton $width={"25rem"} $height={"4.8rem"} $textTransform={"uppercase"}>
                    {c.createOpportunity}
                  </GreyButton>
                </a>
              </ViewContain>
            }
            {data.map((opportunity) => (
              <OpportunityCard opportunity={opportunity} key={opportunity.id + "op"} edit={!opportunity.published}/>
            ))}
          </>
        : 
          <>
            {proposals.length === 0 && !loading &&
              <ViewContain $alignItems={"center"} style={{paddingBottom: "3%"}}>
                <a href={`${stagingEnvironment?"/marketplace":""}/#oppFilter`}>
                  <GreyButton $width={"25rem"} $height={"4.8rem"} $textTransform={"uppercase"}>
                    {c.seeOpportunities}
                  </GreyButton>
                </a>
              </ViewContain>
            }
            {proposals.map((proposal) => (
              <OpportunityCard opportunity={proposal} key={proposal.id + "op"} edit={!proposal.published} proposal/>
            ))}
          </>
        }
      </CardsContain>
      {showMoreButton && !loading &&
        <ViewContain $alignItems={"center"} style={{paddingBottom: "3%"}}>
          <GreyButton $width={"25rem"} $height={"4.8rem"}  $textTransform={"uppercase"} onClick={()=>getApiOpportunities(true)}>{c.moreButton}</GreyButton>
        </ViewContain>
      }
      {loading &&
        <ViewContain $alignItems={"center"} style={{paddingBottom: "3%"}}>
          <LoadingBars color={"#5E5E5E"}/>
        </ViewContain>
      }
    </>
  );
};

export default SectionOpPrCards;