import React from "react";
import MainCarouselBanner from "../_components/MainCarouselBanner";
import NewsCarouselBanner from "../_components/NewsCarouselBanner";

import { ViewContain } from "../_components/ViewContain";
import { InfoText } from "../_components/InfoText";
import { GreenButton } from "../_components/GreenButton";
import { InfoTitle } from "../_components/InfoTitle";
import ServicesInfoCardHomepage from "./_components/ServicesInfoCardHomepage";
import MinkaMap from "./_components/MinkaMap";

import greenBusiness from "../../images/cards/green-business.webp"
import inclusiveBusiness from "../../images/cards/inclusive-business.webp"
import circleBusiness from "../../images/cards/circle-business.webp"
import diagnostic from "../../images/icons/diagnostic.webp"
import strategy from "../../images/icons/strategy.webp"
import implementation from "../../images/icons/implementation.webp"
import evaluation from "../../images/icons/evaluation.webp"
import FadeInSection from "../_components/FadeInSection";
import BusinessInfoCardHomePage from "./_components/BusinessInfoCardHomePage";

const HomeIndex = ({ texts, countries, bannerData, blogs, lang, opportunitiesCount, projectsCount }) => {
  
  const bannerNewsData = blogs.map((blog)=>({title: blog?.title, banner_image: blog.logo || blog.image, title_color: blog.title_color, button_action: {text: texts.homepage_last_news_button, action: `/blogentries/${blog?.info_link || blog?.id}`}}))
  
  const businessData = [{image: greenBusiness, title: texts.homepage_business_info_1_title, text: texts.homepage_business_info_1_description}, { image: inclusiveBusiness, title: texts.homepage_business_info_2_title, text: texts.homepage_business_info_2_description}, { image: circleBusiness, title: texts.homepage_business_info_3_title, text: texts.homepage_business_info_3_description}]
  
  const servicesData = [{image: diagnostic, title: texts.homepage_services_info_1_title, text: texts.homepage_services_info_1_description}, { image: strategy, title: texts.homepage_services_info_2_title, text: texts.homepage_services_info_2_description}, { image: implementation, title: texts.homepage_services_info_3_title, text: texts.homepage_services_info_3_description}, { image: evaluation, title: texts.homepage_services_info_4_title, text: texts.homepage_services_info_4_description}]

  const navigate = (url) => {
    window.location = url;
  };

  return (
    <>
      <MainCarouselBanner {...{ bannerData }}/>
      <ViewContain $paddingX={"10%"} $alignItems={"center"}>
        <InfoText $textAlign={"center"} $fontWeight={"600"} >
          {texts.homepage_info_1}
        </InfoText>
        <a href="/services">
          <GreenButton $textTransform={"uppercase"} $width={"54.8rem"} $height={"5.5rem"}>{texts.homepage_green_button}</GreenButton>
        </a>
        <ViewContain $paddingY={"5%"} $alignItems={"center"}>
          <FadeInSection>
            <InfoTitle $textTransform={"uppercase"}>
              {texts.homepage_info_title_1}
            </InfoTitle>
          </FadeInSection>
          {servicesData.map((item, i)=>
            <FadeInSection key={"info"+i}>
              <ServicesInfoCardHomepage image={item.image} title={item.title} text={item.text} i={i}/>
            </FadeInSection>
          )}
          <ViewContain $paddingY={"3%"} $alignItems={"center"}>
            <a href="/services">
              <GreenButton $textTransform={"uppercase"} $width={"54.8rem"} $height={"5.5rem"}>{texts.homepage_services_button}</GreenButton>
            </a>
          </ViewContain>
          <FadeInSection>
            <InfoTitle $textTransform={"uppercase"} dangerouslySetInnerHTML={{__html: texts.homepage_info_title_2}}/>
          </FadeInSection>
          {businessData.map((item, i)=>
            <FadeInSection key={"info"+i}>
              <BusinessInfoCardHomePage image={item.image} title={item.title} text={item.text} i={i}/>
            </FadeInSection>
          )}
          <ViewContain $paddingY={"3%"} $alignItems={"center"}>
            <a href="/business">
              <GreenButton $textTransform={"uppercase"} $width={"54.8rem"} $height={"5.5rem"}>{texts.homepage_business_button}</GreenButton>
            </a>
          </ViewContain>
        </ViewContain>
        <FadeInSection>
          <InfoTitle $textTransform={"uppercase"} dangerouslySetInnerHTML={{__html: texts.homepage_map_title}}/>
        </FadeInSection>
        <MinkaMap {...{countries, lang, texts, opportunitiesCount, projectsCount}} />
        <ViewContain $paddingY={"3%"} $alignItems={"center"}>
          <FadeInSection>
            <GreenButton $textTransform={"uppercase"} onClick={()=>navigate("/business?section=projects")} $width={"54.8rem"} $height={"5.5rem"}>{texts.homepage_projects_button}</GreenButton>
          </FadeInSection>
        </ViewContain>
      </ViewContain>
      <ViewContain $paddingY={"5%"}>
        <FadeInSection>
          <InfoTitle $textTransform={"uppercase"}>
            {texts.homepage_last_news}
          </InfoTitle>
        </FadeInSection>
        <FadeInSection>
          <NewsCarouselBanner bannerData={bannerNewsData}/>
        </FadeInSection>
      </ViewContain>
    </>
  )
}

export default HomeIndex