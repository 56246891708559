import React from 'react'
import styled from "styled-components";

const ProjectCard = ({ project, text }) => {
	const regex = /(<([^>]+)>)/ig;
	const projectKeywords = Array.isArray(project?.keywords) ? project?.keywords.join("-") : project?.keywords.split(",").join(" - ") || []

	return (
		<Card onClick={()=>window.location = `/business/${project.id}`}>

			<CardImg src={project.logo || project.image} $withoutLogo={!project.logo} alt="image"/>
			<CardInfoCont>
				<CardTitle>{project.title}</CardTitle>
				<CardDescription>{(project?.description.replace(/<br>/gi, "").substring(0, 150) + "...").replace(regex, '')}</CardDescription>
				<CardKeyWords>
					<CardKeyWordsTitle>{text.business_project_card_keywords}:</CardKeyWordsTitle>
					{projectKeywords}
				</CardKeyWords>
			</CardInfoCont>
			
		</Card>
	)
}

export default ProjectCard

//Styles
const Card = styled.div`
  display: flex;
	position: relative;
	flex-direction: column;
	width: 36.7rem;
	height: 67.8rem;
	cursor: pointer;
	font-family: var(--Roboto);
`
const CardImg = styled.img`
  height: 36.6rem;
	padding: .5rem;
  overflow: hidden; 
  display:flex; 
  object-fit: ${({$withoutLogo})=>$withoutLogo?"cover":"contain"};
	border: 1px solid rgba(0, 0, 0, 0.40);
`
const CardInfoCont = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  background-color: var(--grey1);
	color: var(--grey2);
  padding: 7%;
  overflow-y: auto;
  font-size: 1.5rem;
  font-weight: 300;
  position: relative;
`
const CardTitle = styled.h3`
	font-size: 2.5rem;
  font-weight: 400;
	margin: 1rem;
	text-transform: uppercase;
	text-align: center;
	font-family: var(--BarlowCondensed);
`
const CardDescription = styled.div`
	font-size: 1.5rem;
	font-weight: 300;
`
const CardKeyWords = styled.div`
  display: flex;
  margin: 5% 0;
	flex-direction: column;
`
const CardKeyWordsTitle = styled.div`
	text-transform: uppercase;
`