import React from "react"
import { ViewContain } from "../../_components/ViewContain.jsx"
import InfoGreyCard from "./InfoGreyCard.jsx"

const Layout = ({ data, c, form, selectedForm, saveForm, changeForm}) => (
  <ViewContain $gap={"5rem"}>
    {data.map((item, i) =>
      <InfoGreyCard {...{i, c, item, form, selectedForm, saveForm, changeForm}} key={"card"+i}>
        {item.component}
      </InfoGreyCard>
    )}
  </ViewContain>
)

export default Layout