import React from 'react'
import styled from 'styled-components'

const DownIcon = ({ width = "3.9rem", height = "1.5rem", color, rotate, backButton }) => {
  return (
    <Icon width={width} height={height} viewBox="0 0 39 15" fill={color || "white"} xmlns="http://www.w3.org/2000/svg" $rotate={rotate} $backButton={backButton}>
      <path id="Polygon 1" d="M19.5 15L0 -9.53674e-07L19.5 -9.53674e-07L39 -9.53674e-07L19.5 15Z"/>
    </Icon>
  )
}

const Icon = styled.svg`
  transform: rotate(${({$rotate, $backButton})=> $backButton ? "90" : $rotate ? "180" : "360"}deg);
  transition: transform 0.5s;
` 

export default DownIcon