import React, { useState, useContext, useEffect } from "react";
import styled from "styled-components";
import { translations } from "../../../lib/Language";
import api from "../../../lib/api";
import { ViewContain } from "../../_components/ViewContain";
import { InfoSubtitle } from "../_components/InfoSubtitle";
import LoginField from "./_components/LoginField";
import LoadingBars from "../../_components/LoadingBars";
import { GreenButton } from "../../_components/GreenButton";
import { InfoTitle } from "../../_components/InfoTitle";

// Change for setups
const configuration = {

  no_match_passwords: "no_match",
  password_empty: "password_empty",
  password_short: "password_short",
  password_confirmation_empty: "password_confirmation_empty",
  invalid_email: "invalid_email",
  first_name_empty: "first_name_empty",
  last_name_empty: "last_name_empty",
  phone_empty: "phone_empty",
  post_empty: "post_empty",
  country_empty: "country_empty",
  check_information: "check_information",
  check_policy: "check_policy",

  fields: {
    first_name: "",
    last_name: "",
    email: "",
    password: "",
    password_confirmation: "",
    language: "",
    phone: "",
    post: "",
    country: "",
  }
};

const regexEmailValidation = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/

const Signin = ({ authenticity_token, t, lang }) => {
  const countries = translations[lang].general.country;
  
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState(configuration.fields);
  const [signinView, setSigninView] = useState(true)
  
  const [confirmInfo, setConfirmInfo] = useState(false);
  const [confirmPolicy, setConfirmPolicy] = useState(false);

  const initialCountries = [{value: "ES", option: t.form_country_spain}, {value: "CO", option: t.form_country_colombia}, {value: "MX", option: t.form_country_mexico}, {value: "US", option: t.form_country_unitedStates}, {value: "FR", option: t.form_country_france}]
  const countryOptions = Object.entries(countries).map(country=>({value:country[0], option: country[1]}));

  const languageOptions = [
    { value: "es-es", option: "Español" },
    { value: "en-us", option: "English" },
    { value: "fr-fr", option: "Français" },
  ]
  
  const callError = (msg) => {
    alert(msg);
    setLoading(false);
  };

  const handleInputs = (e) => {
    let value =
      e.target.type === "checkbox" ? e.target.checked : e.target.value;
    if (value === undefined || value === null) {
      value = e.target.getAttribute("value");
    }
    if (value === "true") value = true;
    if (value === "false") value = false;
    const keys = e.target.name.split(".");
    setData((prev) => {
      return { ...prev, [keys[0]]: value };
    });
  };

  const validate = () => {
    if (!regexEmailValidation.test(data.email)) { 
      callError(t[configuration.invalid_email]); 
      return false; 
    }
    if (data.password.length < 8) {
      callError(t[configuration.password_short]);
      return false;
    }
    if (data.password_confirmation !== data.password) {
      callError(t[configuration.no_match_passwords]);
      return false;
    }
    if (!confirmInfo) {
      callError(t[configuration.check_information]);
      const inputCheck = document.getElementById("formConfirmInfo");
      inputCheck.focus();
      return false;
    }
    if (!confirmPolicy) {
      callError(t[configuration.check_policy]);
      const inputCheck = document.getElementById("formConfirmPolicy");
      inputCheck.focus();
      return false;
    }
    return true;
  };

  const processResponse = (res) => {
    if (!res.success || res.success === false) {
      callError(t[res.response]);
    } else {
      setSigninView(false)
      let messageContent = document.getElementById("welcome_message")
      messageContent.scrollIntoView({ behavior: "smooth", block: "center" });
    }
  };

  const register = () => {
    const information = {
      user: { ...data, is_active: false },
      authenticity_token,
    };
    api.register(information, processResponse);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    /* Validations */
    if (!validate()) return;
    /* After Validations */
    setLoading(true);
    /* API CALLS */
    register();
  };

  useEffect(() => { // Ajusta la altura de la vista al contenido del formulario
    if (!window) return
    let relativeContainer = document.getElementById("signin_view");
    let absoluteContainer = document.getElementById("signin_form");

    const adjustParentHeight = () => {
      let absoluteHeight = absoluteContainer.offsetHeight;
      relativeContainer.style.height = absoluteHeight + 'px';
    }

    adjustParentHeight();

    window.addEventListener('resize', adjustParentHeight);
    return () => {
      if (!window) return
      window.removeEventListener('resize', adjustParentHeight);
    }
  }, [])
  

  return (
    <SigninViewCont id="signin_view">
      <SigninContainer>
        <SigninSliderCont $signinView={signinView}>
          <SigninView>
            <SigninForm id="signin_form" onSubmit={handleSubmit}>
              <ViewContain>
                <ViewContain $alignItems={"center"} $paddingY={"5%"}>
                  <InfoSubtitle style={{fontFamily: "var(--BarlowCondensed)", fontWeight: "400", color: "var(--black2)", textTransform: "uppercase"}}>{t.login_title}</InfoSubtitle>
                </ViewContain>
                <SigninFormCont>
                  <LoginField name="first_name" type="text" label={t.form_name} value={data.first_name} onChange={handleInputs} autoComplete={"given-name"} required/>
                  <LoginField name="last_name" type="text" label={t.form_lastName} value={data.last_name} onChange={handleInputs} autoComplete={"family-name"} required/>
                  <LoginField name="email" type="email" label={t.form_email} value={data.email} onChange={handleInputs} autoComplete={"email"} required/>
                  <LoginField name="phone" type="phone" label={t.form_mobile} value={data.phone} onChange={handleInputs} autoComplete={"tel"} required/>
                  <LoginField name="post" type="text" label={t.form_post} value={data.post} onChange={handleInputs} autoComplete={"organization-title"} required/>
                  <LoginField name="country" type="select" label={t.form_country} value={data.country || ""} onChange={handleInputs} initialOptions={initialCountries} options={countryOptions} include_blank required/>
                  <LoginField name="password" type="password" label={t.form_password} value={data.password} onChange={handleInputs} minLength={8} autoComplete={"new-password"} required/>
                  <LoginField name="password_confirmation" type="password" label={t.form_confirm_password} value={data.password_confirmation} onChange={handleInputs} minLength={8} autoComplete={"new-password"} required/>
                  <SigninFormBottom>
                    <LoginField name="language" type="select" label={t.form_language} value={data.language || lang} onChange={handleInputs} options={languageOptions} include_blank required/>
                    <SigninConfirmation>
                      <input id="formConfirmInfo" type="checkbox" name="confirmInfo" checked={confirmInfo} onChange={() => setConfirmInfo(!confirmInfo)} required/>
                      <div style={{fontFamily: "var(--BarlowCondensed)"}}>{t.acept_information}</div>
                    </SigninConfirmation>
                    <SigninConfirmation>
                      <input id="formConfirmPolicy" type="checkbox" name="confirmPolicy" checked={confirmPolicy} onChange={() => setConfirmPolicy(!confirmPolicy)} required/>
                      <div style={{fontFamily: "var(--BarlowCondensed)"}}>
                        {t.acept_policy}{" "}
                        <a href="/privacy" rel="noopener noreferrer" target="_blank" >
                          <span style={{fontWeight: "600"}}>{t.acept_policy_link} </span>
                        </a>{" "}
                        {t.acept_policy_minka}{" "}
                      </div>
                    </SigninConfirmation>
                  </SigninFormBottom>
                </SigninFormCont>
                <ViewContain $alignItems={"flex-end"}>
                  <SigninButtonCont>
                    {loading ?
                    <LoadingBars/>
                    :
                    <GreenButton type="submit" $width={"19.7rem"} $height={"4rem"} >{t.form_button}</GreenButton>}
                  </SigninButtonCont>
                </ViewContain>
              </ViewContain>
            </SigninForm>
          </SigninView>
          <SigninView>
            <ViewContain id="welcome_message" $alignItems={"center"}>
              <InfoTitle style={{fontFamily: "var(--BarlowCondensed)", fontWeight: "400", color: "var(--black2)", textTransform: "uppercase"}}>{t.signin_message_welcome}</InfoTitle>
              <ViewContain $alignItems={"center"} $paddingY={"5%"}>
                <InfoSubtitle style={{fontFamily: "var(--BarlowCondensed)", fontWeight: "400", color: "var(--black2)"}}>{t.signin_message_text}</InfoSubtitle>
              </ViewContain>
              <SigninLink href="/" style={{fontFamily: "var(--BarlowCondensed)", fontWeight: "400", color: "var(--black2)", textDecoration: "underline"}}>{t.signin_message_back_button}</SigninLink>
            </ViewContain>
          </SigninView>
        </SigninSliderCont>
      </SigninContainer>
    </SigninViewCont>
  );
};

const SigninViewCont = styled.div`
  position: relative;
`
const SigninContainer = styled.div`
  position: absolute;
  top: 0; 
  left: 50%;
  width: 100vw;
  height: 100%;
  overflow: hidden;
  transform: translate(-50%, 0);
  background-color: #D9D9D9;
`
const SigninSliderCont = styled.div`
  position: relative;
  display: flex;
  transition: transform 1s ease-in-out;
  transform: translateX(${({$signinView}) => $signinView ? "0" : "-100"}%);
  height: 100%;
`
const SigninView = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  flex-shrink: 0;
  padding: 0 10%;
  `
const SigninForm = styled.form`
  max-width: 1024px;
  width: 100%;
  padding: 5% 0;
`
const SigninFormCont = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  column-gap: 4rem;
  padding: 0 0 5% 0;
  @media (max-width: 768px) {
    grid-template-columns: auto;
  }
`
const SigninButtonCont = styled.div`
  height: 80px;
  display: flex;
  align-items: center;
`
const SigninFormBottom = styled.div`
  grid-column: 1 / 3;
  @media (max-width: 768px) {
    grid-column: auto;
  }
`
const SigninConfirmation = styled.div`
  display: flex;
  align-items: center;
  margin-top: 3rem;
  font-size: 2.5rem;
  
  input[type="checkbox"]{
    margin-right: 2rem;
    width: 3rem;
    height: 3rem;
    border: none !important;
  }
  div{
    flex: 1;
  }
  
  @media only screen and (orientation: portrait) {
    font-size: 3rem;
  }
` 
const SigninLink = styled.a`
  font-family: var(--BarlowCondensed);
  font-size: 2rem;
  font-weight: 500;
  text-transform: uppercase;
  cursor: pointer;
`

export default Signin;