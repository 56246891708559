import React, { useContext } from "react";
import Layout from "../../_components/Layout";
import { LangContext, translations } from "../../../../lib/Language";
import ViewField from "../../_components/ViewField";
import InfoOrganization from "./InfoOrganization";
import { GreyButton } from "../../../_components/GreyButton";
import { ViewContain } from "../../../_components/ViewContain";

const OrganizationView = ({object, selectors, dispatch, getSelector}) =>{
  const l = useContext(LangContext)
  const f = translations[l].forms.organization
	const c = translations[l].forms.controls
  const t = translations[l].organizations

  const changeView = () => {
    dispatch({type:"form", selectedForm: 0})
  }

  const common = {object, f, c, t, selectors, getSelector}
  const props = {
    data: [
      {title: t.information, component: <Data {...common} changeView={changeView}/>},
      {title: t.adicional, component: <Adicional {...common}/>},
      {title: t.sostenibilidad, component:  <Sostenibilidad {...common} />},
			{title: t.role, component: <Role {...common}/>}
    ],
    c
  }
  return (
    <>
      <ViewContain style={{ marginTop: "2%", marginBottom: "2%", paddingTop: "2%", paddingBottom: "2%", position: "sticky", top: 0, backgroundColor: "#fff", zIndex: 10}}>
        <GreyButton $width={"27.7rem"} $height={"3.9rem"} onClick={changeView} $textTransform={"uppercase"} type='button'>{c.editar}</GreyButton>
      </ViewContain>
      <ViewContain style={{paddingBottom: "5rem"}}>
        <Layout {...props}/>
      </ViewContain>
    </>
  )
}

export default OrganizationView

const Data = (props) => <>
  <InfoOrganization org={props.object}/>
  <ViewField {...props} t="text" field="nif" label="Nif" orgInfo/>
  <ViewField {...props} t="sector" field="sector" orgInfo/>
  <ViewField {...props} t="selector" field="otype" label={props.f.type} secondary="type" orgInfo/>
  <ViewField {...props} t="selector" field="age" label={props.f.age} orgInfo/>
  <ViewField {...props} t="selector" field="billing"  label={props.f.billing} orgInfo/>
  <ViewField {...props} t="selector" field="employees"  label={props.f.employees} orgInfo/>
</>

const Adicional = (props)=>(
  <>
    <ViewField {...props} t="list" field="pr"  label={props.f.pr}/> 
    <ViewField {...props} t="listPercentage" field="act" label={props.f.act}/> 
  </>
)

const Sostenibilidad = (props)=> 
<>
  <ViewField {...props} t="selector" field="sustainability" label={props.f.sustainability}/>
  <ViewField {...props} t="list" field="sus" label={props.f.sus}/> 
</>

const Role = (props)=> 
<>
  <ViewField {...props} t="selector" field="role" label={props.f.role}/>
  <ViewField {...props} t="selector" field="department" label={props.f.department}/>
  <ViewField {...props} t="boolean" field="representation" label={props.f.representation}/>
</>