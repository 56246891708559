import React, { useState, useEffect } from "react";
import ImpactMap from "./ImpactMap";
import MapIndicators from "./MapIndicators";

import api from "../../../lib/api";
import { pinCoordinates } from "../../../lib/data";
import FadeInSection from "../../_components/FadeInSection";
import CountrySelector from "./CountrySelector";
import styled from "styled-components";

function MinkaMap({ countries, texts, lang, opportunitiesCount, projectsCount }) {
  const [selectedCountry, setSelectedCountry] = useState("CO");
  const [ops, setOps] = useState(opportunitiesCount)
  const [projects, setProjects] = useState(projectsCount)

  const changeCountry = (countryCode) => {
    setSelectedCountry(countryCode);
    api.getFilteredMap(countryCode, (r) =>{
      if(r.status){
        setOps(r.ops)
        setProjects(r.pro)
      }
    })
  };

	// const countries2 = [ "BR", "PY", "BO", "CL", "AR", "UY"]

  return (
    <MinkaMapCont>
      <FadeInSection>
        <CountrySelector
          countries={countries}
          selectCountry={(countryCode) => changeCountry(countryCode)}
          iconCountry={selectedCountry}
          lang={lang}
          texts={texts}
        />
        <ImpactMap selectedCountry={selectedCountry} pins={pinCoordinates} countries={countries} />
      </FadeInSection>
      <MapIndicators
        numOpportunity={ops}
        numProjects={projects}
        opportunityText={texts.homepage_map_opportunities}
        opportunityTextSingular={texts.homepage_map_opportunities_singular}
        projectsText={texts.homepage_map_projects}
        projectsTextSingular={texts.homepage_map_projects_singular}
      />
    </MinkaMapCont>
  );
}

const MinkaMapCont = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
  align-items: center;
  gap: 5rem;
`

export default MinkaMap;
