import React from 'react'

const CloseIcon = ({ width = "3.9rem", height = "3.9rem", color = "white" }) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width={width} height={height} viewBox="0 0 39 39" fill="none">
      <path d="M9.57837 28.7354L28.7352 9.5785" stroke={color}/>
      <path d="M28.7351 28.7354L9.57826 9.5785" stroke={color}/>
    </svg>
  )
}

export default CloseIcon