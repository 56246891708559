import React from 'react'
import SectionContact from './_components/SectionContact'
import styled from 'styled-components'

import linkedin_icon from '../../../images/svg/linkedin_icon.svg'
import x_icon from '../../../images/icons/x_icon.webp'
import instagram_icon from '../../../images/svg/instagram_icon.svg'
import facebook_icon from '../../../images/svg/fecebook_icon.svg'
import youtube_icon from '../../../images/svg/youtube_icon.svg'

function Footer({ texts }){
	const countries = [texts.footer_colombia, texts.footer_mexico, texts.footer_spain]
	
	let marketPlaceLink 
	if (!process.env.REACT_APP_NODE_ENV || process.env.REACT_APP_NODE_ENV === 'development') marketPlaceLink = "http://marketplace.minka.com:3000" // development environment
	else if (process.env.REACT_APP_NODE_ENV === 'staging') marketPlaceLink = "http://minka-dev.onrender.com/marketplace" // staging environment
	else marketPlaceLink = "https://marketplace.minka-dev.com" // production environment

	return(
		<FooterCont>
			<FooterTop>
				<FooterTopCont>
					<div>
						<FooterTopTitle>{texts.footer_about}</FooterTopTitle>
						<FooterInfo>
							<a href="/services"><span>{texts.footer_consulting}</span></a>
							<a href="/bridge2zero"><span>{texts.footer_service_bridge2zero}</span></a>
							<a href="/business"><span>{texts.footer_business}</span></a>
							<a href={marketPlaceLink}><span>{texts.footer_marketplace}</span></a>
							<a href="/about_us"><span>{texts.footer_about_us}</span></a>
							<a href="/blogentries"><span>{texts.footer_news}</span></a>
						</FooterInfo>
					</div>
					<FooterSocial>
						<SocialIcons>
							<a href="https://www.linkedin.com/company/minkadev/" target="_blank" rel="noopener noreferrer"><img src={linkedin_icon} alt="linkedin" /></a>
							<a href="https://twitter.com/MinkaDev/" target="_blank" rel="noopener noreferrer"><img src={x_icon} alt="x" /></a>
							<a href="https://www.instagram.com/minkadev/" target="_blank" rel="noopener noreferrer"><img src={instagram_icon} alt="instagram" /></a>
							<a href="https://www.facebook.com/MinkaDev/" target="_blank" rel="noopener noreferrer"><img src={facebook_icon} alt="facebook" /></a>
							<a href="https://www.youtube.com/user/MinkaDev" target="_blank" rel="noopener noreferrer"><img src={youtube_icon} alt="youtube" /></a>
						</SocialIcons>
						<span>info@minka-dev.com</span>
					</FooterSocial>
					<div>
						<FooterTopTitle>{texts.footer_where_are_we}</FooterTopTitle>
						<FooterInfo>
							{countries.map((country, i)=>
								<span key={"Country"+i}>{country}</span>
							)}
						</FooterInfo>
					</div>
				</FooterTopCont>
				<SectionContact texts={texts}/>
			</FooterTop>
			<FooterBottom>
				<div>{texts.footer_rights}</div>
				<a href="/privacy" target="_blank" rel="noopener noreferrer">{texts.footer_privacy}</a>
			</FooterBottom>
		</FooterCont>
	)
}

const FooterCont = styled.footer`
	width: 100%;
  height: auto;
  background-color: var(--white2);

	li, span{
		font-family: var(--Roboto);
    font-weight: 300;
    font-size: 2.2rem;
    color:var(--darkPurple)
	}
`

const FooterTop = styled.div`
	display: grid;
  grid-template-columns: 1fr 45%;
  max-width: 1520px;
  margin: auto;
  padding: 2% 10% 3%;

	@media (max-width: 800px) {
    grid-template-columns: auto;
    padding: 2% 10% 3%;
	}
`

const FooterTopCont = styled.div`
  display: grid;
	grid-template-columns: repeat(2, 1fr);

	@media (max-width: 500px) {
    grid-template-columns: auto;
    place-items: center;
    text-align: center;
	}
`

const FooterTopTitle = styled.h3`
  font-family: var(--BarlowCondensed);
  font-size: 4rem;
  font-weight: 500;
`

const FooterInfo = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  gap: 2rem;
`

const FooterSocial = styled.div`
  display: flex;
  flex-direction: column;
  gap: 3rem;
  grid-row: 3;
  grid-column: 1 / 3;
  margin: 2rem 0 0;

	@media (max-width: 500px) {
    grid-row: auto;
    grid-column: auto;
	}
`

const SocialIcons = styled.div`
  display: flex;
  gap: 1rem;

	img{
		height: 6.4rem;
		width: 6.4rem;
	}
`

const FooterBottom = styled.div`
  display: flex;
  justify-content: space-evenly;
  height: 65px;
  align-items: center;
  background-color: white;
	color: var(--darkPurple);
`

export default Footer;